var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/* eslint-disable react-hooks/rules-of-hooks */
import { apiURI } from 'apiConfig';
import EmptyImage from '../imgs/emptyImage.jpg';
import { getUser } from '../auth/userManager';
export var RequestDataType;
(function (RequestDataType) {
    RequestDataType[RequestDataType["FormData"] = 0] = "FormData";
    RequestDataType[RequestDataType["Entity"] = 1] = "Entity";
})(RequestDataType || (RequestDataType = {}));
function createFormData(data) {
    var formDataToSend = new FormData();
    Object.entries(data).forEach(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        if (value) {
            if (key === 'address' && typeof value === 'object') {
                var addressObj = value;
                Object.entries(addressObj).forEach(function (_a) {
                    var _b = __read(_a, 2), addressKey = _b[0], addressValue = _b[1];
                    formDataToSend.append("address.".concat(addressKey), addressValue);
                });
            }
            else {
                formDataToSend.append(key, value);
            }
        }
    });
    //// Just for debugging
    //for (const [key, value] of formDataToSend.entries()) {
    //    console.log(key, value);
    //}
    return formDataToSend;
}
export var useAccessToken = function () {
    var user = getUser();
    var token = user === null || user === void 0 ? void 0 : user.access_token;
    return token;
};
export var getRequest = function (routingPath) { return __awaiter(void 0, void 0, void 0, function () {
    var accessToken, response, result, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                accessToken = useAccessToken();
                return [4 /*yield*/, fetch("".concat(apiURI).concat(routingPath), {
                        headers: {
                            Authorization: "Bearer ".concat(accessToken),
                        },
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                result = _a.sent();
                return [2 /*return*/, result];
            case 3:
                e_1 = _a.sent();
                console.error(e_1);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var getImageRequest = function (routingPath) { return __awaiter(void 0, void 0, void 0, function () {
    var accessToken, response, result, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                accessToken = useAccessToken();
                return [4 /*yield*/, fetch("".concat(apiURI).concat(routingPath), {
                        headers: {
                            Authorization: "Bearer ".concat(accessToken),
                        },
                    })];
            case 1:
                response = _a.sent();
                if (response.status === 404) {
                    return [2 /*return*/, EmptyImage];
                }
                if (!response.ok && response.status !== 404) {
                    throw new Error('Failed to fetch image');
                }
                return [4 /*yield*/, response.blob()];
            case 2:
                result = _a.sent();
                return [2 /*return*/, URL.createObjectURL(result)];
            case 3:
                error_1 = _a.sent();
                console.error('Error fetching the image:', error_1);
                return [2 /*return*/, EmptyImage];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var getFileRequest = function (routingPath) { return __awaiter(void 0, void 0, void 0, function () {
    var accessToken, response, result, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                accessToken = useAccessToken();
                return [4 /*yield*/, fetch("".concat(apiURI).concat(routingPath), {
                        headers: {
                            Authorization: "Bearer ".concat(accessToken),
                        },
                    })];
            case 1:
                response = _a.sent();
                if (response.status === 404) {
                    console.error('File not found');
                    return [2 /*return*/, ""];
                }
                if (!response.ok) {
                    throw new Error('Failed to fetch the file');
                }
                return [4 /*yield*/, response.blob()];
            case 2:
                result = _a.sent();
                return [2 /*return*/, URL.createObjectURL(result)];
            case 3:
                error_2 = _a.sent();
                console.error('Error fetching the 3D file:', error_2);
                return [2 /*return*/, ""];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var postRequest = function (routingPath, data, requestDataType) { return __awaiter(void 0, void 0, void 0, function () {
    var accessToken, dataBody, headers, response, result, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                accessToken = useAccessToken();
                dataBody = (requestDataType == RequestDataType.Entity) ? JSON.stringify(data) : createFormData(data);
                headers = {
                    Authorization: "Bearer ".concat(accessToken),
                };
                if (requestDataType === RequestDataType.Entity) {
                    headers['Content-Type'] = 'application/json';
                }
                return [4 /*yield*/, fetch("".concat(apiURI).concat(routingPath), {
                        method: 'POST',
                        headers: headers,
                        body: dataBody,
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                result = _a.sent();
                if (response.ok) {
                    return [2 /*return*/, {
                            result: result,
                            statusCode: response.status,
                        }];
                }
                else {
                    console.warn(response);
                    console.warn("POST request failed");
                    return [2 /*return*/, {
                            result: "",
                            statusCode: response.status,
                        }];
                }
                return [3 /*break*/, 4];
            case 3:
                error_3 = _a.sent();
                console.error(error_3);
                return [2 /*return*/, {
                        result: "",
                        statusCode: 400,
                    }];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var putRequest = function (routingPath, data, requestDataType) { return __awaiter(void 0, void 0, void 0, function () {
    var accessToken, dataBody, headers, response, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                accessToken = useAccessToken();
                dataBody = (requestDataType == RequestDataType.Entity) ? JSON.stringify(data) : createFormData(data);
                headers = {
                    Authorization: "Bearer ".concat(accessToken),
                };
                if (requestDataType === RequestDataType.Entity) {
                    headers['Content-Type'] = 'application/json';
                }
                return [4 /*yield*/, fetch("".concat(apiURI).concat(routingPath), {
                        method: 'PUT',
                        headers: headers,
                        body: dataBody,
                    })];
            case 1:
                response = _a.sent();
                if (response.ok) {
                    return [2 /*return*/, {
                            statusCode: response.status,
                        }];
                }
                else {
                    console.warn("PUT request failed");
                    return [2 /*return*/, {
                            statusCode: response.status,
                        }];
                }
                return [3 /*break*/, 3];
            case 2:
                error_4 = _a.sent();
                console.error(error_4);
                return [2 /*return*/, {
                        statusCode: 400,
                    }];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var deleteRequest = function (routingPath, entityId) { return __awaiter(void 0, void 0, void 0, function () {
    var accessToken, response, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                accessToken = useAccessToken();
                return [4 /*yield*/, fetch("".concat(apiURI).concat(routingPath, "/").concat(entityId), {
                        method: 'DELETE',
                        headers: {
                            Authorization: "Bearer ".concat(accessToken),
                            'Content-Type': 'application/json',
                        }
                    })];
            case 1:
                response = _a.sent();
                if (response.ok) {
                    return [2 /*return*/, {
                            statusCode: response.status,
                        }];
                }
                else {
                    console.warn("Delete request failed");
                    return [2 /*return*/, {
                            statusCode: response.status,
                        }];
                }
                return [3 /*break*/, 3];
            case 2:
                error_5 = _a.sent();
                console.error(error_5);
                return [2 /*return*/, {
                        statusCode: 400,
                    }];
            case 3: return [2 /*return*/];
        }
    });
}); };
