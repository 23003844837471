var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Card, Icon } from '@metsooutotec/modes-react-components';
import React from 'react';
import { itemViewDetailsContainerStyles } from '../components/common-style/common-style';
var tableContainerStyles = __assign(__assign({}, itemViewDetailsContainerStyles), { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '91%' });
var iconAndTextStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5em',
};
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { hasError: false };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function (error) {
        return { hasError: true, error: error };
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        console.error('ErrorBoundary caught an error', error, errorInfo);
    };
    ErrorBoundary.prototype.render = function () {
        var _a;
        if (this.state.hasError) {
            return (_jsx("div", __assign({ style: tableContainerStyles }, { children: _jsx(Card, { collapsible: true, content: _jsxs("p", { children: ["Error: ", (_a = this.state.error) === null || _a === void 0 ? void 0 : _a.toString()] }), header: _jsxs("span", __assign({ style: iconAndTextStyle }, { children: [_jsx(Icon, { color: "red", name: "icon-alarm-o-24" }, "error-boundary"), _jsx("h4", __assign({ style: { display: 'contents' } }, { children: "Something went wrong!" }))] })) }) })));
        }
        return this.props.children;
    };
    return ErrorBoundary;
}(React.Component));
export default ErrorBoundary;
