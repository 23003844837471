var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, Icon, Tab, Table, ToggleSwitch } from "@metsooutotec/modes-react-components";
import { useCallback, useState } from "react";
import { provisionTypeToIcon } from "components/provisions/provisions-utils/provision-events-icon";
import { ProvisionTypes } from "models/provisionsModuleModels";
var NoElementsRender = function (_a) {
    var type = _a.type;
    return (_jsxs("div", __assign({ style: { display: 'flex', alignItems: 'center', gap: 20, margin: 20 } }, { children: [_jsx("div", { children: _jsx(Icon, { name: "icon-info-16" }) }), _jsxs("div", { children: ["There are no ", type, " added to decommission yet for this asset section. Select ", type, " by clicking them in the SVG."] })] })));
};
export var SidebarSelection = function (_a) {
    var selectable = _a.selectable, selected = _a.selected, onSelectElement = _a.onSelectElement;
    var _b = __read(useState(true), 2), isLinersTabSelected = _b[0], setIsLinersTabSelected = _b[1];
    var _c = __read(useState(false), 2), showDecommissioned = _c[0], setShowDecommissioned = _c[1];
    // Eww - relies on other component loading...
    // const { provisionEventsDetail } = provisionEventStore;
    // const provisionEvent = provisionEventsDetail.filter(pe => pe.id === eventId)[0]
    var listElements = [];
    // if (provisionEvent) {
    {
        if (isLinersTabSelected) {
            var decomliners_1 = selected.liners.map(function (l) { return l.id.toLocaleLowerCase(); });
            listElements = selectable.liners.map(function (l) {
                var llReference = l.linerLocation.reference;
                var linerName = l.name;
                return {
                    id: l.id,
                    value: "".concat(linerName, " - ").concat(llReference),
                    decommissioned: decomliners_1.includes(l.id.toLocaleLowerCase()),
                    element: l,
                    type: "Liner"
                };
            });
        }
        else {
            var decomsensors_1 = selected.sensors.map(function (l) { return l.id.toLocaleLowerCase(); });
            listElements = selectable.sensors.map(function (s) {
                var lrName = s.linerRegion.name;
                var sensorName = s.name;
                return {
                    id: s.id,
                    value: "".concat(lrName, " - ").concat(sensorName),
                    decommissioned: decomsensors_1.includes(s.id.toLocaleLowerCase()),
                    element: s,
                    type: "Sensor"
                };
            });
        }
    }
    var possibleListElements = listElements.filter(function (le) {
        if (showDecommissioned) {
            return le.decommissioned;
        }
        return true;
    });
    var selectAllMode = "disabled";
    var renderListElements = possibleListElements.map(function (le) {
        var icon = provisionTypeToIcon(le.decommissioned ? ProvisionTypes.Decommission : ProvisionTypes.Commission);
        var disabled = !isLinersTabSelected && selected.liners.findIndex(function (l) { return l.id === le.element.linerRegion.linerId; }) > -1;
        if (!disabled && selectAllMode === 'disabled') {
            selectAllMode = 'deselect';
        }
        if (!disabled && le.decommissioned) {
            selectAllMode = 'select';
        }
        return (_jsx(Table.Row, __assign({ disabled: disabled, selected: le.decommissioned, style: { height: 40 } }, { children: _jsxs(Table.Cell, { children: [icon, _jsx("span", __assign({ style: { marginLeft: 40 } }, { children: le.value }))] }) }), le.id));
    });
    var onSelectClick = useCallback(function () {
        var allD1 = possibleListElements.filter(function (le) {
            var disabled = !isLinersTabSelected && selected.liners.findIndex(function (l) { return l.id === le.element.linerRegion.linerId; }) > -1;
            return !disabled;
        });
        var allD2 = allD1.filter(function (le) {
            if (selectAllMode === 'deselect') {
                return !le.decommissioned;
            }
            return le.decommissioned;
        });
        var allD3 = allD2.map(function (le) {
            return {
                type: le.type,
                id: le.element.id
            };
        });
        allD3.forEach(function (a) { return onSelectElement({ type: a.type, id: a.id }); });
    }, [possibleListElements, selectAllMode]);
    return (_jsx(Card, { className: "with-heading width50", headerClassName: "tab-align-in-card-header", contentClassName: "list-align-in-card-content", "data-cy": "card", header: _jsx("div", __assign({ style: { width: '100%' } }, { children: _jsx(Tab, { as: "div", "data-cy": "tab", activeIndex: isLinersTabSelected ? 0 : 1, menu: {
                    pointing: true,
                    secondary: true
                }, style: { width: '100%' }, panes: [{ menuItem: "Liners" }, { menuItem: "Sensors" }], onTabChange: function (e, d) {
                    var nextIndex = Number(d.activeIndex);
                    setIsLinersTabSelected(nextIndex === 0);
                } }) })), content: _jsxs("div", __assign({ style: { width: '100%' } }, { children: [_jsx(ToggleSwitch, { "data-cy": "toggle-switch", label: "Show decommissioned", checked: showDecommissioned, onChange: function (e, d) {
                        setShowDecommissioned(d.checked || false);
                    }, style: { margin: 10 } }), _jsx("div", __assign({ style: { display: 'inline', marginLeft: 20 } }, { children: _jsxs(Button, __assign({ disabled: selectAllMode === 'disabled', onClick: onSelectClick, subtle: true }, { children: [selectAllMode === 'deselect' ? "Deselect" : "Select", " all"] })) })), _jsx(Table, __assign({ "data-cy": "table", hoverable: true, selectable: true, onSelectRow: function (row) {
                        var _a = listElements[row], type = _a.type, element = _a.element;
                        onSelectElement({ type: type, id: element.id });
                    } }, { children: _jsx(Table.Body, { children: renderListElements.length > 0 ? renderListElements : _jsx(NoElementsRender, { type: isLinersTabSelected ? "liners" : 'sensors' }) }) }))] })) }));
};
