var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export function groupSensorBatchesStockManagement(sensorBatches) {
    try {
        var stockManagementEntries_1 = [];
        var partNumbers = new Set(sensorBatches.map(function (s) { var _a; return (_a = s.hardwareVariant) === null || _a === void 0 ? void 0 : _a.trim(); }).filter(function (p) { return p; }));
        partNumbers.forEach(function (p) {
            var onHand = getOnHandSensors(sensorBatches, p);
            var onOrder = getOnOrderSensors(sensorBatches, p);
            var allocated = getAllocatedSensors(sensorBatches, p);
            var dispatched = getDispatched(sensorBatches, p);
            var stockEntry = {
                id: p,
                partNumber: p,
                onHand: onHand.length,
                onOrder: onOrder.length,
                allocated: allocated.length,
                available: onHand.length - allocated.length,
                dispatched: dispatched.length,
                ableToCommit: CalculateAbleToCommitSensors(onHand.length, onOrder.length, allocated.length),
            };
            stockManagementEntries_1.push(stockEntry);
        });
        return stockManagementEntries_1;
    }
    catch (e) {
        console.log("Something went wrong while groupSensorBatchesStockManagement:", e);
        return [];
    }
}
export function getOnHandSensors(sensorBatches, parNumber) {
    //      On Hand value is calculated by retrieving all the sensors that are in the database that have the following
    //      Commissioning and Decommissioning ID should be NULL ;
    //      Manufactured Flag should be TRUE
    //      FAULT should be empty.
    if (!Array.isArray(sensorBatches) || sensorBatches.length < 1 || !parNumber)
        return [];
    var filteredSensorBatches = sensorBatches.filter(function (s) { var _a; return ((_a = s.hardwareVariant) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === parNumber.toLowerCase(); });
    var onHandSensors = [];
    filteredSensorBatches.forEach(function (batch) {
        if (Array.isArray(batch.sensors)) {
            var onHandInBatch = batch.sensors.filter(function (s) {
                return s.isManufactured &&
                    !s.dispatchDate &&
                    !s.commissionId &&
                    !s.decommissionId &&
                    !s.fault;
            });
            onHandSensors.push.apply(onHandSensors, __spreadArray([], __read(onHandInBatch), false));
        }
    });
    // Sort the onHandSensors array by name
    onHandSensors.sort(function (a, b) { return a.serialNumber.localeCompare(b.serialNumber); });
    return onHandSensors;
}
export function getAllocatedSensors(sensorBatches, partNumber) {
    // Allocated value is calculated from all sensors that:
    // Commissioning and Decommissioning ID should be NULL
    // AllocatedState should be true.
    // FAULT should be empty.
    // DispatchDate ==null.
    if (!Array.isArray(sensorBatches) || sensorBatches.length < 1 || !partNumber)
        return [];
    var filteredSensorBatches = sensorBatches.filter(function (s) { var _a; return ((_a = s.hardwareVariant) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === partNumber.toLowerCase(); });
    var allocatedSensors = [];
    filteredSensorBatches.forEach(function (batch) {
        if (Array.isArray(batch.sensors)) {
            var allocatedInBatch = batch.sensors.filter(function (s) {
                return !s.commissionId &&
                    !s.decommissionId &&
                    s.allocatedState &&
                    !s.fault &&
                    !s.dispatchDate;
            });
            allocatedSensors.push.apply(allocatedSensors, __spreadArray([], __read(allocatedInBatch), false));
        }
    });
    return allocatedSensors;
}
export function getOnOrderSensors(sensorBatches, partNumber) {
    //On Order value is calculated from all sensors that have the Manufactured Flag as FALSE.
    if (!Array.isArray(sensorBatches) || sensorBatches.length < 1 || !partNumber)
        return [];
    var filteredSensorBatches = sensorBatches.filter(function (s) { var _a; return ((_a = s.hardwareVariant) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === partNumber.toLowerCase(); });
    var onOrderSensors = [];
    filteredSensorBatches.forEach(function (batch) {
        if (Array.isArray(batch.sensors)) {
            var onOrderInBatch = batch.sensors.filter(function (s) { return !s.isManufactured; });
            onOrderSensors.push.apply(onOrderSensors, __spreadArray([], __read(onOrderInBatch), false));
        }
    });
    return onOrderSensors;
}
export function getDispatched(sensorBatches, partNumber) {
    //On Order value is calculated from all sensors that have the Manufactured Flag as FALSE.
    if (!Array.isArray(sensorBatches) || sensorBatches.length < 1 || !partNumber)
        return [];
    var filteredSensorBatches = sensorBatches.filter(function (s) { var _a; return ((_a = s.hardwareVariant) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === partNumber.toLowerCase(); });
    var onOrderSensors = [];
    filteredSensorBatches.forEach(function (batch) {
        if (Array.isArray(batch.sensors)) {
            var onOrderInBatch = batch.sensors.filter(function (s) { return s.dispatchDate; });
            onOrderSensors.push.apply(onOrderSensors, __spreadArray([], __read(onOrderInBatch), false));
        }
    });
    return onOrderSensors;
}
export function CalculateAbleToCommitSensors(onHandLength, onOrderLength, allocatedLength) {
    return onHandLength + onOrderLength - allocatedLength;
}
export function getAbleToCommitSensorsNumber(sensorBatches, partNumber) {
    if (!Array.isArray(sensorBatches) || sensorBatches.length < 1 || !partNumber)
        return 0;
    // Get on-hand, on-order, and allocated sensors
    var onHandSensors = getOnHandSensors(sensorBatches, partNumber);
    var onOrderSensors = getOnOrderSensors(sensorBatches, partNumber);
    var allocatedSensors = getAllocatedSensors(sensorBatches, partNumber);
    // // Combine and deduplicate onHandSensors and onOrderSensors
    //const combinedSensors = [...onHandSensors, ...onOrderSensors]
    //    .filter((sensor, index, self) =>
    //        index === self.findIndex(s => s.id === sensor.id)
    //    );
    // // Filter out allocatedSensors from combinedSensors
    //const availableSensors = combinedSensors.filter(sensor =>
    //    !allocatedSensors.some(allocatedSensor => allocatedSensor.id === sensor.id)
    //);
    /* console.log(partNumber, availableSensors);*/
    return CalculateAbleToCommitSensors(onHandSensors.length, onOrderSensors.length, allocatedSensors.length);
}
