var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CssSVGClasses } from "../editor-utils/editor-types";
import { GetLinerId } from "../editor-utils/id-string-utils";
import { GetLinerLocationInfo } from "./wear-reference-plane-helper";
/**
 * Assigns incremental IDs to references of liner paths within an SVG document and updates a React state with these assignments.
 *
 * This function:
 * 1. Identifies liners from both the input `svgDocument` and `renderedSvg`.
 * 2. Checks if a liner in the `svgDocument` has a reference class (`ws-reference`). If not, it adds this class.
 * 3. Updates the provided React state (`setSelectedLinersRef`) with an object mapping the old liner ID to the new incremental ID.
 *
 * Liners are identified based on their ID containing the term 'liner', not containing 'hole', and having the class 'ws-liner'.
 *
 * @param {Document} svgDocument - The SVG document used as a reference.
 * @param {SVGSVGElement} renderedSvg - The SVG element that is rendered in the DOM.
 * @param {React.Dispatch<React.SetStateAction<LinerIDPair[]>>} setSelectedLinersRef - The React state setter function to update with the new IDs.
 */
export function AssignIncrementalIdsToDrawingReferencesToLiners(linerLocations, svgDocument, renderedSvg, selectedLinersDrawingRef, setSelectedLinersDrawingRef) {
    try {
        var docAllPaths = Array.from(svgDocument.querySelectorAll('path'));
        var allPaths = Array.from(renderedSvg.querySelectorAll('path'));
        var docLiners_1 = docAllPaths.filter(function (path) { return path.classList.contains(CssSVGClasses.WsLiner); });
        var liners = allPaths.filter(function (path) { return path.classList.contains(CssSVGClasses.WsLiner); });
        var updatedLiners_1 = __spreadArray([], __read(selectedLinersDrawingRef), false);
        liners.forEach(function (liner) {
            var _a, _b;
            var linerIdPart = GetLinerId(liner.id);
            if (!linerIdPart)
                return;
            var linerLocationInfo = GetLinerLocationInfo(linerLocations, linerIdPart);
            var docLinerPart = docLiners_1.filter(function (path) { return path.id === linerIdPart; });
            if (!((_a = docLinerPart[0]) === null || _a === void 0 ? void 0 : _a.classList.contains(CssSVGClasses.WsReferenceTemp)))
                docLinerPart[0].classList.add(CssSVGClasses.WsReferenceTemp);
            if (linerIdPart) {
                var existingRefIndex = updatedLiners_1.findIndex(function (entry) { return entry.linerReference === linerIdPart; });
                var newRefDI = "DWPS-".concat(linerIdPart);
                if (existingRefIndex !== -1) {
                    updatedLiners_1[existingRefIndex].newDrawingReferenceName = newRefDI;
                }
                else {
                    updatedLiners_1.push({ linerLocationId: (_b = linerLocationInfo === null || linerLocationInfo === void 0 ? void 0 : linerLocationInfo.id) !== null && _b !== void 0 ? _b : "", linerReference: linerIdPart, newDrawingReferenceName: newRefDI });
                }
            }
        });
        setSelectedLinersDrawingRef(updatedLiners_1);
    }
    catch (e) {
        console.error("Error happen in AssignIncrementalIdsToReferencesToLiners", e);
    }
}
export var GetLinerLocationName = function (linerLocations, pathId) {
    var _a, _b;
    try {
        var linerName_1 = GetLinerId(pathId);
        var backEndWearReferencePlane = (_b = (_a = linerLocations === null || linerLocations === void 0 ? void 0 : linerLocations.find(function (l) { var _a; return ((_a = l.reference) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()) === (linerName_1 === null || linerName_1 === void 0 ? void 0 : linerName_1.toLocaleLowerCase()); })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "";
        if (backEndWearReferencePlane) {
            return backEndWearReferencePlane;
        }
        else {
            return "";
        }
    }
    catch (e) {
        console.error("Error happen in GetLinerLocationName", e);
        return "";
    }
};
