var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, DialogDescription, DialogHeader, Grid, GridRow, useIntl } from "@metsooutotec/modes-react-components";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
;
var AssetSectionEditorCloseModal = observer(function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, navigateTo = _a.navigateTo;
    var formatMessage = useIntl().formatMessage;
    var navigate = useNavigate();
    var labels = {
        close: formatMessage("assetSectionEditor.close"),
        cancel: formatMessage("assetSectionEditor.cancel"),
        header: formatMessage("assetSectionEditorClose.header"),
        description: formatMessage("assetSectionEditor.description"),
    };
    var onClose = function () {
        setIsOpen(false);
        navigate(navigateTo);
    };
    var onCancle = function () {
        setIsOpen(false);
    };
    var renderButtons = function () {
        var buttonsConfig = [
            { label: labels.cancel, onClick: onCancle, primary: false },
            { label: labels.close, onClick: onClose, primary: true },
        ];
        return buttonsConfig.map(function (button, index) {
            var label = button.label, onClick = button.onClick, primary = button.primary;
            var buttonGridStyle = index === 0 ? {
                marginBottom: "var(--size-m)"
            } : {};
            return (_jsx(GridRow, __assign({ style: buttonGridStyle }, { children: _jsx(Button, { onClick: onClick, flexible: true, children: label, centered: true, size: 'medium', primary: primary }) }), label));
        });
    };
    return (_jsx(Grid, { children: _jsxs(Dialog, __assign({ dimmer: true, open: isOpen, size: 'small', style: { maxWidth: "512px" } }, { children: [_jsx(DialogHeader, __assign({ username: '' }, { children: labels.header })), _jsx(DialogDescription, __assign({ style: { marginBottom: "var(--size-xxl)" } }, { children: labels.description })), _jsx("div", { style: { marginBottom: "var(--size-xxl)" } }), renderButtons()] })) }));
});
export default AssetSectionEditorCloseModal;
