var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeAutoObservable, observable, runInAction } from 'mobx';
import { getAssetSectionLinersLocations, putAssetSectionLiners, putLinersLocationWearReferencePlan, putAssetSectionImage, putLinerRegionsHoles, putlinersDrawingReferences } from '../../services/customer-services/asset-section-service';
import customersStore from './customer-store';
var LinersLocationsStore = /** @class */ (function () {
    function LinersLocationsStore() {
        var _this = this;
        this.linersLocationsLoading = false;
        this.loadLinerLocations = function (assetSectionId) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.linersLocationsLoading) {
                            return [2 /*return*/];
                        }
                        runInAction(function () {
                            _this.linersLocationsLoading = true;
                        });
                        return [4 /*yield*/, getAssetSectionLinersLocations(assetSectionId)];
                    case 1:
                        data = _a.sent();
                        runInAction(function () {
                            customersStore.assetSectionMap[assetSectionId].linerLocations = data.map(function (liner) { return observable.object(liner); });
                            data.forEach(function (linerlocation) {
                                customersStore.linerLocationMap[linerlocation.id] = observable.object(linerlocation);
                            });
                            _this.linersLocationsLoading = false;
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        this.GetLinerLocationsByAssetSectionId = function (id) {
            var _a;
            try {
                var assetSection = customersStore.assetSectionMap[id];
                return (_a = assetSection.linerLocations) !== null && _a !== void 0 ? _a : [];
            }
            catch (e) {
                return [];
            }
        };
        this.GetLinerLocationById = function (id) {
            try {
                return customersStore.linerLocationMap[id];
            }
            catch (e) {
                return null;
            }
        };
        this.GetLinerLocationIdByReference = function (assetSectionId, reference) {
            var _a;
            try {
                var linerLocations = customersStore.assetSectionMap[assetSectionId].linerLocations;
                var linerlocation = linerLocations.find(function (l) { return l.reference.toLowerCase() === reference.toLowerCase(); });
                return (_a = linerlocation === null || linerlocation === void 0 ? void 0 : linerlocation.id) !== null && _a !== void 0 ? _a : null;
            }
            catch (e) {
                return null;
            }
        };
        this.GetLinerRegionIdByLinerLocationRef = function (linerLocationId, reference) {
            var _a, _b, _c;
            try {
                var linerLocation = customersStore.linerLocationMap[linerLocationId];
                var linerRegions = (_a = linerLocation.linerRegions) !== null && _a !== void 0 ? _a : [];
                return (_c = (_b = linerRegions.find(function (l) { return l.reference.trim().toLowerCase() == reference.toLowerCase(); })) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : null;
            }
            catch (e) {
                return null;
            }
        };
        this.GetLinerRegionByLinerRegionRef = function (linerLocationId, reference) {
            var _a, _b;
            try {
                var linerLocation = customersStore.linerLocationMap[linerLocationId];
                var linerRegions = (_a = linerLocation.linerRegions) !== null && _a !== void 0 ? _a : [];
                return (_b = linerRegions.find(function (l) { return l.reference.trim().toLowerCase() === reference.toLowerCase(); })) !== null && _b !== void 0 ? _b : null;
            }
            catch (e) {
                return null;
            }
        };
        this.GetLinerRegionIdByReference = function (linerLocationId, reference) {
            var _a;
            try {
                var linerlocations = customersStore.linerLocationMap[linerLocationId].linerRegions;
                var linerRegion = linerlocations.find(function (l) { return l.reference.toLowerCase() === reference.toLowerCase(); });
                return (_a = linerRegion === null || linerRegion === void 0 ? void 0 : linerRegion.id) !== null && _a !== void 0 ? _a : null;
            }
            catch (e) {
                return null;
            }
        };
        this.PutLinerLocations = function (entities) { return __awaiter(_this, void 0, void 0, function () {
            var response, assetSectionId, message, error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.linersLocationsLoading) {
                            return [2 /*return*/, { passed: false, message: "", entityId: "" }];
                        }
                        runInAction(function () {
                            _this.linersLocationsLoading = true;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, putAssetSectionLiners(entities)];
                    case 2:
                        response = _a.sent();
                        if (response.statusCode === 200) {
                            assetSectionId = entities.id;
                            this.linersLocationsLoading = false;
                            this.loadLinerLocations(assetSectionId);
                            return [2 /*return*/, { passed: true, message: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            console.error("Error editing entity:", response);
                            return [2 /*return*/, { passed: false, message: message, entityId: "" }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.error("Error editing entity:", error_1);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs", entityId: "" }];
                    case 4:
                        runInAction(function () {
                            _this.linersLocationsLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.PutAssetSectionImage = function (entities) { return __awaiter(_this, void 0, void 0, function () {
            var response, message, error_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.linersLocationsLoading) {
                            return [2 /*return*/, { passed: false, message: "", entityId: "" }];
                        }
                        runInAction(function () {
                            _this.linersLocationsLoading = true;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, putAssetSectionImage(entities)];
                    case 2:
                        response = _a.sent();
                        if (response.statusCode === 200) {
                            return [2 /*return*/, { passed: true, message: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            console.error("Error upload Asset Image:", response);
                            return [2 /*return*/, { passed: false, message: message, entityId: "" }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_2 = _a.sent();
                        console.error("Error editing entity:", error_2);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs", entityId: "" }];
                    case 4:
                        runInAction(function () {
                            _this.linersLocationsLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.EditLinerLocationsForWearReferencePlane = function (assetSectionId, entities) { return __awaiter(_this, void 0, void 0, function () {
            var filterData, response, message, error_3;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.linersLocationsLoading) {
                            return [2 /*return*/, { passed: false, message: "", entityId: "" }];
                        }
                        runInAction(function () {
                            _this.linersLocationsLoading = true;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        filterData = entities.filter(function (e) { return e.linerLocationId; });
                        return [4 /*yield*/, putLinersLocationWearReferencePlan(filterData)];
                    case 2:
                        response = _a.sent();
                        if (response.statusCode === 200) {
                            this.linersLocationsLoading = false;
                            this.loadLinerLocations(assetSectionId);
                            return [2 /*return*/, { passed: true, message: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            console.error("Error editing entity:", response);
                            return [2 /*return*/, { passed: false, message: message, entityId: "" }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_3 = _a.sent();
                        console.error("Error editing entity:", error_3);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs", entityId: "" }];
                    case 4:
                        runInAction(function () {
                            _this.linersLocationsLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.PutAssetSectionHoles = function (assetSectionId, entities) { return __awaiter(_this, void 0, void 0, function () {
            var filterData, response, message, error_4;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.linersLocationsLoading) {
                            return [2 /*return*/, { passed: false, message: "", entityId: "" }];
                        }
                        runInAction(function () {
                            _this.linersLocationsLoading = true;
                        });
                        filterData = entities.filter(function (e) { return e.linerLocationId; });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, putLinerRegionsHoles(filterData)];
                    case 2:
                        response = _a.sent();
                        if (response.statusCode === 200) {
                            this.linersLocationsLoading = false;
                            this.loadLinerLocations(assetSectionId);
                            return [2 /*return*/, { passed: true, message: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            console.error("Error editing entity:", response);
                            return [2 /*return*/, { passed: false, message: message, entityId: "" }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_4 = _a.sent();
                        console.error("Error editing entity:", error_4);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs", entityId: "" }];
                    case 4:
                        runInAction(function () {
                            _this.linersLocationsLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.EditLinerLocationsDrawingReference = function (assetSectionId, entities) { return __awaiter(_this, void 0, void 0, function () {
            var filterData, response, message, error_5;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.linersLocationsLoading) {
                            return [2 /*return*/, { passed: false, message: "", entityId: "" }];
                        }
                        runInAction(function () {
                            _this.linersLocationsLoading = true;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        filterData = entities.filter(function (e) { return e.linerLocationId; });
                        return [4 /*yield*/, putlinersDrawingReferences(filterData)];
                    case 2:
                        response = _a.sent();
                        if (response.statusCode === 200) {
                            this.linersLocationsLoading = false;
                            this.loadLinerLocations(assetSectionId);
                            return [2 /*return*/, { passed: true, message: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            console.error("Error editing entity:", response);
                            return [2 /*return*/, { passed: false, message: message, entityId: "" }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_5 = _a.sent();
                        console.error("Error editing entity:", error_5);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs", entityId: "" }];
                    case 4:
                        runInAction(function () {
                            _this.linersLocationsLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.customersStore = customersStore;
        makeAutoObservable(this);
    }
    return LinersLocationsStore;
}());
export default new LinersLocationsStore();
