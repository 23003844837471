var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icon, PageTitle, Select, useIntl } from "@metsooutotec/modes-react-components";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import sensorBatchStore from "../../../stores/ioT-stores/sensor-batch-store";
import { itemViewDetailsContainerStyles } from "../../common-style/common-style";
import { convertValueToOption } from "../../common/select-component-helper";
import ListView from "../../utils/list-view/list-view";
import { groupSensorBatchesDispatchedRows } from "./dispatch-history-helper";
var DispatchHistortView = observer(function () {
    var formatMessage = useIntl().formatMessage;
    var params = useParams();
    var navigate = useNavigate();
    var sensorBatches = sensorBatchStore.sensorBatches, getAllHardwareVariants = sensorBatchStore.getAllHardwareVariants, sensors = sensorBatchStore.sensors;
    var _a = __read(useState([]), 2), dataTable = _a[0], setDataTable = _a[1];
    var _b = __read(useState({ text: "", value: "" }), 2), searchInput = _b[0], setSearchInput = _b[1];
    var _c = __read(useState([]), 2), listOfPartNumbers = _c[0], setListOfPartNumbers = _c[1];
    var tableContainerStyles = {
        borderTop: '2px solid gray',
        marginTop: '1em',
    };
    var loadHardwareVariants = function () {
        var hardwareVariants = getAllHardwareVariants;
        if (hardwareVariants.length > 0) {
            setListOfPartNumbers(hardwareVariants.map(function (h) { return ({
                text: h,
                value: h
            }); }));
        }
    };
    // Sync state with URL params on initial load or when params change
    useEffect(function () {
        if (params.partNumber && params.partNumber !== searchInput.text) {
            setSearchInput({ text: params.partNumber, value: params.partNumber });
        }
    }, [params.partNumber]);
    // Navigate when search input changes due to user action
    useEffect(function () {
        if (searchInput.text && searchInput.text !== params.partNumber) {
            navigate("/stock-management/dispatch-history/".concat(searchInput.text));
        }
    }, [searchInput]);
    useEffect(function () {
        var data = groupSensorBatchesDispatchedRows(sensorBatches, searchInput.text);
        setDataTable(data);
    }, [searchInput, sensorBatches, sensors]);
    useEffect(function () {
        loadHardwareVariants();
    }, [sensorBatches]);
    var onSearchChange = function (resultValue) {
        setSearchInput(resultValue);
    };
    var onClickRow = function (stockAllocation) {
        navigate("/stock-management/dispatched-sensors/".concat(searchInput.text, "/").concat(stockAllocation.customer, "/").concat(stockAllocation.site));
    };
    var tableTemplate = [
        {
            headingKey: "headings.customer",
            cellFunc: function (s) {
                return s.customer;
            },
            isSearchable: true,
        },
        {
            headingKey: "headings.site",
            cellFunc: function (s) {
                return s.site;
            },
        },
        {
            headingKey: "headings.quantity",
            cellFunc: function (s) {
                return s.quantity;
            },
        }
    ];
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { onPathChange: navigate, title: formatMessage("dispatchHistory.title") }), _jsxs("div", __assign({ style: itemViewDetailsContainerStyles }, { children: [_jsx(Select, { id: "SockAllocationSearchInput", icon: _jsx(Icon, { className: "icon-search-16" }), label: formatMessage("headings.partNumber"), onChange: function (value) { return onSearchChange(convertValueToOption(value, listOfPartNumbers)); }, options: listOfPartNumbers, value: searchInput.text }), _jsx("div", __assign({ style: tableContainerStyles }, { children: _jsx(ListView, { noneYetKey: "stockAllocation.nonYet", template: tableTemplate, data: dataTable, onClickRow: function (s) { return onClickRow(s); } }) }))] }))] }));
});
export default DispatchHistortView;
