/**
 * Retrieves the ID corresponding to a "Liner" from a given input string.
 *
 * The function looks for a pattern in the input string that starts with the word "Liner",
 * optionally followed by any combination of letters and numbers. The pattern can end with a "-"
 * or might not have any character after.
 *
 * @param {string} inputStr - The input string from which the Liner ID is to be extracted.
 *
 * @returns {string | null} - Returns the matched Liner ID if found, otherwise returns null.
 */
export function GetLinerId(inputStr) {
    if (inputStr == null)
        return null;
    // Matches a section that starts with "Liner", followed optionally by any letters or numbers
    // and then potentially ends with a "-" or nothing.
    var regex = /(liner[\w]*)(-|$)/i;
    var match = inputStr.match(regex);
    return match ? match[1] : null;
}
/**
 * Retrieves the ID corresponding to a "Liners" from a given input string.
 * @param inputStr
 * @returns
 */
export function GetLinersId(inputStr) {
    if (inputStr == null)
        return null;
    // Matches a section that starts with "Liner", followed optionally by any letters or numbers
    // and then potentially ends with a "-" or nothing.
    var regex = /(liners[\w]*)(-|$)/i;
    var match = inputStr.match(regex);
    return match ? match[1] : null;
}
/**
 * Extracts the numeric part from a liner name string.
 *
 * @example
 * // Returns 123
 * extractLinerNumber("liner123");
 *
 * @example
 * // Returns 456
 * extractLinerNumber("liner456");
 *
 * @example
 * // Returns null because there's no numeric part after "liner"
 * extractLinerNumber("linerNoNumber");
 *
 *
 * */
export function ExtractDefinedLinerNumber(linerName) {
    // Adjusted regex to optionally include letters between "liner" and the numeric part
    var regex = /liner[a-z]*(\d+)/i;
    var match = linerName.match(regex);
    if (match && match[1]) {
        return parseInt(match[1], 10); // Convert captured string to an integer
    }
    else {
        return null; // Return null if no number part is found
    }
}
/**
 * Extracts and retrieves the Hole ID from the provided input string.
 *
 * The function searches the `inputStr` for a pattern that starts with the word "hole",
 * followed optionally by any combination of letters and numbers. This pattern can either
 * end with a hyphen ("-") or no character at all.
 *
 * For instance, given the string "hole1234-", the function will return "hole1234".
 *
 * @param {string} inputStr - The input string from which the Hole ID is to be extracted.
 *
 * @returns {string | null} - The extracted Hole ID if found, otherwise returns null.
 *                            If the input string is null, the function returns null.
 */
export function GetHoleId(inputStr) {
    if (inputStr == null)
        return null;
    // Matches a section that starts with "Liner", followed optionally by any letters or numbers
    // and then potentially ends with a "-" or nothing.
    var regex = /(hole[\w]*)(-|$)/i;
    var match = inputStr.match(regex);
    return match ? match[1] : null;
}
/**
 * Extracts the leading sequence of letters from the provided string.
 *
 * This function searches the beginning of the given string (`str`) for a sequence
 * of one or more alphabetic characters (both uppercase and lowercase).
 *
 * For example, given the string "liner123", the function will return "liner".
 *
 * @param {string} str - The input string from which the leading sequence of letters is to be extracted.
 *
 * @returns {string} - The extracted sequence of letters if found; otherwise, an empty string.
 *                     If no letters are found at the start of the string, it returns an empty string.
 */
export var ExtractLetters = function (str) {
    var match = str.match(/^[a-zA-Z]+/); // This regex matches one or more letters at the start of a string
    return match ? match[0] : ""; // Return the matched substring or an empty string
};
/**
 * Removes the last segment from the provided string, based on the "-" delimiter.
 *
 * This function divides the input string (`input`) into segments using the "-"
 * character as a delimiter. It then removes the last segment and returns the
 * modified string. If the input string does not contain the "-" character or
 * has only one segment, the original string is returned unchanged.
 *
 * Examples:
 * - Given "liner-region-hole-123", the function will return "liner-region-hole".
 * - Given "liner", the function will return "liner".
 *
 * @param {string} input - The input string from which the last segment is to be stripped.
 *
 * @returns {string} - The modified string after removing the last segment.
 *                     If the input has no "-" or just one segment, the original string is returned.
 */
export function StripLastSegment(input) {
    try {
        var segments = input.split('-');
        if (segments.length <= 1) {
            return input; // If there's no '-', return the input as is.
        }
        segments.pop(); // Remove the last segment.
        return segments.join('-'); // Join the remaining segments back together.
    }
    catch (e) {
        return "";
    }
}
/**
 * Truncates a string to a maximum length and adds "..." if it's longer.
 *
 * @param input - The input string to be truncated.
 * @param maxLength - The maximum length of the truncated string.
 * @returns The truncated string with "..." added if necessary.
 */
export function TruncateString(input, maxLength) {
    try {
        if (input.length <= maxLength) {
            return input;
        }
        else {
            return input.slice(0, maxLength) + "...";
        }
    }
    catch (e) {
        console.error("Cannot trunc ".concat(input));
        throw (e);
    }
}
