var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogHeader, DialogDescription, useIntl, Input, Grid, GridRow } from "@metsooutotec/modes-react-components";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import ModalsButtons from "../../../../common/modals-buttons";
import { fetchSvgImageFromUrl, updateAssetSectionImage } from "../helpers/svg-helpers";
import { MapWearReferencePlane } from "../helpers/wear-reference-plane-helper";
import asEditorStor from "./../local-store/asset-section-editor-store";
var SVGEditorWearReferencePlaneModal = observer(function (props) {
    var selectedWearReferencePlane = asEditorStor.selectedWearReferencePlane, setSelectedWearReferencePlane = asEditorStor.setSelectedWearReferencePlane;
    var assetSectionImage = asEditorStor.assetSectionImage, setAssetSectionImage = asEditorStor.setAssetSectionImage;
    var formatMessage = useIntl().formatMessage;
    var _a = __read(useState(0), 2), wearReferencePlane = _a[0], setWearReferencePlane = _a[1];
    var _b = __read(useState(""), 2), errorMessage = _b[0], setErrorMessage = _b[1];
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, svgDocument, renderedSvg, blobUrl, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetchSvgImageFromUrl(assetSectionImage)];
                case 2:
                    _a = _b.sent(), svgDocument = _a.svgDocument, renderedSvg = _a.renderedSvg;
                    MapWearReferencePlane(props.assetSectionId, svgDocument, renderedSvg, selectedWearReferencePlane, setSelectedWearReferencePlane, wearReferencePlane);
                    return [4 /*yield*/, updateAssetSectionImage(svgDocument)];
                case 3:
                    blobUrl = _b.sent();
                    setAssetSectionImage(blobUrl);
                    onCloseProcess();
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _b.sent();
                    setErrorMessage("Somthing Wrong has been happen, please check the logs");
                    console.error(e_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var resetModal = function () {
        setErrorMessage("");
        setWearReferencePlane(0);
    };
    var onCloseProcess = function () {
        resetModal();
        props.setIsModalOpen(false);
    };
    var labels = {
        header: formatMessage("wearReferencePlaneModal.header"),
        description: formatMessage("wearReferencePlaneModal.description"),
        wearReferencePlane: formatMessage("wearReferencePlaneModal.wearReferencePlane")
    };
    var inputConfig = [
        { id: "wearReferencePlane", label: labels.wearReferencePlane, placeholder: "Type ".concat(labels.wearReferencePlane, " here..."), type: "number" },
    ];
    var handleChange = function (stringValue) {
        var value = parseFloat(stringValue);
        setWearReferencePlane(value);
    };
    var renderInputs = function () {
        return (_jsx(_Fragment, { children: inputConfig.map(function (input) {
                var id = input.id, label = input.label, type = input.type, placeholder = input.placeholder;
                var inputProps = {
                    id: id,
                    label: label,
                    placeholder: placeholder,
                    onChange: function (e) { return handleChange(e.target.value); },
                    type: type,
                    spanning: true,
                    heightClass: 'medium',
                    required: true,
                    uiRequired: true,
                    value: wearReferencePlane,
                };
                return (_jsx(GridRow, __assign({ style: { marginBottom: "var(--size-m)" } }, { children: _jsx(Input, __assign({}, inputProps)) }), id));
            }) }));
    };
    return (_jsx(Grid, { children: _jsxs(Dialog, __assign({ open: props.isModalOpen, size: 'small', style: { maxWidth: "512px" } }, { children: [_jsx(DialogHeader, __assign({ username: '' }, { children: labels.header })), errorMessage && _jsx("p", __assign({ style: { color: 'red' } }, { children: errorMessage })), _jsx(DialogDescription, __assign({ style: { marginBottom: "var(--size-xxl)" } }, { children: labels.description })), renderInputs(), _jsx(ModalsButtons, { handleSubmit: handleSubmit, onCloseProcess: onCloseProcess, isLoading: false })] })) }));
});
export default SVGEditorWearReferencePlaneModal;
