var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PageTitle, useIntl } from "@metsooutotec/modes-react-components";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import customerStore from "../../../../stores/customer-stores/customer-store";
import sensorBatchStore from "../../../../stores/ioT-stores/sensor-batch-store";
import { itemViewListContainerStyles } from "../../../common-style/common-style";
import LowSpinner from "../../../common/low-spinner";
import { convertDateToDDMMMYY } from "../../../utils/date-formatter-utils";
import ListView from "../../../utils/list-view/list-view";
import { NotFound } from "../../../utils/not-found";
import { getAllocatedSensors } from "../../stock-managment/stock-management-helper";
import { getMatchingSensorIds } from "../stock-allocation-helper";
var AllocatedSensorsListView = observer(function () {
    var formatMessage = useIntl().formatMessage;
    var navigate = useNavigate();
    var _a = useParams(), partNumber = _a.partNumber, customerName = _a.customerName, siteName = _a.siteName;
    var sensorBatches = sensorBatchStore.sensorBatches;
    var _b = __read(useState([]), 2), listOfSensors = _b[0], setListOfSensors = _b[1];
    var title = "Allocated Sensors For PartNumber: ".concat(partNumber);
    var customers = customerStore.customers, loadAllCustomers = customerStore.loadAllCustomers;
    /**
     * useEffect hook to load all customers when the component mounts or when the customers list is empty.
     * It ensures that the customer data is fetched and available for use in the component.
     */
    useEffect(function () {
        if (!customers.length) {
            loadAllCustomers();
        }
    }, []);
    useEffect(function () {
        if (partNumber && sensorBatches.length > 0) {
            // Fetch allocated sensors based on the part number.
            var allocatedSensors = getAllocatedSensors(sensorBatches, partNumber);
            // Fetch IDs of sensors matching the criteria.
            var matchedSensorIds_1 = getMatchingSensorIds(allocatedSensors, customerName, siteName);
            // Filter the allocatedSensors to include only those whose IDs are in matchedSensorIds.
            var filteredData = allocatedSensors.filter(function (sensor) { return matchedSensorIds_1.includes(sensor.id); });
            setListOfSensors(filteredData);
        }
    }, [partNumber, customerName, siteName]);
    var breadcrumbsPath = [
        {
            text: formatMessage('stockManagement.title'),
            to: '/stock-management',
        },
        {
            text: formatMessage('stockAllocation.title'),
            to: "/stock-management/stock-allocation/".concat(partNumber),
        },
        {
            text: partNumber,
            to: "/stock-management/stock-allocation/".concat(partNumber),
        },
    ];
    var template = [
        {
            headingKey: "headings.serialNumber",
            cellFunc: function (a) { return a.serialNumber; },
            isSearchable: true
        },
        {
            headingKey: "headings.macAddress",
            cellFunc: function (a) { return a.macAddress; },
            isSearchable: true
        },
        {
            headingKey: "headings.customer",
            cellFunc: function (a) { return a.customerName; },
        },
        {
            headingKey: "headings.site",
            cellFunc: function (a) { return a.siteName; },
        },
        {
            headingKey: "headings.allocationDate",
            cellFunc: function (a) { return convertDateToDDMMMYY(a.allocatedDate); },
            isSearchable: true
        }
    ];
    return ((sensorBatches.length < 1)
        ? _jsx(LowSpinner, { id: "sensorBatch.loading" })
        : (!listOfSensors)
            ? _jsx(NotFound, { id: title })
            :
                _jsxs(_Fragment, { children: [_jsx(PageTitle, { breadcrumbsPath: breadcrumbsPath, onPathChange: navigate, title: title }), _jsx("div", __assign({ style: itemViewListContainerStyles }, { children: _jsx(ListView, { data: listOfSensors || [], noneYetKey: "sensor.notFound", template: template }) }))] }));
});
export default AllocatedSensorsListView;
