var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CssSVGClasses } from "../editor-utils/editor-types";
import { GetHoleId, GetLinerId } from "../editor-utils/id-string-utils";
import { RemoveUnListedClasses } from "./svg-helpers";
import linerLocationStore from "../../../../../stores/customer-stores/liner-locations-store";
import linerLocationsStore from "../../../../../stores/customer-stores/liner-locations-store";
/**
 * Determines if a specified hole is completely inside a given liner based on their bounding boxes.
 *
 * This function computes the bounding boxes for both the hole and the liner. It then checks if
 * the entire bounding box of the hole is contained within the bounding box of the liner.
 *
 * @param {SVGPathElement} hole - The SVG path element representing the hole.
 * @param {SVGPathElement} liner - The SVG path element representing the liner.
 *
 * @returns {boolean} - Returns true if the hole is completely inside the liner; otherwise, returns false.
 */
export function IsHoleInsideLiner(hole, liner) {
    try {
        var holeBBox = hole.getBBox();
        var linerBBox = liner.getBBox();
        var isInside = (holeBBox.x >= linerBBox.x &&
            holeBBox.y >= linerBBox.y &&
            holeBBox.x + holeBBox.width <= linerBBox.x + linerBBox.width &&
            holeBBox.y + holeBBox.height <= linerBBox.y + linerBBox.height);
        return isInside;
    }
    catch (e) {
        console.error("Error happen in IsHoleInsideLiner", e);
        return false;
    }
}
/**
 * Finds and returns the liner path element that completely surrounds a specified hole from a list of liners.
 *
 * This function iterates over the provided list of liner path elements and checks if any of them
 * completely surrounds the specified hole. It returns the first liner that meets this condition.
 * If no liner surrounds the hole, the function returns null.
 *
 * @param {SVGPathElement} hole - The SVG path element representing the hole.
 * @param {SVGPathElement[]} liners - An array of SVG path elements representing potential surrounding liners.
 *
 * @returns {SVGPathElement | null} - The liner that surrounds the hole or null if no such liner exists.
 */
export function getSurroundingLiner(hole, liners) {
    var e_1, _a;
    try {
        try {
            for (var liners_1 = __values(liners), liners_1_1 = liners_1.next(); !liners_1_1.done; liners_1_1 = liners_1.next()) {
                var liner = liners_1_1.value;
                if (IsHoleInsideLiner(hole, liner)) {
                    return liner;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (liners_1_1 && !liners_1_1.done && (_a = liners_1.return)) _a.call(liners_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return null;
    }
    catch (e) {
        console.error("Error happen in getSurroundingLiner", e);
        return null;
    }
}
/**
 * Appends a surrounding region path to the SVG containing a specified hole path element.
 *
 * This function:
 * 1. Ensures that the provided hole path element is valid and has the required class ("ws-hole").
 * 2. Identifies or calculates the ID for the surrounding region path based on the ID of the hole path element.
 * 3. Checks for and removes any existing region path elements with the same ID in the SVG to avoid duplicates.
 * 4. Rescales the region path using the provided scale factor.
 * 5. Creates a new SVG path element for the region and appends it to the SVG before the hole path element.
 *
 * @param {string} renderedPathID - The ID of the rendered path.
 * @param {SVGPathElement} holePathElement - The SVG path element representing the hole.
 * @param {number} scale - A scaling factor to adjust the size of the region path.
 * @param {string} oldHoleId - The previous ID of the hole before any modifications.
 */
export function AppendSurroundingPaths(svgDocument, renderedPathID, holePathElement, scale, oldHoleId) {
    var _a, _b, _c, _d, _e;
    if (!holePathElement.classList.contains(CssSVGClasses.WsHole) || !renderedPathID) {
        return;
    }
    try {
        var idSplot = holePathElement.id.split("-");
        var regionId = "".concat(idSplot[0], "-").concat(idSplot[1]);
        var regionD = ReScaleRegionPath(svgDocument, renderedPathID, scale);
        var oldIdSplot = oldHoleId.split("-");
        var oldRegionId = "".concat(oldIdSplot[0], "-").concat(oldIdSplot[1]);
        // Check if an element with the same id already exists and remove it
        var existingRegionEle = (_a = holePathElement.parentElement) === null || _a === void 0 ? void 0 : _a.querySelector("path[id=\"".concat(regionId, "\"].").concat(CssSVGClasses.WsRegionTemp));
        var oldExistingRegionEle = (_b = holePathElement.parentElement) === null || _b === void 0 ? void 0 : _b.querySelector("path[id=\"".concat(oldRegionId, "\"].").concat(CssSVGClasses.WsRegionTemp));
        if (existingRegionEle) {
            (_c = holePathElement.parentElement) === null || _c === void 0 ? void 0 : _c.removeChild(existingRegionEle);
        }
        else if (oldExistingRegionEle) {
            (_d = holePathElement.parentElement) === null || _d === void 0 ? void 0 : _d.removeChild(oldExistingRegionEle);
        }
        var regionEle = document.createElementNS("http://www.w3.org/2000/svg", "path");
        regionEle.setAttribute("d", regionD);
        regionEle.setAttribute("class", CssSVGClasses.WsRegionTemp);
        regionEle.setAttribute("id", regionId);
        (_e = holePathElement.parentElement) === null || _e === void 0 ? void 0 : _e.insertBefore(regionEle, holePathElement);
    }
    catch (e) {
        console.error("Error happen in getSurroundingLiner", e);
    }
}
/**
* Rescales and sets an existing surrounding region path associated with a specified hole path element.
*
* The process followed by this function includes:
* 1. Identifying the corresponding rendered hole element in the SVG using the provided hole path element's ID.
* 2. Extracting or computing the ID for the surrounding region path based on the ID of the hole path element.
* 3. Rescaling the region path based on the provided scale factor.
* 4. Checking for any pre-existing region path elements with the same ID in the SVG and removing them if found.
* 5. Creating a new SVG path element for the region with the rescaled data.
* 6. Appending the newly created region path element to the SVG, positioning it before the hole path element.
*
* @param {SVGPathElement} holePathElement - The SVG path element representing the hole.
* @param {number} scale - A scaling factor to adjust the size of the region path.
*/
export function ReScaleSetExistingRegionPath(svgDocument, holePathElement, scale) {
    var _a, _b, _c;
    try {
        var sel = "path[id*=\"".concat(holePathElement.id, "\"].").concat(CssSVGClasses.WsHole);
        var rendedHole = svgDocument.querySelector(sel);
        var idSplot = holePathElement.id.split("-");
        var regionId = "".concat(idSplot[0], "-").concat(idSplot[1]);
        var regionD = ReScaleRegionPath(svgDocument, rendedHole.id, scale);
        var existingRegionEle = (_a = holePathElement.parentElement) === null || _a === void 0 ? void 0 : _a.querySelector("path[id*=\"".concat(regionId, "\"].").concat(CssSVGClasses.WsRegionTemp));
        var regionEle = svgDocument.createElementNS("http://www.w3.org/2000/svg", "path");
        regionEle.setAttribute("d", regionD);
        regionEle.setAttribute("class", CssSVGClasses.WsRegionTemp);
        regionEle.setAttribute("id", regionId);
        if (existingRegionEle) {
            (_b = holePathElement.parentElement) === null || _b === void 0 ? void 0 : _b.removeChild(existingRegionEle);
        }
        (_c = holePathElement.parentElement) === null || _c === void 0 ? void 0 : _c.insertBefore(regionEle, holePathElement);
    }
    catch (e) {
        //console.error("Error happen in ReScaleSetExistingRegionPath", e);
        return;
    }
}
/**
* Rescales the region path surrounding a specified hole based on the provided scale factor.
*
* This function performs the following steps:
* 1. Locates the rendered hole element in the SVG using the given path ID.
* 2. Extracts the 'd' attribute from the rendered hole, which contains the path data.
* 3. Calculates the bounding box for the hole path to determine its dimensions.
* 4. Uses the bounding box to compute the center and diameter of the circular hole.
* 5. Computes the new radius of the surrounding region by multiplying the hole's radius with the scale factor.
* 6. Constructs and returns a new path data string for the rescaled region using the SVG path commands.
*
* If any issues arise during the execution (like the hole not being found or attribute extraction failing),
* the function will catch the exceptions and return an empty path string.
*
* @param {string} renderedHolePathID - The ID of the SVG path element representing the rendered hole.
* @param {number} scale - A scaling factor to adjust the size of the region path.
* @returns {string} - A string representing the rescaled SVG path data for the region.
*/
export function ReScaleRegionPath(svgDocument, renderedHolePathID, scale) {
    try {
        var renderedHole = svgDocument.querySelector("path[id=\"".concat(renderedHolePathID, "\" i]"));
        if (!renderedHole)
            return "";
        var dAttribute = renderedHole.getAttribute('d');
        if (!dAttribute)
            return "";
        // Get the bounding box of the path
        var boundingBox = renderedHole.getBBox();
        //Calculate the center of the circle.
        var centerX = boundingBox.x + boundingBox.width / 2;
        var centerY = boundingBox.y + boundingBox.height / 2;
        // Calculate the diameter based on the bounding box dimensions
        var diameter = Math.max(boundingBox.width, boundingBox.height);
        var radius = diameter / 2;
        var regionRadius = radius * scale;
        return ("M " + (centerX + regionRadius) + "," + centerY +
            " a " + regionRadius + "," + regionRadius +
            " 0 1 0 " + (regionRadius * -2) + ",0 " +
            regionRadius + "," + regionRadius +
            " 0 1 0 " + (regionRadius * 2) + ",0" +
            " z");
    }
    catch (e) {
        console.error("Error happen in ReScaleRegionPath", e);
        return "";
    }
}
/**
* Assigns incremental IDs to holes within liners in an SVG document and updates a React state with these assignments.
*
* This function:
* 1. Identifies holes and liners from both the input `svgDocument` and `renderedSvg`.
* 2. Determines which liner each hole belongs to.
* 3. Assigns a new ID to each hole based on its surrounding liner and its position within that liner.
* 4. Updates the provided React state (`setSelectedHoles`) with an object mapping the old hole ID to the new incremental ID.
*
* Holes and liners are identified based on their IDs containing specific keywords ('hole' for holes and 'liner' for liners).
* Additional filtering ensures that regions and other non-relevant paths are excluded.
*
* @param {Document} svgDocument - The SVG document used as a reference.
* @param {SVGSVGElement} renderedSvg - The SVG element that is rendered in the DOM.
* @param {React.Dispatch<React.SetStateAction<HoleIDPair[]>>} setSelectedHoles - The React state setter function to update with the new IDs.
* @param {number} scale - A scaling factor (presumably used in the `AppendSurroundingPaths` function).
*/
export function AssignIncrementalIdsToHolesInLiners(assetSectionId, svgDocument, renderedSvg, selectedHoles, setSelectedHoles, scale) {
    try {
        var originalAllPath = Array.from(svgDocument.querySelectorAll('path'));
        var originalHoles_1 = originalAllPath.filter(function (path) { return path.id.toLowerCase().includes('hole'); });
        var allPaths = Array.from(renderedSvg.querySelectorAll('path'));
        var holeIdPattern_1 = /^hole/i;
        var holes = allPaths.filter(function (path) { return holeIdPattern_1.test(path.id) && !path.id.toLowerCase().includes('region'); });
        var liners_2 = allPaths.filter(function (path) { return path.classList.contains(CssSVGClasses.WsLiner); });
        var linerHoleCounts_1 = {};
        var updatedHoles_1 = __spreadArray([], __read(selectedHoles), false);
        holes.forEach(function (hole) {
            var _a;
            var surroundingLiner = getSurroundingLiner(hole, liners_2);
            if (surroundingLiner) {
                var linerIdPart = GetLinerId(surroundingLiner.id);
                if (linerIdPart) {
                    var linerLocationId = linerLocationStore.GetLinerLocationIdByReference(assetSectionId, linerIdPart);
                    if (!linerLocationId)
                        return;
                    var linerLocation = linerLocationsStore.GetLinerLocationById(linerLocationId);
                    if (linerLocation) {
                        var oldId_1 = SearchGetHoleId(hole.id);
                        // Use the linerHoleCounts to determine hole number
                        if (!linerHoleCounts_1[linerIdPart]) {
                            linerHoleCounts_1[linerIdPart] = 1;
                        }
                        else {
                            linerHoleCounts_1[linerIdPart]++;
                        }
                        var holeNumber = linerHoleCounts_1[linerIdPart];
                        var newlinerRegionId = "".concat(linerIdPart, "-Region").concat(holeNumber);
                        var newHoleID = "".concat(newlinerRegionId, "-Hole1");
                        if (oldId_1) {
                            var holeToEdit = originalHoles_1.filter(function (hole) { return hole.id.toLowerCase() === oldId_1.toLowerCase(); });
                            holeToEdit[0].id = newHoleID;
                            holeToEdit[0].classList.add(CssSVGClasses.WsHole);
                            RemoveUnListedClasses(holeToEdit[0]);
                            // Call AppendSurroundingPaths after updating the hole's ID
                            AppendSurroundingPaths(renderedSvg, hole.id, holeToEdit[0], scale, oldId_1);
                            var existLinerRegions = linerLocationStore.GetLinerRegionByLinerRegionRef(linerLocationId, oldId_1);
                            var existingHoleIndex = updatedHoles_1.findIndex(function (entry) { return entry.existReference === oldId_1; });
                            if (existingHoleIndex !== -1) {
                                updatedHoles_1[existingHoleIndex].newReference = newHoleID;
                            }
                            else {
                                updatedHoles_1.push({ id: (_a = existLinerRegions === null || existLinerRegions === void 0 ? void 0 : existLinerRegions.id) !== null && _a !== void 0 ? _a : "", linerLocationId: linerLocation.id, existName: "", newReference: newlinerRegionId, existReference: "", newName: "" });
                            }
                        }
                    }
                }
            }
            setSelectedHoles(updatedHoles_1);
        });
    }
    catch (e) {
        //console.error("Error happen in AssignIncrementalIdsToHolesInLiners", e);
        return;
    }
}
/**
* Searches and retrieves the Hole ID from the specified path ID.
*
* The function first queries the document for a path element containing the provided `selectedPathId`
* as its ID. It then checks if the path element has the class "ws-hole".
*
* If the path element has the "ws-hole" class:
*   - The function returns the portion of the `selectedPathId` before the last hyphen ("-").
*   - If there's no hyphen in the `selectedPathId`, the entire `selectedPathId` is returned.
*
* If the path element doesn't have the "ws-hole" class, the function delegates the ID search
* to the `GetHoleId` function using the `selectedPathId` as its input.
*
* @param {string} selectedPathId - The ID of the path element to search for.
*
* @returns {string | null} - The retrieved Hole ID or null if not found or if the input is null.
*/
export function SearchGetHoleId(selectedPathId) {
    try {
        if (selectedPathId == null)
            return null;
        var path = document.querySelector("path[id=\"".concat(selectedPathId, "\"]"));
        var idDefined = path === null || path === void 0 ? void 0 : path.classList.contains(CssSVGClasses.WsHole);
        if (idDefined) {
            var lastIndex = selectedPathId.lastIndexOf('-');
            if (lastIndex !== -1) {
                return selectedPathId.substring(0, lastIndex);
            }
            return selectedPathId;
        }
        else {
            return GetHoleId(selectedPathId);
        }
    }
    catch (e) {
        //console.error("Error happen in SearchGetHoleId", e);
        return null;
    }
}
/**
 * Extracts and returns the liner region reference from a given hole identifier.
 *
 * The function takes an input string structured in a hierarchical format, typically
 * representing a path or a sequence like "LinerA9-Region1-Hole1". It then processes
 * this string to isolate and return the first two segments (e.g., "LinerA9-Region1"),
 * which represent the liner and region references respectively. These segments are
 * assumed to be delimited by dashes ("-").
 *
 * @param {string} inputString - The hole identifier from which to extract the liner
 *                               region reference. Expected format: "Part1-Part2-Part3".
 * @return {string | null} The concatenated string of the first two segments if
 *                         extraction is successful, null otherwise. For the example
 *                         input "LinerA9-Region1-Hole1", the function returns
 *                         "LinerA9-Region1". In cases where the input format is
 *                         unexpected or an error occurs, the function returns null.
 */
export function GetLinerRegionReferenceFromHoleId(inputString) {
    try {
        // Split the string into an array using dash as the separator
        var sections = inputString.split('-');
        // Take the first two elements of the array and join them back into a string
        var firstTwoSections = sections.slice(0, 2).join('-');
        return firstTwoSections;
    }
    catch (e) {
        //console.error("Error happen in GetLinerRegionReferenceFromHoleId", e);
        return null;
    }
}
export function GetTopDefinedRegionCount(linerLocation) {
    try {
        var linerRegions = linerLocation.linerRegions;
        // Use a regular expression to find numbers at the end of the string
        var numbers = linerRegions.map(function (l) {
            var match = l.reference.match(/\d+$/); // Matches digits at the end of the string
            return match ? parseInt(match[0], 10) : 0; // Parse the matched digits or default to 0
        });
        return Math.max.apply(Math, __spreadArray([], __read(numbers), false));
    }
    catch (e) {
        //console.error('Error in GetTopDefinedRegionCount with linerLocation:', linerLocation, e);
        return 0;
    }
}
