var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import customerStore from 'stores/customer-stores/customer-store';
import { Button, FormattedMessage, PageTitle, Table, ToastLevel, useIntl, useToast } from '@metsooutotec/modes-react-components';
import { assetImageViewStyle, assetCellsStyle, } from '../styles/customer-styles';
import EmptyImage from 'imgs/emptyImage.jpg';
import ListView from '../../utils/list-view/list-view';
import { NotFound } from '../../utils/not-found';
import { ModalTypes } from '../customers-utils/customer-modal-helper';
import AssetModal from './asset-modal';
import { getAsset3DFile, getAssetImage } from '../../../services/customer-services/customer-service';
import EntityDeleteModal from '../customers-utils/entity-delete-modal';
import { GenerateBreadcrumbs } from '../customers-utils/customers-bread-crumbs';
import AssetSectionModal from '../asset-section/view/asset-section-modal';
import { downloadFileFromBlobUrl } from '../../utils/files-utils';
import { itemViewDetailsContainerStyles, itemViewDetailsTableStyles, itemViewListContainerStyles, itemViewListExtraTopPaddingStyles, itemViewSeparatorStyles, itemViewSubtitleStyles } from '../../common-style/common-style';
import LowSpinner from '../../common/low-spinner';
import assetSectionFilesStore from '../../../stores/customer-stores/asset-section-files-store';
var AssetView = observer(function () {
    var formatMessage = useIntl().formatMessage;
    var navigate = useNavigate();
    var _a = useParams(), customerId = _a.customerId, siteId = _a.siteId, assetId = _a.assetId;
    var setMessage = useToast().setMessage;
    var location = useLocation();
    var assetSectionStore = assetSectionFilesStore;
    var params = location.state;
    var _b = params || {}, Created = _b.created, Edited = _b.edited;
    var customerMap = customerStore.customerMap, siteMap = customerStore.siteMap, assetMap = customerStore.assetMap;
    var customer = customerMap[customerId || ""];
    var site = siteMap[siteId || ""];
    var asset = assetMap[assetId || ""];
    var _c = __read(useState(false), 2), isAssetModalOpen = _c[0], setAssetModalOpen = _c[1];
    var _d = __read(useState(true), 2), loadingAssetImage = _d[0], setLoadingAssetImage = _d[1];
    var _e = __read(useState(false), 2), isDeleteModalOpen = _e[0], setIsDeleteModalOpen = _e[1];
    var _f = __read(useState(false), 2), isAssetSectionModalOpen = _f[0], setAssetSectionModalOpen = _f[1];
    var _g = __read(useState(EmptyImage), 2), assetImage = _g[0], setAssetImage = _g[1];
    var breadcrumbsPath = GenerateBreadcrumbs(customer, site, asset, undefined);
    var _h = __read(useState(false), 2), downloadLoading = _h[0], setDownloadLoading = _h[1];
    var template = [
        {
            headingKey: "assetSection.name",
            cellFunc: function (a) { return a.name; },
        },
        {
            headingKey: "assetSection.description",
            cellFunc: function (a) { return a.description; },
        },
        {
            headingKey: "assetSection.SvgFile",
            cellFunc: function (a) {
                if (assetSectionStore.isAnySectionLoading) {
                    return 'Loading...';
                }
                else {
                    // Check if the SVG file exists in the assetSectionFilesStore
                    var checkFilesExist = assetSectionFilesStore.assetSectionFilesMap[a.id];
                    return checkFilesExist && checkFilesExist.isSvgExist ? "".concat(a.name, ".svg") : 'Not Exist';
                }
            },
        },
        {
            headingKey: "assetSection.MudMap",
            cellFunc: function (a) {
                if (assetSectionStore.isAnySectionLoading) {
                    return 'Loading...';
                }
                else {
                    // Check if the Mud Map PDF file exists in the assetSectionFilesStore
                    var checkFilesExist = assetSectionFilesStore.assetSectionFilesMap[a.id];
                    return checkFilesExist && checkFilesExist.isPdfExist ? "".concat(a.name, "Mudmap.pdf") : 'Not Exist';
                }
            },
        },
    ];
    var showNotifier = function (text) {
        setMessage(text, ToastLevel.SUCCESS, 6000);
    };
    var fetchImage = function () { return __awaiter(void 0, void 0, void 0, function () {
        var imageUrl, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    setLoadingAssetImage(true);
                    return [4 /*yield*/, getAssetImage(assetId)];
                case 1:
                    imageUrl = _b.sent();
                    setAssetImage(imageUrl);
                    setLoadingAssetImage(false);
                    return [3 /*break*/, 3];
                case 2:
                    _a = _b.sent();
                    setLoadingAssetImage(false);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var checkFilesExists = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
                asset.assetSections.forEach(function (a) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                console.log(a.id);
                                return [4 /*yield*/, assetSectionStore.loadassetSectionFiles(a.id)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); });
            }
            catch (e) {
                console.warn('Error in checkFilesExists', e);
            }
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        checkFilesExists();
    }, [assetId]);
    var memoizedFetchImage = useMemo(function () { return fetchImage; }, [assetId, Edited]);
    useEffect(function () {
        memoizedFetchImage();
    }, [memoizedFetchImage]);
    useEffect(function () {
        if (Created) {
            showNotifier("New Asset created successfully");
        }
        if (Edited) {
            showNotifier("All changes made successfully");
        }
        window.history.replaceState({}, document.title);
    }, [Created, Edited, params]);
    var editAssetModal = {
        open: function () {
            setAssetModalOpen(true);
        },
        close: function (passed, assetId, navigationCustomerOptions) {
            setAssetModalOpen(false);
            if (passed) {
                navigate(location.pathname, {
                    state: navigationCustomerOptions
                });
            }
        }
    };
    var deleteAssetModal = {
        open: function () {
            setIsDeleteModalOpen(true);
        },
        close: function (params) {
            setIsDeleteModalOpen(false);
            if (params.deleted) {
                navigate("/customers/".concat(customerId, "/sites/").concat(siteId), { state: params });
            }
        }
    };
    var createAssetSectionModal = {
        open: function () {
            setAssetSectionModalOpen(true);
        },
        close: function (passed, assetSectionId, navigationCustomerOptions) {
            setAssetSectionModalOpen(false);
            if (passed) {
                navigate("".concat(location.pathname, "/assetsections/").concat(assetSectionId), {
                    state: navigationCustomerOptions
                });
            }
        }
    };
    var download3DFile = function () {
        setDownloadLoading(true);
        getAsset3DFile(assetId)
            .then(function (blobUrl) {
            if (blobUrl) {
                downloadFileFromBlobUrl(blobUrl, "Asset_".concat(assetId, "-3D.obj"));
            }
            else {
                setMessage("The requested file could not be found", ToastLevel.WARNING, 6000);
            }
        })
            .catch(function (err) {
            console.error('An error occurred:', err);
            setMessage("An error occurred while downloading the file", ToastLevel.ALERT, 6000);
        })
            .finally(function () {
            setDownloadLoading(false);
        });
    };
    var rightPageButtons = (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ icon: "icon-download-16", labelPosition: "right", onClick: download3DFile, primary: true, loading: downloadLoading, disabled: downloadLoading }, { children: formatMessage("asset.download3DFile") })), _jsx(Button, __assign({ icon: "icon-edit-16", labelPosition: "right", onClick: editAssetModal.open, primary: true }, { children: formatMessage("asset.edit") })), _jsx(Button, __assign({ icon: "icon-trash-16", labelPosition: "left", onClick: deleteAssetModal.open, secondary: true }, { children: formatMessage("asset.delete") }))] }));
    var rightAssetSectionButton = (_jsx(_Fragment, { children: _jsx(Button, __assign({ icon: "icon-plus-16", labelPosition: "right", onClick: createAssetSectionModal.open, primary: true }, { children: formatMessage("assetSection.addNew") })) }));
    var navStub = "/customers/".concat(customer === null || customer === void 0 ? void 0 : customer.id, "/sites/").concat(site === null || site === void 0 ? void 0 : site.id, "/assets/").concat(asset.id, "/assetsections");
    return !asset ? _jsx(NotFound, { id: assetId }) : (_jsxs(_Fragment, { children: [_jsx(PageTitle, { breadcrumbsPath: breadcrumbsPath, onPathChange: navigate, title: asset.name, extraItems: rightPageButtons }), _jsxs("div", __assign({ style: itemViewDetailsContainerStyles }, { children: [_jsx("h3", __assign({ style: itemViewSubtitleStyles }, { children: _jsx(FormattedMessage, { id: "asset.details" }) })), _jsx(Table, __assign({ style: itemViewDetailsTableStyles, tight: true }, { children: _jsx(Table.Body, { children: _jsxs(Table.Row, { children: [_jsx(Table.HeaderCell, __assign({ style: assetCellsStyle }, { children: _jsx(FormattedMessage, { id: "asset.description" }) })), _jsx(Table.Cell, __assign({ style: assetCellsStyle }, { children: (asset.description) ? asset.description : 'Empty' })), _jsx(Table.Cell, __assign({ style: __assign(__assign({}, assetCellsStyle), { display: 'flex', justifyContent: 'right', paddingRight: '6em' }) }, { children: _jsx("div", __assign({ style: __assign({}, assetImageViewStyle) }, { children: (loadingAssetImage)
                                                ? _jsx(LowSpinner, { id: "asset.imageLoading" })
                                                : _jsx("img", { src: assetImage, alt: "logo", style: { height: '98%', objectFit: 'contain' } }) })) }))] }) }) }))] })), _jsx("div", { style: itemViewSeparatorStyles }), _jsxs("div", __assign({ style: itemViewListContainerStyles }, { children: [_jsxs("div", __assign({ style: { display: "flex", justifyContent: "space-between", alignItems: "flex-start" } }, { children: [_jsx("h3", __assign({ style: itemViewSubtitleStyles }, { children: _jsx(FormattedMessage, { id: "assetSectionList.title" }) })), rightAssetSectionButton] })), _jsx("div", __assign({ style: asset.assetSections && asset.assetSections.length
                            ? itemViewListExtraTopPaddingStyles
                            : {} }, { children: _jsx(ListView, { data: asset.assetSections || [], noneYetKey: "assetSectionList.noassetSectionYet", onClickRow: function (a) { return navigate("".concat(navStub, "/").concat(a.id)); }, template: template }) }))] })), _jsx(AssetModal, { isOpen: isAssetModalOpen, onClose: editAssetModal.close, asset: asset, siteId: siteId, customerId: customerId }), _jsx(AssetSectionModal, { isOpen: isAssetSectionModalOpen, onClose: createAssetSectionModal.close, assetId: assetId, siteId: siteId, customerId: customerId }), _jsx(EntityDeleteModal, { isOpen: isDeleteModalOpen, onClose: deleteAssetModal.close, entity: asset, modalType: ModalTypes.AssetModal, customerId: customer === null || customer === void 0 ? void 0 : customer.id, siteId: siteId })] }));
});
export default AssetView;
