import { convertDateToDDMMMYYYY } from "../../utils/date-formatter-utils";
/**
 * Groups sensor batches by stock allocation based on a specified hardware variant filter.
 * It filters the sensor batches to include only those that match the specified hardware variant,
 * then groups sensors within these batches by customer and site, calculating the total quantity
 * for each unique combination of customer and site. The result is an array of stock allocation rows
 * suitable for display or further processing.
 *
 * @param {SensorBatch[]} sensorBatches - Array of sensor batch objects to be grouped.
 * @param {string} filteredBy - The hardware variant to filter sensor batches by.
 * @returns {StockAllocationRow[]} - An array of stock allocation rows, each representing a group
 *                                   of sensors allocated to a specific customer and site, with a
 *                                   total quantity for that grouping.
 */
export function groupSensorBatchesStockAllocation(sensorBatches, filteredBy, convertDate, sortAllocations) {
    if (convertDate === void 0) { convertDate = convertDateToDDMMMYYYY; }
    if (sortAllocations === void 0) { sortAllocations = sortStockAllocations; }
    if (!filteredBy || !sensorBatches)
        return [];
    var filteredBatches = filterSensorBatchesByHardwareVariant(sensorBatches, filteredBy);
    if (filteredBatches.length === 0)
        return [];
    var allocations = aggregateSensorsToStockAllocation(filteredBatches, convertDate);
    return sortAllocations(allocations);
}
/**
 * Filters sensor batches by the specified hardware variant.
 */
function filterSensorBatchesByHardwareVariant(sensorBatches, hardwareVariant) {
    return sensorBatches.filter(function (sb) { var _a; return ((_a = sb.hardwareVariant) === null || _a === void 0 ? void 0 : _a.toLowerCase().trim()) === hardwareVariant.toLowerCase().trim(); });
}
/**
 * Maps and aggregates sensors within batches to StockAllocationRow format.
 */
function aggregateSensorsToStockAllocation(filteredBatches, convertDate) {
    if (convertDate === void 0) { convertDate = convertDateToDDMMMYYYY; }
    var allocationsMap = {};
    filteredBatches.forEach(function (batch) {
        batch.sensors.forEach(function (sensor) {
            var _a;
            if (sensor.allocatedState && !sensor.dispatchDate) {
                var key = "".concat(sensor.customerName || 'Unknown', "-").concat(sensor.siteName || 'Unknown');
                if (!allocationsMap[key]) {
                    allocationsMap[key] = {
                        id: sensor.id,
                        customer: sensor.customerName || 'Unknown',
                        site: sensor.siteName || 'Unknown',
                        date: convertDate((_a = sensor.allocatedDate) !== null && _a !== void 0 ? _a : null),
                        quantity: 0
                    };
                }
                allocationsMap[key].quantity += 1;
            }
        });
    });
    return Object.values(allocationsMap);
}
/**
 * Sorts an array of stock allocation rows primarily by customer name, then by site name.
 * Null values for customers or sites are sorted to the end of the array. This function
 * ensures that the final list of allocations is presented in a consistent and predictable order.
 *
 * @param {StockAllocationRow[]} allocations - The array of stock allocation rows to be sorted.
 * @returns {StockAllocationRow[]} - The sorted array of stock allocation rows.
 */
function sortStockAllocations(allocations) {
    return allocations.sort(function (a, b) {
        // Sorting by customer name, nulls last
        if (a.customer === null)
            return 1;
        if (b.customer === null)
            return -1;
        if (a.customer < b.customer)
            return -1;
        if (a.customer > b.customer)
            return 1;
        // If customers are equal, then sort by site, nulls last
        if (a.site === null)
            return 1;
        if (b.site === null)
            return -1;
        if (a.site < b.site)
            return -1;
        if (a.site > b.site)
            return 1;
        return 0; // Customers and sites are equal
    });
}
/**
 * Normalizes the site name by converting "unknown" or empty values to `null`.
 * This function is designed to ensure that site names with the specific case-insensitive string "unknown",
 * as well as null or undefined values, are treated uniformly as `null` in the application's data processing logic.
 * This approach can be particularly useful for data cleaning and preparation before saving or further processing.
 *
 * @param {string | null | undefined} siteName - The site name to be checked and normalized. The value can be a string,
 *                                                including the case-insensitive string "unknown", or `null`/`undefined`
 *                                                to indicate that the site name is not specified.
 * @returns {string | null} - Returns the original `siteName` if it is neither "unknown" nor an empty value (including
 *                            `null` or `undefined`). Returns `null` if `siteName` is "unknown", an empty string, `null`,
 *                            or `undefined`, to signify the absence or irrelevance of the site name in the given context.
 *
 * Example usage:
 * const site = checkSiteName("Unknown"); // returns null
 * const site = checkSiteName(""); // returns null
 * const site = checkSiteName(null); // returns null
 * const site = checkSiteName("Main Site"); // returns "Main Site"
 */
export function convertUnknownSiteNameToNull(siteName) {
    if ((siteName === null || siteName === void 0 ? void 0 : siteName.toLocaleLowerCase()) === 'unknown' || !siteName) {
        return null;
    }
    return siteName;
}
/**
* Filters an array of sensors based on matching customer and site names with a given stock allocation.
* It normalizes the customer and site names to lowercase for comparison and handles "unknown" site names by converting them to null.
*
* @param {Sensor[]} sensors - An array of sensors to filter.
* @param {StockAllocation} stockAllocation - The stock allocation object containing the customer and site to match against.
* @returns {string[]} - An array of sensor IDs that match the given customer and site criteria.
*/
export function getMatchingSensorIds(sensors, customer, site) {
    return sensors
        .filter(function (sensor) {
        var _a, _b, _c;
        // Normalize customer names and compare
        var normalizedCustomerName = ((_a = sensor.customerName) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()) === (customer === null || customer === void 0 ? void 0 : customer.toLocaleLowerCase());
        // Convert "unknown" site names to null, normalize, and compare
        var normalizedSiteName = (_b = convertUnknownSiteNameToNull(site)) === null || _b === void 0 ? void 0 : _b.toLocaleLowerCase();
        // Explicitly handle null or undefined site names in comparison
        var siteNameMatch = sensor.siteName == null
            ? normalizedSiteName == null
            : ((_c = sensor.siteName) === null || _c === void 0 ? void 0 : _c.toLocaleLowerCase()) === normalizedSiteName;
        return normalizedCustomerName && siteNameMatch;
    })
        .map(function (sensor) { return sensor.id; });
}
