import { jsx as _jsx } from "react/jsx-runtime";
import { Spinner, useIntl } from '@metsooutotec/modes-react-components';
import { observer } from 'mobx-react-lite';
import { useEffect, } from 'react';
import { Outlet } from 'react-router-dom';
import linerMaterialStore from '../../../stores/provisions/liner-material-store';
var LinerMaterialLibraryLoader = observer(function () {
    var formatMessage = useIntl().formatMessage;
    var linerMaterials = linerMaterialStore.linerMaterials, loadAllLinerMaterials = linerMaterialStore.loadAllLinerMaterials, materialTypes = linerMaterialStore.materialTypes, loadAllMaterialTypes = linerMaterialStore.loadAllMaterialTypes;
    useEffect(function () {
        if (!linerMaterials.length) {
            loadAllLinerMaterials();
        }
        if (!materialTypes.length) {
            loadAllMaterialTypes();
        }
    });
    if (!linerMaterials.length || !materialTypes.length) {
        return _jsx(Spinner, { text: formatMessage("linerMaterial.loading"), style: { zIndex: 8 } });
    }
    else {
        return _jsx(Outlet, {});
    }
});
export default LinerMaterialLibraryLoader;
