var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ExtractLetters, GetLinerId, StripLastSegment } from "../editor-utils/id-string-utils";
import { AppendSurroundingPaths } from "../helpers/holes-helper";
import { SetLinerAtributes } from "../helpers/liners-helper";
import { fetchSvgImageFromUrl, RemoveUnListedClasses, ResetSelectedPath, SvgHandler } from "../helpers/svg-helpers";
import { CssSVGClasses, EditorSteps } from "../editor-utils/editor-types";
import linerLocationStore from "../../../../../stores/customer-stores/liner-locations-store";
import asEditorStore from "./../local-store/asset-section-editor-store";
import { ToastLevel } from "@metsooutotec/modes-react-components";
export var ProcessSubmitEditBox = function (_a) {
    var assetSectionId = _a.assetSectionId, setMessage = _a.setMessage;
    return __awaiter(void 0, void 0, void 0, function () {
        var extractedLettersFromInput, svgDocument, reference, pathElement, inpurtValue_1, existLinerLocations, referenceId, existLinerLocationInSVG, existLinerLocationInBackEnd, linerLocationId, updatedList, updatedList, linerLocationId, linerLocationId, linerLocation, newlinerRegionId, newHoleID, isDefined, updatedHolesList, existLinerRegions, linerLocationId, updatedReferencesList, blobUrl;
        var _b, _c, _d, _e, _f, _g, _h, _j;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0:
                    if (!asEditorStore.selectedPathId)
                        return [2 /*return*/];
                    extractedLettersFromInput = ExtractLetters(asEditorStore.editBoxInputValue);
                    return [4 /*yield*/, fetchSvgImageFromUrl(asEditorStore.assetSectionImage)];
                case 1:
                    svgDocument = (_k.sent()).svgDocument;
                    reference = "";
                    pathElement = null;
                    switch (asEditorStore.currentStep) {
                        case EditorSteps.LinersLocation:
                            // if a Liner has the save value as the edit box don't complete.
                            if (asEditorStore.firstLinerName === "" || asEditorStore.firstLinerName !== extractedLettersFromInput)
                                asEditorStore.setFirstLinerName(extractedLettersFromInput);
                            reference = StripLastSegment(asEditorStore.selectedPathId);
                            if (reference) {
                                inpurtValue_1 = asEditorStore.editBoxInputValue.trim();
                                existLinerLocations = linerLocationStore.GetLinerLocationsByAssetSectionId(assetSectionId);
                                pathElement = svgDocument.querySelector("path[id=\"".concat(inpurtValue_1, "\"]"));
                                referenceId = reference;
                                existLinerLocationInSVG = (pathElement === null || pathElement === void 0 ? void 0 : pathElement.id.trim().toLowerCase()) === inpurtValue_1.toLowerCase();
                                existLinerLocationInBackEnd = existLinerLocations.find(function (l) { return l.reference.trim().toLowerCase() === inpurtValue_1.toLowerCase(); });
                                if (existLinerLocationInSVG && existLinerLocationInBackEnd) {
                                    setMessage("The Input value is already reserved in the svg!", ToastLevel.WARNING, 8000);
                                    return [2 /*return*/];
                                }
                                else if (existLinerLocationInBackEnd && existLinerLocationInSVG) {
                                    setMessage("The Input value is already reserved in the svg and in backend!", ToastLevel.WARNING, 8000);
                                    return [2 /*return*/];
                                }
                                else if (existLinerLocationInBackEnd && !existLinerLocationInSVG) { //TODO:: see if this is a good way to handle it.
                                    referenceId = inpurtValue_1;
                                }
                                pathElement = svgDocument.querySelector("path[id=\"".concat(reference, "\"]"));
                                RemoveUnListedClasses(pathElement);
                                pathElement.classList.add(CssSVGClasses.WsLiner);
                                // Check and set stroke-width if not set or 0
                                SetLinerAtributes(pathElement, inpurtValue_1, (_b = asEditorStore.assetSectionSettings) === null || _b === void 0 ? void 0 : _b.lineWeight);
                                linerLocationId = (_c = linerLocationStore.GetLinerLocationIdByReference(assetSectionId, referenceId || inpurtValue_1)) !== null && _c !== void 0 ? _c : "";
                                updatedList = asEditorStore.selectedLiners.filter(function (liner) { return liner.existReference !== (pathElement === null || pathElement === void 0 ? void 0 : pathElement.id); });
                                asEditorStore.setSelectedLiners(__spreadArray(__spreadArray([], __read(updatedList), false), [{ id: linerLocationId, existReference: reference, newReference: inpurtValue_1 }], false).sort(function (a, b) { return a.newReference.localeCompare(b.newReference); }));
                            }
                            break;
                        case EditorSteps.WearReferencePlane:
                            reference = StripLastSegment(asEditorStore.selectedPathId);
                            if (reference) {
                                pathElement = svgDocument.querySelector("path[id=\"".concat(reference, "\"]"));
                                RemoveUnListedClasses(pathElement);
                                pathElement.classList.add(CssSVGClasses.WsWearReferencePlaneTemp);
                                updatedList = asEditorStore.selectedWearReferencePlane.filter(function (liner) { return liner.reference !== reference; });
                                linerLocationId = (_d = linerLocationStore.GetLinerLocationIdByReference(assetSectionId, reference)) !== null && _d !== void 0 ? _d : "";
                                asEditorStore.setSelectedWearReferencePlane(__spreadArray(__spreadArray([], __read(updatedList), false), [{ linerLocationId: linerLocationId, reference: reference, wallThickness: asEditorStore.editBoxInputValue }], false).sort(function (a, b) { return a.reference.localeCompare(b.reference); }));
                            }
                            break;
                        case EditorSteps.HolesLocation:
                            reference = StripLastSegment(asEditorStore.selectedPathId);
                            if (reference) {
                                linerLocationId = (_e = linerLocationStore.GetLinerLocationIdByReference(assetSectionId, asEditorStore.surroundingHoleLiner)) !== null && _e !== void 0 ? _e : "";
                                linerLocation = linerLocationStore.GetLinerLocationById(linerLocationId);
                                if (!linerLocation) {
                                    setMessage("Suronded LinerLocation not defined!", ToastLevel.WARNING, 8000);
                                    return [2 /*return*/];
                                }
                                newlinerRegionId = "".concat(asEditorStore.surroundingHoleLiner, "-Region").concat(asEditorStore.editBoxInputValue);
                                newHoleID = "".concat(newlinerRegionId, "-Hole1");
                                pathElement = svgDocument.querySelector("path[id=\"".concat(reference, "\"]"));
                                RemoveUnListedClasses(pathElement);
                                isDefined = pathElement.classList.contains(CssSVGClasses.WsHole);
                                if (!isDefined)
                                    pathElement.classList.add(CssSVGClasses.WsHole);
                                pathElement.setAttribute('id', newHoleID);
                                updatedHolesList = asEditorStore.selectedholes.filter(function (hole) { return hole.existReference !== (pathElement === null || pathElement === void 0 ? void 0 : pathElement.id); });
                                existLinerRegions = linerLocationStore.GetLinerRegionByLinerRegionRef(linerLocationId, newlinerRegionId);
                                asEditorStore.setSelectedHoles(__spreadArray(__spreadArray([], __read(updatedHolesList), false), [{
                                        linerLocationId: linerLocationId,
                                        id: (_f = existLinerRegions === null || existLinerRegions === void 0 ? void 0 : existLinerRegions.id) !== null && _f !== void 0 ? _f : "",
                                        newName: (_g = asEditorStore.editBoxLinerRegionNameInputValue) !== null && _g !== void 0 ? _g : "",
                                        existName: (_h = existLinerRegions === null || existLinerRegions === void 0 ? void 0 : existLinerRegions.name) !== null && _h !== void 0 ? _h : "",
                                        existReference: reference,
                                        newReference: newlinerRegionId
                                    }], false).sort(function (a, b) { return a.newReference.localeCompare(b.newReference); }));
                                AppendSurroundingPaths(document, asEditorStore.selectedPathId, pathElement, asEditorStore.editAssetSectionWindow.sensorNotificationArea, reference);
                            }
                            break;
                        case EditorSteps.LinersDrawingReferences:
                            if (asEditorStore.firstDrawingReferenceName === "" || asEditorStore.firstDrawingReferenceName !== extractedLettersFromInput)
                                asEditorStore.setFirstDrawingReferenceName(extractedLettersFromInput);
                            reference = GetLinerId(asEditorStore.selectedPathId); // Assuming we're using the same ID format for references, else create a new helper function.
                            if (reference) {
                                pathElement = svgDocument.querySelector("path[id=\"".concat(reference, "\"]"));
                                linerLocationId = (_j = linerLocationStore.GetLinerLocationIdByReference(assetSectionId, reference)) !== null && _j !== void 0 ? _j : "";
                                RemoveUnListedClasses(pathElement);
                                pathElement.classList.add(CssSVGClasses.WsReferenceTemp);
                                updatedReferencesList = asEditorStore.selectedLinersDrawingRef.filter(function (reference) { return reference.linerReference !== (pathElement === null || pathElement === void 0 ? void 0 : pathElement.id); });
                                asEditorStore.setSelectedLinersDrawingRef(__spreadArray(__spreadArray([], __read(updatedReferencesList), false), [{ linerLocationId: linerLocationId, linerReference: reference, newDrawingReferenceName: asEditorStore.editBoxInputValue }], false).sort(function (a, b) { return a.newDrawingReferenceName.localeCompare(b.newDrawingReferenceName); }));
                            }
                            break;
                    }
                    if (!reference) {
                        console.warn("Path has :".concat(asEditorStore.selectedPathId, ", so ").concat(asEditorStore.currentStep.toString().toUpperCase(), " could not be found"));
                        return [2 /*return*/];
                    }
                    ResetSelectedPath(asEditorStore.selectedPathId);
                    asEditorStore.resetUIStates();
                    blobUrl = SvgHandler.Serialize(svgDocument);
                    asEditorStore.setAssetSectionImage(blobUrl);
                    return [2 /*return*/];
            }
        });
    });
};
