var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, DateTimeInput, Dialog, DialogDescription, DialogHeader, Grid, GridRow, ToastLevel, useIntl, useToast } from "@metsooutotec/modes-react-components";
import { useCallback, useState } from "react";
import sensorBatchStore from "../../../stores/ioT-stores/sensor-batch-store";
import { convertDateToDDMMMYYYY, getTodayDateString } from "../../utils/date-formatter-utils";
var SensorsToggleModal = function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, sensorTable = _a.sensorTable, setSensorsTable = _a.setSensorsTable;
    // If sensorsToEdit is null or undefined, render nothing
    if (!sensorTable) {
        return null;
    }
    var formatMessage = useIntl().formatMessage;
    var _b = __read(useState({ isError: false, errorMessage: "" }), 2), datePickerError = _b[0], setDatePickerError = _b[1];
    var _c = __read(useState(getTodayDateString()), 2), manufacturedDate = _c[0], setManufacturedDate = _c[1];
    var _d = __read(useState(false), 2), isLoading = _d[0], setLoading = _d[1];
    var setMessage = useToast().setMessage;
    var handleDateChange = useCallback(function (value) {
        try {
            setDatePickerError({ isError: false, errorMessage: "" });
            var date = new Date(value);
            if (isNaN(date.getDate())) {
                setDatePickerError({ isError: true, errorMessage: "Invalid Date!" });
            }
            else {
                var formatedDate = convertDateToDDMMMYYYY(date);
                setManufacturedDate(formatedDate);
            }
        }
        catch (e) {
            console.log(e);
            setDatePickerError({ isError: true, errorMessage: e.message });
        }
    }, []);
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var selectedRows, listOfEditSensorDto, request, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    setLoading(true);
                    setDatePickerError({ isError: false, errorMessage: "" });
                    selectedRows = sensorTable.filter(function (s) { return s.isChecked; });
                    if (selectedRows.length === 0)
                        return [2 /*return*/];
                    setLoading(true);
                    listOfEditSensorDto = selectedRows.map(function (sensor) { return ({
                        id: sensor.id,
                        sensorBatchId: sensor.sensorBatchId,
                        serialNumber: sensor.serialNumber,
                        macAddress: sensor.macAddress,
                        isManufactured: !sensor.isManufactured,
                        manufacturedDate: (!sensor.isManufactured === true) ? manufacturedDate : null
                    }); });
                    return [4 /*yield*/, sensorBatchStore.toggleSensorsManuf(listOfEditSensorDto)];
                case 2:
                    request = _a.sent();
                    if (request.passed) {
                        setMessage("Sensors Manifactured states has been toggled", ToastLevel.SUCCESS, 6000);
                        setIsOpen(false);
                    }
                    else {
                        setMessage("Something whent wrong when Sensors Manifactured states has been toggled!", ToastLevel.ALERT, 6000);
                    }
                    sensorTable.forEach(function (s) { return s.isChecked = false; });
                    setLoading(false);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    setDatePickerError({ isError: true, errorMessage: "Form submission error, please read logs" });
                    console.error("Form submission error:", error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onCloseProcess = function () {
        setDatePickerError({ isError: false, errorMessage: "" });
        setIsOpen(false);
    };
    var labels = {
        confirm: formatMessage("modal.confirm"),
        cancel: formatMessage("modal.cancel"),
        header: formatMessage("sensorModal.toggleTitle"),
        description: formatMessage("sensorsModal.descriptionToggle"),
        manufacturedDate: formatMessage("sensorsModal.manufacturedDate"),
    };
    var renderButtons = function () {
        var buttonsConfig = [
            { label: labels.confirm, onClick: handleSubmit, primary: true },
            { label: labels.cancel, onClick: onCloseProcess, primary: false },
        ];
        return buttonsConfig.map(function (button, index) {
            var label = button.label, onClick = button.onClick, primary = button.primary;
            var buttonGridStyle = index === 0 ? {
                marginBottom: "var(--size-m)"
            } : {};
            return (_jsx(GridRow, __assign({ style: buttonGridStyle }, { children: _jsx(Button, { loading: isLoading, disabled: isLoading, onClick: onClick, flexible: true, children: label, centered: true, size: 'medium', primary: primary }) }), label));
        });
    };
    return (_jsxs(Dialog, __assign({ dimmer: true, open: isOpen, size: 'small' }, { children: [_jsxs(DialogHeader, __assign({ username: '' }, { children: [labels.header, " ", _jsxs("span", __assign({ style: { color: "red" } }, { children: ["# ", sensorTable.filter(function (s) { return s.isChecked; }).length] }))] })), datePickerError.isError && _jsx("p", __assign({ style: { color: 'red' } }, { children: datePickerError.errorMessage })), _jsx(DialogDescription, __assign({ style: { marginBottom: "var(--size-xxl)" } }, { children: labels.description })), _jsxs(Grid, __assign({ columns: 2 }, { children: [_jsx(GridRow, { children: _jsx(DateTimeInput, { label: labels.manufacturedDate, onChange: function (value) { return handleDateChange(value.toString()); }, value: manufacturedDate, format: "DD-MMM-YYYY", timeFormat: false, error: datePickerError.errorMessage }) }), renderButtons()] }))] })));
};
export default SensorsToggleModal;
