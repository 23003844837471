var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeAutoObservable, runInAction } from "mobx";
import { convertDDMMMYYtoDate, convertDDMMMYYYYtoDate } from "../../components/utils/date-formatter-utils";
import { addStockAllocation, editStockAllocation, deleteStockAllocation, editDispatches } from "../../services/stock-management-services/stock-management-service";
import customerStore from "../customer-stores/customer-store";
import sensorBatchStore, { DownloadGeneretedSensors } from "../ioT-stores/sensor-batch-store";
import { returnOnCatch, returnOnFail } from "../stores-helper";
var StockManagementStore = /** @class */ (function () {
    function StockManagementStore() {
        var _this = this;
        this.addStockAllocation = function (entity) { return __awaiter(_this, void 0, void 0, function () {
            var response, result, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.onStart();
                        return [4 /*yield*/, addStockAllocation(entity)];
                    case 1:
                        response = _a.sent();
                        if (response.statusCode === 200) {
                            result = response.result;
                            runInAction(function () {
                                result.forEach(function (s) {
                                    var _a;
                                    var sensorId = s.id;
                                    var existingSensor = sensorBatchStore.sensorMap[sensorId];
                                    var sensorBatchId = existingSensor.sensorBatchId;
                                    if (existingSensor) {
                                        var updateSensor_1 = __assign(__assign({}, existingSensor), { sensorDispatchInfoId: s.decommissionId, allocatedState: s.allocatedState, allocatedDate: s.allocatedDate, customerName: s.customerName, siteName: s.siteName, customerId: s.customerId, siteId: s.siteId });
                                        sensorBatchStore.sensorMap[sensorId] = updateSensor_1;
                                        var updatedSensors = (_a = sensorBatchStore.sensorBatchMap[sensorBatchId].sensors) === null || _a === void 0 ? void 0 : _a.map(function (s) { return s.id === sensorId ? __assign(__assign({}, s), updateSensor_1) : s; });
                                        sensorBatchStore.sensorBatchMap[sensorBatchId].sensors = updatedSensors;
                                    }
                                });
                            });
                            if (result.length > 0) {
                                DownloadGeneretedSensors("AddedStockAllocations", result);
                            }
                            return [2 /*return*/, { passed: true, message: "", entities: result }];
                        }
                        else {
                            return [2 /*return*/, returnOnFail()];
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _a.sent();
                        return [2 /*return*/, returnOnCatch(error_1)];
                    case 3:
                        this.onFinally();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.updateStockAllocation = function (entity) { return __awaiter(_this, void 0, void 0, function () {
            var response, error_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.onStart();
                        return [4 /*yield*/, editStockAllocation(entity)];
                    case 1:
                        response = _a.sent();
                        if (response.statusCode === 200) {
                            runInAction(function () {
                                var customer = customerStore.customers.find(function (c) { return c.id == entity.newCustomerId; });
                                var site = customerStore.sites.find(function (s) { return s.id == entity.newSiteId; });
                                entity.listOfSensorsIds.forEach(function (sensorId) {
                                    var _a, _b, _c, _d;
                                    var existingSensor = sensorBatchStore.sensorMap[sensorId];
                                    if (existingSensor) {
                                        // Update sensor details
                                        var updateSensor = __assign(__assign({}, existingSensor), { allocatedDate: convertDDMMMYYYYtoDate(entity.newAllocationDate), allocatedState: (entity.newAllocationDate) ? true : false, customerName: (_a = customer === null || customer === void 0 ? void 0 : customer.name) !== null && _a !== void 0 ? _a : null, customerId: (_b = customer === null || customer === void 0 ? void 0 : customer.id) !== null && _b !== void 0 ? _b : null, siteId: (_c = site === null || site === void 0 ? void 0 : site.id) !== null && _c !== void 0 ? _c : null, siteName: (_d = site === null || site === void 0 ? void 0 : site.name) !== null && _d !== void 0 ? _d : null });
                                        sensorBatchStore.sensorMap[sensorId] = updateSensor;
                                        // Update in sensorBatchMap if necessary
                                        _this.updatedSensorBatches(existingSensor, sensorId, updateSensor);
                                    }
                                });
                            });
                            return [2 /*return*/, { passed: true, message: "" }];
                        }
                        else {
                            return [2 /*return*/, returnOnFail()];
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        error_2 = _a.sent();
                        return [2 /*return*/, returnOnCatch(error_2)];
                    case 3:
                        this.onFinally();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.deleteStockAllocation = function (entity) { return __awaiter(_this, void 0, void 0, function () {
            var response, error_3;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.onStart();
                        return [4 /*yield*/, deleteStockAllocation(entity)];
                    case 1:
                        response = _a.sent();
                        if (response.statusCode === 200) {
                            runInAction(function () {
                                entity.listOfSensorsIds.forEach(function (sensorId) {
                                    var existingSensor = sensorBatchStore.sensorMap[sensorId];
                                    if (existingSensor) {
                                        // Update sensor details
                                        var updateSensor = __assign(__assign({}, existingSensor), { allocatedDate: null, allocatedState: false, customerId: null, customerName: null, siteId: null, siteName: null, dispatchDate: null, dispatchState: false, sensorDispatchInfoId: null });
                                        sensorBatchStore.sensorMap[sensorId] = updateSensor;
                                        // Update in sensorBatchMap if necessary
                                        _this.updatedSensorBatches(existingSensor, sensorId, updateSensor);
                                    }
                                });
                            });
                            return [2 /*return*/, { passed: true, message: "" }];
                        }
                        else {
                            return [2 /*return*/, returnOnFail()];
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        error_3 = _a.sent();
                        return [2 /*return*/, returnOnCatch(error_3)];
                    case 3:
                        runInAction(function () {
                            sensorBatchStore.sensorBatchLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.updateDispatch = function (entity) { return __awaiter(_this, void 0, void 0, function () {
            var response, error_4;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.onStart();
                        return [4 /*yield*/, editDispatches(entity)];
                    case 1:
                        response = _a.sent();
                        if (response.statusCode === 200) {
                            runInAction(function () {
                                var sensors = entity.dispatchEdits;
                                sensors.forEach(function (sensor) {
                                    var sensorId = sensor.sensorId;
                                    var existingSensor = sensorBatchStore.sensorMap[sensorId];
                                    if (existingSensor) {
                                        // Update sensor details
                                        var updateSensor = __assign(__assign({}, existingSensor), { dispatchDate: convertDDMMMYYtoDate(sensor.dispatchDate), dispatchState: sensor.dispatchState });
                                        sensorBatchStore.sensorMap[sensorId] = updateSensor;
                                        // Update in sensorBatchMap if necessary
                                        _this.updatedSensorBatches(existingSensor, sensorId, updateSensor);
                                    }
                                });
                            });
                            return [2 /*return*/, { passed: true, message: "" }];
                        }
                        else {
                            return [2 /*return*/, returnOnFail()];
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        error_4 = _a.sent();
                        return [2 /*return*/, returnOnCatch(error_4)];
                    case 3:
                        this.onFinally();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.sensorBatchStore = sensorBatchStore;
        makeAutoObservable(this);
    }
    Object.defineProperty(StockManagementStore.prototype, "isSensorBatchLoading", {
        get: function () {
            return this.sensorBatchStore.sensorBatchLoading;
        },
        enumerable: false,
        configurable: true
    });
    StockManagementStore.prototype.onStart = function () {
        runInAction(function () {
            sensorBatchStore.sensorBatchLoading = true;
        });
    };
    StockManagementStore.prototype.onFinally = function () {
        runInAction(function () {
            sensorBatchStore.sensorBatchLoading = false;
        });
    };
    StockManagementStore.prototype.updatedSensorBatches = function (existingSensor, sensorId, updateSensor) {
        var _a;
        var sensorBatchId = existingSensor === null || existingSensor === void 0 ? void 0 : existingSensor.sensorBatchId;
        var updatedSensors = (_a = sensorBatchStore.sensorBatchMap[sensorBatchId].sensors) === null || _a === void 0 ? void 0 : _a.map(function (s) { return s.id === sensorId ? __assign(__assign({}, s), updateSensor) : s; });
        sensorBatchStore.sensorBatchMap[sensorBatchId].sensors = updatedSensors;
    };
    return StockManagementStore;
}());
export default new StockManagementStore();
