var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogHeader, DialogDescription, Input, Grid, GridRow, useIntl, Icon } from '@metsooutotec/modes-react-components';
import customerStore from 'stores/customer-stores/customer-store';
import { validateEmail, validateText, validateAddress, validateHours, validateSiteLogitudeLatitudeDecimal } from '../../../validators/inputValidators';
import { addressParss, ModalTypes, validateSiteForm } from '../customers-utils/customer-modal-helper';
import { handleUploadFileClick, resetRecordFormData } from '../../utils/modal-helper';
/**
 * Modal component for creating or editing a customer.
 */
var SiteModal = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var isOpen = _a.isOpen, onClose = _a.onClose, site = _a.site, customerId = _a.customerId;
    var formatMessage = useIntl().formatMessage;
    var _k = __read(useState(false), 2), isLoading = _k[0], setLoading = _k[1];
    var _l = __read(useState(Boolean(site === null || site === void 0 ? void 0 : site.id)), 2), isEditing = _l[0], setIsEditing = _l[1];
    var _m = __read(useState({ isError: false, errorMessage: "" }), 2), isError = _m[0], setIsError = _m[1];
    var _o = __read(useState(false), 2), isFormModified = _o[0], setFormModified = _o[1];
    var _p = __read(useState(null), 2), selectedFlowSheet = _p[0], setSelectedFlowSheet = _p[1];
    var _q = __read(useState({
        siteName: (site === null || site === void 0 ? void 0 : site.name) || "",
        address: (site === null || site === void 0 ? void 0 : site.address) ? "".concat((_b = site === null || site === void 0 ? void 0 : site.address) === null || _b === void 0 ? void 0 : _b.country, ", ").concat((_c = site === null || site === void 0 ? void 0 : site.address) === null || _c === void 0 ? void 0 : _c.state, ", ").concat((_d = site === null || site === void 0 ? void 0 : site.address) === null || _d === void 0 ? void 0 : _d.region) : "",
        contact: (site === null || site === void 0 ? void 0 : site.contact) || "",
        metsoContact: (site === null || site === void 0 ? void 0 : site.metsoContact) || "",
        latitude: ((_f = (_e = site === null || site === void 0 ? void 0 : site.address) === null || _e === void 0 ? void 0 : _e.latitude) === null || _f === void 0 ? void 0 : _f.toString()) || "",
        longitude: ((_h = (_g = site === null || site === void 0 ? void 0 : site.address) === null || _g === void 0 ? void 0 : _g.longitude) === null || _h === void 0 ? void 0 : _h.toString()) || "",
        utcOffsetHours: ((_j = site === null || site === void 0 ? void 0 : site.utcOffsetHours) === null || _j === void 0 ? void 0 : _j.toString()) || "",
        uploadFlowSheetName: selectedFlowSheet === null || selectedFlowSheet === void 0 ? void 0 : selectedFlowSheet.name,
    }), 2), formData = _q[0], setFormData = _q[1];
    var _r = __read(useState({
        siteName: "",
        address: "",
        contact: "",
        metsoContact: "",
        latitude: "",
        longitude: "",
        utcOffsetHours: "",
    }), 2), formErrors = _r[0], setFormErrors = _r[1];
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (site) {
            var isSameSite = site.id === formData.id;
            if (isSameSite)
                return;
            setIsEditing(Boolean(site.id));
            setFormData({
                id: site.id,
                siteName: site.name || "",
                address: (site === null || site === void 0 ? void 0 : site.address)
                    ? "".concat((_a = site === null || site === void 0 ? void 0 : site.address) === null || _a === void 0 ? void 0 : _a.country, ", ").concat((_b = site === null || site === void 0 ? void 0 : site.address) === null || _b === void 0 ? void 0 : _b.state, ", ").concat((_c = site === null || site === void 0 ? void 0 : site.address) === null || _c === void 0 ? void 0 : _c.region)
                    : 'null,null,null',
                contact: (site === null || site === void 0 ? void 0 : site.contact) || "",
                metsoContact: (site === null || site === void 0 ? void 0 : site.metsoContact) || "",
                latitude: ((_e = (_d = site === null || site === void 0 ? void 0 : site.address) === null || _d === void 0 ? void 0 : _d.latitude) === null || _e === void 0 ? void 0 : _e.toString()) || "",
                longitude: ((_g = (_f = site === null || site === void 0 ? void 0 : site.address) === null || _f === void 0 ? void 0 : _f.longitude) === null || _g === void 0 ? void 0 : _g.toString()) || "",
                utcOffsetHours: ((_h = site.utcOffsetHours) === null || _h === void 0 ? void 0 : _h.toString()) || "",
            });
        }
        else {
            setIsEditing(false);
            resetRecordFormData(setFormData);
        }
        // Reset the form modification state when the site changes
        setFormModified(false);
    }, [site, formData.id]);
    var resetForm = function () {
        setIsError({ isError: false, errorMessage: "" });
        resetRecordFormData(setFormData);
        resetRecordFormData(setFormErrors);
        setSelectedFlowSheet(null);
    };
    var handleValidation = function (fieldName, validationError) {
        setFormErrors(function (prevErrors) {
            var _a;
            return (__assign(__assign({}, prevErrors), (_a = {}, _a[fieldName] = validationError, _a)));
        });
    };
    var handleChange = function (fieldName, value) {
        isError.isError = false;
        setFormData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[fieldName] = value, _a)));
        });
        // Set the form modification state to true
        setFormModified(true);
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var errors, hasErrors, siteName, contact, metsoContact, address, longitude, latitude, utcOffsetHours, splidedAddress, newAddress, siteData, requestResult, routeParam, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    // Check if the form has been modified
                    if (isEditing && !isFormModified && !selectedFlowSheet) {
                        setIsError({
                            isError: true,
                            errorMessage: "No changes made. Please update the form before submitting.",
                        });
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    errors = validateSiteForm(formData);
                    hasErrors = Object.values(errors).some(function (error) { return error !== ""; });
                    if (hasErrors) {
                        setIsError({ isError: true, errorMessage: "There are validation errors, update the form texts" });
                        setFormErrors(errors);
                        setLoading(false); // Reset loading state
                        return [2 /*return*/];
                    }
                    siteName = formData.siteName, contact = formData.contact, metsoContact = formData.metsoContact, address = formData.address, longitude = formData.longitude, latitude = formData.latitude, utcOffsetHours = formData.utcOffsetHours;
                    splidedAddress = addressParss(address);
                    newAddress = __assign(__assign({}, splidedAddress), { longitude: longitude, latitude: latitude });
                    siteData = {
                        customerId: customerId || '',
                        address: newAddress,
                        utcOffsetHours: utcOffsetHours,
                        flowSheetImage: selectedFlowSheet || new File([], 'empty_file'),
                        name: siteName,
                        contact: contact,
                        metsoContact: metsoContact
                    };
                    return [4 /*yield*/, (isEditing
                            ? customerStore.editEntity(__assign(__assign({}, siteData), { id: site === null || site === void 0 ? void 0 : site.id }), ModalTypes.SiteModal)
                            : customerStore.createEntity(siteData, ModalTypes.SiteModal))];
                case 2:
                    requestResult = _a.sent();
                    if (!requestResult.passed) {
                        setIsError({ isError: true, errorMessage: requestResult.message });
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    //Handle success
                    console.log("Form submitted successfully!", requestResult.message, requestResult.entityId);
                    resetForm(); // Reset form data and errors
                    routeParam = { created: !isEditing, edited: isEditing };
                    onClose(true, requestResult.entityId, routeParam);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    setIsError({ isError: true, errorMessage: "Form submission error, please read logs" });
                    console.error("Form submission error:", error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onCloseProcess = function () {
        resetForm();
        setIsError({ isError: false, errorMessage: "" });
        onClose(false);
    };
    var onClearClick = function (inputId) {
        setFormData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[inputId] = "", _a)));
        });
        setFormErrors(function (prevErrors) {
            var _a;
            return (__assign(__assign({}, prevErrors), (_a = {}, _a[inputId] = "", _a)));
        });
    };
    var handleUploadFlowSheetClick = function () {
        handleUploadFileClick(".svg", setSelectedFlowSheet, setFormData, null, "uploadFlowSheetName");
    };
    var clearFlowSheetFile = function () {
        console.log('unselect:', setSelectedFlowSheet === null || setSelectedFlowSheet === void 0 ? void 0 : setSelectedFlowSheet.name);
        setFormData(function (prevData) { return (__assign(__assign({}, prevData), { uploadFlowSheetName: "" })); });
        setSelectedFlowSheet(null);
    };
    var labels = {
        siteName: formatMessage("siteModal.name"),
        address: formatMessage("siteModal.address"),
        contact: formatMessage("siteModal.contact"),
        metsoContact: formatMessage("siteModal.metsoContact"),
        confirm: formatMessage("modal.confirm"),
        cancel: formatMessage("modal.cancel"),
        headerEdit: formatMessage("siteModal.headerEdit"),
        header: formatMessage("siteModal.header"),
        description: formatMessage("modal.description"),
        latitude: formatMessage("siteModal.latitude"),
        longitude: formatMessage("siteModal.longitude"),
        utcOffsetHours: formatMessage("siteModal.utcOffsetHours"),
        uploadFlowSheet: formatMessage("siteModal.uploadFlowSheet"),
    };
    var inputConfig = [
        { id: "siteName", label: labels.siteName, placeholder: "Type ".concat(labels.siteName, " here..."), type: "string", validate: validateText, isRequired: true },
        { id: "address", label: labels.address, placeholder: "Type ".concat(labels.address, " here..."), type: "string", validate: validateAddress, isRequired: false },
        { id: "longitude", label: labels.longitude, placeholder: "Type ".concat(labels.longitude, " here..."), type: "string", validate: validateSiteLogitudeLatitudeDecimal, isRequired: false },
        { id: "latitude", label: labels.latitude, placeholder: "Type ".concat(labels.latitude, " here..."), type: "string", validate: validateSiteLogitudeLatitudeDecimal, isRequired: false },
        { id: "utcOffsetHours", label: labels.utcOffsetHours, placeholder: "Type ".concat(labels.utcOffsetHours, " here..."), type: "string", validate: validateHours, isRequired: true },
        { id: "contact", label: labels.contact, placeholder: "Type ".concat(labels.contact, " here..."), type: "email", validate: validateEmail, isRequired: false },
        { id: "metsoContact", label: labels.metsoContact, placeholder: "Type ".concat(labels.metsoContact, " here..."), type: "email", validate: validateEmail, isRequired: true },
        { id: "uploadFlowSheetName", label: labels.uploadFlowSheet, placeholder: "Select from files...", type: "string", onFileUpload: handleUploadFlowSheetClick, unSelectFile: clearFlowSheetFile, isRequired: false },
    ];
    var flexItemStyle = {
        marginBottom: "var(--size-m)"
    };
    var renderInputs = function () {
        return (_jsxs(_Fragment, { children: [inputConfig.slice(0, 7).map(function (input, index) {
                    var id = input.id, label = input.label, validate = input.validate, type = input.type, placeholder = input.placeholder, isRequired = input.isRequired;
                    var noValidationRequired = !isRequired && !formData[id];
                    var clearIcon = formData[id] && (_jsx(Icon, { className: "icon-cross-circle-filled-16", onClick: function () { return onClearClick(id); } }));
                    var inputProps = {
                        id: id,
                        label: label,
                        placeholder: placeholder,
                        validate: (noValidationRequired) ? undefined : validate,
                        onValidation: (noValidationRequired) ? undefined : (function (validationError) { return handleValidation(id, validationError); }),
                        onChange: function (e) { return handleChange(id, e.target.value); },
                        type: type,
                        error: (noValidationRequired) ? undefined : formErrors[id],
                        spanning: true,
                        heightClass: 'medium',
                        required: isRequired,
                        uiRequired: isRequired,
                        icon: clearIcon,
                        value: formData[id]
                    };
                    return (_jsx(GridRow, __assign({ style: flexItemStyle }, { children: _jsx(Input, __assign({}, inputProps)) }), id));
                }), inputConfig.slice(7).map(function (input, index) {
                    var id = input.id, label = input.label, type = input.type, placeholder = input.placeholder, onFileUpload = input.onFileUpload, unSelectFile = input.unSelectFile;
                    var icons = (_jsxs(_Fragment, { children: [formData[id] && (_jsx(Icon, { className: "icon-cross-circle-filled-16", onClick: unSelectFile, style: { marginRight: '2px' } })), _jsx(Icon, { className: "icon-upload-16", onClick: onFileUpload })] }));
                    var inputProps = {
                        id: id,
                        label: label,
                        placeholder: placeholder,
                        onChange: function (e) { return handleChange(id, e.target.value); },
                        type: type,
                        error: formErrors[id],
                        spanning: true,
                        heightClass: 'medium',
                        onClick: onFileUpload,
                        value: formData[id],
                        icon: icons,
                        readOnly: true,
                    };
                    return (_jsx(GridRow, __assign({ style: flexItemStyle }, { children: _jsx(Input, __assign({}, inputProps)) }), id));
                })] }));
    };
    var renderButtons = function () {
        var buttonsConfig = [
            { label: labels.confirm, onClick: handleSubmit, primary: true },
            { label: labels.cancel, onClick: onCloseProcess, primary: false },
        ];
        return buttonsConfig.map(function (button, index) {
            var label = button.label, onClick = button.onClick, primary = button.primary;
            var buttonGridStyle = index === 0 ? {
                marginBottom: "var(--size-m)"
            } : {};
            return (_jsx(GridRow, __assign({ style: buttonGridStyle }, { children: _jsx(Button, { loading: isLoading, disabled: isLoading, onClick: onClick, flexible: true, children: label, centered: true, size: 'medium', primary: primary }) }), label));
        });
    };
    return (_jsx(Grid, { children: _jsxs(Dialog, __assign({ dimmer: true, open: isOpen, size: 'small', style: { maxWidth: "512px" } }, { children: [_jsx(DialogHeader, __assign({ username: '' }, { children: isEditing ? labels.headerEdit : labels.header })), isError.isError && _jsx("p", __assign({ style: { color: 'red' } }, { children: isError.errorMessage })), _jsx(DialogDescription, __assign({ style: { marginBottom: "var(--size-xxl)" } }, { children: labels.description })), renderInputs(), _jsx("div", { style: { marginBottom: "var(--size-xxl)" } }), renderButtons()] })) }));
};
export default SiteModal;
