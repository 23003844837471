var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var stripList = [
    'xmlns:',
    'rdf:',
    'inkscape:',
    '-inkscape-font-specification',
    'sodipodi'
];
var htmlDecode = function (input) {
    var e = document.createElement('div');
    e.innerHTML = input;
    var val = e.childNodes[0].nodeValue;
    if (val) {
        return val;
    }
    return '';
};
var styleToObject = function (input) {
    var result = {};
    var attributes = input.split(';');
    for (var i = 0; i < attributes.length; i++) {
        var entry = attributes[i].split(':');
        var keyR = entry.splice(0, 1)[0];
        var key = covertToCamelCase(keyR.trim(), '-');
        if (key === '')
            continue;
        var value = entry.join(':');
        result[key] = value.trim();
    }
    return result;
};
var capitalizeFirstLetter = function (key) {
    return key.charAt(0).toUpperCase() + key.slice(1);
};
var covertToCamelCase = function (key, splitter) {
    if (!key.includes(splitter))
        return key;
    var _a = __read(key.split(splitter), 2), head = _a[0], tail = _a[1];
    return "".concat(head).concat(covertToCamelCase(capitalizeFirstLetter(tail), splitter));
};
var attrConvert = function (key, value) {
    if (stripList.some(function (sl) { return key.includes(sl); }))
        return [key, null];
    if (key === 'class')
        return ['className', value];
    if (key === 'style') {
        var s = styleToObject(value);
        return ['style', s];
    }
    if (key.includes('-')) {
        if (key.startsWith('data-'))
            return [key, value];
        return [covertToCamelCase(key, '-'), value];
    }
    if (key.includes(':')) {
        return [covertToCamelCase(key, ':'), value];
    }
    return [key, value];
};
var parseAttrs = function (attrs) {
    if (!attrs)
        return {};
    var newAttrs = {};
    Object.entries(attrs).forEach(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        var _c = __read(attrConvert(key, value), 2), newKey = _c[0], newValue = _c[1];
        if (newValue) {
            newAttrs[newKey] = newValue;
        }
    });
    return newAttrs;
};
export function SVGFromJson(_a) {
    var json = _a.json, data = _a.data, rest = __rest(_a, ["json", "data"]);
    if (!json)
        return null;
    if (json.type === 'text') {
        return _jsx(React.Fragment, { children: htmlDecode(json.value) });
    }
    var attrs = parseAttrs(json.attributes);
    var mainSVG = [];
    if (json.children && Array.isArray(json.children)) {
        mainSVG = json.children.map(function (c, idx) {
            return _jsx(SVGFromJson, { json: c, data: data }, idx);
        });
    }
    return React.createElement(json.name, attrs, mainSVG);
}
