var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { isValidDateDDMMMYYYY } from "./inputValidators";
// Validation function
export var isValidRowSensorsCSVReader = function (row) {
    if (row.length < 2)
        return false;
    var _a = __read(row, 4), serial = _a[0], mac = _a[1], isManu = _a[2], manuDate = _a[3];
    var isSerialString = typeof serial === 'string';
    var macAsString = mac.toString();
    var isMacValid = /^[\dA-Fa-f]{16}$/.test(macAsString);
    // Default to true; these only change if the columns are present.
    var isIsManuValid = true;
    var isManuDateValid = true;
    // If there are more than 2 columns, validate isManu and manuDate.
    if (row.length > 2) {
        isIsManuValid = isManu === null || typeof isManu === 'boolean';
        isManuDateValid = manuDate === null || isValidDateDDMMMYYYY(manuDate);
    }
    return isSerialString && isMacValid && isIsManuValid && isManuDateValid;
};
