import { jsx as _jsx } from "react/jsx-runtime";
import { Icon } from "@metsooutotec/modes-react-components";
import { ProvisionTypes } from "models/provisionsModuleModels";
export function provisionTypeToIcon(type) {
    switch (type) {
        case ProvisionTypes.Commission:
            return _jsx(Icon, { name: "icon-ok-circle-16", color: "green" });
        case ProvisionTypes.Decommission:
            return _jsx(Icon, { name: "icon-cross-circle-16", color: "red" });
        case ProvisionTypes.Planned:
            return _jsx(Icon, { name: "icon-ok-circle-16", color: "orange" });
    }
}
