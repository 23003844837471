import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useEffect, } from 'react';
import { Outlet } from 'react-router-dom';
import sensorBatchStore from '../../../stores/ioT-stores/sensor-batch-store';
import LowSpinner from '../../common/low-spinner';
var SensorBatchLoader = observer(function () {
    var sensorBatches = sensorBatchStore.sensorBatches, loadAllSensorBatches = sensorBatchStore.loadAllSensorBatches;
    useEffect(function () {
        if (!sensorBatches.length) {
            loadAllSensorBatches();
        }
    });
    if (!sensorBatches.length) {
        return _jsx(LowSpinner, { id: "sensorBatch.loading" });
    }
    else {
        return _jsx(Outlet, {});
    }
});
export default SensorBatchLoader;
