var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { makeAutoObservable, observable, runInAction } from "mobx";
import { deleteLinerMaterial, editLinerMaterial, getAllLinerMaterials, getAllMaterialTypes, postNewLinerMaterial } from "services/provision-services/liner-materal-service";
var LinerMaterialStore = /** @class */ (function () {
    function LinerMaterialStore() {
        var _this = this;
        this.linerMaterialMap = observable.object({});
        this.linerMaterialLoading = false;
        this.materialTypesMap = observable.object({});
        this.materialTypesLoading = false;
        this.loadAllLinerMaterials = function () { return __awaiter(_this, void 0, void 0, function () {
            var data;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.linerMaterialLoading) {
                            return [2 /*return*/];
                        }
                        runInAction(function () {
                            _this.linerMaterialLoading = true;
                        });
                        return [4 /*yield*/, getAllLinerMaterials()];
                    case 1:
                        data = _a.sent();
                        try {
                            runInAction(function () {
                                var e_1, _a;
                                try {
                                    for (var data_1 = __values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                                        var linerMaterial = data_1_1.value;
                                        _this.linerMaterialMap[linerMaterial.id] = observable.object(linerMaterial);
                                    }
                                }
                                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                                finally {
                                    try {
                                        if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
                                    }
                                    finally { if (e_1) throw e_1.error; }
                                }
                                _this.sortLinerMaterialMap();
                            });
                        }
                        catch (e) {
                            console.log(e);
                        }
                        finally {
                            this.linerMaterialLoading = false;
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.loadAllMaterialTypes = function () { return __awaiter(_this, void 0, void 0, function () {
            var data;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.materialTypesLoading) {
                            return [2 /*return*/];
                        }
                        runInAction(function () {
                            _this.materialTypesLoading = true;
                        });
                        return [4 /*yield*/, getAllMaterialTypes()];
                    case 1:
                        data = _a.sent();
                        try {
                            runInAction(function () {
                                var e_2, _a;
                                try {
                                    for (var data_2 = __values(data), data_2_1 = data_2.next(); !data_2_1.done; data_2_1 = data_2.next()) {
                                        var materialType = data_2_1.value;
                                        _this.materialTypesMap[materialType.id] = observable.object(materialType);
                                    }
                                }
                                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                                finally {
                                    try {
                                        if (data_2_1 && !data_2_1.done && (_a = data_2.return)) _a.call(data_2);
                                    }
                                    finally { if (e_2) throw e_2.error; }
                                }
                                _this.sortMaterialTypeMap();
                            });
                        }
                        catch (e) {
                            console.log(e);
                        }
                        finally {
                            this.materialTypesLoading = false;
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.createEntity = function (entity) { return __awaiter(_this, void 0, void 0, function () {
            var response, createdEntity_1, entityId_1, message, error_1;
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.linerMaterialLoading) {
                            return [2 /*return*/, { passed: false, message: "", entityId: "" }];
                        }
                        runInAction(function () {
                            _this.linerMaterialLoading = true;
                        });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, postNewLinerMaterial(entity)];
                    case 2:
                        response = _b.sent();
                        if (response.statusCode === 200) {
                            createdEntity_1 = response.result;
                            entityId_1 = (_a = createdEntity_1 === null || createdEntity_1 === void 0 ? void 0 : createdEntity_1.id) !== null && _a !== void 0 ? _a : "";
                            runInAction(function () {
                                _this.linerMaterialMap[entityId_1] = observable.object(createdEntity_1);
                                _this.sortLinerMaterialMap();
                            });
                            return [2 /*return*/, { passed: true, message: "", entityId: entityId_1 }];
                        }
                        else if (response.statusCode === 409) {
                            return [2 /*return*/, { passed: false, message: "Conflict-409: an entity with the same name is exist.", entityId: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            return [2 /*return*/, { passed: false, message: message, entityId: "" }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _b.sent();
                        console.error("Error creating entity:", error_1);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs", entityId: "" }];
                    case 4:
                        runInAction(function () {
                            _this.linerMaterialLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.editEntity = function (entity) { return __awaiter(_this, void 0, void 0, function () {
            var response, message, error_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.linerMaterialLoading) {
                            return [2 /*return*/, { passed: false, message: "", entityId: "" }];
                        }
                        runInAction(function () {
                            _this.linerMaterialLoading = true;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, editLinerMaterial(entity)];
                    case 2:
                        response = _a.sent();
                        if (response.statusCode === 200) {
                            this.linerMaterialMap[entity.id] = observable.object(entity);
                            return [2 /*return*/, { passed: true, message: "", entityId: entity.id }];
                        }
                        else if (response.statusCode === 409) {
                            return [2 /*return*/, { passed: false, message: "Conflict-409: an entity with the same name is exist.", entityId: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            return [2 /*return*/, { passed: false, message: message, entityId: "" }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_2 = _a.sent();
                        console.error("Error editing entity:", error_2);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs", entityId: "" }];
                    case 4:
                        runInAction(function () {
                            _this.linerMaterialLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.deleteEntitiy = function (entityId) { return __awaiter(_this, void 0, void 0, function () {
            var response, message, error_3;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.linerMaterialLoading) {
                            return [2 /*return*/, { passed: false, message: "" }];
                        }
                        runInAction(function () {
                            _this.linerMaterialLoading = true;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, deleteLinerMaterial(entityId)];
                    case 2:
                        response = _a.sent();
                        if (response.statusCode === 200) {
                            delete this.linerMaterialMap[entityId];
                            return [2 /*return*/, { passed: true, message: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            return [2 /*return*/, { passed: false, message: message }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_3 = _a.sent();
                        console.error("Error creating customer:", error_3);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs" }];
                    case 4:
                        runInAction(function () {
                            _this.linerMaterialLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        makeAutoObservable(this);
    }
    Object.defineProperty(LinerMaterialStore.prototype, "linerMaterials", {
        get: function () {
            return Object.values(this.linerMaterialMap);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LinerMaterialStore.prototype, "materialTypes", {
        get: function () {
            return Object.values(this.materialTypesMap);
        },
        enumerable: false,
        configurable: true
    });
    LinerMaterialStore.prototype.sortLinerMaterialMap = function () {
        var e_3, _a;
        var sortedKeys = Object.keys(this.linerMaterialMap).sort(function (a, b) {
            return a < b ? -1 : 1;
        });
        var sortedLinerMaterialMap = {};
        try {
            for (var sortedKeys_1 = __values(sortedKeys), sortedKeys_1_1 = sortedKeys_1.next(); !sortedKeys_1_1.done; sortedKeys_1_1 = sortedKeys_1.next()) {
                var key = sortedKeys_1_1.value;
                sortedLinerMaterialMap[key] = this.linerMaterialMap[key];
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (sortedKeys_1_1 && !sortedKeys_1_1.done && (_a = sortedKeys_1.return)) _a.call(sortedKeys_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.linerMaterialMap = sortedLinerMaterialMap;
    };
    LinerMaterialStore.prototype.sortMaterialTypeMap = function () {
        var e_4, _a;
        var sortedKeys = Object.keys(this.materialTypesMap).sort(function (a, b) {
            return a < b ? -1 : 1;
        });
        var sortedMaterialTypesMap = {};
        try {
            for (var sortedKeys_2 = __values(sortedKeys), sortedKeys_2_1 = sortedKeys_2.next(); !sortedKeys_2_1.done; sortedKeys_2_1 = sortedKeys_2.next()) {
                var key = sortedKeys_2_1.value;
                sortedMaterialTypesMap[key] = this.materialTypesMap[key];
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (sortedKeys_2_1 && !sortedKeys_2_1.done && (_a = sortedKeys_2.return)) _a.call(sortedKeys_2);
            }
            finally { if (e_4) throw e_4.error; }
        }
        this.materialTypesMap = sortedMaterialTypesMap;
    };
    return LinerMaterialStore;
}());
export default new LinerMaterialStore();
