import { validateAddress, validateEmail, validateHours, validateSiteLogitudeLatitudeDecimal, validateText } from "../../../validators/inputValidators";
/**
 * Parses an address line and returns an Address object.
 *
 * @param addressLine - The address line to parse.
 * @returns The parsed Address object with properties country, state, and region.
 */
export var addressParss = function (addressLine) {
    var addressValues = addressLine.split(",");
    return {
        country: (addressValues[0] || "").trim(),
        state: (addressValues[1] || "").trim(),
        region: (addressValues[2] || "").trim(),
        latitude: '0',
        longitude: '0'
    };
};
/**
 * Validates a customer form data.
 *
 * @param formData - The customer form data to validate.
 * @returns An object containing validation errors for each form field.
 */
export var validateCustomerForm = function (formData) {
    var errors = {};
    errors.customerName = validateText(formData.customerName);
    errors.contact = (formData.contact) ? validateEmail(formData.contact) : "";
    errors.metsoContact = validateEmail(formData.metsoContact);
    return errors;
};
/**
 * Validates a site form data.
 *
 * @param formData - The site form data to validate.
 * @returns An object containing validation errors for each form field.
 */
export var validateSiteForm = function (formData) {
    var errors = {};
    errors.siteName = validateText(formData.siteName);
    errors.address = validateAddress(formData.address);
    errors.contact = (formData.contact) ? validateEmail(formData.contact) : "";
    errors.metsoContact = validateEmail(formData.metsoContact);
    errors.latitude = validateSiteLogitudeLatitudeDecimal(formData.latitude);
    errors.longitude = validateSiteLogitudeLatitudeDecimal(formData.longitude);
    errors.utcOffsetHours = validateHours(formData.utcOffsetHours);
    return errors;
};
export var ModalTypes;
(function (ModalTypes) {
    ModalTypes[ModalTypes["CustomerModal"] = 0] = "CustomerModal";
    ModalTypes[ModalTypes["SiteModal"] = 1] = "SiteModal";
    ModalTypes[ModalTypes["AssetModal"] = 2] = "AssetModal";
    ModalTypes[ModalTypes["AssetSectionModal"] = 3] = "AssetSectionModal";
})(ModalTypes || (ModalTypes = {}));
