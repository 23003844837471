var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button, PageTitle, useIntl, useToast, ToastLevel, Icon } from '@metsooutotec/modes-react-components';
import customerStore from 'stores/customer-stores/customer-store';
import ListView from 'components/utils/list-view/list-view';
import CustomerModal from './customer-modal';
import { itemViewDetailsContainerStyles } from '../../common-style/common-style';
var CustomerListView = observer(function () {
    var formatMessage = useIntl().formatMessage;
    var setMessage = useToast().setMessage;
    var navigate = useNavigate();
    var location = useLocation();
    var customers = customerStore.customers;
    var deleteParams = location.state;
    var _a = deleteParams || {}, Deleted = _a.deleted, DeletedCustomerName = _a.entityName;
    var _b = __read(useState(false), 2), isModalOpen = _b[0], setIsModalOpen = _b[1];
    //if customerToEdit is undefinded the modal will open with empty fields to create a new customer.
    var _c = __read(useState(), 2), customerToEdit = _c[0], setCustomerToEdit = _c[1];
    var template = [
        {
            headingKey: "headings.customer",
            cellFunc: function (c) { return c.name; },
            isSearchable: true,
        },
        {
            headingKey: "headings.contact",
            cellFunc: function (c) { return c.contact; },
            isSearchable: true,
        },
        {
            headingKey: "headings.metsoContact",
            cellFunc: function (c) { return c.metsoContact; },
            isSearchable: true,
        },
        {
            headingKey: "headings.icon",
            cellFunc: function (c) { return _jsx(Icon, { name: "icon-edit-16", onClick: function (event) { return handleEditIconClick(event, c); } }); },
        },
    ];
    useEffect(function () {
        if (Deleted) {
            {
                showNotifier("You have deleted customer '".concat(DeletedCustomerName, "'."));
            }
        }
        window.history.replaceState({}, document.title);
    }, [Deleted, DeletedCustomerName, deleteParams]);
    var showNotifier = function (text) {
        setMessage(text, ToastLevel.SUCCESS, 6000);
    };
    var customerModal = {
        open: function () {
            setIsModalOpen(true);
        },
        close: function (passed, customerId, navigationCustomerOptions) {
            setIsModalOpen(false);
            setCustomerToEdit(undefined);
            if (passed)
                navigate("/customers/".concat(customerId), {
                    state: navigationCustomerOptions
                });
        }
    };
    var handleEditIconClick = function (event, customer) {
        event.stopPropagation();
        setCustomerToEdit(customer);
        setIsModalOpen(true);
    };
    var rightPageButtons = (_jsx(_Fragment, { children: _jsx(Button, __assign({ icon: "icon-plus-16", labelPosition: "right", primary: true, onClick: customerModal.open }, { children: formatMessage("customer.create") })) }));
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { title: formatMessage("customerList.title"), extraItems: rightPageButtons }), _jsxs("div", __assign({ style: itemViewDetailsContainerStyles }, { children: [_jsx(ListView, { noneYetKey: "customerList.noCustomersYet", template: template, data: customers, onClickRow: function (c) { return navigate("/customers/" + c.id); } }), _jsx(CustomerModal, { isOpen: isModalOpen, onClose: customerModal.close, customer: customerToEdit })] }))] }));
});
export default CustomerListView;
