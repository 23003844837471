var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Button, Icon, PageTitle, Spinner, ToastLevel, useIntl, useToast } from "@metsooutotec/modes-react-components";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import ListView from "../../utils/list-view/list-view";
import linerMaterialStore from '../../../stores/provisions/liner-material-store';
import { itemViewDetailsContainerStyles } from "../../common-style/common-style";
import LinerMaterialLibraryModal from "./liner-material-library-modal";
import LinerMaterialDeleteModal from "../provisions-utils/liner-material-delete-modal";
var LinerMaterialLibraryListView = observer(function () {
    var navigate = useNavigate();
    var formatMessage = useIntl().formatMessage;
    var location = useLocation();
    var linerMaterials = linerMaterialStore.linerMaterials;
    var _a = __read(useState(false), 2), isCreateModalOpen = _a[0], setIsCreateModalOpen = _a[1];
    var _b = __read(useState(null), 2), linerSpecificationToEdit = _b[0], setLinerSpecificationToEdit = _b[1];
    var _c = __read(useState(false), 2), isLinerSpecificationEditModalOpen = _c[0], setIsLinerSpecificationEditModalOpen = _c[1];
    var _d = __read(useState(null), 2), linerSpecificationToDelete = _d[0], setLinerSpecificationToDelete = _d[1];
    var _e = __read(useState(false), 2), isLinerSpecificationDeleteModalOpen = _e[0], setIsLinerSpecificationDeleteModalOpen = _e[1];
    var deleteParams = location.state;
    var _f = deleteParams || {}, DeletedLinerMaterial = _f.deleted, LinerMaterialName = _f.entityName;
    var setMessage = useToast().setMessage;
    var createLinerMaterialModal = {
        open: function () {
            setIsCreateModalOpen(true);
        },
        close: function () {
            setIsCreateModalOpen(false);
        }
    };
    var deleteLinerMaterialModal = {
        open: function (event, linerSpecification) {
            setLinerSpecificationToDelete(linerSpecification);
            setIsLinerSpecificationDeleteModalOpen(true);
        },
        close: function () {
            setIsLinerSpecificationDeleteModalOpen(false);
        }
    };
    var editLinerMaterialModal = {
        open: function (event, linerSpecification) {
            setLinerSpecificationToEdit(linerSpecification);
            setIsLinerSpecificationEditModalOpen(true);
        },
        close: function () {
            setIsLinerSpecificationEditModalOpen(false);
        }
    };
    var showNotifier = function (text) {
        setMessage(text, ToastLevel.SUCCESS, 6000);
    };
    useEffect(function () {
        if (DeletedLinerMaterial) {
            {
                // TODO: i8n
                showNotifier("Liner Material '".concat(LinerMaterialName, "' deleted."));
            }
        }
        window.history.replaceState({}, document.title);
    }, [DeletedLinerMaterial, LinerMaterialName, deleteParams]);
    var template = [
        {
            headingKey: "linerMaterialLibrary.headings.name",
            cellFunc: function (s) { return s.name; },
            isSearchable: true,
        },
        {
            headingKey: "linerMaterialLibrary.headings.materialReference",
            cellFunc: function (s) { return s.materialReference; },
            isSearchable: true,
        },
        {
            headingKey: "linerMaterialLibrary.headings.code",
            cellFunc: function (s) { return s.code; },
            isSearchable: true,
        },
        {
            headingKey: "linerMaterialLibrary.headings.thickness",
            cellFunc: function (s) { return s.thickness; },
            isSearchable: true,
        },
        {
            headingKey: "linerMaterialLibrary.headings.wearSurfaceThickness",
            cellFunc: function (s) { return s.wearSurfaceThickness; },
            isSearchable: true,
        },
        {
            headingKey: "linerMaterialLibrary.headings.backingThickness",
            cellFunc: function (s) { return s.backingThickness; },
            isSearchable: true,
        },
        {
            headingKey: "headings.delete",
            cellFunc: function (s) { return _jsx("span", __assign({ style: { display: "flex", gap: "1.5em" } }, { children: _jsx(Icon, { name: "icon-trash-16", onClick: function (event) { return deleteLinerMaterialModal.open(event, s); } }) })); },
        },
        {
            headingKey: "headings.edit",
            cellFunc: function (s) { return _jsx("span", __assign({ style: { display: "flex", gap: "1.5em" } }, { children: _jsx(Icon, { name: "icon-edit-16", onClick: function (event) { return editLinerMaterialModal.open(event, s); } }) })); },
        },
    ];
    var rightPageButtons = (_jsx(_Fragment, { children: _jsx(Button, __assign({ icon: "icon-plus-16", labelPosition: "left", onClick: createLinerMaterialModal.open, primary: true }, { children: formatMessage("linerMaterial.create") })) }));
    var tableContainerStyles = __assign(__assign({}, itemViewDetailsContainerStyles), { height: '91%' });
    var breadcrumbsPath = [{
            text: formatMessage('breadcrumb.home'),
            to: '/',
        }, {
            text: formatMessage('linerMaterialLibrary.breadcrumb'),
            to: '/liner-material-library',
        }];
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { breadcrumbsPath: breadcrumbsPath, onPathChange: navigate, title: formatMessage("linerMaterialLibrary.title"), extraItems: rightPageButtons }), _jsxs("div", __assign({ style: tableContainerStyles }, { children: [(linerMaterials.length < 1)
                        ? (_jsx(Spinner, { text: formatMessage("linerMaterial.loading") }))
                        : _jsx(ListView, { noneYetKey: "linerMaterial.nonYet", template: template, data: linerMaterials }), _jsx(LinerMaterialLibraryModal, { isOpen: isCreateModalOpen, onClose: createLinerMaterialModal.close }), _jsx(LinerMaterialLibraryModal, { isOpen: isLinerSpecificationEditModalOpen, onClose: editLinerMaterialModal.close, linerMaterial: linerSpecificationToEdit }), _jsx(LinerMaterialDeleteModal, { isOpen: isLinerSpecificationDeleteModalOpen, onClose: deleteLinerMaterialModal.close, entity: linerSpecificationToDelete })] }))] }));
});
export default LinerMaterialLibraryListView;
