import { EditorSteps } from "../editor-utils/editor-types";
export var generateSteps = function (selectedLiners, selectedWearReferencePlane, selectedholes, selectedLinersDrawingRef) {
    return [
        { key: EditorSteps.SVGSettings, text: "Details and Settings", boxLabel: "", sideBarTitle: "", sideBarList: null },
        { key: EditorSteps.LinersLocation, text: "Liner Location", boxLabel: "Liner Location.", sideBarTitle: "Liner Names Changes", sideBarList: selectedLiners },
        { key: EditorSteps.WearReferencePlane, text: "Wear Reference Plane", boxLabel: "", sideBarTitle: "Wear Reference Plane", sideBarList: selectedWearReferencePlane },
        { key: EditorSteps.HolesLocation, text: "Hole Location", boxLabel: "Hole Region.", sideBarTitle: "Holes Names Changes", sideBarList: selectedholes },
        { key: EditorSteps.LinersDrawingReferences, text: "Liner Drawing Reference.", boxLabel: "Drawing Reference", sideBarTitle: "Reference Names", sideBarList: selectedLinersDrawingRef },
    ];
};
