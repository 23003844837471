var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, DateTimeInput, Dialog, DialogDescription, DialogHeader, Grid, GridRow, Icon, Input, Select, ToastLevel, useIntl, useToast } from "@metsooutotec/modes-react-components";
import { useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import provisionEventStore from "stores/provisions/provision-event-store";
import { validateText } from "validators/inputValidators";
import { convertDateToDDMMMYY, convertDDMMMYYtoDate, getTodayDateString } from "../../../utils/date-formatter-utils";
import { provisionEventsLinkGeneratorSimple } from "../../provisions-utils/provision-events-link-generator";
export var ProvisionEventModal = function (props) {
    if (!props.isOpen)
        return null;
    return _jsx(_ProvisionEventModal, __assign({}, props));
};
var cssStyle = "\n.date-time-adjust > div {\n  width: 100%;\n}\n.date-time-adjust > div > div {\n  width: 100%;\n}\n.min-height-adjust-1 > div > div > div {\n  min-height: 48px;\n  align-items: center;\n}\n.min-height-adjust-2 > div > div > div {\n  min-height: 41px;\n}\n";
var _ProvisionEventModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, provisionEvent = _a.provisionEvent;
    var isEditing = !!provisionEvent;
    var formatMessage = useIntl().formatMessage;
    var navigate = useNavigate();
    var setMessage = useToast().setMessage;
    var editExitingProvisionEvent = provisionEventStore.editExitingProvisionEvent, createProvisionEvent = provisionEventStore.createProvisionEvent;
    var _b = useParams(), customerId = _b.customerId, siteId = _b.siteId, assetId = _b.assetId, assetSectionId = _b.assetSectionId;
    var _c = __read(useState(false), 2), isLoading = _c[0], setLoading = _c[1];
    var _d = __read(useState({ isError: false, errorMessage: "" }), 2), isError = _d[0], setIsError = _d[1];
    var _e = __read(useState((isEditing) ? convertDateToDDMMMYY(provisionEvent === null || provisionEvent === void 0 ? void 0 : provisionEvent.eventDate) : getTodayDateString), 2), eventDate = _e[0], setEventDate = _e[1];
    var _f = __read(useState({
        description: (provisionEvent === null || provisionEvent === void 0 ? void 0 : provisionEvent.description) || "",
        type: (provisionEvent === null || provisionEvent === void 0 ? void 0 : provisionEvent.type) || "Planned"
    }), 2), formData = _f[0], setFormData = _f[1];
    var _g = __read(useState({
        description: "",
        type: ""
    }), 2), formErrors = _g[0], setFormErrors = _g[1];
    var _h = __read(useState({ isError: false, errorMessage: "" }), 2), datePickerError = _h[0], setDatePickerError = _h[1];
    var labels = {
        confirm: formatMessage("modal.confirm"),
        cancel: formatMessage("modal.cancel"),
        header: isEditing ? formatMessage("provisionEvents.modal.edit.header") : formatMessage("provisionEvents.modal.create.header"),
        description: formatMessage("provisionEvents.modal.description"),
        descriptionData: formatMessage("provisionEvents.modal.data.description"),
        eventDate: formatMessage("provisionEvents.modal.data.eventDate"),
        type: formatMessage("provisionEvents.modal.data.type"),
    };
    var handleChange = function (fieldName, value) {
        isError.isError = false;
        setFormData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[fieldName] = value, _a)));
        });
    };
    var handleValidation = function (fieldName, validationError) {
        setFormErrors(function (prevErrors) {
            var _a;
            return (__assign(__assign({}, prevErrors), (_a = {}, _a[fieldName] = validationError, _a)));
        });
    };
    var onCloseProcess = function () {
        resetForm();
        setIsError({ isError: false, errorMessage: "" });
        onClose();
    };
    var resetForm = function () {
        resetFormData();
        resetErrors();
    };
    var resetErrors = function () {
        setFormErrors({
            description: "",
            type: ""
        });
        setDatePickerError({ isError: false, errorMessage: "" });
    };
    var resetFormData = function () {
        setFormData({
            description: "",
            type: ""
        });
        setEventDate(getTodayDateString);
    };
    var onClearClick = function (inputId) {
        setFormData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[inputId] = "", _a)));
        });
        setFormErrors(function (prevErrors) {
            var _a;
            return (__assign(__assign({}, prevErrors), (_a = {}, _a[inputId] = "", _a)));
        });
    };
    var handleDateChange = useCallback(function (value) {
        try {
            setDatePickerError({ isError: false, errorMessage: "" });
            var date = new Date(value);
            if (isNaN(date.getDate())) {
                setDatePickerError({ isError: true, errorMessage: "Invalid Date!" });
            }
            else {
                var formatedDate = convertDateToDDMMMYY(date);
                setEventDate(formatedDate);
                // setIsFormModified(true);
            }
        }
        catch (e) {
            console.log(e);
            setDatePickerError({ isError: true, errorMessage: e.message });
        }
    }, []);
    var validateAssetSectionForm = function (formData) {
        var errors = {};
        errors.description = validateText(formData.description);
        errors.type = validateText(formData.type);
        return errors;
    };
    var handleSubmit = useCallback(function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var errors, hasErrors, description, type, date_1, data_1, whenEditing, whenCreating, link, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    setLoading(true);
                    errors = validateAssetSectionForm(formData);
                    hasErrors = Object.values(errors).some(function (error) { return error !== ""; });
                    if (hasErrors) {
                        setIsError({ isError: true, errorMessage: "There are validation errors, update the form texts" });
                        setFormErrors(errors);
                        setLoading(false); // Reset loading state
                        return [2 /*return*/];
                    }
                    description = formData.description, type = formData.type;
                    date_1 = convertDDMMMYYtoDate(eventDate);
                    if (!date_1) {
                        setIsError({ isError: true, errorMessage: "Invalid date" });
                        setFormErrors(errors);
                        return [2 /*return*/];
                    }
                    data_1 = {
                        assetId: assetId || '',
                        description: description || "",
                        eventDate: eventDate,
                        type: type
                    };
                    whenEditing = function () { return __awaiter(void 0, void 0, void 0, function () {
                        var id, link;
                        return __generator(this, function (_a) {
                            if (!provisionEvent)
                                return [2 /*return*/, null];
                            id = provisionEvent.id;
                            editExitingProvisionEvent(id, data_1, date_1);
                            link = provisionEventsLinkGeneratorSimple(customerId, siteId, assetId, assetSectionId, id) + '/edit';
                            return [2 /*return*/, link];
                        });
                    }); };
                    whenCreating = function () { return __awaiter(void 0, void 0, void 0, function () {
                        var link;
                        return __generator(this, function (_a) {
                            createProvisionEvent(data_1, date_1);
                            link = provisionEventsLinkGeneratorSimple(customerId, siteId, assetId, assetSectionId, "new-event") + '/edit';
                            return [2 /*return*/, link];
                        });
                    }); };
                    return [4 /*yield*/, (isEditing ? whenEditing : whenCreating)()];
                case 2:
                    link = _a.sent();
                    if (link)
                        navigate(link);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    setIsError({ isError: true, errorMessage: "Form submission error, please read logs" });
                    // TODO i18n
                    setMessage("Failed to save", ToastLevel.ALERT, 6000);
                    console.error("Form submission error:", error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [provisionEvent, formData, eventDate]);
    var renderInputs = function () {
        var inputProps = {
            id: 'description',
            label: labels.descriptionData,
            placeholder: "Type ".concat(labels.descriptionData, " here..."),
            validate: validateText,
            onValidation: function (validationError) { return handleValidation('description', validationError); },
            onChange: function (e) { return handleChange('description', e.target.value); },
            type: 'string',
            error: formErrors.description,
            spanning: true,
            heightClass: 'medium',
            required: true,
            uiRequired: true,
            value: formData.description,
            icon: formData.description && (_jsx(Icon, { className: "icon-cross-circle-filled-16", onClick: function () { return onClearClick('description'); } }))
        };
        //   const materialTypesOptions = useMemo(() => {
        //     return materialTypes.map(m => {
        //         const { id, name } = m
        //         return { text: id, value: name } as SelectOption
        //     })
        // }, [materialTypes])
        var selectInputProps = {
            id: 'type',
            label: labels.type,
            placeholder: "",
            validate: validateText,
            onValidation: function (validationError) { return handleValidation('type', validationError); },
            onChange: function (value) { return handleChange('type', Array.isArray(value) ? value[0] : value); },
            type: 'select',
            error: formErrors.type,
            heightClass: 'small',
            spanning: true,
            required: true,
            uiRequired: true,
            icon: null,
            value: formData.type,
            options: [
                {
                    text: "Planned",
                    value: "Planned"
                }, {
                    text: "Commission",
                    value: "Commission"
                }, {
                    text: "Decommission",
                    value: "Decommission"
                }
            ],
            freeText: false,
            multiSelect: false
        };
        return (_jsxs(_Fragment, { children: [_jsx("style", { children: cssStyle }), _jsx(GridRow, __assign({ style: { marginBottom: "var(--size-m)" } }, { children: _jsx(Input, __assign({}, inputProps)) })), _jsx(GridRow, __assign({ className: "date-time-adjust min-height-adjust-1", style: { marginBottom: "var(--size-m)" } }, { children: _jsx(DateTimeInput, { label: labels.eventDate, onChange: function (value) { return handleDateChange(value.toString()); }, value: convertDDMMMYYtoDate(eventDate), format: "D-MMM-YY", timeFormat: false, error: datePickerError.errorMessage }) })), _jsx(GridRow, __assign({ className: "min-height-adjust-2", style: { marginBottom: "var(--size-m)" } }, { children: _jsx(Select, __assign({}, selectInputProps)) }))] }));
    };
    var renderButtons = function () {
        var buttonsConfig = [
            { label: labels.confirm, onClick: handleSubmit, primary: true },
            { label: labels.cancel, onClick: onCloseProcess, primary: false },
        ];
        return buttonsConfig.map(function (button, index) {
            var label = button.label, onClick = button.onClick, primary = button.primary;
            var buttonGridStyle = index === 0 ? {
                marginBottom: "var(--size-m)"
            } : {};
            return (_jsx(GridRow, __assign({ style: buttonGridStyle }, { children: _jsx(Button, { loading: isLoading, disabled: isLoading, onClick: onClick, flexible: true, children: label, centered: true, size: 'medium', primary: primary }) }), label));
        });
    };
    return (_jsx(Grid, { children: _jsxs(Dialog, __assign({ dimmer: true, open: isOpen, size: 'small', style: { maxWidth: "512px" } }, { children: [_jsx(DialogHeader, __assign({ username: '' }, { children: labels.header })), isError.isError && _jsx("p", __assign({ style: { color: 'red' } }, { children: isError.errorMessage })), _jsx(DialogDescription, __assign({ style: { marginBottom: "var(--size-xxl)" } }, { children: labels.description })), renderInputs(), _jsx("div", { style: { marginBottom: "var(--size-xxl)" } }), renderButtons()] })) }));
};
