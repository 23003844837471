export var linerMaterialSampleData = [
    {
        id: '6B639515-8A2B-4269-ACF9-0DCF38EBAF14',
        thickness: 9,
        materialReference: "9/8 Slik 3000",
        code: "",
        name: "9/8 Slik 3000",
        description: "9/8 Slik 3000",
        backingThickness: 8,
        wearSurfaceThickness: 9,
        isDeleted: false
    }, {
        id: '58C03BC6-CE32-4B42-BFEE-1F8088D707AB',
        thickness: 20,
        materialReference: "20/12 XO80",
        code: "",
        name: "20/12 XO80",
        description: "20/12 XO80",
        backingThickness: 12,
        wearSurfaceThickness: 20,
        isDeleted: false
    }
];
export var materialTypeSampleData = [
    {
        id: 'CC0',
        name: "CC0",
        description: "",
        isDeleted: false
    },
    {
        id: 'CC1',
        name: "CC1",
        description: "",
        isDeleted: false
    },
];
