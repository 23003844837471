var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { makeAutoObservable, observable, runInAction } from "mobx";
import { deleteProvisionEvent, editProvisionEvent, getAllProvisionEvents, getProvisionEventsForAsset, postNewProvisionEvent } from "services/provision-services/provision-event-service";
import { convertDateToDDMMMYYYY } from "../../components/utils/date-formatter-utils";
var ProvisionEventStore = /** @class */ (function () {
    // editingProvisionId: string | null = null
    // editingProvisionEventDetail: ProvisionEventEquipment | null = {
    //   id: '',
    //   description: "test",
    //   type: "Decommission",
    //   isDeleted: false,
    //   eventDate: new Date(2020, 9, 1),
    //   liners: [],
    //   sensors: [],
    //   assetId: "94d466f3-ade7-4578-b818-9e656902c833"
    // }
    function ProvisionEventStore() {
        var _this = this;
        this.provisionEventMap = observable.object({});
        this.provisionEventLoading = false;
        this.provisionEventsDetailMap = observable.object({});
        this.provisionEventsDetailLoading = false;
        this.editingProvisionId = null;
        this.editingProvisionEventDetail = null;
        this.editExitingProvisionEvent = function (id, event, newdate) { return __awaiter(_this, void 0, void 0, function () {
            var a, newProvisionEvent;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.editingProvisionId = id;
                        a = __assign(__assign(__assign({}, this.provisionEventsDetailMap[id]), event), { eventDate: newdate });
                        return [4 /*yield*/, editProvisionEvent(__assign(__assign({ id: id }, event), { eventDate: convertDateToDDMMMYYYY(newdate) }))];
                    case 1:
                        newProvisionEvent = _a.sent();
                        this.editingProvisionEventDetail = a;
                        return [2 /*return*/];
                }
            });
        }); };
        this.createProvisionEvent = function (event, date) { return __awaiter(_this, void 0, void 0, function () {
            var a, newProvisionEvent;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.editingProvisionId = null;
                        a = __assign(__assign(__assign({}, event), { eventDate: date }), {
                            sensors: [],
                            liners: [],
                            isDeleted: false,
                            id: "",
                        });
                        return [4 /*yield*/, postNewProvisionEvent(event)];
                    case 1:
                        newProvisionEvent = _a.sent();
                        this.editingProvisionEventDetail = a;
                        return [2 /*return*/];
                }
            });
        }); };
        this.loadProvisionEventsForAsset = function (assetId) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.provisionEventsDetailLoading) {
                            return [2 /*return*/];
                        }
                        runInAction(function () {
                            _this.provisionEventsDetailLoading = true;
                        });
                        return [4 /*yield*/, getProvisionEventsForAsset(assetId)];
                    case 1:
                        data = _a.sent();
                        try {
                            runInAction(function () {
                                var e_1, _a;
                                try {
                                    for (var data_1 = __values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                                        var provisionEvent = data_1_1.value;
                                        _this.provisionEventsDetailMap[provisionEvent.id] = observable.object(provisionEvent);
                                    }
                                }
                                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                                finally {
                                    try {
                                        if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
                                    }
                                    finally { if (e_1) throw e_1.error; }
                                }
                            });
                        }
                        catch (e) {
                            console.log(e);
                        }
                        finally {
                            this.provisionEventsDetailLoading = false;
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.loadAllProvisionEvents = function () { return __awaiter(_this, void 0, void 0, function () {
            var data;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.provisionEventLoading) {
                            return [2 /*return*/];
                        }
                        runInAction(function () {
                            _this.provisionEventLoading = true;
                        });
                        return [4 /*yield*/, getAllProvisionEvents()];
                    case 1:
                        data = _a.sent();
                        try {
                            runInAction(function () {
                                var e_2, _a;
                                try {
                                    for (var data_2 = __values(data), data_2_1 = data_2.next(); !data_2_1.done; data_2_1 = data_2.next()) {
                                        var provisionEvent = data_2_1.value;
                                        _this.provisionEventMap[provisionEvent.id] = observable.object(provisionEvent);
                                    }
                                }
                                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                                finally {
                                    try {
                                        if (data_2_1 && !data_2_1.done && (_a = data_2.return)) _a.call(data_2);
                                    }
                                    finally { if (e_2) throw e_2.error; }
                                }
                                _this.sortProvisionEventMap();
                            });
                        }
                        catch (e) {
                            console.log(e);
                        }
                        finally {
                            this.provisionEventLoading = false;
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.saveProvisionEvent = function (entity) { return __awaiter(_this, void 0, void 0, function () {
            var newEvent, response, _a, provisionEvent_1, provisionEventDetail_1, id_1, message, error_1;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.provisionEventLoading) {
                            return [2 /*return*/, { passed: false, message: "", entityId: "" }];
                        }
                        runInAction(function () {
                            _this.provisionEventLoading = true;
                        });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        newEvent = { assetId: entity.assetId, description: entity.description, eventDate: convertDateToDDMMMYYYY(entity.eventDate), type: entity.type };
                        return [4 /*yield*/, postNewProvisionEvent(newEvent)];
                    case 2:
                        response = _b.sent();
                        if (response.statusCode === 200) {
                            _a = response.result, provisionEvent_1 = _a.provisionEvent, provisionEventDetail_1 = _a.provisionEventDetail;
                            id_1 = provisionEvent_1.id;
                            runInAction(function () {
                                _this.provisionEventMap[id_1] = observable.object(provisionEvent_1);
                                console.log(JSON.parse(JSON.stringify(_this.provisionEventsDetailMap)));
                                _this.provisionEventsDetailMap[id_1] = observable.object(provisionEventDetail_1);
                                console.log(provisionEventDetail_1);
                                console.log(JSON.parse(JSON.stringify(_this.provisionEventsDetailMap)));
                                _this.sortProvisionEventMap();
                            });
                            return [2 /*return*/, { passed: true, message: "", entityId: id_1, entities: { provisionEvent: provisionEvent_1, provisionEventDetail: provisionEventDetail_1 } }];
                        }
                        else if (response.statusCode === 409) {
                            return [2 /*return*/, { passed: false, message: "Conflict-409: an entity with the same name is exist.", entityId: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            return [2 /*return*/, { passed: false, message: message, entityId: "" }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _b.sent();
                        console.error("Error creating entity:", error_1);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs", entityId: "" }];
                    case 4:
                        runInAction(function () {
                            _this.provisionEventLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.edit = function (entity) { return __awaiter(_this, void 0, void 0, function () {
            var response, message, error_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.provisionEventLoading) {
                            return [2 /*return*/, { passed: false, message: "", entityId: "" }];
                        }
                        runInAction(function () {
                            _this.provisionEventLoading = true;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, editProvisionEvent(entity)];
                    case 2:
                        response = _a.sent();
                        if (response.statusCode === 200) {
                            Object.entries(entity).forEach(function (_a) {
                                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                                _this.provisionEventMap[entity.id][key] = value;
                            });
                            return [2 /*return*/, { passed: true, message: "", entityId: entity.id, entities: this.provisionEventMap[entity.id] }];
                        }
                        else if (response.statusCode === 409) {
                            return [2 /*return*/, { passed: false, message: "Conflict-409: an entity with the same name is exist.", entityId: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            return [2 /*return*/, { passed: false, message: message, entityId: "" }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_2 = _a.sent();
                        console.error("Error editing entity:", error_2);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs", entityId: "" }];
                    case 4:
                        runInAction(function () {
                            _this.provisionEventLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.delete = function (id) { return __awaiter(_this, void 0, void 0, function () {
            var response, message, error_3;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.provisionEventLoading) {
                            return [2 /*return*/, { passed: false, message: "" }];
                        }
                        runInAction(function () {
                            _this.provisionEventLoading = true;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, deleteProvisionEvent(id)];
                    case 2:
                        response = _a.sent();
                        if (response.statusCode === 200) {
                            delete this.provisionEventMap[id];
                            return [2 /*return*/, { passed: true, message: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            return [2 /*return*/, { passed: false, message: message }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_3 = _a.sent();
                        console.error("Error creating customer:", error_3);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs" }];
                    case 4:
                        runInAction(function () {
                            _this.provisionEventLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        makeAutoObservable(this);
    }
    Object.defineProperty(ProvisionEventStore.prototype, "provisionEvents", {
        get: function () {
            return Object.values(this.provisionEventMap);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProvisionEventStore.prototype, "provisionEventsDetail", {
        get: function () {
            return Object.values(this.provisionEventsDetailMap);
        },
        enumerable: false,
        configurable: true
    });
    ProvisionEventStore.prototype.sortProvisionEventMap = function () {
        var e_3, _a;
        var sortedKeys = Object.keys(this.provisionEventMap).sort(function (a, b) {
            return a < b ? -1 : 1;
        });
        var sortedProvisionEventMap = {};
        try {
            for (var sortedKeys_1 = __values(sortedKeys), sortedKeys_1_1 = sortedKeys_1.next(); !sortedKeys_1_1.done; sortedKeys_1_1 = sortedKeys_1.next()) {
                var key = sortedKeys_1_1.value;
                sortedProvisionEventMap[key] = this.provisionEventMap[key];
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (sortedKeys_1_1 && !sortedKeys_1_1.done && (_a = sortedKeys_1.return)) _a.call(sortedKeys_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.provisionEventMap = sortedProvisionEventMap;
    };
    return ProvisionEventStore;
}());
export default new ProvisionEventStore();
