var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import { EditorSteps } from "../editor-utils/editor-types";
import { TruncateString } from "../editor-utils/id-string-utils";
import { generateSteps } from "./steps-generator";
import asEditorStore from "./../local-store/asset-section-editor-store";
export var SideBarContents = observer(function () {
    var _a, _b;
    var currentStep = asEditorStore.currentStep, selectedLiners = asEditorStore.selectedLiners, selectedWearReferencePlane = asEditorStore.selectedWearReferencePlane, selectedholes = asEditorStore.selectedholes, selectedLinersDrawingRef = asEditorStore.selectedLinersDrawingRef;
    var steps = generateSteps(selectedLiners, selectedWearReferencePlane, selectedholes, selectedLinersDrawingRef);
    var charLong = currentStep === EditorSteps.LinersLocation ? 12 : 22;
    var displayValues = function (pair) {
        switch (currentStep) {
            case EditorSteps.LinersLocation:
                return {
                    first: TruncateString(pair.existReference, charLong),
                    second: pair.newReference
                };
            case EditorSteps.WearReferencePlane:
                return {
                    first: TruncateString(pair.reference, charLong),
                    second: pair.wallThickness
                };
            case EditorSteps.LinersDrawingReferences:
                var DRef = pair;
                return {
                    first: TruncateString(DRef.linerReference, charLong),
                    second: DRef.newDrawingReferenceName
                };
            case EditorSteps.HolesLocation:
                var Rhole = pair;
                return {
                    first: TruncateString(Rhole.existReference, charLong),
                    second: Rhole.newReference
                };
        }
        return { first: "", second: "" };
    };
    return (_jsx(_Fragment, { children: (_b = (_a = steps[currentStep]) === null || _a === void 0 ? void 0 : _a.sideBarList) === null || _b === void 0 ? void 0 : _b.map(function (pair, index) {
            var _a = displayValues(pair), first = _a.first, second = _a.second;
            return (_jsxs("div", __assign({ className: "idPairRectangle" }, { children: [_jsx("span", { children: first }), _jsx("span", { children: "-->" }), _jsx("span", { children: second })] }), index));
        }) }));
});
