var _a;
import { User } from "oidc-client-ts";
var MinimumAdminPortalRole = "WearSenseDaviesAdmin";
var appURL = process.env.REACT_APP_URL;
var redirectUri = "".concat(appURL, "/signin-oidc");
var redirectSignoutUri = "".concat(appURL, "/signout-oidc");
var authority = (_a = process.env.REACT_APP_AUTHORITY) !== null && _a !== void 0 ? _a : 'https://auth.wearsense.com';
var ClientId = 'WsAdminPortalSpa';
var userManagerConfig = {
    client_id: ClientId,
    authority: authority,
    redirect_uri: redirectUri,
    response_type: 'code',
    scope: 'openid profile email role wsspaAPI.read offline_access',
    silent_redirect_uri: redirectUri,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    accessTokenExpiringNotificationTimeInSeconds: 60000,
};
function hasAdminRole(input) {
    if (Array.isArray(input)) {
        return input.some(function (str) { return str === MinimumAdminPortalRole; });
    }
    else {
        return input == MinimumAdminPortalRole;
    }
}
export { userManagerConfig, hasAdminRole };
export function getUser() {
    //oidc.user: https://localhost:44393:WsAdminPortalSpa
    var oidcStorage = sessionStorage.getItem("oidc.user:".concat(authority, ":").concat(ClientId));
    if (!oidcStorage) {
        return null;
    }
    return User.fromStorageString(oidcStorage);
}
