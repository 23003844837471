var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { AssetNavigationSidebar, useIntl } from '@metsooutotec/modes-react-components';
import customerStore from 'stores/customer-stores/customer-store';
import { customerSidebarViewContentStyles } from '../styles/customer-styles';
import LowSpinner from '../../common/low-spinner';
var itemToTreeItem = function (text, value, icon, children) {
    var treeItem = { icon: icon, text: text, value: value };
    if (children && children.length) {
        treeItem.children = children;
    }
    return treeItem;
};
var assetSectionToTreeItem = function (section, pathBase) {
    var path = pathBase + "/assetsections/" + section.id;
    return itemToTreeItem(section.name, path, "icon-component-16");
};
var assetToTreeItem = function (asset, pathBase) {
    var _a;
    var path = pathBase + "/assets/" + asset.id;
    var children = (_a = asset.assetSections) === null || _a === void 0 ? void 0 : _a.map(function (section) { return assetSectionToTreeItem(section, path); });
    return itemToTreeItem(asset.name, path, "icon-equipment-16", children);
};
var siteToTreeItem = function (site, pathBase) {
    var _a;
    var path = pathBase + "/sites/" + site.id;
    var children = (_a = site.assets) === null || _a === void 0 ? void 0 : _a.map(function (asset) { return assetToTreeItem(asset, path); });
    return itemToTreeItem(site.name, path, "icon-location-o-16", children);
};
var customerToTreeItem = function (customer) {
    var _a;
    var path = "/customers/" + customer.id;
    var children = (_a = customer.sites) === null || _a === void 0 ? void 0 : _a.map(function (site) { return siteToTreeItem(site, path); });
    return itemToTreeItem(customer.name, path, "icon-person-o-16", children);
};
var search = function (filterExpr, items) {
    var regex = new RegExp(filterExpr, "i");
    return items.reduce(function (acc, item) {
        if (item.text.search(regex) >= 0) {
            return __spreadArray(__spreadArray([], __read(acc), false), [item], false);
        }
        var children = search(filterExpr, item.children || []);
        if (children.length) {
            return __spreadArray(__spreadArray([], __read(acc), false), [__assign(__assign({}, item), { children: children })], false);
        }
        return acc;
    }, []);
};
var getOpenItemsAfterSearch = function (filterExpr, items) { return __spreadArray(__spreadArray([], __read(items.filter(function (item) { return item.children && item.children.some(function (c) { return c.text.search(new RegExp(filterExpr, "i")) >= 0; }); }).map(function (item) { return item.value; })), false), __read(items.map(function (item) { return getOpenItemsAfterSearch(filterExpr, item.children || []); }).flat()), false); };
var CustomerSidebarViewComponent = function (_a) {
    var tree = _a.tree;
    var formatMessage = useIntl().formatMessage;
    var currentLocation = useLocation();
    var navigate = useNavigate();
    var _b = __read(useState([]), 2), openItems = _b[0], setOpenItems = _b[1];
    var _c = __read(useState(""), 2), searchTerm = _c[0], setSearchTerm = _c[1];
    var _d = __read(useState(true), 2), sidebarOpen = _d[0], setSidebarOpen = _d[1];
    //search section
    var items = useMemo(function () {
        return searchTerm ? search(searchTerm, tree) : tree;
    }, [searchTerm, tree]);
    useEffect(function () {
        if (searchTerm) {
            setOpenItems(getOpenItemsAfterSearch(searchTerm, items));
        }
        else {
            setOpenItems([]);
        }
    }, [searchTerm, items]);
    useEffect(function () {
        // this trick only to make all elments inside the list to be closed
        if (sidebarOpen) {
            setOpenItems(function (prevOpenItems) { return __spreadArray(__spreadArray([], __read(prevOpenItems), false), ["your-item"], false); });
        }
        else {
            setOpenItems([]);
        }
    }, [sidebarOpen]);
    return (_jsx(AssetNavigationSidebar, __assign({ title: formatMessage("customerSidebar.title"), assetNavigationProps: {
            openItems: __spreadArray([], __read(openItems), false),
        }, items: items, onChange: function (vals) { return navigate(vals[0]); }, onHeaderClick: function () { return setSidebarOpen(!sidebarOpen); }, search: true, searchOnChange: function (e) { return setSearchTerm(e.target.value); }, searchProps: { placeholder: formatMessage("customerSidebar.searchPlaceholder"),
            value: searchTerm,
        }, selectedItems: [currentLocation.pathname], visible: sidebarOpen }, { children: _jsx(AssetNavigationSidebar.Content, __assign({ style: customerSidebarViewContentStyles }, { children: _jsx(Outlet, {}) })) })));
};
var CustomerSidebarView = observer(function () {
    var formatMessage = useIntl().formatMessage;
    var customers = customerStore.customers, loadAllCustomers = customerStore.loadAllCustomers, loadSitesForCustomer = customerStore.loadSitesForCustomer;
    // load data
    useEffect(function () {
        if (!customers.length) {
            loadAllCustomers();
        }
    });
    //load sites for customers if not exist
    useEffect(function () {
        var e_1, _a;
        try {
            for (var customers_1 = __values(customers), customers_1_1 = customers_1.next(); !customers_1_1.done; customers_1_1 = customers_1.next()) {
                var c = customers_1_1.value;
                if (!c.sites) {
                    loadSitesForCustomer(c.id);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (customers_1_1 && !customers_1_1.done && (_a = customers_1.return)) _a.call(customers_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }, [JSON.stringify(customers)]);
    //here to inject side items
    var tree = useMemo(function () {
        var headerTreeItem = {
            icon: "icon-home-16",
            text: formatMessage("customerSidebar.allCustomers"),
            value: "/customers",
        };
        return __spreadArray([headerTreeItem], __read(customers.map(customerToTreeItem)), false);
    }, [JSON.stringify(customers)]);
    return (tree.length <= 1)
        ? (_jsx(LowSpinner, { id: "customer.loading" }))
        : _jsx(CustomerSidebarViewComponent, { tree: tree });
});
export default CustomerSidebarView;
