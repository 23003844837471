var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { CssSVGClasses } from "../editor-utils/editor-types";
import { ExtractDefinedLinerNumber, GetLinerId, GetLinersId } from "../editor-utils/id-string-utils";
import { IsHoleInsideLiner } from "./holes-helper";
import { RemoveUnListedClasses } from "./svg-helpers";
import { GetLinerLocationInfo } from "./wear-reference-plane-helper";
export function MapLiners(linerLocations, svgDocument, renderedSvg, selectedLiners, setSelectedLiners, strokeWidth) {
    try {
        var originalAllPath = Array.from(svgDocument.querySelectorAll('path'));
        var originalLiners_1 = originalAllPath.filter(function (path) { return path.id.toLowerCase().startsWith('liners'); });
        var renderedPaths = Array.from(renderedSvg.querySelectorAll('path'));
        var renderedliners = renderedPaths.filter(function (path) { return path.id.toLowerCase().startsWith('liners'); })
            .sort(function (a, b) {
            var numA = parseInt(a.id.toLowerCase().split('_')[1], 10);
            var numB = parseInt(b.id.toLowerCase().split('_')[1], 10);
            return numA - numB;
        });
        // the SVG might have some defined liners such as LinerA8, so it will take the last number 8 to be increased on top of it.
        var topDefinedLinerCounter_1 = GetTopDefinedLinerCount(svgDocument);
        var updatedLiners_1 = __spreadArray([], __read(selectedLiners), false);
        renderedliners.forEach(function (liner) {
            var _a;
            topDefinedLinerCounter_1++;
            var linerIdPart = liner.id;
            var isDefinedLiner = liner.classList.contains(CssSVGClasses.WsLiner);
            if (linerIdPart) {
                // if the path is a defined liner then get its as it is. 
                var existLinerId_1 = (isDefinedLiner) ? GetLinerId(linerIdPart) : GetLinersId(linerIdPart); // the defined liner should start with "Liner", while the not defined should start with "Liners".
                var linerLocationInfo = GetLinerLocationInfo(linerLocations, existLinerId_1);
                var linerNumber = topDefinedLinerCounter_1;
                var newId = "LinerA".concat(linerNumber); // adjust this ID format as per your needs
                if (existLinerId_1) {
                    var linerToEdit = originalLiners_1.find(function (l) { return l.id.toLowerCase() === existLinerId_1.toLowerCase(); });
                    if (linerToEdit) {
                        RemoveUnListedClasses(linerToEdit);
                        SetLinerAtributes(linerToEdit, newId, strokeWidth);
                        linerToEdit.id = newId;
                        linerToEdit.classList.add(CssSVGClasses.WsLiner);
                        linerToEdit.setAttribute('stroke-width', strokeWidth.toString());
                        linerToEdit.setAttribute('stroke', 'black');
                        var existingLinerIndex = updatedLiners_1.findIndex(function (entry) { return entry.existReference === existLinerId_1; });
                        if (existingLinerIndex !== -1) {
                            updatedLiners_1[existingLinerIndex].newReference = newId;
                        }
                        else {
                            updatedLiners_1.push({ id: (_a = linerLocationInfo === null || linerLocationInfo === void 0 ? void 0 : linerLocationInfo.id) !== null && _a !== void 0 ? _a : "", existReference: existLinerId_1, newReference: newId });
                        }
                    }
                }
            }
        });
        setSelectedLiners(updatedLiners_1);
    }
    catch (e) {
        console.error("Error happen in MapLiners", e);
    }
}
/**
* Retrieves the ID of the liner surrounding a specified hole within an SVG element.
*
* This function searches for paths in the SVG that represent liners. It then checks if the
* specified hole is inside any of these liners. If a surrounding liner is found, the function
* returns its ID; otherwise, it returns null.
*
* Note: The function uses specific checks to filter out paths that are not liners by looking for
* the presence of the term 'liner' in their IDs and ensuring they don't include 'hole' or 'region'.
*
* @param {SVGPathElement} hole - The SVG path element representing the hole.
* @param {SVGSVGElement} svg - The SVG element containing paths.
*
* @returns {string | null} - The ID of the surrounding liner or null if no liner surrounds the hole.
*/
export function GetSurroundingLinerID(hole, svg) {
    var e_1, _a;
    try {
        if (!svg)
            return null;
        var paths = Array.from(svg.querySelectorAll("path"));
        // filter the paths from regions and holes.
        var liners = paths.filter(function (path) { return path.classList.contains(CssSVGClasses.WsLiner); });
        try {
            for (var liners_1 = __values(liners), liners_1_1 = liners_1.next(); !liners_1_1.done; liners_1_1 = liners_1.next()) {
                var liner = liners_1_1.value;
                if (IsHoleInsideLiner(hole, liner)) {
                    return GetLinerId(liner.id);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (liners_1_1 && !liners_1_1.done && (_a = liners_1.return)) _a.call(liners_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return null;
    }
    catch (e) {
        console.error("Error happen in GetSurroundingLinerID", e);
        return null;
    }
}
/**
 * Sets various attributes for an SVG path element that represents a liner.
 *
 * This function is designed to modify an SVG path element (referred to as 'liner') by setting specific
 * attributes on it. These attributes include stroke width, an ID, and CSS class names relevant to its
 * role as a liner in the SVG context. The function also sets the stroke color to black.
 *
 * @param {SVGPathElement} liner - The SVG path element to which attributes will be applied.
 * @param {string} id - The ID to be assigned to the liner.
 * @param {number} strokeWidth - The stroke width to be applied to the liner.
 *
 * The function performs the following actions:
 * 1. Checks if the liner already has a 'stroke-width' attribute set and if it's not zero. If not,
 *    it sets the 'stroke-width' attribute to the provided strokeWidth value.
 * 2. Sets the 'id' attribute of the liner to the provided id.
 * 3. Adds the class 'WsLiner' (from CssSVGClasses) to the liner.
 * 4. Sets the 'stroke' attribute to 'black'.
 *
 * @returns {void} This function does not return a value; it modifies the liner element directly.
 *
 * Note: The function includes error handling, which logs any errors to the console. It ensures that
 *       any unexpected issues during the attribute setting process are caught and logged.
 */
export function SetLinerAtributes(liner, id, strokeWidth) {
    try {
        var existStrokeWidth = liner.getAttribute('stroke-width');
        if (!existStrokeWidth || parseFloat(existStrokeWidth) === 0) {
            liner.setAttribute('stroke-width', strokeWidth.toString());
        }
        liner.id = id;
        liner.classList.add(CssSVGClasses.WsLiner);
        liner.setAttribute('stroke', 'black');
    }
    catch (e) {
        console.error("Error happen in SetLinerAtributes", e);
    }
}
export function GetTopDefinedLinerCount(svgDocument) {
    try {
        var originalAllPath = Array.from(svgDocument.querySelectorAll('path'));
        var definedLiners = originalAllPath.filter(function (path) { return path.id.toLowerCase().startsWith('liner') && path.classList.contains(CssSVGClasses.WsLiner); });
        var topCount_1 = 0;
        if (definedLiners.length > 0) {
            definedLiners.forEach(function (d) {
                var excractedNumber = ExtractDefinedLinerNumber(d.id);
                if (excractedNumber !== null && excractedNumber > topCount_1)
                    topCount_1 = excractedNumber;
            });
        }
        return topCount_1;
    }
    catch (e) {
        console.error('Error in GetTopDefinedLinerCount', e);
        return 0;
    }
}
