import Papa from 'papaparse';
import { isValidRowSensorsCSVReader } from '../../../validators/csvValidators';
var SensorCSVReader = function (file) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onload = function (event) {
            var _a;
            var csvData = (_a = event.target) === null || _a === void 0 ? void 0 : _a.result;
            if (typeof csvData !== 'string') {
                reject(new Error('File content is not a string'));
                return;
            }
            Papa.parse(csvData, {
                header: false,
                dynamicTyping: false,
                skipEmptyLines: true,
                complete: function (result) {
                    var invalidRows = result.data.filter(function (row) { return !isValidRowSensorsCSVReader(row); });
                    if (invalidRows.length > 0) {
                        reject(new Error('Invalid rows detected'));
                        return;
                    }
                    var rows = result.data.map(function (row) {
                        var isManufactured = row.length > 2 ? row[2] : false;
                        return {
                            serialNumber: row[0],
                            macAddress: row[1],
                            isManufactured: isManufactured,
                            manufacturedDate: isManufactured && row.length > 3 ? row[3] : null,
                        };
                    });
                    resolve(rows);
                },
            });
        };
        reader.onerror = function () {
            reject(new Error('Failed to read file'));
        };
        reader.readAsText(file);
    });
};
export default SensorCSVReader;
export var ConverSensorsToCsv = function (sensors) {
    var header = [
        "serialNumber",
        "macAddress",
    ];
    var csvRows = [header.join(",")];
    sensors.forEach(function (sensor) {
        var row = [
            sensor.serialNumber,
            sensor.macAddress,
        ];
        csvRows.push(row.join(","));
    });
    return csvRows.join("\n");
};
