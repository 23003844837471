var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { makeAutoObservable, observable, runInAction } from "mobx";
import { IotDevicesModalTypes } from "../../components/iot-devices/iot-devices-utils/iot-entity-modal-helper";
import { ConverSensorsToCsv } from "../../components/iot-devices/sensors/sensor-csv-reader";
import { convertDDMMMYYYYtoDate, getTodayDateString } from "../../components/utils/date-formatter-utils";
import { downloadFileFromBlobUrl } from "../../components/utils/files-utils";
import { deleteSensor, deleteSensorBatch, editSensor, editSensorBatch, generateSensors, getAllSensorBatches, getSensorsForSensorBatch, postImportNewSensors, postNewSensorBatch, toggleManufSensor } from "../../services/ioT-services/sensor-batch-service";
var SensorBatchStore = /** @class */ (function () {
    function SensorBatchStore() {
        var _this = this;
        this.sensorBatchMap = observable.object({});
        this.sensorMap = observable.object({});
        this.sensorBatchLoading = false;
        this.loadAllSensorBatches = function () { return __awaiter(_this, void 0, void 0, function () {
            var data;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.sensorBatchLoading) {
                            return [2 /*return*/];
                        }
                        runInAction(function () {
                            _this.sensorBatchLoading = true;
                        });
                        return [4 /*yield*/, getAllSensorBatches()];
                    case 1:
                        data = _a.sent();
                        try {
                            runInAction(function () {
                                var e_1, _a;
                                try {
                                    for (var data_1 = __values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                                        var sensorBach = data_1_1.value;
                                        _this.sensorBatchMap[sensorBach.id] = observable.object(sensorBach);
                                    }
                                }
                                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                                finally {
                                    try {
                                        if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
                                    }
                                    finally { if (e_1) throw e_1.error; }
                                }
                                _this.sortSensorBatchMap();
                            });
                        }
                        catch (e) {
                            console.log(e);
                        }
                        finally {
                            this.sensorBatchLoading = false;
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.loadSensors = function (sensorBatchId, addTelementries) { return __awaiter(_this, void 0, void 0, function () {
            var data_2, error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        if (this.sensorBatchLoading) {
                            return [2 /*return*/];
                        }
                        runInAction(function () {
                            _this.sensorBatchLoading = true;
                        });
                        return [4 /*yield*/, getSensorsForSensorBatch(sensorBatchId, addTelementries)];
                    case 1:
                        data_2 = ((_a.sent()) || []);
                        runInAction(function () {
                            var e_2, _a;
                            _this.sensorBatchMap[sensorBatchId].sensors = data_2;
                            try {
                                for (var data_3 = __values(data_2), data_3_1 = data_3.next(); !data_3_1.done; data_3_1 = data_3.next()) {
                                    var sensor = data_3_1.value;
                                    _this.sensorMap[sensor.id] = sensor;
                                }
                            }
                            catch (e_2_1) { e_2 = { error: e_2_1 }; }
                            finally {
                                try {
                                    if (data_3_1 && !data_3_1.done && (_a = data_3.return)) _a.call(data_3);
                                }
                                finally { if (e_2) throw e_2.error; }
                            }
                            _this.sensorBatchLoading = false;
                        });
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _a.sent();
                        console.error("Failed to load sensors for batch ".concat(sensorBatchId, ":"), error_1);
                        return [3 /*break*/, 4];
                    case 3:
                        runInAction(function () {
                            _this.sensorBatchLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.createEntity = function (entity, modalType) { return __awaiter(_this, void 0, void 0, function () {
            var modalFunctions, modalFunction, response, createdEntity_1, entityId_1, notAddedSensorsCount_1, message, error_2;
            var _a;
            var _this = this;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this.sensorBatchLoading) {
                            return [2 /*return*/, { passed: false, message: "", entityId: "" }];
                        }
                        runInAction(function () {
                            _this.sensorBatchLoading = true;
                        });
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, 4, 5]);
                        modalFunctions = (_a = {},
                            _a[IotDevicesModalTypes.SensorBatchModal] = postNewSensorBatch,
                            _a[IotDevicesModalTypes.SensorModal] = postImportNewSensors,
                            _a);
                        modalFunction = modalFunctions[modalType];
                        return [4 /*yield*/, modalFunction(entity)];
                    case 2:
                        response = _c.sent();
                        if (response.statusCode === 200) {
                            createdEntity_1 = response.result;
                            entityId_1 = (_b = createdEntity_1 === null || createdEntity_1 === void 0 ? void 0 : createdEntity_1.id) !== null && _b !== void 0 ? _b : "";
                            notAddedSensorsCount_1 = 0;
                            runInAction(function () {
                                var _a;
                                switch (modalType) {
                                    case IotDevicesModalTypes.SensorBatchModal:
                                        _this.sensorBatchMap[entityId_1] = observable.object(createdEntity_1);
                                        //resort Sensor Batches
                                        _this.sortSensorBatchMap();
                                        break;
                                    case IotDevicesModalTypes.SensorModal:
                                        var sensorBatchId = entity.sensorBatchId;
                                        var listOfSensors_1 = createdEntity_1;
                                        listOfSensors_1.forEach(function (sensorDto) {
                                            _this.sensorMap[sensorDto.id] = observable.object(sensorDto);
                                        });
                                        var existingSensors = ((_a = _this.sensorBatchMap[sensorBatchId]) === null || _a === void 0 ? void 0 : _a.sensors) || [];
                                        _this.sensorBatchMap[sensorBatchId].sensors = __spreadArray(__spreadArray([], __read(listOfSensors_1), false), __read(existingSensors), false);
                                        var notAddedSensors = entity.sensors.filter(function (e) { return !listOfSensors_1.some(function (eS) { return eS.macAddress === e.macAddress; }); });
                                        if (notAddedSensors.length > 0) {
                                            notAddedSensorsCount_1 = notAddedSensors.length;
                                            console.log("These Sensors have not been added:", notAddedSensors);
                                        }
                                        break;
                                    default:
                                        throw new Error("Invalid modal type");
                                }
                            });
                            if (modalType == IotDevicesModalTypes.SensorModal && notAddedSensorsCount_1 > 0) {
                                return [2 /*return*/, { passed: true, message: "Some Sensors have not been added, please check the logs...", entityId: entityId_1 }];
                            }
                            return [2 /*return*/, { passed: true, message: "", entityId: entityId_1 }];
                        }
                        else if (response.statusCode === 409) {
                            return [2 /*return*/, { passed: false, message: "Conflict-409: an entity with the same name is exist.", entityId: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            return [2 /*return*/, { passed: false, message: message, entityId: "" }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_2 = _c.sent();
                        console.error("Error creating entity:", error_2);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs", entityId: "" }];
                    case 4:
                        runInAction(function () {
                            _this.sensorBatchLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.editEntity = function (entity, modalType) { return __awaiter(_this, void 0, void 0, function () {
            var modalFunctions, modalFunction, response, entityId_2, message, error_3;
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.sensorBatchLoading) {
                            return [2 /*return*/, { passed: false, message: "", entityId: "" }];
                        }
                        runInAction(function () {
                            _this.sensorBatchLoading = true;
                        });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        modalFunctions = (_a = {},
                            _a[IotDevicesModalTypes.SensorBatchModal] = editSensorBatch,
                            _a[IotDevicesModalTypes.SensorModal] = editSensor,
                            _a);
                        modalFunction = modalFunctions[modalType];
                        return [4 /*yield*/, modalFunction(entity)];
                    case 2:
                        response = _b.sent();
                        if (response.statusCode === 200) {
                            entityId_2 = "";
                            runInAction(function () {
                                var _a, _b, _c, _d;
                                // Add new update when succeed here.
                                switch (modalType) {
                                    case IotDevicesModalTypes.SensorBatchModal:
                                        {
                                            var enitiySensorBatch = entity;
                                            entityId_2 = enitiySensorBatch === null || enitiySensorBatch === void 0 ? void 0 : enitiySensorBatch.id;
                                            var existingBatch = _this.sensorBatchMap[entityId_2];
                                            if (existingBatch) {
                                                var updatedSensorBatch = __assign(__assign({}, existingBatch), { batchDate: (_a = convertDDMMMYYYYtoDate(enitiySensorBatch === null || enitiySensorBatch === void 0 ? void 0 : enitiySensorBatch.batchDate)) !== null && _a !== void 0 ? _a : new Date(), purchaseOrder: enitiySensorBatch === null || enitiySensorBatch === void 0 ? void 0 : enitiySensorBatch.purchaseOrder, manufacturer: enitiySensorBatch === null || enitiySensorBatch === void 0 ? void 0 : enitiySensorBatch.manufacturer, hardwareRevision: enitiySensorBatch === null || enitiySensorBatch === void 0 ? void 0 : enitiySensorBatch.hardwareRevision, hardwareVariant: enitiySensorBatch === null || enitiySensorBatch === void 0 ? void 0 : enitiySensorBatch.hardwareVariant, firmwareRevision: enitiySensorBatch === null || enitiySensorBatch === void 0 ? void 0 : enitiySensorBatch.firmwareRevision, description: enitiySensorBatch === null || enitiySensorBatch === void 0 ? void 0 : enitiySensorBatch.description, probeOffset: enitiySensorBatch === null || enitiySensorBatch === void 0 ? void 0 : enitiySensorBatch.probeOffset, probeResolution: enitiySensorBatch === null || enitiySensorBatch === void 0 ? void 0 : enitiySensorBatch.probeResolution, probeLength: enitiySensorBatch === null || enitiySensorBatch === void 0 ? void 0 : enitiySensorBatch.probeLength });
                                                // Check for CSV
                                                if ((enitiySensorBatch === null || enitiySensorBatch === void 0 ? void 0 : enitiySensorBatch.sensorBatchCsv) && enitiySensorBatch.sensorBatchCsv.size > 0) {
                                                    updatedSensorBatch.isExistCsv = true;
                                                }
                                                // Check for PDF
                                                if ((enitiySensorBatch === null || enitiySensorBatch === void 0 ? void 0 : enitiySensorBatch.sensorBatchPdf) && enitiySensorBatch.sensorBatchPdf.size > 0) {
                                                    updatedSensorBatch.isExistPdf = true;
                                                }
                                                // Update in the map
                                                _this.sensorBatchMap[entityId_2] = updatedSensorBatch;
                                                //resort Sensor Batches
                                                _this.sortSensorBatchMap();
                                            }
                                            break;
                                        }
                                    case IotDevicesModalTypes.SensorModal:
                                        {
                                            var enitiySensor = entity;
                                            entityId_2 = enitiySensor === null || enitiySensor === void 0 ? void 0 : enitiySensor.id;
                                            var existingSensor = _this.sensorMap[entityId_2];
                                            var sensorBatchId = enitiySensor.sensorBatchId;
                                            if (existingSensor) {
                                                var updateSensor_1 = __assign(__assign({}, existingSensor), { serialNumber: enitiySensor.serialNumber, macAddress: enitiySensor.macAddress, isManufactured: (_b = enitiySensor.isManufactured) !== null && _b !== void 0 ? _b : false, manufacturedDate: enitiySensor.isManufactured ? convertDDMMMYYYYtoDate((_c = enitiySensor === null || enitiySensor === void 0 ? void 0 : enitiySensor.manufacturedDate) !== null && _c !== void 0 ? _c : "") : null });
                                                _this.sensorMap[entityId_2] = updateSensor_1;
                                                var updatedSensors = (_d = _this.sensorBatchMap[sensorBatchId].sensors) === null || _d === void 0 ? void 0 : _d.map(function (s) { return s.id === entityId_2 ? __assign(__assign({}, s), updateSensor_1) : s; });
                                                _this.sensorBatchMap[sensorBatchId].sensors = updatedSensors;
                                            }
                                            break;
                                        }
                                    default:
                                        throw new Error("Invalid modal type");
                                }
                            });
                            return [2 /*return*/, { passed: true, message: "", entityId: entityId_2 }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            return [2 /*return*/, { passed: false, message: message, entityId: "" }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_3 = _b.sent();
                        console.error("Error editing entity:", error_3);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs", entityId: "" }];
                    case 4:
                        runInAction(function () {
                            _this.sensorBatchLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.deleteEntitiy = function (entityId, modalType, sensorBatchId) { return __awaiter(_this, void 0, void 0, function () {
            var modalFunctions, modalFunction, response, message, error_4;
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.sensorBatchLoading) {
                            return [2 /*return*/, { passed: false, message: "" }];
                        }
                        runInAction(function () {
                            _this.sensorBatchLoading = true;
                        });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        modalFunctions = (_a = {},
                            _a[IotDevicesModalTypes.SensorBatchModal] = deleteSensorBatch,
                            _a[IotDevicesModalTypes.SensorModal] = deleteSensor,
                            _a);
                        modalFunction = modalFunctions[modalType];
                        return [4 /*yield*/, modalFunction(entityId)];
                    case 2:
                        response = _b.sent();
                        if (response.statusCode === 200) {
                            runInAction(function () {
                                switch (modalType) {
                                    case IotDevicesModalTypes.SensorBatchModal:
                                        {
                                            delete _this.sensorBatchMap[entityId];
                                            break;
                                        }
                                    case IotDevicesModalTypes.SensorModal:
                                        {
                                            delete _this.sensorMap[entityId];
                                            var sensorBatch = _this.sensorBatchMap[sensorBatchId];
                                            if (sensorBatch && sensorBatch.sensors) {
                                                sensorBatch.sensors = sensorBatch.sensors.filter(function (s) { return s.id !== entityId; });
                                            }
                                            console.log(sensorBatch);
                                            break;
                                        }
                                }
                                return { passed: true, message: "" };
                            });
                            return [2 /*return*/, { passed: true, message: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            return [2 /*return*/, { passed: false, message: message }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_4 = _b.sent();
                        console.error("Error creating customer:", error_4);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs" }];
                    case 4:
                        runInAction(function () {
                            _this.sensorBatchLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.toggleSensorsManuf = function (entiities) { return __awaiter(_this, void 0, void 0, function () {
            var response, message, error_5;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        runInAction(function () {
                            _this.sensorBatchLoading = true;
                        });
                        return [4 /*yield*/, toggleManufSensor(entiities)];
                    case 1:
                        response = _a.sent();
                        if (response.statusCode === 200) {
                            runInAction(function () {
                                entiities.forEach(function (s) {
                                    var _a, _b;
                                    var enitiySensor = s;
                                    var entityId = enitiySensor === null || enitiySensor === void 0 ? void 0 : enitiySensor.id;
                                    var existingSensor = _this.sensorMap[entityId];
                                    var sensorBatchId = enitiySensor.sensorBatchId;
                                    if (existingSensor) {
                                        var updateSensor_2 = __assign(__assign({}, existingSensor), { isManufactured: enitiySensor.isManufactured, manufacturedDate: enitiySensor.isManufactured ? convertDDMMMYYYYtoDate((_a = enitiySensor === null || enitiySensor === void 0 ? void 0 : enitiySensor.manufacturedDate) !== null && _a !== void 0 ? _a : "") : null });
                                        _this.sensorMap[entityId] = updateSensor_2;
                                        var updatedSensors = (_b = _this.sensorBatchMap[sensorBatchId].sensors) === null || _b === void 0 ? void 0 : _b.map(function (s) { return s.id === entityId ? __assign(__assign({}, s), updateSensor_2) : s; });
                                        _this.sensorBatchMap[sensorBatchId].sensors = updatedSensors;
                                    }
                                });
                            });
                            return [2 /*return*/, { passed: true, message: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            return [2 /*return*/, { passed: false, message: message }];
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        error_5 = _a.sent();
                        console.error("Error creating entity:", error_5);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs", entityId: "" }];
                    case 3:
                        runInAction(function () {
                            _this.sensorBatchLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.generateSensors = function (entity) { return __awaiter(_this, void 0, void 0, function () {
            var response, listOfSensors_2, sensorBatchId_1, message, error_6;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        runInAction(function () {
                            _this.sensorBatchLoading = true;
                        });
                        return [4 /*yield*/, generateSensors(entity)];
                    case 1:
                        response = _a.sent();
                        if (response.statusCode === 200) {
                            console.log(response.result);
                            listOfSensors_2 = response.result;
                            sensorBatchId_1 = entity.sensorBatchId;
                            runInAction(function () {
                                var _a;
                                listOfSensors_2.forEach(function (sensorDto) {
                                    _this.sensorMap[sensorDto.id] = observable.object(sensorDto);
                                });
                                var existingSensors = ((_a = _this.sensorBatchMap[sensorBatchId_1]) === null || _a === void 0 ? void 0 : _a.sensors) || [];
                                _this.sensorBatchMap[sensorBatchId_1].sensors = __spreadArray(__spreadArray([], __read(listOfSensors_2), false), __read(existingSensors), false);
                                DownloadGeneretedSensors("NewGeneratedSensors$", listOfSensors_2);
                            });
                            return [2 /*return*/, { passed: true, message: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            return [2 /*return*/, { passed: false, message: message }];
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        error_6 = _a.sent();
                        console.error("Error creating entity:", error_6);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs", entityId: "" }];
                    case 3:
                        runInAction(function () {
                            _this.sensorBatchLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        makeAutoObservable(this);
    }
    Object.defineProperty(SensorBatchStore.prototype, "sensorBatches", {
        get: function () {
            return Object.values(this.sensorBatchMap);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SensorBatchStore.prototype, "sensors", {
        get: function () {
            return Object.values(this.sensorMap);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SensorBatchStore.prototype, "getAllHardwareVariants", {
        get: function () {
            try {
                if (this.sensorBatchLoading) {
                    return [];
                }
                if (!this.sensorBatches || this.sensorBatches.length === 0)
                    return [];
                // Extract hardwareVariant from each sensor batch and use a Set to ensure uniqueness.
                var hardwareVariants = new Set(this.sensorBatches
                    .filter(function (batch) { return batch.hardwareVariant && batch.hardwareVariant.trim() !== ""; })
                    .map(function (batch) { return batch.hardwareVariant.trim(); }));
                // Convert the Set back to an array
                return Array.from(hardwareVariants);
            }
            catch (e) {
                console.log("Something went wrong while getAllHardwareVariants:", e);
                return [];
            }
        },
        enumerable: false,
        configurable: true
    });
    ;
    SensorBatchStore.prototype.sortSensorBatchMap = function () {
        var e_3, _a;
        var _this = this;
        var sortedKeys = Object.keys(this.sensorBatchMap).sort(function (a, b) {
            var dateA = new Date(_this.sensorBatchMap[a].batchDate);
            var dateB = new Date(_this.sensorBatchMap[b].batchDate);
            return dateB.getTime() - dateA.getTime(); // Descending order
        });
        var sortedSensorBatchMap = {};
        try {
            for (var sortedKeys_1 = __values(sortedKeys), sortedKeys_1_1 = sortedKeys_1.next(); !sortedKeys_1_1.done; sortedKeys_1_1 = sortedKeys_1.next()) {
                var key = sortedKeys_1_1.value;
                sortedSensorBatchMap[key] = this.sensorBatchMap[key];
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (sortedKeys_1_1 && !sortedKeys_1_1.done && (_a = sortedKeys_1.return)) _a.call(sortedKeys_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.sensorBatchMap = sortedSensorBatchMap;
    };
    return SensorBatchStore;
}());
export function DownloadGeneretedSensors(name, sensors) {
    // Generate CSV
    var csvContent = ConverSensorsToCsv(sensors);
    // Create a Blob from the CSV string
    var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    // Create a Blob URL
    var blobUrl = URL.createObjectURL(blob);
    // Trigger download
    downloadFileFromBlobUrl(blobUrl, "".concat(name, "-").concat(getTodayDateString(), ".csv"));
}
export default new SensorBatchStore();
