var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useMemo } from "react";
import { PageTitle, Spinner, useIntl } from "@metsooutotec/modes-react-components";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import provisionEventStore from "stores/provisions/provision-event-store";
import ListView from "components/utils/list-view/list-view";
import { itemViewDetailsContainerStyles } from "../../../common-style/common-style";
import customerStore from "stores/customer-stores/customer-store";
import { provisionEventsBreadcrumbsGenerator } from "../../provisions-utils/provision-events-bread-crumbs";
import { provisionTypeToIcon } from "../../provisions-utils/provision-events-icon";
import { filterProvisionEvents } from "../../provisions-utils/provision-events-filter";
import { provisionEventsLinkGeneratorSimple } from "components/provisions/provisions-utils/provision-events-link-generator";
import { convertDateToDDMMMYY } from "../../../utils/date-formatter-utils";
var ProvisionEventListView = observer(function () {
    var navigate = useNavigate();
    var formatMessage = useIntl().formatMessage;
    var _a = useParams(), customerId = _a.customerId, siteId = _a.siteId;
    var provisionEvents = provisionEventStore.provisionEvents, provisionEventLoading = provisionEventStore.provisionEventLoading;
    var customerMap = customerStore.customerMap, siteMap = customerStore.siteMap;
    var _b = __read(useState([]), 2), tableData = _b[0], setTableData = _b[1];
    var provisionEventsFiltered = useMemo(function () {
        return filterProvisionEvents(provisionEvents, { customerId: customerId, siteId: siteId });
    }, [provisionEvents, customerId, siteId, customerMap, siteMap]);
    useEffect(function () {
        setTableData(provisionEventsFiltered);
    }, [provisionEventsFiltered]);
    var template = [
        {
            headingKey: "provisionEvents.headings.eventDate",
            cellFunc: function (s) { var _a; return (_a = convertDateToDDMMMYY(s.eventDate)) !== null && _a !== void 0 ? _a : ""; },
            isSearchable: true,
        },
        {
            headingKey: "provisionEvents.headings.provision",
            cellFunc: function (s) { return _jsxs("span", __assign({ style: { display: "flex", gap: "1.5em" } }, { children: [provisionTypeToIcon(s.type), s.type] })); },
            isSearchable: true,
        },
        {
            headingKey: "provisionEvents.headings.description",
            cellFunc: function (s) { var _a; return (_a = s === null || s === void 0 ? void 0 : s.description) !== null && _a !== void 0 ? _a : ""; },
            isSearchable: true,
        },
    ];
    if (!customerId) {
        template.push({
            headingKey: "provisionEvents.headings.customer",
            cellFunc: function (s) { var _a, _b; return (_b = (_a = s === null || s === void 0 ? void 0 : s.customer) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ""; },
            isSearchable: true,
        });
    }
    if (!siteId) {
        template.push({
            headingKey: "provisionEvents.headings.site",
            cellFunc: function (s) { var _a, _b; return (_b = (_a = s === null || s === void 0 ? void 0 : s.site) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ""; },
            isSearchable: true,
        });
    }
    template.push({
        headingKey: "provisionEvents.headings.asset",
        cellFunc: function (s) { var _a, _b; return (_b = (_a = s === null || s === void 0 ? void 0 : s.asset) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ""; },
        isSearchable: true,
    });
    var tableContainerStyles = __assign(__assign({}, itemViewDetailsContainerStyles), { height: '91%' });
    // TODO: useMemo (maybe)
    var breadcrumbsPath = provisionEventsBreadcrumbsGenerator(customerId ? customerMap[customerId] : undefined, siteId ? siteMap[siteId] : undefined, undefined, undefined);
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { breadcrumbsPath: breadcrumbsPath, onPathChange: navigate, title: formatMessage("provisionEvents.title") }), _jsx("div", __assign({ style: tableContainerStyles }, { children: provisionEventLoading
                    ? (_jsx(Spinner, { text: formatMessage("provisionEvents.loading") }))
                    : _jsx(ListView, { noneYetKey: "provisionEvents.noneYet", template: template, data: tableData, onClickRow: function (a) {
                            var navStub = provisionEventsLinkGeneratorSimple(a.customer.id, a.site.id, a.asset.id);
                            navigate("".concat(navStub, "/event/").concat(a.id));
                        } }) }))] }));
});
export default ProvisionEventListView;
