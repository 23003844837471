var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, PageTitle, Tab, useIntl } from "@metsooutotec/modes-react-components";
import { observer } from "mobx-react-lite";
import { useMemo, useState, useCallback, useEffect } from "react";
import customerStore from "stores/customer-stores/customer-store";
import provisionEventStore from "stores/provisions/provision-event-store";
import { ProvisionEventsSVGLoader } from "../provision-events-svg-loader";
import { ProvisionEventErrors } from "./provision-event-errors";
import { getCurrentCommissionStateForDecommission } from "components/provisions/utils/provision-state";
import { SidebarSelection } from "./provision-event-sidebar-selection";
import { useNavigate } from "react-router-dom";
import { provisionEventsLinkGenerator } from "components/provisions/provisions-utils/provision-events-link-generator";
import { ProvisionEventsViewSVGEnricher } from "./provision-events-edit-svg-enrich";
import { ProvisionEventEditModalSave } from "./provision-event-edit-modal-save";
import { ProvisionEventEditModalLeave } from "./provision-event-edit-modal-leave";
import { convertDateToDDMMMYY } from "../../../utils/date-formatter-utils";
var inlineStyles = "\n.width50 {\n    width: 50%\n}\n.tab-align-in-card-header {\n    padding-bottom: 0px !important;\n    padding-top: 22px !important;\n}\n.list-align-in-card-content {\n    padding-left: 0px !important;\n    padding-right: 0px !important;\n}\n\n.wrapper> div {\n    width: 100% !important;\n    height: 100% !important;\n}\n\n.wrapper svg {\n    width: 100% !important;\n    height: 100% !important;\n}\n";
var TabContent = function (_a) {
    var indexAssetSection = _a.indexAssetSection, customer = _a.customer, site = _a.site, asset = _a.asset, eventId = _a.eventId;
    var navigate = useNavigate();
    var assetSections = asset.assetSections;
    var assetSectionLabels = assetSections.map(function (s) { return s.name; });
    return (_jsx(Tab, { as: "div", "data-cy": "tab", activeIndex: indexAssetSection, onTabChange: function (e, d) {
            var nextIndex = Number(d.activeIndex);
            var nextAssetSection = assetSections[nextIndex];
            if (nextAssetSection) {
                navigate(provisionEventsLinkGenerator(customer, site, asset, nextAssetSection, { id: eventId || 'new-event' }, '/edit'));
            }
        }, menu: {
            pointing: true,
            secondary: true
        }, style: { width: '100%' }, panes: assetSectionLabels.map(function (asl) {
            return { menuItem: asl };
        }) }));
};
export var ProvisionEventListEditDecommission = observer(function (props) {
    var customerId = props.customerId, siteId = props.siteId, assetSectionId = props.assetSectionId, assetId = props.assetId, provisionEvent = props.provisionEvent;
    var formatMessage = useIntl().formatMessage;
    var navigate = useNavigate();
    var editingProvisionEventDetail = provisionEventStore.editingProvisionEventDetail, provisionEventsDetail = provisionEventStore.provisionEventsDetail, editingProvisionId = provisionEventStore.editingProvisionId, saveProvisionEvent = provisionEventStore.saveProvisionEvent;
    var customerMap = customerStore.customerMap, siteMap = customerStore.siteMap, assetMap = customerStore.assetMap, assetSectionMap = customerStore.assetSectionMap;
    var _a = __read(useState(false), 2), isCreateModalOpen = _a[0], setIsSaveModalOpen = _a[1];
    var _b = __read(useState(false), 2), isLeaveModalOpen = _b[0], setIsLeaveModalOpen = _b[1];
    var _c = __read(useState({
        liners: [],
        sensors: [],
    }), 2), selected = _c[0], setSelected = _c[1];
    useEffect(function () {
        setSelected({
            liners: provisionEvent.liners,
            sensors: provisionEvent.sensors
        });
    }, [provisionEvent]);
    var customer = customerMap[customerId];
    var site = siteMap[siteId];
    var asset = assetMap[assetId];
    var assetSections = asset.assetSections;
    // const assetSection = assetSectionMap[assetSectionId]
    // Use zero index if not found (i.e. route does not include asset selection)
    var indexAssetSection = assetSections && assetSections.length ? Math.max(assetSections.findIndex(function (s) { return s.id === assetSectionId; }), 0) : null;
    var currentTabAssetSection = indexAssetSection != null ? assetSections[indexAssetSection] : null;
    var tableContainerStyles = {
        padding: "var(--size-m)",
        height: '91%',
        display: 'flex',
        width: '100%',
        gap: 'var(--size-m)'
    };
    var onSave = useCallback(function (cb) { return __awaiter(void 0, void 0, void 0, function () {
        var saveableEvent;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    saveableEvent = __assign(__assign({}, provisionEvent), selected);
                    return [4 /*yield*/, saveProvisionEvent(JSON.parse(JSON.stringify(saveableEvent)))];
                case 1:
                    _a.sent();
                    cb();
                    return [2 /*return*/];
            }
        });
    }); }, [provisionEvent, selected]);
    var provisionEventsEditSaveModal = {
        open: function () {
            // TODO: Check diffs and do not use modal if there aren't any changes
            setIsSaveModalOpen(true);
        },
        close: function () {
            setIsSaveModalOpen(false);
        },
        confirm: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                onSave(function () {
                    setIsSaveModalOpen(false);
                    navigate(provisionEventsLinkGenerator(customer, site, asset));
                });
                return [2 /*return*/];
            });
        }); }
    };
    var provisionEventsEditLeaveModal = {
        open: function () {
            // TODO: Check diffs and do not use modal if there aren't any changes
            setIsLeaveModalOpen(true);
        },
        close: function () {
            setIsLeaveModalOpen(false);
        },
        confirm: function () {
            setIsLeaveModalOpen(false);
            navigate(provisionEventsLinkGenerator(customer, site, asset));
        }
    };
    var pageTitle = "".concat(convertDateToDDMMMYY(provisionEvent.eventDate), " / ").concat(provisionEvent.description);
    var provisionState = getCurrentCommissionStateForDecommission(provisionEventsDetail, provisionEvent);
    if (provisionState.errors.length > 0) {
        var errors = provisionState.errors.map(function (e) {
            return {
                header: "".concat(e.equipment, " / ").concat(e.id),
                content: (_jsxs("div", { children: [_jsxs("div", { children: [_jsx("b", { children: "EventId:" }), " ", e.event.id] }), _jsxs("div", { children: [_jsx("b", { children: "Reason:" }), " ", e.reason] })] }))
            };
        });
        return _jsx(ProvisionEventErrors, { errors: errors });
    }
    var selectable = useMemo(function () {
        var liners = Object.values(provisionState.commissionedLiners).filter(function (l) { return l.linerLocation.assetSectionId === assetSectionId; });
        var linerIds = liners.map(function (l) { return l.id; });
        var sensors = Object.values(provisionState.commissionedSensors).filter(function (s) { return linerIds.includes(s.linerRegion.linerId); });
        return {
            liners: liners,
            sensors: sensors
        };
    }, [provisionState]);
    var onSelectElement = useCallback(function (newSelected) {
        setSelected(function (oldAllSelected) {
            if (newSelected.type === 'Sensor') {
                // Sensor
                var existingMatchIndex_1 = oldAllSelected.sensors.findIndex(function (s) { return s.id === newSelected.id; });
                if (existingMatchIndex_1 > -1) {
                    // Exists in list, deselect
                    return {
                        liners: oldAllSelected.liners,
                        sensors: __spreadArray([], __read(oldAllSelected.sensors), false).filter(function (s) { return s.id !== newSelected.id; })
                    };
                }
                // Does not exist in list, select
                var sensor = selectable.sensors.filter(function (s) { return s.id === newSelected.id; })[0];
                var newSensors_1 = __spreadArray([], __read(oldAllSelected.sensors), false).concat([sensor]);
                return {
                    liners: oldAllSelected.liners,
                    sensors: newSensors_1
                };
            }
            // Liner
            var existingMatchIndex = oldAllSelected.liners.findIndex(function (s) { return s.id === newSelected.id; });
            if (existingMatchIndex > -1) {
                // Exists in list, deselect
                return {
                    liners: __spreadArray([], __read(oldAllSelected.liners), false).filter(function (s) { return s.id !== newSelected.id; }),
                    sensors: __spreadArray([], __read(oldAllSelected.sensors), false).filter(function (s) { return s.linerRegion.linerId !== newSelected.id; })
                };
            }
            // Does not exist in list, select
            var liner = selectable.liners.filter(function (s) { return s.id === newSelected.id; })[0];
            var sensorsToAdd = selectable.sensors
                .filter(function (s) { return s.linerRegion.linerId === newSelected.id; })
                .filter(function (s) { return oldAllSelected.sensors.findIndex(function (os) { return os.id === s.id; }) === -1; });
            var newLiners = __spreadArray([], __read(oldAllSelected.liners), false).concat([liner]);
            var newSensors = __spreadArray([], __read(oldAllSelected.sensors), false).concat(sensorsToAdd);
            return {
                liners: newLiners,
                sensors: newSensors
            };
        });
    }, [selectable]);
    return (_jsxs(_Fragment, { children: [_jsx("style", { children: inlineStyles }), _jsx(PageTitle, { title: pageTitle, extraItems: _jsxs(_Fragment, { children: [_jsx(Button, __assign({ icon: "icon-arrow-left-16", labelPosition: "left", onClick: provisionEventsEditLeaveModal.open, subtle: true }, { children: formatMessage("provisionEvents.edit.back") })), _jsx(Button, __assign({ icon: "icon-plus-16", labelPosition: "left", onClick: provisionEventsEditSaveModal.open, primary: true }, { children: formatMessage("provisionEvents.edit.save") }))] }) }), _jsxs("div", __assign({ style: tableContainerStyles }, { children: [indexAssetSection != null && currentTabAssetSection && _jsx(ProvisionEventsSVGLoader, { tabContent: _jsx(TabContent, { asset: asset, customer: customer, indexAssetSection: indexAssetSection, site: site, eventId: editingProvisionId }), assetSectionId: currentTabAssetSection.id, SVGInnerComponent: ProvisionEventsViewSVGEnricher, selectableElements: selectable, selectedElements: selected, assetId: assetId, onSelectElement: onSelectElement }), _jsx(SidebarSelection, { selectable: selectable, selected: selected, onSelectElement: onSelectElement })] })), _jsx(ProvisionEventEditModalSave, { isOpen: isCreateModalOpen, onClose: provisionEventsEditSaveModal.close, onConfirm: provisionEventsEditSaveModal.confirm }), _jsx(ProvisionEventEditModalLeave, { isOpen: isLeaveModalOpen, onClose: provisionEventsEditLeaveModal.close, onConfirm: provisionEventsEditLeaveModal.confirm })] }));
});
