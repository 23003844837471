var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogDescription, DialogHeader, ListSelector, ToastLevel, useIntl, useToast } from "@metsooutotec/modes-react-components";
import { useEffect, useState } from "react";
import sensorBatchStore from "../../../stores/ioT-stores/sensor-batch-store";
import stockManagementStore from "../../../stores/stock-management-stores/stock-management-store";
import ModalsButtons from "../../common/modals-buttons";
import { convertDateToDDMMMYYYY } from "../../utils/date-formatter-utils";
import { convertUnknownSiteNameToNull } from "../stock-allocation/stock-allocation-helper";
import { getAllocatedSensors, getOnHandSensors } from "../stock-managment/stock-management-helper";
var DispatchModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, partNumber = _a.partNumber, sensorToEdit = _a.sensorToEdit, dispatchDate = _a.dispatchDate, customerId = _a.customerId, siteId = _a.siteId, siteName = _a.siteName;
    var formatMessage = useIntl().formatMessage;
    var setMessage = useToast().setMessage;
    var sensorBatches = sensorBatchStore.sensorBatches;
    var _b = __read(useState([]), 2), onHandSensors = _b[0], setOnHandSensors = _b[1];
    var _c = __read(useState([]), 2), listItemsSelector = _c[0], setListItemsSelector = _c[1];
    var _d = __read(useState(""), 2), selectedItemId = _d[0], setSelectedItemId = _d[1];
    var _e = __read(useState(false), 2), loading = _e[0], setLoading = _e[1];
    var _f = __read(useState(""), 2), errorMessage = _f[0], setErrorMessage = _f[1];
    var _g = __read(useState(Boolean(sensorToEdit === null || sensorToEdit === void 0 ? void 0 : sensorToEdit.id)), 2), isEditing = _g[0], setIsEditing = _g[1];
    useEffect(function () {
        setIsEditing(Boolean(sensorToEdit === null || sensorToEdit === void 0 ? void 0 : sensorToEdit.id));
        var onHandSen = getOnHandSensors(sensorBatches, partNumber);
        var allocatedSensors = getAllocatedSensors(sensorBatches, partNumber);
        var filteredSensors = allocatedSensors.filter(function (a) { return (a.customerId === customerId) && ((a.siteId === siteId) || (a.siteName === convertUnknownSiteNameToNull(siteName))); });
        var listOfSensorsItems = onHandSen
            .filter(function (s) { return !filteredSensors.some(function (a) { return a.id === s.id; }); })
            .map(function (sensor) { return ({
            title: "".concat(sensor.serialNumber, " - ").concat(sensor.macAddress),
            value: sensor.id,
        }); });
        setListItemsSelector(listOfSensorsItems);
        setOnHandSensors(onHandSen);
    }, [isOpen, partNumber]);
    var handleSelectedSensorChange = function (idValue) {
        setErrorMessage("");
        setSelectedItemId(idValue);
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var sensor, newSiteId, editStockAllocation, result, sensorToDelete, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    setErrorMessage("");
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, 6, 7]);
                    if (!selectedItemId) {
                        setErrorMessage("Nothing has been selected!");
                        return [2 /*return*/];
                    }
                    sensor = onHandSensors.find(function (s) { return s.id === selectedItemId; });
                    if (!sensor) {
                        setMessage("Sensor has not been found!", ToastLevel.ALARM, 4000);
                        return [2 /*return*/];
                    }
                    newSiteId = convertUnknownSiteNameToNull(siteId);
                    editStockAllocation = {
                        listOfSensorsIds: [sensor.id],
                        newCustomerId: customerId,
                        newSiteId: newSiteId,
                        newAllocationDate: convertDateToDDMMMYYYY(dispatchDate)
                    };
                    return [4 /*yield*/, stockManagementStore.updateStockAllocation(editStockAllocation)];
                case 2:
                    result = _a.sent();
                    if (!result.passed) {
                        setMessage("Something went wrong!", ToastLevel.ALARM, 4000);
                        return [2 /*return*/];
                    }
                    if (!(isEditing && sensorToEdit)) return [3 /*break*/, 4];
                    sensorToDelete = { listOfSensorsIds: [sensorToEdit.id] };
                    return [4 /*yield*/, stockManagementStore.deleteStockAllocation(sensorToDelete)];
                case 3:
                    result = _a.sent();
                    if (!result.passed) {
                        setErrorMessage(result.message);
                        setMessage("Something went wrong!", ToastLevel.ALARM, 4000);
                        return [2 /*return*/];
                    }
                    _a.label = 4;
                case 4:
                    setMessage("It has been submitted!", ToastLevel.SUCCESS, 4000);
                    onCloseProcess();
                    return [3 /*break*/, 7];
                case 5:
                    error_1 = _a.sent();
                    console.error(error_1); // Log the error or handle it as needed
                    setMessage("An error occurred.", ToastLevel.ALARM, 4000);
                    return [3 /*break*/, 7];
                case 6:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var onCloseProcess = function () {
        onClose();
    };
    var labels = {
        header: formatMessage("Edit a despatched sensor"),
        description: formatMessage("dispatchEdit.description"),
    };
    return (_jsxs(Dialog, __assign({ dimmer: true, open: isOpen, size: 'small' }, { children: [_jsx(DialogHeader, __assign({ username: '' }, { children: labels.header })), errorMessage && _jsx("p", __assign({ style: { color: 'red' } }, { children: errorMessage })), _jsx(DialogDescription, __assign({ style: { marginBottom: "var(--size-xxl)" } }, { children: labels.description })), (!onHandSensors.length) ?
                _jsx(_Fragment, { children: formatMessage("sensor.notFound") })
                :
                    _jsx(ListSelector, { items: listItemsSelector, onChange: function (s) { return handleSelectedSensorChange(s); }, selectedItem: selectedItemId, style: { maxHeight: '12em', overflow: 'scroll' } }), _jsx(ModalsButtons, { handleSubmit: handleSubmit, onCloseProcess: onCloseProcess, isLoading: loading })] })));
};
export default DispatchModal;
