var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, DateTimeInput, Grid, GridColumn, Icon, Input, PageTitle, Select, ToastLevel, useIntl, useToast } from "@metsooutotec/modes-react-components";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import customerStore from "../../../stores/customer-stores/customer-store";
import sensorBatchStore from "../../../stores/ioT-stores/sensor-batch-store";
import { itemViewDetailsContainerStyles, itemViewListExtraTopPaddingStyles, itemViewSeparatorStyles, itemViewSubtitleStyles } from "../../common-style/common-style";
import LowSpinner from "../../common/low-spinner";
import { convertValueToOption } from "../../common/select-component-helper";
import { convertDateToDDMMMYYYY } from "../../utils/date-formatter-utils";
import StockAllocationDeleteModal from "../stock-allocation/stock-allocation-delete-modal";
import { getMatchingSensorIds } from "../stock-allocation/stock-allocation-helper";
import { getFilteredSiteOptions } from "../stock-allocation/stock-allocation-modal/stock-allocation-modal-helper";
import { getAllocatedSensors } from "../stock-managment/stock-management-helper";
import DispatchModal from "./dispatch-modal";
import DispatchSubmitModal from "./dispatch-submit-modal";
var DispatchView = observer(function () {
    var formatMessage = useIntl().formatMessage;
    var setMessage = useToast().setMessage;
    var _a = useParams(), partNumber = _a.partNumber, customerName = _a.customerName, siteName = _a.siteName;
    var _b = __read(useState([]), 2), listOfPartNumbers = _b[0], setListOfPartNumbers = _b[1];
    var _c = __read(useState([]), 2), listOfCustomers = _c[0], setListOfCustomers = _c[1];
    var _d = __read(useState([]), 2), listOfSites = _d[0], setListOfSites = _d[1];
    var _e = __read(useState(), 2), pickedPartNumber = _e[0], setPickedPartNumber = _e[1];
    var _f = __read(useState(), 2), pickedCustomer = _f[0], setPickedCustomer = _f[1];
    var _g = __read(useState(), 2), pickedSite = _g[0], setPickedSite = _g[1];
    var _h = __read(useState(new Date()), 2), dispatchDate = _h[0], setDispatchDate = _h[1];
    var _j = __read(useState(0), 2), quantity = _j[0], setQuantity = _j[1];
    var _k = __read(useState([]), 2), listOfSensors = _k[0], setListOfSensors = _k[1];
    var _l = __read(useState(null), 2), stockAllocationToDelete = _l[0], setStockAllocationToDelete = _l[1];
    var _m = __read(useState(false), 2), isDeleteAllocationOpen = _m[0], setIsDeleteAllocationOpen = _m[1];
    var _o = __read(useState(), 2), sensorToEdit = _o[0], setSensorToEdit = _o[1];
    var _p = __read(useState(false), 2), isDispatchModalOpen = _p[0], setIsDispatchModalOpen = _p[1];
    var _q = __read(useState(false), 2), isDispatchSubmitModalOpen = _q[0], setIsDispatchSubmitModalOpen = _q[1];
    var sensorBatches = sensorBatchStore.sensorBatches, getAllHardwareVariants = sensorBatchStore.getAllHardwareVariants;
    var customers = customerStore.customers, loadAllCustomers = customerStore.loadAllCustomers;
    var loadPartNumbers = function () {
        var hardwareVariants = getAllHardwareVariants;
        if (hardwareVariants.length > 0) {
            setListOfPartNumbers(hardwareVariants.map(function (h) { return ({
                text: h,
                value: h
            }); }));
        }
    };
    useEffect(function () {
        loadPartNumbers();
    }, [sensorBatches]);
    // load customers data
    useEffect(function () {
        if (!customers.length) {
            loadAllCustomers();
        }
    }, []);
    // Setting List of Customers
    useEffect(function () {
        var customerOptions = customers.map(function (c) { return ({ text: c.name, value: c.id }); });
        setListOfCustomers(customerOptions);
    }, [customers]);
    // Loading Part Numbers
    useEffect(function () {
        loadPartNumbers();
    }, [getAllHardwareVariants]);
    // Setting Picked Items
    useEffect(function () {
        var partNumberOption = listOfPartNumbers.find(function (p) { return p.text.toLowerCase() === (partNumber === null || partNumber === void 0 ? void 0 : partNumber.toLowerCase()); });
        if (partNumberOption && partNumberOption.value !== (pickedPartNumber === null || pickedPartNumber === void 0 ? void 0 : pickedPartNumber.value)) {
            setPickedPartNumber(partNumberOption);
        }
    }, [partNumber, listOfPartNumbers]);
    useEffect(function () {
        var customerOption = listOfCustomers.find(function (c) { return c.text.toLowerCase() === (customerName === null || customerName === void 0 ? void 0 : customerName.toLowerCase()); });
        if (customerOption && customerOption.value !== (pickedCustomer === null || pickedCustomer === void 0 ? void 0 : pickedCustomer.value)) {
            setPickedCustomer(customerOption);
        }
    }, [customerName, listOfCustomers]);
    useEffect(function () {
        var siteOption = listOfSites.find(function (s) { return s.text.toLowerCase() === (siteName === null || siteName === void 0 ? void 0 : siteName.toLowerCase()); });
        if (siteOption && siteOption.value !== (pickedSite === null || pickedSite === void 0 ? void 0 : pickedSite.value)) {
            setPickedSite(siteOption);
        }
    }, [siteName, listOfSites]);
    // Setting Allocated Sensors
    useEffect(function () {
        if ((pickedCustomer === null || pickedCustomer === void 0 ? void 0 : pickedCustomer.value) && (pickedPartNumber === null || pickedPartNumber === void 0 ? void 0 : pickedPartNumber.text) && (pickedSite === null || pickedSite === void 0 ? void 0 : pickedSite.text)) {
            // Fetch allocated sensors based on the part number.
            var allocatedSensors = getAllocatedSensors(sensorBatches, pickedPartNumber.text);
            // Fetch IDs of sensors matching the criteria.
            var matchedSensorIds_1 = getMatchingSensorIds(allocatedSensors, pickedCustomer === null || pickedCustomer === void 0 ? void 0 : pickedCustomer.text, pickedSite === null || pickedSite === void 0 ? void 0 : pickedSite.text);
            // Filter the allocatedSensors to include only those whose IDs are in matchedSensorIds.
            var filteredData = allocatedSensors.filter(function (sensor) { return matchedSensorIds_1.includes(sensor.id); });
            setListOfSensors(filteredData);
            setQuantity(filteredData.length);
        }
    }, [pickedCustomer, pickedPartNumber, pickedSite, isDispatchModalOpen, isDeleteAllocationOpen, isDispatchSubmitModalOpen]);
    useEffect(function () {
        if (!(pickedCustomer === null || pickedCustomer === void 0 ? void 0 : pickedCustomer.value)) {
            setListOfSites([]);
            return;
        }
        var findCustomer = customers.find(function (s) { return s.id === pickedCustomer.value; });
        var filteredSiteOptions = getFilteredSiteOptions(findCustomer === null || findCustomer === void 0 ? void 0 : findCustomer.sites);
        setListOfSites(filteredSiteOptions);
        setPickedSite({ text: "Unknown", value: "" });
    }, [pickedCustomer]);
    var modalTitle = formatMessage("dispatch.title");
    var onChangeActions = {
        onPartNumber: function (pickedValueResult) {
            setPickedPartNumber(pickedValueResult);
            setListOfSensors([]);
        },
        onCustomer: function (pickedValueResult) {
            setPickedCustomer(pickedValueResult);
            setListOfSensors([]);
        },
        onSite: function (pickedValueResult) {
            setPickedSite(pickedValueResult);
            setListOfSensors([]);
        },
        onDispatchDate: useCallback(function (value) {
            try {
                var date = new Date(value);
                if (isNaN(date.getDate())) {
                    console.warn("Invalid Date!");
                }
                else {
                    setDispatchDate(date);
                }
            }
            catch (e) {
                console.warn(e);
            }
        }, []),
    };
    var sensorsActionsHandlers = {
        close: function () {
            setStockAllocationToDelete(null);
            setIsDeleteAllocationOpen(false);
            setSensorToEdit(null);
            setIsDispatchModalOpen(false);
        },
        edit: function (sensor) {
            setSensorToEdit(sensor);
            setIsDispatchModalOpen(true);
        },
        delete: function (senssor) {
            var toDelete = { listOfSensorsIds: [senssor.id] };
            setStockAllocationToDelete(toDelete);
            setIsDeleteAllocationOpen(true);
        },
        submit: function () {
            if (listOfSensors.length < 0 || dispatchDate == null) {
                setMessage("No sesnors or dispatch Date is empty!", ToastLevel.SUCCESS, 4000);
                return;
            }
            setIsDispatchSubmitModalOpen(true);
        }
    };
    var dipatchActionsHandlers = {
        open: function () {
            setIsDispatchModalOpen(true);
        }
    };
    var labels = {
        partNumber: formatMessage("headings.partNumber"),
        customer: formatMessage("headings.customer"),
        site: formatMessage("headings.site"),
        DispatchDate: formatMessage("headings.DispatchDate"),
        quantity: formatMessage("headings.quantity"),
    };
    var rightPageButtons = (_jsx(_Fragment, { children: _jsx(Button, __assign({ icon: "icon-submitted-16", labelPosition: "right", onClick: sensorsActionsHandlers.submit, primary: true }, { children: formatMessage("dispatch.submit") })) }));
    var rightDispatchButtons = (_jsx(_Fragment, { children: _jsx(Button, __assign({ icon: "icon-plus-16", labelPosition: "right", onClick: dipatchActionsHandlers.open, primary: true }, { children: formatMessage("dispatch.addNew") })) }));
    var RenderInputFeilds = function () {
        return ((!customers.length) ?
            _jsx(LowSpinner, { id: "customer.loading" })
            :
                _jsx("div", __assign({ style: itemViewDetailsContainerStyles }, { children: _jsxs(Grid, __assign({ columns: 3 }, { children: [_jsx(GridColumn, { children: (listOfPartNumbers.length > 0) ? _jsx(Select, { icon: _jsx(Icon, { className: "icon-search-16" }), label: labels.partNumber, onChange: function (value) { return onChangeActions.onPartNumber(convertValueToOption(value, listOfPartNumbers)); }, options: listOfPartNumbers, value: pickedPartNumber === null || pickedPartNumber === void 0 ? void 0 : pickedPartNumber.text, required: true }) : _jsx(_Fragment, {}) }), _jsx(GridColumn, { children: (listOfCustomers.length > 0) ? _jsx(Select, { icon: _jsx(Icon, { className: "icon-search-16" }), label: labels.customer, onChange: function (value) { return onChangeActions.onCustomer(convertValueToOption(value, listOfCustomers)); }, options: listOfCustomers, value: pickedCustomer === null || pickedCustomer === void 0 ? void 0 : pickedCustomer.text, required: true }) : _jsx(_Fragment, {}) }), _jsx(GridColumn, { children: (listOfSites.length > 0) ? _jsx(Select, { icon: _jsx(Icon, { className: "icon-search-16" }), label: labels.site, onChange: function (value) { return onChangeActions.onSite(convertValueToOption(value, listOfSites)); }, options: listOfSites, value: pickedSite === null || pickedSite === void 0 ? void 0 : pickedSite.text, required: true }) : _jsx(_Fragment, {}) }), _jsx(GridColumn, { children: _jsx(DateTimeInput, { label: labels.DispatchDate, onChange: function (value) { return onChangeActions.onDispatchDate(value.toString()); }, value: convertDateToDDMMMYYYY(dispatchDate), format: "DD-MMM-YYYY", timeFormat: false }) }), _jsx(GridColumn, { children: _jsx(Input, { label: labels.quantity, value: quantity, readOnly: true }) })] })) })));
    };
    var sensorCell = function (sensor) {
        var icons = (_jsxs("span", __assign({ style: { display: 'flex', gap: '3px', cursor: 'pointer' } }, { children: [_jsx(Icon, { className: "icon-edit-16", onClick: function () { return sensorsActionsHandlers.edit(sensor); } }), _jsx(Icon, { className: "icon-trash-16", onClick: function () { return sensorsActionsHandlers.delete(sensor); } })] })));
        return (_jsx(GridColumn, { children: _jsx(Input, { value: sensor.serialNumber, icon: icons, readOnly: true }, sensor.id) }));
    };
    var RenderSensorsFeilds = function () {
        return (_jsxs("div", __assign({ style: itemViewDetailsContainerStyles }, { children: [_jsxs("div", __assign({ style: { display: "flex", justifyContent: "space-between", alignItems: "flex-start" } }, { children: [_jsxs("h3", __assign({ style: itemViewSubtitleStyles }, { children: [formatMessage("dispatch.serialNumbers"), ":"] })), rightDispatchButtons] })), _jsx("div", __assign({ style: itemViewListExtraTopPaddingStyles }, { children: _jsx(Grid, __assign({ columns: 4 }, { children: (listOfSensors.length > 0) ? listOfSensors.map(function (s) {
                            return sensorCell(s);
                        }) : _jsx(_Fragment, {}) })) }))] })));
    };
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { title: modalTitle, extraItems: rightPageButtons }), RenderInputFeilds(), _jsx("div", { style: itemViewSeparatorStyles }), RenderSensorsFeilds(), isDeleteAllocationOpen && _jsx(StockAllocationDeleteModal, { isOpen: isDeleteAllocationOpen, setIsOpen: setIsDeleteAllocationOpen, entity: stockAllocationToDelete }), isDispatchModalOpen && (pickedPartNumber === null || pickedPartNumber === void 0 ? void 0 : pickedPartNumber.text) && (pickedCustomer === null || pickedCustomer === void 0 ? void 0 : pickedCustomer.value) && (pickedSite === null || pickedSite === void 0 ? void 0 : pickedSite.text) &&
                _jsx(DispatchModal, { isOpen: isDispatchModalOpen, onClose: sensorsActionsHandlers.close, partNumber: pickedPartNumber === null || pickedPartNumber === void 0 ? void 0 : pickedPartNumber.text, sensorToEdit: sensorToEdit, dispatchDate: dispatchDate, customerId: pickedCustomer === null || pickedCustomer === void 0 ? void 0 : pickedCustomer.value, siteId: pickedSite === null || pickedSite === void 0 ? void 0 : pickedSite.value, siteName: pickedSite.text }), isDispatchSubmitModalOpen && _jsx(DispatchSubmitModal, { isOpen: isDispatchSubmitModalOpen, setIsOpen: setIsDispatchSubmitModalOpen, dispatchDate: dispatchDate, listOfSensors: listOfSensors })] }));
});
export default DispatchView;
