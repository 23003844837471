import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
var SignOutCallback = function () {
    useEffect(function () {
        window.history.replaceState(null, '', '/');
        window.location.href = "/";
    }, []);
    return (_jsx("div", { children: _jsx("p", { children: " Redirecting..." }) }));
};
export default SignOutCallback;
