var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, DialogHeader, useIntl } from "@metsooutotec/modes-react-components";
export var ProvisionEventEditModalSave = function (props) {
    var formatMessage = useIntl().formatMessage;
    var isOpen = props.isOpen, onClose = props.onClose, onConfirm = props.onConfirm;
    return (_jsx(_Fragment, { children: _jsxs(Dialog, __assign({ open: isOpen, dimmer: true, size: "small", style: { maxWidth: "696px" } }, { children: [_jsx(DialogHeader, { children: formatMessage('provisionEvents.edit.modal.save.title') }), _jsx(DialogContent, __assign({ "data-cy": "dialog-content" }, { children: formatMessage('provisionEvents.edit.modal.save.content') })), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: function () { return onClose(); }, subtle: true }, { children: formatMessage('provisionEvents.edit.modal.save.button.cancel') })), _jsx(Button, __assign({ onClick: function () { return onConfirm(); }, primary: true }, { children: formatMessage('provisionEvents.edit.modal.save.button.confirm') }))] })] })) }));
};
