import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useIntl, Spinner } from "@metsooutotec/modes-react-components";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import provisionEventStore from "stores/provisions/provision-event-store";
import customerStore from "stores/customer-stores/customer-store";
import { NoProvisionEvent } from "./provision-event-not-found";
import { ProvisionEventListEditDecommission } from "./provision-event-asset-edit-decommission";
import { ProvisionEventListEditPlanned } from "./provision-event-asset-edit-planned";
import { ProvisionEventListEditCommission } from "./provision-event-asset-edit-commission";
var LoadCustomerInner = observer(function (_a) {
    var children = _a.children;
    var formatMessage = useIntl().formatMessage;
    var customers = customerStore.customers, loadAllCustomers = customerStore.loadAllCustomers, loadSitesForCustomer = customerStore.loadSitesForCustomer;
    var customerId = useParams().customerId;
    // load data
    useEffect(function () {
        if (!customers.length) {
            loadAllCustomers();
        }
    });
    //load sites for customers if not exist
    useEffect(function () {
        if (!customerId)
            return;
        var customer = customers.filter(function (c) { return c.id === customerId; })[0];
        if (customer && !customer.sites) {
            loadSitesForCustomer(customerId);
        }
    }, [customerId, JSON.stringify(customers)]);
    if (customers.length === 0)
        return _jsx(Spinner, { text: formatMessage("customer.loading"), style: { zIndex: 8 } });
    return children;
});
var WrappedProvisionEventListEdit = function () {
    var _a = useParams(), customerId = _a.customerId, siteId = _a.siteId, assetId = _a.assetId, assetSectionId = _a.assetSectionId, provisionEventId = _a.provisionEventId;
    // Bad link?!
    if (!customerId)
        return null;
    if (!siteId)
        return null;
    if (!assetId)
        return null;
    if (!provisionEventId)
        return null;
    var editingProvisionEventDetail = provisionEventStore.editingProvisionEventDetail;
    if (!editingProvisionEventDetail) {
        return _jsx(NoProvisionEvent, {});
    }
    var inner = null;
    if (editingProvisionEventDetail.type === 'Decommission') {
        inner = _jsx(ProvisionEventListEditDecommission, { customerId: customerId, siteId: siteId, assetSectionId: assetSectionId, assetId: assetId, provisionEvent: editingProvisionEventDetail });
    }
    if (editingProvisionEventDetail.type === 'Commission') {
        inner = _jsx(ProvisionEventListEditCommission, { customerId: customerId, siteId: siteId, assetSectionId: assetSectionId, assetId: assetId, provisionEvent: editingProvisionEventDetail });
    }
    if (editingProvisionEventDetail.type === 'Planned') {
        inner = _jsx(ProvisionEventListEditPlanned, { customerId: customerId, siteId: siteId, assetSectionId: assetSectionId, assetId: assetId, provisionEvent: editingProvisionEventDetail });
    }
    return (_jsx(LoadCustomerInner, { children: inner }));
};
export default WrappedProvisionEventListEdit;
