var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useState, } from 'react';
import { IntlProvider } from '@metsooutotec/modes-react-components';
import translations_en from './translations/en.json';
import translations_fi from './translations/fi.json';
var getTranslations = function (locale) {
    switch (locale) {
        case "en": return translations_en;
        case "fi": return translations_fi;
        default: return translations_en;
    }
};
var I18nContext = React.createContext(["", function (s) { }]);
export var localeOptions = ["en", "fi"];
export var useCurrentLocale = function () { return useContext(I18nContext); };
export var I18nProvider = function (_a) {
    var children = _a.children;
    var _b = __read(useState("en"), 2), currentLocale = _b[0], setCurrentLocale = _b[1];
    return (_jsx(IntlProvider, __assign({ messages: getTranslations(currentLocale), locale: currentLocale }, { children: _jsx(I18nContext.Provider, __assign({ value: [currentLocale, setCurrentLocale] }, { children: children })) })));
};
