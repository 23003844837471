var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Button, Icon, Input } from "@metsooutotec/modes-react-components";
var TableFilterBox = function (_a) {
    var onSearch = _a.onSearch, isLast = _a.isLast;
    var _b = __read(useState(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    var _c = __read(useState(), 2), inputValue = _c[0], setInputValue = _c[1];
    var ref = useRef(null);
    var handleClickOutside = function (event) {
        var _a;
        if (ref.current && !((_a = ref.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))) {
            setIsOpen(false);
        }
    };
    useEffect(function () {
        document.addEventListener('mousedown', handleClickOutside);
        return function () {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    var onInputChange = function (value) {
        onSearch(value);
        setInputValue(value);
    };
    var onClearClick = function () {
        onInputChange("");
    };
    var icons = _jsxs(_Fragment, { children: [inputValue && (_jsx(Icon, { className: "icon-cross-circle-filled-16", onClick: function () { return onClearClick(); } })), _jsx(Icon, { className: "icon-search-16" })] });
    return (_jsxs("div", __assign({ ref: ref, className: "table-filter-box" }, { children: [_jsxs("div", __assign({ className: "table-filter-buttonDiv" }, { children: [_jsx(Button, { size: "small", icon: "icon-search-16", onClick: function () { return setIsOpen(!isOpen); }, style: { backgroundColor: 'transparent' } }), inputValue && inputValue.trim() !== '' && (_jsx("span", __assign({ className: "table-filter-starDiv" }, { children: "*" })))] })), isOpen && (_jsx("div", __assign({ className: "table-filter-inputDiv ".concat(isLast ? 'right-0' : 'right-auto') }, { children: _jsx(Input, { placeholder: "Search...", onChange: function (e) { return onInputChange(e.target.value); }, icon: icons, value: inputValue, heightClass: "small", autoFocus: true }) })))] })));
};
export default TableFilterBox;
