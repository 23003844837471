var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CssSVGClasses } from "../editor-utils/editor-types";
import { GetLinerId } from "../editor-utils/id-string-utils";
import linerStore from "../../../../../stores/customer-stores/liner-locations-store";
/**
 * Maps Wear Reference Plane  to specific elements within an SVG document and updates the state accordingly.
 *
 * This function takes an SVG document, a rendered SVG element, a setter function for Wear Reference Plane state,
 * and a global Wear Reference Plane value. It processes the SVG paths classified as 'planes' and updates
 * their thickness in the state. This function is designed to work within the context of a React application
 * where state management is handled via hooks.
 *
 * @param {Document} svgDocument - The original SVG document to query for path elements.
 * @param {SVGSVGElement} renderedSvg - The rendered SVG element in the DOM.
 * @param {React.Dispatch<React.SetStateAction<LinerLocation[]>>} setSelectedWearReferencePlane -
 *        A React dispatch function to set the state for Wear Reference Plane.
 * @param {number} globalWearReferencePlane - The global Wear Reference Plane value to be applied.
 *
 * The function first collects all path elements from both the original SVG document and the rendered SVG.
 * It then filters these paths to identify those that are classified as 'liners'. For each liner,
 * the function checks if it's also classified as a 'Wear Reference Plane' and updates the class list if necessary.
 * Finally, it updates the Wear Reference Plane state using the provided setter function, either by updating
 * an existing entry or adding a new one.
 *
 * @returns {void} This function does not return a value; it modifies the state and updates class lists directly.
 *
 * Note: This function contains a try-catch block to handle any exceptions that might occur during processing.
 *       It logs any caught errors to the console.
 */
export function MapWearReferencePlane(assetSectionId, svgDocument, renderedSvg, selectedWearReferencePlane, setSelectedWearReferencePlane, globalWearReferencePlane) {
    try {
        var docAllPaths = Array.from(svgDocument.querySelectorAll('path'));
        var allPaths = Array.from(renderedSvg.querySelectorAll('path'));
        var docLiners_1 = docAllPaths.filter(function (path) { return path.classList.contains(CssSVGClasses.WsLiner); });
        var liners = allPaths.filter(function (path) { return path.classList.contains(CssSVGClasses.WsLiner); });
        var updatedWearReferencePlane_1 = __spreadArray([], __read(selectedWearReferencePlane), false);
        liners.forEach(function (liner) {
            var _a, _b;
            var linerReference = GetLinerId(liner.id);
            var docLinerPart = docLiners_1.filter(function (path) { return path.id === linerReference; });
            if (!((_a = docLinerPart[0]) === null || _a === void 0 ? void 0 : _a.classList.contains(CssSVGClasses.WsWearReferencePlaneTemp)))
                docLinerPart[0].classList.add(CssSVGClasses.WsWearReferencePlaneTemp);
            if (linerReference) {
                var linerId = (_b = linerStore.GetLinerLocationIdByReference(assetSectionId, linerReference)) !== null && _b !== void 0 ? _b : "";
                var existingWearReferencePlane = updatedWearReferencePlane_1.findIndex(function (entry) { return entry.reference === linerReference; });
                if (existingWearReferencePlane !== -1) {
                    updatedWearReferencePlane_1[existingWearReferencePlane].wallThickness = globalWearReferencePlane;
                }
                else {
                    updatedWearReferencePlane_1.push({ linerLocationId: linerId, reference: linerReference, wallThickness: globalWearReferencePlane });
                }
            }
        });
        setSelectedWearReferencePlane(updatedWearReferencePlane_1);
    }
    catch (e) {
        console.error("Error happen in MapWearReferencePlane", e);
    }
}
export var GetLinerLocationInfo = function (linerLocations, pathId) {
    try {
        var linerName_1 = GetLinerId(pathId);
        if (!linerName_1) {
            return null;
        }
        return linerLocations.find(function (l) { var _a; return ((_a = l.reference) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === linerName_1.toLowerCase(); }) || null;
    }
    catch (e) {
        return null;
    }
};
export var GetDrawingReference = function (linerLocations, pathId) {
    var _a, _b;
    try {
        var linerName_2 = GetLinerId(pathId);
        var backEndWearReferencePlane = (_b = (_a = linerLocations === null || linerLocations === void 0 ? void 0 : linerLocations.find(function (l) { var _a; return ((_a = l.reference) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()) === (linerName_2 === null || linerName_2 === void 0 ? void 0 : linerName_2.toLocaleLowerCase()); })) === null || _a === void 0 ? void 0 : _a.wallThickness) !== null && _b !== void 0 ? _b : 0;
        if (backEndWearReferencePlane !== 0) {
            return backEndWearReferencePlane;
        }
        else {
            return 0;
        }
    }
    catch (e) {
        console.error("Error happen in GetWearReferencePlane", e);
        return 0;
    }
};
