var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Dialog, DialogDescription, DialogHeader, Grid, useIntl, useToast, GridRow, Input, DateTimeInput, GridColumn, Select, Icon, ToastLevel } from "@metsooutotec/modes-react-components";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import customerStore from "../../../../stores/customer-stores/customer-store";
import { validateDecimal, validateText } from "../../../../validators/inputValidators";
import ModalsButtons from "../../../common/modals-buttons";
import { convertValueToOption } from "../../../common/select-component-helper";
import { convertDateToDDMMMYYYY, convertDDMMMYYYYtoDate, getTodayDateString } from "../../../utils/date-formatter-utils";
import { addStockAllocation, editingProcess, getFilteredSiteOptions } from "./stock-allocation-modal-helper";
var StockAllocateModal = observer(function (_a) {
    var _b, _c;
    var isOpen = _a.isOpen, onClose = _a.onClose, existAbleToCommitSensorsNumber = _a.existAbleToCommitSensorsNumber, stockAllocateRow = _a.stockAllocateRow, partNumber = _a.partNumber;
    var formatMessage = useIntl().formatMessage;
    var setMessage = useToast().setMessage;
    var maxQunatity = existAbleToCommitSensorsNumber + ((_b = stockAllocateRow === null || stockAllocateRow === void 0 ? void 0 : stockAllocateRow.quantity) !== null && _b !== void 0 ? _b : 0);
    var _d = __read(useState(""), 2), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = __read(useState(""), 2), datePickerError = _e[0], setDatePickerError = _e[1];
    var _f = __read(useState(Boolean(stockAllocateRow === null || stockAllocateRow === void 0 ? void 0 : stockAllocateRow.id)), 2), isEditing = _f[0], setIsEditing = _f[1];
    var _g = __read(useState(false), 2), loading = _g[0], setLoading = _g[1];
    var _h = __read(useState(false), 2), isFormModified = _h[0], setIsFormModified = _h[1];
    var _j = __read(useState(convertDDMMMYYYYtoDate((_c = stockAllocateRow === null || stockAllocateRow === void 0 ? void 0 : stockAllocateRow.date) !== null && _c !== void 0 ? _c : getTodayDateString())), 2), allocationDate = _j[0], setAllocationDate = _j[1];
    var _k = __read(useState(), 2), pickedCustomer = _k[0], setPickedCustomer = _k[1];
    var _l = __read(useState(), 2), pickedSite = _l[0], setPickedSite = _l[1];
    var _m = __read(useState(existAbleToCommitSensorsNumber), 2), quantity = _m[0], setQuantity = _m[1];
    var _o = __read(useState([]), 2), listOfCustomers = _o[0], setListOfCustomers = _o[1];
    var _p = __read(useState([]), 2), listOfSites = _p[0], setListOfSites = _p[1];
    var customers = customerStore.customers, loadAllCustomers = customerStore.loadAllCustomers;
    /**
     * useEffect hook to load all customers when the component mounts or when the customers list is empty.
     * It ensures that the customer data is fetched and available for use in the component.
     */
    useEffect(function () {
        if (!customers.length) {
            loadAllCustomers();
        }
    }, []);
    /**
     * useEffect hook to update the listOfCustomers state whenever the customers data changes.
     * This effect transforms the customers data into a format suitable for the Select component options.
     */
    useEffect(function () {
        setListOfCustomers(customers.map(function (s) {
            return { text: s.name, value: s.id };
        }));
    }, [customers]);
    /**
     * useEffect hook to handle changes in the selected customer (pickedCustomer).
     * Based on the selected customer, it updates the listOfSites with the sites related to that customer.
     * If no customer is selected, it clears the listOfSites.
     */
    useEffect(function () {
        if (!(pickedCustomer === null || pickedCustomer === void 0 ? void 0 : pickedCustomer.value)) {
            setListOfSites([]);
            return;
        }
        var findCustomer = customers.find(function (s) { return s.id === pickedCustomer.value; });
        var filteredSiteOptions = getFilteredSiteOptions(findCustomer === null || findCustomer === void 0 ? void 0 : findCustomer.sites);
        setListOfSites(filteredSiteOptions);
        setPickedSite({ text: "Unknown", value: "" });
    }, [pickedCustomer]);
    /**
     * useEffect hook to handle the stockAllocateRow data.
     * It sets form fields such as isEditing, quantity, and allocationDate based on the selected stockAllocateRow.
     * If stockAllocateRow is not present, it resets these fields to default values.
     */
    useEffect(function () {
        if (stockAllocateRow) {
            setIsEditing(Boolean(stockAllocateRow.id));
            setQuantity(stockAllocateRow.quantity);
            if (stockAllocateRow.date) {
                var temp = convertDDMMMYYYYtoDate(stockAllocateRow.date);
                setAllocationDate(temp);
            }
        }
        else {
            setIsEditing(false);
        }
    }, [stockAllocateRow, customers]);
    /**
     * useEffect hook to update pickedCustomer and pickedSite based on the listOfCustomers and listOfSites.
     * It searches for and sets the pickedCustomer and pickedSite based on the stockAllocateRow's customer and site.
     */
    useEffect(function () {
        if (!pickedCustomer && listOfCustomers.length) {
            var findCustomer = listOfCustomers.find(function (s) { var _a; return s.text.toLocaleLowerCase() === ((_a = stockAllocateRow === null || stockAllocateRow === void 0 ? void 0 : stockAllocateRow.customer) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()); });
            setPickedCustomer(findCustomer);
        }
        if (!(pickedSite === null || pickedSite === void 0 ? void 0 : pickedSite.text) && listOfSites.length) {
            var findSite = listOfSites.find(function (s) { var _a; return s.text.toLocaleLowerCase() === ((_a = stockAllocateRow === null || stockAllocateRow === void 0 ? void 0 : stockAllocateRow.site) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()); });
            setPickedSite(findSite);
        }
    }, [listOfCustomers, listOfSites]);
    /**
     * Resets the form fields to their initial state.
     * This function is called to clear the form after submission or when the user chooses to reset the form.
     * It sets the editing mode to false, clears the allocation date, resets the selected customer and site to empty values,
     * clears the lists of customers and sites, and sets the quantity back to zero.
     */
    var resetForm = function () {
        setIsEditing(false);
        setAllocationDate(null);
        setPickedCustomer({ text: "", value: "" });
        setPickedSite({ text: "", value: "" });
        setListOfCustomers([]);
        setListOfSites([]);
        setQuantity(0);
    };
    /**
     * Validates the form fields before submission.
     * Checks if the required fields are filled out correctly and sets an error message if validation fails.
     * The function specifically validates that a customer and a site are selected, the quantity is within an acceptable range,
     * and an allocation date is set.
     *
     * @returns {boolean} - Returns true if the form is valid, otherwise false.
     */
    function validateForm() {
        if (!(pickedCustomer === null || pickedCustomer === void 0 ? void 0 : pickedCustomer.value) || quantity < 1 || quantity > maxQunatity || allocationDate === null) {
            setErrorMessage("Please make sure to fill all required fields.");
            return false;
        }
        return true;
    }
    /**
     * Handles the form submission event. It prevents the default form submission, manages loading state,
     * validates the form, submits the data for addition or editing based on the editing state, and handles the response.
     *
     * @param {React.FormEvent} e - The event object associated with the form submission.
     */
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var requestResult, _a, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 6, 7, 8]);
                    setLoading(true);
                    setErrorMessage("");
                    // Validates the form fields and aborts submission if validation fails.
                    if (!validateForm()) {
                        return [2 /*return*/];
                    }
                    // Checks if any modifications have been made to the form data to avoid unnecessary submissions.
                    if (!isFormModified) {
                        setErrorMessage("Nothing has been modified!");
                        return [2 /*return*/];
                    }
                    if (!isEditing) return [3 /*break*/, 3];
                    return [4 /*yield*/, editingProcess(isEditing, partNumber, stockAllocateRow, quantity, allocationDate, pickedCustomer, pickedSite)];
                case 2:
                    _a = _b.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, addStockAllocation(quantity, partNumber, allocationDate, pickedCustomer, pickedSite)];
                case 4:
                    _a = _b.sent();
                    _b.label = 5;
                case 5:
                    requestResult = _a;
                    // Handles the response from the submission process.
                    if (requestResult && !requestResult.passed) {
                        setErrorMessage("Somthing Wrong has been happen, please check the logs");
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    else if (requestResult && requestResult.passed) {
                        setMessage(isEditing ? "Stock Allocation has been Updated!" : "Stock Allocation has been added!", ToastLevel.SUCCESS, 3000);
                        console.log("Form submitted successfully!", requestResult.message);
                    }
                    onCloseProcess();
                    return [3 /*break*/, 8];
                case 6:
                    e_1 = _b.sent();
                    setErrorMessage("Somthing Wrong has been happen, please check the logs");
                    console.error(e_1);
                    return [3 /*break*/, 8];
                case 7:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    /**
     * Resets the form to its initial state and closes the modal/dialog.
     */
    var onCloseProcess = function () {
        resetForm();
        onClose();
    };
    /**
     * Handles changes to the quantity input field. It updates the quantity state and sets the form as modified.
     *
     * @param {string} value - The new value of the quantity input field.
     */
    var handleQunatityChange = useCallback(function (value) {
        setErrorMessage("");
        var num = parseInt(value, 10);
        if ((num < quantity && num > 0) || num <= maxQunatity) {
            setQuantity(num);
            setIsFormModified(true);
        }
        else {
            setErrorMessage("The number must be over 0 and less or qual the number of Sensors: ".concat(maxQunatity));
            return;
        }
    }, []);
    /**
     * Handles changes to the date input field. It updates the allocationDate state and sets the form as modified.
     *
     * @param {string} value - The new value of the date input field.
     */
    var handleDateChange = useCallback(function (value) {
        try {
            setDatePickerError("");
            var date = new Date(value);
            if (isNaN(date.getDate())) {
                setDatePickerError("Invalid Date!");
            }
            else {
                setAllocationDate(date);
                setIsFormModified(true);
            }
        }
        catch (e) {
            console.log(e);
            setDatePickerError(e.message);
        }
    }, []);
    /**
    * Updates the pickedCustomer state based on the user's selection.
    *
    * @param {SelectOption} pickedValueresult - The selected customer option.
    */
    var onCustomerChange = function (pickedValueresult) {
        setPickedCustomer(pickedValueresult);
        setIsFormModified(true);
    };
    /**
     * Updates the pickedSite state based on the user's selection.
     *
     * @param {SelectOption} pickedValueresult - The selected site option.
     */
    var onSiteChange = function (pickedValueresult) {
        setPickedSite(pickedValueresult);
        setIsFormModified(true);
    };
    /**
     * Configures labels for form fields and buttons using localized messages.
     * This object maps form field identifiers to their display text, supporting internationalization.
     */
    var labels = {
        header: isEditing ? "".concat(formatMessage("stockAllocation.editAllocation")) : "".concat(formatMessage("stockAllocation.addAllocation"), ": ").concat(partNumber),
        description: formatMessage("stockAllocation.description"),
        date: formatMessage("headings.date"),
        customer: formatMessage("headings.customer"),
        site: formatMessage("headings.site"),
        quantity: formatMessage("headings.quantity"),
    };
    /**
     * Defines configuration for input fields in the form, including validation rules and placeholders.
     * This setup helps to maintain consistency in form field definitions and simplifies adjustments.
     */
    var inputConfig = {
        customer: {
            id: "customerSelect",
            label: labels.customer,
            placeholder: "Type ".concat(labels.customer, " here..."),
            type: "string",
            isRequired: true,
            validate: validateText,
            listOfCustomer: []
        },
        site: {
            id: "siteSelect",
            label: labels.site,
            placeholder: "Type ".concat(labels.site, " here..."),
            type: "string",
            isRequired: true,
            validate: validateText,
            listOfSites: []
        },
        quantity: {
            id: "quantity",
            label: labels.quantity,
            placeholder: "Type ".concat(labels.quantity, " here..."),
            type: "number",
            isRequired: true,
            validate: validateDecimal,
            maxValue: maxQunatity,
        }
    };
    /**
     * Renders the input fields for quantity and date, as well as customer and site selectors.
     * Utilizes the configuration object to standardize the creation and behavior of these fields.
     *
     * @returns {React.ReactNode} - A collection of React components that represent the form fields.
     */
    var getInputfields = function () {
        var inputQuantityProps = {
            id: inputConfig.quantity.id,
            label: inputConfig.quantity.label,
            placeholder: inputConfig.quantity.placeholder,
            validate: inputConfig.quantity.validate,
            onChange: function (e) { return handleQunatityChange(e.target.value); },
            type: inputConfig.quantity.type,
            spanning: true,
            heightClass: 'small',
            required: inputConfig.quantity.isRequired,
            uiRequired: inputConfig.quantity.isRequired,
            value: quantity,
            max: inputConfig.quantity.maxValue,
            min: 1
        };
        return (_jsxs(_Fragment, { children: [_jsxs(GridRow, { children: [_jsx(GridColumn, __assign({ className: "allocationDate" }, { children: _jsx(DateTimeInput, { label: labels.date, onChange: function (value) { return handleDateChange(value.toString()); }, value: convertDateToDDMMMYYYY(allocationDate), format: "DD-MMM-YYYY", timeFormat: false, error: datePickerError }) })), _jsx(GridColumn, { children: _jsx(Input, __assign({}, inputQuantityProps)) })] }, "quantityDateSelectorsRow"), _jsxs(GridRow, __assign({ style: { marginBottom: "var(--size-m)" } }, { children: [_jsx(GridColumn, { children: _jsx(Select, { id: inputConfig.customer.id, icon: _jsx(Icon, { className: "icon-search-16" }), label: inputConfig.customer.label, onChange: function (value) { return onCustomerChange(convertValueToOption(value, listOfCustomers)); }, options: listOfCustomers, value: pickedCustomer === null || pickedCustomer === void 0 ? void 0 : pickedCustomer.text, required: inputConfig.customer.isRequired }) }), _jsx(GridColumn, { children: _jsx(Select, { id: inputConfig.site.id, icon: _jsx(Icon, { className: "icon-search-16" }), label: inputConfig.site.label, onChange: function (value) { return onSiteChange(convertValueToOption(value, listOfSites)); }, options: listOfSites, value: pickedSite === null || pickedSite === void 0 ? void 0 : pickedSite.text, required: inputConfig.site.isRequired }) })] }), "customersSitesSelectorsRow")] }));
    };
    return (_jsxs(Dialog, __assign({ dimmer: true, open: isOpen, size: 'small', style: { maxWidth: "512px" } }, { children: [_jsx(DialogHeader, __assign({ username: '' }, { children: labels.header })), errorMessage && _jsx("p", __assign({ style: { color: 'red' } }, { children: errorMessage })), _jsx(DialogDescription, __assign({ style: { marginBottom: "var(--size-xxl)" } }, { children: labels.description })), (customers.length < 1)
                ? _jsx(_Fragment, { children: formatMessage("customer.loading") })
                :
                    _jsx(Grid, __assign({ columns: 2 }, { children: getInputfields() })), _jsx("div", { style: { marginBottom: "var(--size-xxl)" } }), _jsx(ModalsButtons, { handleSubmit: handleSubmit, onCloseProcess: onCloseProcess, isLoading: loading })] })));
});
export default StockAllocateModal;
