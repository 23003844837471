export var itemViewCellStyles = { border: "none", padding: 0 };
export var itemViewDetailsContainerStyles = { padding: "var(--size-xl)" };
export var itemViewDetailsTableStyles = { padding: 0 };
export var itemViewListContainerStyles = {
    marginTop: "var(--size-xxxxl)",
    padding: "0 var(--size-xxl)",
};
export var itemViewListExtraTopPaddingStyles = {
    padding: "var(--size-s) 0 0 0"
};
export var itemViewSeparatorStyles = {
    height: "1px",
    borderBottom: "1px solid #B3B3B3",
    margin: "0 var(--size-m)",
};
export var itemViewSubtitleStyles = { margin: "0 0 var(--size-l) 0" };
export var itemXlPadding = { padding: "var(--size-xl)" };
