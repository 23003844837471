import { useIntl } from '@metsooutotec/modes-react-components';
import { provisionEventsLinkGenerator } from './provision-events-link-generator';
export var provisionEventsBreadcrumbsGenerator = function (customer, site, asset, assetSection) {
    var formatMessage = useIntl().formatMessage;
    var breadcrumbs = [
        {
            text: formatMessage('customerList.title'),
            to: '/',
        },
        {
            text: formatMessage('provisionEvents.title'),
            to: provisionEventsLinkGenerator(),
        }
    ];
    if (customer) {
        breadcrumbs.push({
            text: customer.name,
            to: provisionEventsLinkGenerator(customer),
        });
        if (site) {
            breadcrumbs.push({
                text: site.name,
                to: provisionEventsLinkGenerator(customer, site),
            });
            if (asset) {
                breadcrumbs.push({
                    text: asset.name,
                    to: provisionEventsLinkGenerator(customer, site, asset),
                });
                if (assetSection) {
                    breadcrumbs.push({
                        text: assetSection.name,
                        to: provisionEventsLinkGenerator(customer, site, asset, assetSection),
                    });
                }
            }
        }
    }
    return breadcrumbs;
};
