var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, DateTimeInput, Dialog, DialogDescription, DialogHeader, Grid, GridColumn, GridRow, Icon, Input, ToastLevel, ToggleSwitch, useIntl, useToast } from "@metsooutotec/modes-react-components";
import { useCallback, useEffect, useState } from "react";
import { handleUploadFileClick, resetRecordFormData } from "../../utils/modal-helper";
import SensorCSVReader from "./sensor-csv-reader";
import sensorBatchStore from "../../../stores/ioT-stores/sensor-batch-store";
import { validateHexadecimal16Digit, validateText } from "../../../validators/inputValidators";
import { IotDevicesModalTypes } from "../iot-devices-utils/iot-entity-modal-helper";
import { convertDateToDDMMMYYYY, getTodayDateString } from "../../utils/date-formatter-utils";
var SensorImportModal = function (_a) {
    var _b;
    var isOpen = _a.isOpen, onClose = _a.onClose, sensor = _a.sensor, sensorBatchId = _a.sensorBatchId;
    var formatMessage = useIntl().formatMessage;
    var setMessage = useToast().setMessage;
    var _c = __read(useState(false), 2), isLoading = _c[0], setLoading = _c[1];
    var _d = __read(useState({ isError: false, errorMessage: "" }), 2), isError = _d[0], setIsError = _d[1];
    var _e = __read(useState(Boolean(sensor === null || sensor === void 0 ? void 0 : sensor.id)), 2), isEditing = _e[0], setIsEditing = _e[1];
    var _f = __read(useState(false), 2), isFormModified = _f[0], setIsFormModified = _f[1];
    var _g = __read(useState((_b = sensor === null || sensor === void 0 ? void 0 : sensor.isManufactured) !== null && _b !== void 0 ? _b : false), 2), isManufactured = _g[0], setIsManufactured = _g[1];
    var _h = __read(useState({ isError: false, errorMessage: "" }), 2), datePickerError = _h[0], setDatePickerError = _h[1];
    var _j = __read(useState((isEditing) ? convertDateToDDMMMYYYY(sensor === null || sensor === void 0 ? void 0 : sensor.manufacturedDate) : getTodayDateString), 2), manufacturedDate = _j[0], setManufacturedDate = _j[1];
    var _k = __read(useState(null), 2), selectedCSV = _k[0], setSelectedCSV = _k[1];
    var _l = __read(useState([]), 2), sensorListDto = _l[0], setSensorListDto = _l[1];
    var _m = __read(useState({
        serialNumber: (sensor === null || sensor === void 0 ? void 0 : sensor.serialNumber) || "",
        macAddress: (sensor === null || sensor === void 0 ? void 0 : sensor.macAddress) || "",
        uploadCSVName: selectedCSV === null || selectedCSV === void 0 ? void 0 : selectedCSV.name,
    }), 2), formData = _m[0], setFormData = _m[1];
    var _o = __read(useState({
        serialNumber: (sensor === null || sensor === void 0 ? void 0 : sensor.serialNumber) || "",
        macAddress: (sensor === null || sensor === void 0 ? void 0 : sensor.macAddress) || "",
    }), 2), formErrors = _o[0], setFormErrors = _o[1];
    useEffect(function () {
        var _a;
        if (sensor) {
            var isSameSite = sensor.id === formData.id;
            if (isSameSite)
                return;
            setIsEditing(Boolean(sensor.id));
            setFormData({
                serialNumber: (sensor === null || sensor === void 0 ? void 0 : sensor.serialNumber) || "",
                macAddress: (sensor === null || sensor === void 0 ? void 0 : sensor.macAddress) || "",
            });
            setIsManufactured((_a = sensor.isManufactured) !== null && _a !== void 0 ? _a : false);
        }
        else {
            setIsEditing(false);
            resetRecordFormData(setFormData);
        }
        // Reset the form modification state when the asset changes
        setIsFormModified(false);
    }, [sensor, formData.id]);
    useEffect(function () {
        if (selectedCSV) {
            fetchCSVData();
        }
    }, [selectedCSV]);
    var fetchCSVData = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            SensorCSVReader(selectedCSV)
                .then(function (sensorRows) {
                setSensorListDto(sensorRows);
                console.log('Sensors imported from the CSV:`', sensorRows);
            })
                .catch(function (error) {
                setIsError({ isError: true, errorMessage: "Error while parsing the CSV, check the logs..." });
                console.error('Error while parsing:', error);
                clearCSV();
            });
            return [2 /*return*/];
        });
    }); };
    var resetForm = function () {
        setIsEditing(false);
        resetRecordFormData(setFormData);
        resetRecordFormData(setFormErrors);
        setSelectedCSV(null);
        setSensorListDto([]);
        setIsManufactured(false);
    };
    var handleChange = useCallback(function (fieldName, value) {
        setIsError({ isError: false, errorMessage: "" });
        setFormData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[fieldName] = value, _a)));
        });
        setIsFormModified(true);
    }, []);
    var onCloseProcess = function () {
        resetForm();
        setIsError({ isError: false, errorMessage: "" });
        onClose(false);
    };
    var handleValidation = function (fieldName, validationError) {
        setFormErrors(function (prevErrors) {
            var _a;
            return (__assign(__assign({}, prevErrors), (_a = {}, _a[fieldName] = validationError, _a)));
        });
    };
    var onClearClick = function (inputId) {
        setFormData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[inputId] = "", _a)));
        });
        setFormErrors(function (prevErrors) {
            var _a;
            return (__assign(__assign({}, prevErrors), (_a = {}, _a[inputId] = "", _a)));
        });
    };
    var handleCSVClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            handleUploadFileClick(".csv", setSelectedCSV, setFormData, null, "uploadCSVName");
            return [2 /*return*/];
        });
    }); };
    var clearCSV = function () {
        console.log('unselect:', selectedCSV === null || selectedCSV === void 0 ? void 0 : selectedCSV.name);
        setFormData(function (prevData) { return (__assign(__assign({}, prevData), { uploadCSVName: "" })); });
        setSelectedCSV(null);
        setSensorListDto([]);
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var serialNumber, macAddress, importSensorsDto, requestResult, _a, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 6, 7, 8]);
                    if (isEditing && !isFormModified) {
                        setIsError({ isError: true, errorMessage: "Nothing has been changed!" });
                        return [2 /*return*/];
                    }
                    setLoading(true);
                    setIsError({ isError: false, errorMessage: "" });
                    setSensorListDto([]);
                    if (selectedCSV) {
                        fetchCSVData();
                    }
                    serialNumber = formData.serialNumber, macAddress = formData.macAddress;
                    if (serialNumber || macAddress) {
                        sensorListDto.push({ serialNumber: serialNumber, macAddress: macAddress, isManufactured: isManufactured, manufacturedDate: manufacturedDate });
                    }
                    if (sensorListDto.length <= 0) {
                        setIsError({ isError: true, errorMessage: "The CSV or the fields are empty, please fill the fields." });
                        return [2 /*return*/];
                    }
                    importSensorsDto = { sensorBatchId: sensorBatchId, sensors: sensorListDto };
                    if (!isEditing) return [3 /*break*/, 3];
                    return [4 /*yield*/, sensorBatchStore.editEntity({ id: sensor === null || sensor === void 0 ? void 0 : sensor.id, sensorBatchId: sensorBatchId, serialNumber: serialNumber, macAddress: macAddress, isManufactured: isManufactured, manufacturedDate: isManufactured ? manufacturedDate : null }, IotDevicesModalTypes.SensorModal)];
                case 2:
                    _a = _b.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, sensorBatchStore.createEntity(importSensorsDto, IotDevicesModalTypes.SensorModal)];
                case 4:
                    _a = _b.sent();
                    _b.label = 5;
                case 5:
                    requestResult = _a;
                    if (!requestResult.passed) {
                        setIsError({ isError: true, errorMessage: requestResult.message });
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    //Handle success
                    console.log("Form submitted successfully!", requestResult.message, requestResult.entityId);
                    resetForm(); // Reset form data and errors
                    if (isEditing) {
                        setMessage("".concat(sensor === null || sensor === void 0 ? void 0 : sensor.serialNumber, " has been updated!"), ToastLevel.SUCCESS, 6000);
                    }
                    else {
                        if (requestResult.message) {
                            setMessage(requestResult.message, ToastLevel.WARNING, 6000);
                        }
                        else {
                            setMessage("Sensors have been Created!", ToastLevel.SUCCESS, 6000);
                        }
                    }
                    onClose(false);
                    return [3 /*break*/, 8];
                case 6:
                    error_1 = _b.sent();
                    setIsError({ isError: true, errorMessage: "Form submission error, please read logs" });
                    console.error("Form submission error:", error_1);
                    return [3 /*break*/, 8];
                case 7:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var handleDateChange = useCallback(function (value) {
        try {
            setDatePickerError({ isError: false, errorMessage: "" });
            var date = new Date(value);
            if (isNaN(date.getDate())) {
                setDatePickerError({ isError: true, errorMessage: "Invalid Date!" });
            }
            else {
                var formatedDate = convertDateToDDMMMYYYY(date);
                setManufacturedDate(formatedDate);
                setIsFormModified(true);
            }
        }
        catch (e) {
            console.log(e);
            setDatePickerError({ isError: true, errorMessage: e.message });
        }
    }, []);
    var handleIsManufacturedToggle = function (e, data) {
        setIsManufactured(!isManufactured);
        setIsFormModified(true);
    };
    var labels = {
        confirm: formatMessage("modal.confirm"),
        cancel: formatMessage("modal.cancel"),
        headerEdit: formatMessage("sensorModal.editTitle"),
        header: formatMessage("sensorModal.importTitle"),
        description: formatMessage("sensorsModal.description"),
        addSingleSensor: formatMessage("sensorsModal.addSingleSensor"),
        AddMultibleSensors: formatMessage("sensorsModal.AddMultibleSensors"),
        serialNumber: formatMessage("sensorsModal.serialNumber"),
        macAddress: formatMessage("sensorsModal.macAddress"),
        uploadCSVName: formatMessage("sensorsModal.uploadCSVName"),
        isManufactured: formatMessage("sensorsModal.isManufactured"),
        manufacturedDate: formatMessage("sensorsModal.manufacturedDate"),
    };
    var inputStrings = [
        { id: "serialNumber", label: labels.serialNumber, placeholder: "Type ".concat(labels.serialNumber, " here..."), type: "string", isRequired: false, validate: validateText },
        { id: "macAddress", label: labels.macAddress, placeholder: "Type ".concat(labels.macAddress, " here..."), type: "string", isRequired: false, validate: validateHexadecimal16Digit },
    ];
    var inputCsv = [
        { id: "uploadCSVName", label: labels.uploadCSVName, placeholder: "Type ".concat(labels.uploadCSVName, " here..."), type: "string", onFileUpload: handleCSVClick, unSelectFile: clearCSV, isRequired: false },
    ];
    var renderInputStrings = function () {
        return (_jsx(_Fragment, { children: _jsx(GridRow, { children: inputStrings.map(function (input, index) {
                    var id = input.id, label = input.label, type = input.type, placeholder = input.placeholder, isRequired = input.isRequired, validate = input.validate;
                    var clearIcon = formData[id] && (_jsx(Icon, { className: "icon-cross-circle-filled-16", onClick: function () { return onClearClick(id); } }));
                    var inputProps = {
                        id: id,
                        label: label,
                        placeholder: placeholder,
                        validate: validate,
                        onValidation: function (validationError) { return handleValidation(id, validationError); },
                        onChange: function (e) { return handleChange(id, e.target.value); },
                        type: type,
                        error: formErrors[id],
                        spanning: true,
                        heightClass: 'medium',
                        required: isRequired,
                        uiRequired: isRequired,
                        value: formData[id],
                        icon: clearIcon
                    };
                    return (_jsx(GridColumn, { children: _jsx(Input, __assign({}, inputProps)) }));
                }) }) }));
    };
    var renderInputCsv = function () {
        return (_jsx(_Fragment, { children: inputCsv.map(function (input, index) {
                var id = input.id, label = input.label, type = input.type, placeholder = input.placeholder, onFileUpload = input.onFileUpload, unSelectFile = input.unSelectFile;
                var icons = (_jsxs(_Fragment, { children: [formData[id] && (_jsx(Icon, { className: "icon-cross-circle-filled-16", onClick: unSelectFile, style: { marginRight: '2px' } })), _jsx(Icon, { className: "icon-upload-16", onClick: onFileUpload })] }));
                var inputProps = {
                    id: id,
                    label: label,
                    placeholder: placeholder,
                    onChange: function (e) { return handleChange(id, e.target.value); },
                    type: type,
                    spanning: true,
                    heightClass: 'medium',
                    readOnly: true,
                    onClick: onFileUpload,
                    value: formData[id],
                    icon: icons
                };
                return (_jsx(GridRow, { children: _jsx(Input, __assign({}, inputProps)) }, id));
            }) }));
    };
    var renderButtons = function () {
        var buttonsConfig = [
            { label: labels.confirm, onClick: handleSubmit, primary: true },
            { label: labels.cancel, onClick: onCloseProcess, primary: false },
        ];
        return buttonsConfig.map(function (button, index) {
            var label = button.label, onClick = button.onClick, primary = button.primary;
            var buttonGridStyle = index === 0 ? {
                marginBottom: "var(--size-m)"
            } : {};
            return (_jsx(GridRow, __assign({ style: buttonGridStyle }, { children: _jsx(Button, { loading: isLoading, disabled: isLoading, onClick: onClick, flexible: true, children: label, centered: true, size: 'medium', primary: primary }) }), label));
        });
    };
    return (_jsxs(Dialog, __assign({ dimmer: true, open: isOpen, size: 'small' }, { children: [_jsx(DialogHeader, __assign({ username: '' }, { children: isEditing ? labels.headerEdit : labels.header })), isError.isError && _jsx("p", __assign({ style: { color: 'red' } }, { children: isError.errorMessage })), _jsx(DialogDescription, __assign({ style: { marginBottom: "var(--size-xxl)" } }, { children: labels.description })), _jsxs(Grid, __assign({ columns: 2 }, { children: [_jsx("h5", { children: labels.addSingleSensor }), renderInputStrings(), _jsxs(GridRow, { children: [_jsx(GridColumn, __assign({ style: { alignSelf: 'center' } }, { children: _jsx(ToggleSwitch, { noStatusText: true, label: labels.isManufactured, checked: isManufactured, onChange: function (e, data) { return handleIsManufacturedToggle(e, data); } }) })), isManufactured && _jsx(GridColumn, __assign({ style: { alignSelf: 'center' } }, { children: _jsx(DateTimeInput, { label: labels.manufacturedDate, onChange: function (value) { return handleDateChange(value.toString()); }, value: manufacturedDate, format: "DD-MMM-YYYY", timeFormat: false, error: datePickerError.errorMessage }) }))] }), !isEditing &&
                        _jsxs(_Fragment, { children: [_jsxs("h5", { children: ["".concat(labels.AddMultibleSensors), sensorListDto && sensorListDto.length > 0 && _jsxs("span", __assign({ style: { color: 'red' } }, { children: [" #", sensorListDto.length, " "] }))] }), renderInputCsv()] }), renderButtons()] }))] })));
};
export default SensorImportModal;
