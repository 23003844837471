var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Icon, Input, useIntl } from "@metsooutotec/modes-react-components";
import { useEffect, useState } from "react";
import { validateFile, validateText } from "../../../../validators/inputValidators";
import { handleUploadFileClick, resetRecordFormData } from "../../../utils/modal-helper";
import { observer } from "mobx-react-lite";
var AssetSectionEditorDetails = observer(function (_a) {
    var assetSection = _a.assetSection, editAssetSection = _a.editAssetSection, setEditAssetSection = _a.setEditAssetSection, errorMessage = _a.errorMessage;
    var formatMessage = useIntl().formatMessage;
    var _b = __read(useState({ isError: false, errorMessage: "" }), 2), isError = _b[0], setIsError = _b[1];
    var _c = __read(useState(null), 2), selectedPdf = _c[0], setSelectedPdf = _c[1];
    var _d = __read(useState(null), 2), selectedSvg = _d[0], setSelectedSvg = _d[1];
    var _e = __read(useState({
        assetSectionName: assetSection.name || "",
        assetSectionDescription: assetSection.description || "",
        uploadPdfName: selectedPdf === null || selectedPdf === void 0 ? void 0 : selectedPdf.name,
        uploadSvgName: selectedSvg === null || selectedSvg === void 0 ? void 0 : selectedSvg.name,
    }), 2), formData = _e[0], setFormData = _e[1];
    var _f = __read(useState({
        assetSectionName: "",
        assetSectionDescription: "",
        uploadPdfName: "",
        uploadSvgName: ""
    }), 2), formErrors = _f[0], setFormErrors = _f[1];
    useEffect(function () {
        resetRecordFormData(setFormData);
        setFormData({
            assetSectionName: assetSection.name || "",
            assetSectionDescription: assetSection.description || "",
            uploadPdfName: selectedPdf === null || selectedPdf === void 0 ? void 0 : selectedPdf.name,
            uploadSvgName: selectedSvg === null || selectedSvg === void 0 ? void 0 : selectedSvg.name,
        });
    }, []);
    useEffect(function () {
        setEditAssetSection(__assign(__assign({}, editAssetSection), { assetSectionPdf: selectedPdf, assetSectionSvg: selectedSvg }));
    }, [selectedPdf, selectedSvg]);
    useEffect(function () {
        if (editAssetSection.assetSectionPdf === null) {
            setSelectedPdf(null);
            setFormData(function (prevData) { return (__assign(__assign({}, prevData), { uploadPdfName: "" })); });
        }
        if (editAssetSection.assetSectionSvg === null) {
            setSelectedSvg(null);
            setFormData(function (prevData) { return (__assign(__assign({}, prevData), { uploadSvgName: "" })); });
        }
    }, [editAssetSection.assetSectionPdf, editAssetSection.assetSectionSvg]);
    var handleChange = function (fieldName, value) {
        var _a;
        isError.isError = false;
        var updatedData = __assign(__assign({}, formData), (_a = {}, _a[fieldName] = value, _a));
        var assetSectionName = updatedData.assetSectionName, assetSectionDescription = updatedData.assetSectionDescription;
        setEditAssetSection(__assign(__assign({}, editAssetSection), { name: assetSectionName, description: assetSectionDescription }));
        setFormData(updatedData);
    };
    var handleValidation = function (fieldName, validationError) {
        setFormErrors(function (prevErrors) {
            var _a;
            return (__assign(__assign({}, prevErrors), (_a = {}, _a[fieldName] = validationError, _a)));
        });
    };
    var onClearClick = function (inputId) {
        var _a;
        var updatedData = __assign(__assign({}, formData), (_a = {}, _a[inputId] = "", _a));
        setFormErrors(function (prevErrors) {
            var _a;
            return (__assign(__assign({}, prevErrors), (_a = {}, _a[inputId] = "", _a)));
        });
        var assetSectionName = updatedData.assetSectionName, assetSectionDescription = updatedData.assetSectionDescription;
        setFormData(updatedData);
        setEditAssetSection(__assign(__assign({}, editAssetSection), { name: assetSectionName, description: assetSectionDescription }));
    };
    var handlePdfClick = function () {
        handleUploadFileClick(".pdf", setSelectedPdf, setFormData, setFormErrors, "uploadPdfName");
    };
    var handleSvgClick = function () {
        handleUploadFileClick(".svg", setSelectedSvg, setFormData, setFormErrors, "uploadSvgName");
    };
    var clearPdf = function () {
        setFormData(function (prevData) { return (__assign(__assign({}, prevData), { uploadPdfName: "" })); });
        setSelectedPdf(null);
    };
    var clearSvg = function () {
        setFormData(function (prevData) { return (__assign(__assign({}, prevData), { uploadSvgName: "" })); });
        setSelectedSvg(null);
    };
    var labels = {
        assetSectionName: formatMessage("assetSectionModal.name"),
        assetSectionDescription: formatMessage("assetSectionModal.description"),
        confirm: formatMessage("modal.confirm"),
        cancel: formatMessage("modal.cancel"),
        header: formatMessage("assetSectionModal.header"),
        description: formatMessage("modal.description"),
        uploadPdf: formatMessage("assetSectionModal.uploadPdf"),
        uploadSvg: formatMessage("assetSectionModal.uploadSvg"),
    };
    var inputConfig = [
        { id: "assetSectionName", label: labels.assetSectionName, placeholder: "Type ".concat(labels.assetSectionName, " here..."), type: "string", validate: validateText },
        { id: "assetSectionDescription", label: labels.assetSectionDescription, placeholder: "Type ".concat(labels.assetSectionDescription, " here..."), type: "string", validate: validateText },
        { id: "uploadPdfName", label: labels.uploadPdf, placeholder: "select from files...", type: "string", onFileUpload: handlePdfClick, unSelectFile: clearPdf, validate: validateFile },
        { id: "uploadSvgName", label: labels.uploadSvg, placeholder: "select from files...", type: "string", onFileUpload: handleSvgClick, unSelectFile: clearSvg, validate: validateFile },
    ];
    var renderInputs = function () {
        return (_jsxs(_Fragment, { children: [(errorMessage) && _jsxs("p", __assign({ style: { color: "red" } }, { children: [" ", errorMessage] })), inputConfig.slice(0, 2).map(function (input, index) {
                    var id = input.id, label = input.label, validate = input.validate, type = input.type, placeholder = input.placeholder;
                    var clearIcon = formData[id] && (_jsx(Icon, { className: "icon-cross-circle-filled-16", onClick: function () { return onClearClick(id); } }));
                    var inputProps = {
                        id: id,
                        label: label,
                        placeholder: placeholder,
                        validate: validate,
                        onValidation: function (validationError) { return handleValidation(id, validationError); },
                        onChange: function (e) { return handleChange(id, e.target.value); },
                        type: type,
                        error: formErrors[id],
                        spanning: true,
                        heightClass: 'small',
                        required: true,
                        uiRequired: true,
                        value: formData[id],
                        icon: clearIcon,
                    };
                    return (_jsx(Input, __assign({ style: { marginBottom: "var(--size-xs)" } }, inputProps), id));
                }), inputConfig.slice(2).map(function (input, index) {
                    var id = input.id, label = input.label, type = input.type, placeholder = input.placeholder, onFileUpload = input.onFileUpload, unSelectFile = input.unSelectFile, validate = input.validate;
                    var icons = (_jsxs(_Fragment, { children: [formData[id] && (_jsx(Icon, { className: "icon-cross-circle-filled-16", onClick: unSelectFile, style: { marginRight: '2px' } })), _jsx(Icon, { className: "icon-upload-16", onClick: onFileUpload })] }));
                    var inputProps = {
                        id: id,
                        label: label,
                        placeholder: placeholder,
                        validate: validate,
                        onValidation: function (validationError) { return handleValidation(id, validationError); },
                        onChange: function (e) { return handleChange(id, e.target.value); },
                        type: type,
                        spanning: true,
                        heightClass: 'small',
                        error: formErrors[id],
                        readOnly: true,
                        onClick: onFileUpload,
                        required: false,
                        uiRequired: false,
                        value: formData[id],
                        icon: icons
                    };
                    return (_jsx(Input, __assign({ style: { marginBottom: "var(--size-xs)" } }, inputProps), id));
                })] }));
    };
    return (_jsxs(_Fragment, { children: [_jsx("p", __assign({ style: { fontSize: '1.5vw', marginBottom: '0.25em' } }, { children: _jsx("strong", { children: "Asset Section Details" }) })), renderInputs(), _jsx("div", { style: { marginBottom: "1vw" } })] }));
});
export default AssetSectionEditorDetails;
