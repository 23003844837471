import { useIntl } from '@metsooutotec/modes-react-components';
export var GenerateBreadcrumbs = function (customer, site, asset, assetSection) {
    var formatMessage = useIntl().formatMessage;
    var breadcrumbs = [
        {
            text: formatMessage('customerList.title'),
            to: '/customers',
        },
    ];
    if (customer) {
        breadcrumbs.push({
            text: customer.name,
            to: "/customers/".concat(customer.id),
        });
        if (site) {
            breadcrumbs.push({
                text: site.name,
                to: "/customers/".concat(customer.id, "/sites/").concat(site.id),
            });
            if (asset) {
                breadcrumbs.push({
                    text: asset.name,
                    to: "/customers/".concat(customer.id, "/sites/").concat(site.id, "/assets/").concat(asset.id),
                });
                if (assetSection) {
                    breadcrumbs.push({
                        text: assetSection.name,
                        to: "/customers/".concat(customer.id, "/sites/").concat(site.id, "/assets/").concat(asset.id, "/assetsections/").concat(assetSection.id),
                    });
                }
            }
        }
    }
    return breadcrumbs;
};
