var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var TooltipBox = function (_a) {
    var message = _a.message, position = _a.position;
    var tooltipStyle = __assign({ position: 'absolute', backgroundColor: '#555', color: 'white', textAlign: 'center', padding: '5px', borderRadius: '6px', zIndex: 5, width: 'max-content', whiteSpace: 'normal', overflowWrap: 'break-word', maxWidth: '15em' }, position);
    return (_jsx("div", __assign({ style: tooltipStyle }, { children: message })));
};
export default TooltipBox;
