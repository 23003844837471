var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ToastLevel } from "@metsooutotec/modes-react-components";
import customerStore from "../../../../../stores/customer-stores/customer-store";
import * as helper from "../helpers/svg-helpers";
import { ModalTypes } from "../../../customers-utils/customer-modal-helper";
import linerLocationsStore from "../../../../../stores/customer-stores/liner-locations-store";
export var onSaveSettingsAndDetails = function (_a) {
    var newEditAssetSection = _a.newEditAssetSection, fetchImage = _a.fetchImage, setLoadingAssetImage = _a.setLoadingAssetImage, errorMessage = _a.errorMessage, assetSectionImage = _a.assetSectionImage, setMessage = _a.setMessage, setNewEditAssetSection = _a.setNewEditAssetSection;
    return __awaiter(void 0, void 0, void 0, function () {
        var _b, requestResult, e_1;
        var _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 7, 8, 9]);
                    setLoadingAssetImage(true);
                    if (!!errorMessage) return [3 /*break*/, 6];
                    if (!((newEditAssetSection === null || newEditAssetSection === void 0 ? void 0 : newEditAssetSection.assetSectionSvg) == null || ((_c = newEditAssetSection === null || newEditAssetSection === void 0 ? void 0 : newEditAssetSection.assetSectionSvg) === null || _c === void 0 ? void 0 : _c.size) <= 0)) return [3 /*break*/, 2];
                    _b = newEditAssetSection;
                    return [4 /*yield*/, helper.ClearSVGBeforeSave(assetSectionImage)];
                case 1:
                    _b.assetSectionSvg = _d.sent();
                    _d.label = 2;
                case 2: return [4 /*yield*/, customerStore.editEntity(newEditAssetSection, ModalTypes.AssetSectionModal)];
                case 3:
                    requestResult = _d.sent();
                    if (!requestResult.passed) return [3 /*break*/, 5];
                    return [4 /*yield*/, fetchImage()];
                case 4:
                    _d.sent();
                    setNewEditAssetSection(__assign(__assign({}, newEditAssetSection), { assetSectionPdf: null, assetSectionSvg: null }));
                    setMessage("All changes have been made successfully. You can proceed to the next page by pressing the \"Next\" button.", ToastLevel.SUCCESS, 6000);
                    return [3 /*break*/, 6];
                case 5:
                    setMessage("Something went wrong, please read the logs", ToastLevel.ALARM, 6000);
                    console.error(requestResult.message);
                    _d.label = 6;
                case 6: return [3 /*break*/, 9];
                case 7:
                    e_1 = _d.sent();
                    console.error(e_1);
                    return [3 /*break*/, 9];
                case 8:
                    setLoadingAssetImage(false);
                    return [7 /*endfinally*/];
                case 9: return [2 /*return*/];
            }
        });
    });
};
export var onSaveLiners = function (_a) {
    var selectedLiners = _a.selectedLiners, setSelectedLiners = _a.setSelectedLiners, assetSectionId = _a.assetSectionId, errorMessage = _a.errorMessage, assetSectionImage = _a.assetSectionImage, setLoadingAssetImage = _a.setLoadingAssetImage, setMessage = _a.setMessage;
    return __awaiter(void 0, void 0, void 0, function () {
        var assetSectionFile, requestModel, requestResult, e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 8, 9, 10]);
                    if (!selectedLiners) {
                        setMessage("There is no selected liners to be saved!", ToastLevel.WARNING, 6000);
                        return [2 /*return*/];
                    }
                    setLoadingAssetImage(true);
                    if (!!errorMessage) return [3 /*break*/, 6];
                    return [4 /*yield*/, helper.ClearSVGBeforeSave(assetSectionImage)];
                case 1:
                    assetSectionFile = _b.sent();
                    requestModel = { id: assetSectionId, liners: selectedLiners };
                    return [4 /*yield*/, linerLocationsStore.PutLinerLocations(requestModel)];
                case 2:
                    requestResult = _b.sent();
                    if (!requestResult.passed) return [3 /*break*/, 4];
                    return [4 /*yield*/, linerLocationsStore.PutAssetSectionImage({ id: assetSectionId, newAssetSectionSvg: assetSectionFile })];
                case 3:
                    requestResult = _b.sent();
                    if (requestResult.passed) {
                        setMessage("All changes have been made successfully. You can proceed to the next page by pressing the \"Next\" button.", ToastLevel.SUCCESS, 6000);
                        setSelectedLiners([]);
                    }
                    return [3 /*break*/, 5];
                case 4:
                    setMessage("Something went wrong, please read the logs", ToastLevel.ALARM, 6000);
                    _b.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    setMessage(errorMessage, ToastLevel.ALARM, 6000);
                    _b.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    e_2 = _b.sent();
                    setMessage("An unexpected error occurred. Please try again later.", ToastLevel.ALARM, 6000);
                    console.error(e_2);
                    return [3 /*break*/, 10];
                case 9:
                    setLoadingAssetImage(false);
                    return [7 /*endfinally*/];
                case 10: return [2 /*return*/];
            }
        });
    });
};
export var onSaveHoles = function (_a) {
    var selectedholes = _a.selectedholes, assetSectionId = _a.assetSectionId, setSelectedHoles = _a.setSelectedHoles, setLoadingAssetImage = _a.setLoadingAssetImage, assetSectionImage = _a.assetSectionImage, errorMessage = _a.errorMessage, setMessage = _a.setMessage;
    return __awaiter(void 0, void 0, void 0, function () {
        var assetSectionFile, requestResult, e_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 8, 9, 10]);
                    if (!selectedholes) {
                        setMessage("There is no selected holes to be saved!", ToastLevel.WARNING, 6000);
                        return [2 /*return*/];
                    }
                    setLoadingAssetImage(true);
                    if (!!errorMessage) return [3 /*break*/, 6];
                    return [4 /*yield*/, helper.ClearSVGBeforeSave(assetSectionImage)];
                case 1:
                    assetSectionFile = _b.sent();
                    return [4 /*yield*/, linerLocationsStore.PutAssetSectionHoles(assetSectionId, selectedholes)];
                case 2:
                    requestResult = _b.sent();
                    if (!requestResult.passed) return [3 /*break*/, 4];
                    return [4 /*yield*/, linerLocationsStore.PutAssetSectionImage({ id: assetSectionId, newAssetSectionSvg: assetSectionFile })];
                case 3:
                    requestResult = _b.sent();
                    if (requestResult.passed) {
                        setMessage("All changes have been made successfully. You can proceed to the next page by pressing the \"Next\" button.", ToastLevel.SUCCESS, 6000);
                        setSelectedHoles([]);
                    }
                    return [3 /*break*/, 5];
                case 4:
                    setMessage("Something went wrong, please read the logs", ToastLevel.ALARM, 6000);
                    _b.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    setMessage(errorMessage, ToastLevel.ALARM, 6000);
                    _b.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    e_3 = _b.sent();
                    setMessage("An unexpected error occurred. Please try again later.", ToastLevel.ALARM, 6000);
                    console.error(e_3);
                    return [3 /*break*/, 10];
                case 9:
                    setLoadingAssetImage(false);
                    return [7 /*endfinally*/];
                case 10: return [2 /*return*/];
            }
        });
    });
};
export var onSaveLinerDrawingReferences = function (_a) {
    var selectedLinersDrawingRef = _a.selectedLinersDrawingRef, assetSectionId = _a.assetSectionId, setSelectedLinersDrawingRef = _a.setSelectedLinersDrawingRef, setLoadingAssetImage = _a.setLoadingAssetImage, assetSectionImage = _a.assetSectionImage, errorMessage = _a.errorMessage, setMessage = _a.setMessage;
    return __awaiter(void 0, void 0, void 0, function () {
        var requestModel, requestResult, e_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 4, 5, 6]);
                    if (!selectedLinersDrawingRef) {
                        setMessage("There is no selected liners to be saved!", ToastLevel.WARNING, 6000);
                        return [2 /*return*/];
                    }
                    setLoadingAssetImage(true);
                    if (!!errorMessage) return [3 /*break*/, 2];
                    requestModel = selectedLinersDrawingRef;
                    return [4 /*yield*/, linerLocationsStore.EditLinerLocationsDrawingReference(assetSectionId, requestModel)];
                case 1:
                    requestResult = _b.sent();
                    if (requestResult.passed) {
                        setMessage("All changes have been made successfully. You can proceed to the next page by pressing the \"Next\" button.", ToastLevel.SUCCESS, 6000);
                        setSelectedLinersDrawingRef([]);
                    }
                    else {
                        setMessage("Something went wrong, please read the logs", ToastLevel.ALARM, 6000);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    setMessage(errorMessage, ToastLevel.ALARM, 6000);
                    _b.label = 3;
                case 3: return [3 /*break*/, 6];
                case 4:
                    e_4 = _b.sent();
                    setMessage("An unexpected error occurred. Please try again later.", ToastLevel.ALARM, 6000);
                    console.error(e_4);
                    return [3 /*break*/, 6];
                case 5:
                    setLoadingAssetImage(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    });
};
export var onSaveWearReferencePlane = function (_a) {
    var selectedWearReferencePlane = _a.selectedWearReferencePlane, assetSectionId = _a.assetSectionId, setSelectedWearReferencePlane = _a.setSelectedWearReferencePlane, assetSectionImage = _a.assetSectionImage, errorMessage = _a.errorMessage, setMessage = _a.setMessage, setLoadingAssetImage = _a.setLoadingAssetImage;
    return __awaiter(void 0, void 0, void 0, function () {
        var requestResult, e_5;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 4, 5, 6]);
                    if (!selectedWearReferencePlane) {
                        setMessage("There is no selected liners to be saved!", ToastLevel.WARNING, 6000);
                        return [2 /*return*/];
                    }
                    setLoadingAssetImage(true);
                    if (!!errorMessage) return [3 /*break*/, 2];
                    return [4 /*yield*/, linerLocationsStore.EditLinerLocationsForWearReferencePlane(assetSectionId, selectedWearReferencePlane)];
                case 1:
                    requestResult = _b.sent();
                    if (requestResult.passed) {
                        setMessage("All changes have been made successfully. You can proceed to the next page by pressing the \"Next\" button.", ToastLevel.SUCCESS, 6000);
                        setSelectedWearReferencePlane([]);
                    }
                    else {
                        setMessage("Something went wrong, please read the logs", ToastLevel.ALARM, 6000);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    setMessage(errorMessage, ToastLevel.ALARM, 6000);
                    _b.label = 3;
                case 3: return [3 /*break*/, 6];
                case 4:
                    e_5 = _b.sent();
                    setMessage("An unexpected error occurred. Please try again later.", ToastLevel.ALARM, 6000);
                    console.error(e_5);
                    return [3 /*break*/, 6];
                case 5:
                    setLoadingAssetImage(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    });
};
