var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CssSVGClasses } from "../editor-utils/editor-types";
/**
 * SvgHandler is an object that provides utility methods to handle SVG content.
 * @type SvgHandlerType
 */
export var SvgHandler = {
    /**
     * @param svgString The SVG string to parse.
     * @returns A Promise that resolves with the parsed SVG Document.
     */
    Parse: function (svgString) {
        var SVG_NAMESPACE = 'http://www.w3.org/2000/svg';
        var SVG_MIME_TYPE = 'image/svg+xml';
        var parser = new DOMParser();
        try {
            // Parse the provided SVG string to an SVG Document.           
            var svgDocument = parser.parseFromString(svgString, SVG_MIME_TYPE);
            if (svgDocument.documentElement.nodeName !== 'svg') {
                {
                    throw new Error('Parsed content is not a valid SVG.');
                }
            }
            return svgDocument;
        }
        catch (error) {
            var emptySvg = parser.parseFromString("<svg xmlns=\"".concat(SVG_NAMESPACE, "\"></svg>"), SVG_MIME_TYPE);
            return emptySvg;
        }
    },
    /**
     * @param svgDocument The SVG Document to serialize.
     * @returns The blob URL string of the serialized SVG content or null in case of error.
     */
    Serialize: function (svgDocument) {
        var SVG_MIME_TYPE = 'image/svg+xml';
        try {
            // Serialize the provided SVG Document
            var serializer = new XMLSerializer();
            var svgString = serializer.serializeToString(svgDocument);
            // Convert the serialized SVG to a blob URL
            var blob = new Blob([svgString], { type: SVG_MIME_TYPE });
            var blobUrl = URL.createObjectURL(blob);
            return blobUrl;
        }
        catch (error) {
            console.error("Error serializing SVG content:", error);
            return null;
        }
    },
    SerializeString: function (svgDocument) {
        try {
            // Serialize the provided SVG Document
            var serializer = new XMLSerializer();
            var svgString = serializer.serializeToString(svgDocument);
            return svgString;
        }
        catch (error) {
            console.error("Error serializing SVG content:", error);
            return null;
        }
    }
};
/**
 * Fetches an SVG image from a given URL and returns it as a File object.
 *
 * This function sends a fetch request to the provided URL, retrieves the SVG image as a blob,
 * and then converts it to a File object with the name 'newSvg.svg' and type 'image/svg+xml'.
 *
 * @param {string} assetSectionImageURL - The URL of the SVG image to be fetched.
 *
 * @returns {Promise<File>} - A promise that resolves to a File object containing the fetched SVG image.
 */
export function FetchSVGAsFile(assetSectionImageURL) {
    return __awaiter(this, void 0, void 0, function () {
        var response, blob, file, e_1, defaultBlob;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch(assetSectionImageURL)];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        console.error("Failed to fetch the SVG image.");
                    }
                    return [4 /*yield*/, response.blob()];
                case 2:
                    blob = _a.sent();
                    file = new File([blob], 'newSvg.svg', { type: 'image/svg+xml' });
                    return [2 /*return*/, file];
                case 3:
                    e_1 = _a.sent();
                    console.error("Error happen in FetchSVGAsFile", e_1);
                    defaultBlob = new Blob([], { type: 'image/svg+xml' });
                    return [2 /*return*/, new File([defaultBlob], 'default.svg', { type: 'image/svg+xml' })];
                case 4: return [2 /*return*/];
            }
        });
    });
}
/**
 * Removes temporary classes from SVG content.
 *
 * This function asynchronously processes SVG content, specifically targeting
 * elements with temporary classes used for certain operations or visual styles.
 * It fetches the SVG content from a provided URL, parses it, and then removes
 * specified temporary classes from elements within the SVG. Finally, it serializes
 * the modified SVG document back into a string.
 *
 * @param {string} svgContent - The URL of the SVG content to be processed.
 * @returns {Promise<string>} A promise that resolves to a string representing the
 *                            modified SVG content. If an error occurs, it returns
 *                            an empty string.
 *
 * Usage:
 * ```
 * const updatedSvg = await RemoveTempClassesFromSvg("http://example.com/my.svg");
 * ```
 */
export function RemoveTempClassesFromSvg(svgContent) {
    return __awaiter(this, void 0, void 0, function () {
        var response, content, svgDoc, classToRemove1_1, classToRemove2_1, pathsWithClasses, svgFile, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    return [4 /*yield*/, fetch(svgContent)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.text()];
                case 2:
                    content = _a.sent();
                    return [4 /*yield*/, SvgHandler.Parse(content)];
                case 3:
                    svgDoc = _a.sent();
                    classToRemove1_1 = CssSVGClasses.WsReferenceTemp;
                    classToRemove2_1 = CssSVGClasses.WsWearReferencePlaneTemp;
                    pathsWithClasses = svgDoc.querySelectorAll("path.".concat(classToRemove1_1, ", path.").concat(classToRemove2_1));
                    // Remove the specified classes from each of these elements
                    pathsWithClasses.forEach(function (element) {
                        element.classList.remove(classToRemove1_1, classToRemove2_1);
                    });
                    svgFile = SvgHandler.Serialize(svgDoc);
                    return [2 /*return*/, svgFile];
                case 4:
                    e_2 = _a.sent();
                    console.error("Error in RemoveTempClassesFromSvg", e_2);
                    return [2 /*return*/, ''];
                case 5: return [2 /*return*/];
            }
        });
    });
}
/**
 * Asynchronously removes all elements with a specific class ('ws-regionTemp') from an SVG document.
 *
 * This function takes a string representing the SVG content, typically a URL or path. It fetches
 * the SVG content, parses it into a document, and then searches for and removes all elements that
 * have the 'ws-regionTemp' class. The updated SVG content, with the specified elements removed,
 * is then serialized back into a string.
 *
 * @param {string} svgContent - The URL or path of the SVG content to be processed.
 * @returns {Promise<string>} - A promise that resolves to the updated SVG content as a string.
 *
 * The function performs the following actions:
 * 1. Fetches and retrieves the SVG content from the specified URL or path.
 * 2. Parses the retrieved content into an SVG document.
 * 3. Selects all elements in the document with the 'ws-regionTemp' class and removes them.
 * 4. Serializes the updated SVG document back into a string.
 *
 * @throws {Error} - An error is logged if there's an issue in fetching, parsing, or processing
 *                   the SVG content. In such cases, an empty string is returned.
 *
 * Note: This function assumes the presence of a global `SvgHandler` object with `Parse` and
 *       `Serialize` methods for handling SVG content, and a `CssSVGClasses` enum for class names.
 */
export function RemmoveTempPathes(svgContent) {
    return __awaiter(this, void 0, void 0, function () {
        var response, content, svgDoc, tempRegionElements, svgFile, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    return [4 /*yield*/, fetch(svgContent)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.text()];
                case 2:
                    content = _a.sent();
                    return [4 /*yield*/, SvgHandler.Parse(content)];
                case 3:
                    svgDoc = _a.sent();
                    tempRegionElements = svgDoc.querySelectorAll(".".concat(CssSVGClasses.WsRegionTemp));
                    tempRegionElements.forEach(function (element) {
                        element.remove();
                    });
                    svgFile = SvgHandler.Serialize(svgDoc);
                    return [2 /*return*/, svgFile];
                case 4:
                    e_3 = _a.sent();
                    console.error("Error in RemoveTempPaths", e_3);
                    return [2 /*return*/, ''];
                case 5: return [2 /*return*/];
            }
        });
    });
}
/**
 * removeUnwantedClasses - Removes classes from an SVG path element that are not specified in a hard-coded list.
 *
 * @param {SVGPathElement} element - The SVG path element from which classes will be removed.
 *
 * This function does the following:
 * 1. Checks if the SVG path element has any classes. If not, the function returns immediately.
 * 2. Iterates through each class present on the SVG path element.
 * 3. If a class is not in the hard-coded list of classes to preserve ('classesToPreserve'), it is removed.
 *
 * The hard-coded 'classesToPreserve' list contains classes that should not be removed from the SVG path element.
 */
export function RemoveUnListedClasses(element) {
    try {
        // Hard-coded list of classes to preserve
        var classesToPreserve_1 = [CssSVGClasses.SelectedPath, CssSVGClasses.WsLiner, CssSVGClasses.WsReferenceTemp, CssSVGClasses.WsRegionTemp, CssSVGClasses.WsHole]; // Add any other classes you want to preserve
        // Get all current classes into an array
        var currentClasses = Array.from(element.classList);
        // If no classes are present, exit the function early
        if (currentClasses.length === 0) {
            return;
        }
        // Iterate over each class and remove it if it's not in classesToPreserve
        currentClasses.forEach(function (className) {
            if (!classesToPreserve_1.includes(className)) {
                element.classList.remove(className);
            }
        });
    }
    catch (e) {
        console.error("Error in RemoveUnListedClasses", e);
    }
}
export var ResetSelectedPath = function (selectedPathId) {
    if (selectedPathId) {
        var pathElement = document.getElementById(selectedPathId);
        if (pathElement) {
            pathElement.classList.remove(CssSVGClasses.SelectedPath);
        }
    }
};
export function ClearSVGBeforeSave(assetSectionImage) {
    return __awaiter(this, void 0, void 0, function () {
        var filterSvg, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    return [4 /*yield*/, RemoveTempClassesFromSvg(assetSectionImage)];
                case 1:
                    filterSvg = _a.sent();
                    return [4 /*yield*/, RemmoveTempPathes(assetSectionImage)];
                case 2:
                    filterSvg = _a.sent();
                    return [4 /*yield*/, FetchSVGAsFile(filterSvg)];
                case 3: return [2 /*return*/, _a.sent()];
                case 4:
                    e_4 = _a.sent();
                    console.error('Error in: ClearSVGBeforeSave', e_4);
                    return [2 /*return*/, new File([], "empty.svg", { type: "image/svg+xml" })];
                case 5: return [2 /*return*/];
            }
        });
    });
}
export var fetchSvgImageFromUrl = function (assetSectionImage) { return __awaiter(void 0, void 0, void 0, function () {
    var response, content, svgDocument, renderedSvg;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch(assetSectionImage)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.text()];
            case 2:
                content = _a.sent();
                return [4 /*yield*/, SvgHandler.Parse(content)];
            case 3:
                svgDocument = _a.sent();
                renderedSvg = document.querySelector('svg');
                return [2 /*return*/, { svgDocument: svgDocument, renderedSvg: renderedSvg }];
        }
    });
}); };
export var updateAssetSectionImage = function (svgDocument) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, SvgHandler.Serialize(svgDocument)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
