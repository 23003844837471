var HEAD_URL = '/provision-events';
export var provisionEventsLinkGenerator = function (customer, site, asset, assetSection, event, additional) {
    if (customer) {
        if (site) {
            if (asset) {
                if (assetSection) {
                    if (event && event.id) {
                        return "".concat(HEAD_URL, "/customer/").concat(customer.id, "/site/").concat(site.id, "/asset/").concat(asset.id, "/assetsection/").concat(assetSection.id, "/event/").concat(event.id).concat(additional || "");
                    }
                    return "".concat(HEAD_URL, "/customer/").concat(customer.id, "/site/").concat(site.id, "/asset/").concat(asset.id, "/assetsection/").concat(assetSection.id);
                }
                return "".concat(HEAD_URL, "/customer/").concat(customer.id, "/site/").concat(site.id, "/asset/").concat(asset.id);
            }
            return "".concat(HEAD_URL, "/customer/").concat(customer.id, "/site/").concat(site.id);
        }
        return "".concat(HEAD_URL, "/customer/").concat(customer.id);
    }
    return HEAD_URL;
};
export var provisionEventsLinkGeneratorSimple = function (customerId, siteId, assetId, assetSectionId, eventId, additional) {
    if (customerId) {
        if (siteId) {
            if (assetId) {
                if (assetSectionId) {
                    if (eventId) {
                        return "".concat(HEAD_URL, "/customer/").concat(customerId, "/site/").concat(siteId, "/asset/").concat(assetId, "/assetsection/").concat(assetSectionId, "/event/").concat(eventId).concat(additional || "");
                    }
                    return "".concat(HEAD_URL, "/customer/").concat(customerId, "/site/").concat(siteId, "/asset/").concat(assetId, "/assetsection/").concat(assetSectionId);
                }
                if (eventId) {
                    return "".concat(HEAD_URL, "/customer/").concat(customerId, "/site/").concat(siteId, "/asset/").concat(assetId, "/event/").concat(eventId).concat(additional || "");
                }
                return "".concat(HEAD_URL, "/customer/").concat(customerId, "/site/").concat(siteId, "/asset/").concat(assetId);
            }
            return "".concat(HEAD_URL, "/customer/").concat(customerId, "/site/").concat(siteId);
        }
        return "".concat(HEAD_URL, "/customer/").concat(customerId);
    }
    return HEAD_URL;
};
