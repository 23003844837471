var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState, useMemo } from "react";
import { Button, Dialog, FormattedMessage, Grid, GridRow, Icon, Input, PageTitle, useIntl, DialogDescription, Select } from "@metsooutotec/modes-react-components";
import { resetRecordFormData } from "../../utils/modal-helper";
import { validateDecimal, validateText } from "../../../validators/inputValidators";
import linerMaterialStore from "stores/provisions/liner-material-store";
var itemViewListContainerStyle = {
    marginTop: "var(--size-l)",
    padding: "0 var(--size-l)",
};
var itemViewSeparatorStyle = {
    height: "1px",
    borderBottom: "1px solid #B3B3B3",
    margin: "var(--size-s) 0 ",
};
var LinerMaterialLibraryModal = function (_a) {
    var _b, _c, _d;
    var isOpen = _a.isOpen, onClose = _a.onClose, linerMaterial = _a.linerMaterial;
    var formatMessage = useIntl().formatMessage;
    var materialTypes = linerMaterialStore.materialTypes;
    var _e = __read(useState(false), 2), isLoading = _e[0], setLoading = _e[1];
    var _f = __read(useState({ isError: false, errorMessage: "" }), 2), isError = _f[0], setIsError = _f[1];
    var _g = __read(useState(Boolean(linerMaterial === null || linerMaterial === void 0 ? void 0 : linerMaterial.id)), 2), isEditing = _g[0], setIsEditing = _g[1];
    var _h = __read(useState(false), 2), isFormModified = _h[0], setIsFormModified = _h[1];
    // The following could be WearSense generalized.
    // Converter from BaseEntity (id, name, description) to SelectOption (text, value)
    var materialTypesOptions = useMemo(function () {
        return materialTypes.map(function (m) {
            var id = m.id, name = m.name;
            return { text: id, value: name };
        });
    }, [materialTypes]);
    var _j = __read(useState({
        name: (linerMaterial === null || linerMaterial === void 0 ? void 0 : linerMaterial.name) || "",
        description: (linerMaterial === null || linerMaterial === void 0 ? void 0 : linerMaterial.description) || "",
        code: (linerMaterial === null || linerMaterial === void 0 ? void 0 : linerMaterial.code) || "",
        materialReference: (linerMaterial === null || linerMaterial === void 0 ? void 0 : linerMaterial.materialReference) || "",
        thickness: ((_b = linerMaterial === null || linerMaterial === void 0 ? void 0 : linerMaterial.thickness) === null || _b === void 0 ? void 0 : _b.toString()) || "0",
        wearSurfaceThickness: ((_c = linerMaterial === null || linerMaterial === void 0 ? void 0 : linerMaterial.wearSurfaceThickness) === null || _c === void 0 ? void 0 : _c.toString()) || "0",
        backingThickness: ((_d = linerMaterial === null || linerMaterial === void 0 ? void 0 : linerMaterial.backingThickness) === null || _d === void 0 ? void 0 : _d.toString()) || "0",
    }), 2), formData = _j[0], setFormData = _j[1];
    var _k = __read(useState({
        name: "",
        description: "",
        code: "",
        materialReference: "",
        thickness: "",
        wearSurfaceThickness: "",
        backingThickness: "",
    }), 2), formErrors = _k[0], setFormErrors = _k[1];
    var modalTitle = (isEditing) ? formatMessage("linerMaterialModal.editTitle") : formatMessage("linerMaterialModal.createTitle");
    useEffect(function () {
        var _a, _b, _c;
        if (linerMaterial) {
            var isSameSite = linerMaterial.id === formData.id;
            if (isSameSite)
                return;
            setIsEditing(Boolean(linerMaterial.id));
            setFormData({
                name: (linerMaterial === null || linerMaterial === void 0 ? void 0 : linerMaterial.name) || "",
                description: (linerMaterial === null || linerMaterial === void 0 ? void 0 : linerMaterial.description) || "",
                code: (linerMaterial === null || linerMaterial === void 0 ? void 0 : linerMaterial.code) || "",
                materialReference: (linerMaterial === null || linerMaterial === void 0 ? void 0 : linerMaterial.materialReference) || "",
                thickness: ((_a = linerMaterial === null || linerMaterial === void 0 ? void 0 : linerMaterial.thickness) === null || _a === void 0 ? void 0 : _a.toString()) || "0",
                wearSurfaceThickness: ((_b = linerMaterial === null || linerMaterial === void 0 ? void 0 : linerMaterial.wearSurfaceThickness) === null || _b === void 0 ? void 0 : _b.toString()) || "0",
                backingThickness: ((_c = linerMaterial === null || linerMaterial === void 0 ? void 0 : linerMaterial.backingThickness) === null || _c === void 0 ? void 0 : _c.toString()) || "0",
            });
        }
        else {
            setIsEditing(false);
            resetRecordFormData(setFormData);
        }
        // Reset the form modification state when the asset changes
        setIsFormModified(false);
    }, [linerMaterial, formData.id]);
    var validateForm = function (formData) {
        var errors = {
            name: validateText(formData.name),
            description: validateText(formData.description),
            code: validateText(formData.code),
            materialReference: validateText(formData.materialReference),
            thickness: validateDecimal(formData.thickness),
            wearSurfaceThickness: validateDecimal(formData.wearSurfaceThickness),
            backingThickness: validateDecimal(formData.backingThickness),
        };
        return errors;
    };
    var handleValidation = useCallback(function (fieldName, validationError) {
        setFormErrors(function (prevErrors) {
            var _a;
            return (__assign(__assign({}, prevErrors), (_a = {}, _a[fieldName] = validationError, _a)));
        });
    }, []);
    var resetForm = function () {
        setIsEditing(false);
        resetRecordFormData(setFormData);
        resetRecordFormData(setFormErrors);
    };
    var handleChange = useCallback(function (fieldName, value) {
        isError.isError = false;
        setFormData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[fieldName] = value, _a)));
        });
        setIsFormModified(true);
    }, []);
    var onCloseProcess = function () {
        resetForm();
        setIsError({ isError: false, errorMessage: "" });
        onClose(false);
    };
    var onClearClick = function (inputId) {
        setFormData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[inputId] = "", _a)));
        });
        setFormErrors(function (prevErrors) {
            var _a;
            return (__assign(__assign({}, prevErrors), (_a = {}, _a[inputId] = "", _a)));
        });
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var errors, hasErrors, backingThickness, code, materialReference, name_1, thickness, wearSurfaceThickness, description, linerMaterialDto, requestResult, routeParam, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    setLoading(true);
                    // Check if the form has been modified
                    if (isEditing && !isFormModified) {
                        setIsError({
                            isError: true,
                            errorMessage: formatMessage('linerMaterialModal.error.modified'),
                        });
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    errors = validateForm(formData);
                    hasErrors = Object.values(errors).some(function (error) { return error !== ""; });
                    if (hasErrors) {
                        setIsError({ isError: true, errorMessage: formatMessage('linerMaterialModel.error.validation') });
                        setFormErrors(errors);
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    backingThickness = formData.backingThickness, code = formData.code, materialReference = formData.materialReference, name_1 = formData.name, thickness = formData.thickness, wearSurfaceThickness = formData.wearSurfaceThickness, description = formData.description;
                    linerMaterialDto = {
                        backingThickness: Number(backingThickness),
                        code: code,
                        materialReference: materialReference,
                        name: name_1,
                        thickness: Number(thickness),
                        wearSurfaceThickness: Number(wearSurfaceThickness),
                        description: description
                    };
                    return [4 /*yield*/, (isEditing
                            ? linerMaterialStore.editEntity(__assign(__assign({}, linerMaterialDto), { id: linerMaterial === null || linerMaterial === void 0 ? void 0 : linerMaterial.id }))
                            : linerMaterialStore.createEntity(linerMaterialDto))];
                case 2:
                    requestResult = _a.sent();
                    if (!requestResult.passed) {
                        setIsError({ isError: true, errorMessage: requestResult.message });
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    //Handle success
                    console.log("Form submitted successfully!", requestResult.message, requestResult.entityId);
                    resetForm(); // Reset form data and errors
                    routeParam = { created: !isEditing, edited: isEditing };
                    onClose(true, requestResult.entityId, routeParam);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    setIsError({ isError: true, errorMessage: "Form submission error, please read logs" });
                    console.error("Form submission error:", error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var labels = {
        name: formatMessage("linerMaterials.name"),
        description: formatMessage("linerMaterials.description"),
        code: formatMessage("linerMaterials.code"),
        materialReference: formatMessage("linerMaterials.materialReference"),
        thickness: formatMessage("linerMaterials.thickness"),
        wearSurfaceThickness: formatMessage("linerMaterials.wearSurfaceThickness"),
        backingThickness: formatMessage("linerMaterials.backingThickness"),
    };
    var inputIdent = [
        { id: "name", label: labels.name, placeholder: "Type ".concat(labels.name, " here..."), type: "string", validate: validateText, isRequired: true },
        { id: "materialReference", label: labels.materialReference, placeholder: "Type ".concat(labels.materialReference, " here..."), type: "string", validate: validateText, isRequired: true },
        { id: "code", label: labels.code, placeholder: "Type ".concat(labels.code, " here..."), type: "select", options: materialTypesOptions, validate: validateText, isRequired: true },
        { id: "description", label: labels.description, placeholder: "Type ".concat(labels.description, " here..."), type: "string", validate: validateText, isRequired: false },
    ];
    var inputTechDetails = [
        { id: "thickness", label: labels.thickness, placeholder: "Type ".concat(labels.thickness, " here..."), type: "string", validate: validateDecimal, isRequired: true },
        { id: "wearSurfaceThickness", label: labels.wearSurfaceThickness, placeholder: "Type ".concat(labels.wearSurfaceThickness, " here..."), type: "string", validate: validateDecimal, isRequired: true },
        { id: "backingThickness", label: labels.backingThickness, placeholder: "Type ".concat(labels.backingThickness, " here..."), type: "string", validate: validateDecimal, isRequired: true },
    ];
    var renderInputs = function (inputs) {
        return (_jsx(_Fragment, { children: inputs.map(function (input, index) {
                var id = input.id, label = input.label, validate = input.validate, type = input.type, placeholder = input.placeholder, isRequired = input.isRequired;
                var clearIcon = formData[id] && (_jsx(Icon, { className: "icon-cross-circle-filled-16", onClick: function () { return onClearClick(id); } }));
                switch (type) {
                    case 'string':
                        {
                            var inputProps = {
                                id: id,
                                label: label,
                                placeholder: placeholder,
                                validate: validate,
                                onValidation: function (validationError) { return handleValidation(id, validationError); },
                                onChange: function (e) { return handleChange(id, e.target.value); },
                                type: type,
                                error: formErrors[id],
                                heightClass: 'small',
                                spanning: true,
                                required: isRequired,
                                uiRequired: isRequired,
                                icon: clearIcon,
                                value: formData[id]
                            };
                            return (_jsx("span", __assign({ style: { width: "15em" } }, { children: _jsx(Input, __assign({}, inputProps)) })));
                        }
                    case 'select':
                        {
                            var inputProps = {
                                id: id,
                                label: label,
                                placeholder: placeholder,
                                validate: validate,
                                onValidation: function (validationError) { return handleValidation(id, validationError); },
                                onChange: function (value) { return handleChange(id, Array.isArray(value) ? value[0] : value); },
                                type: type,
                                error: formErrors[id],
                                heightClass: 'small',
                                spanning: true,
                                required: isRequired,
                                uiRequired: isRequired,
                                icon: clearIcon,
                                value: formData[id],
                                options: input.options,
                                freeText: false,
                                multiSelect: false
                            };
                            return (_jsx("span", __assign({ style: { width: "15em" } }, { children: _jsx(Select, __assign({}, inputProps)) })));
                        }
                    default:
                        console.warn("Unknown");
                        return null;
                }
            }) }));
    };
    var renderInputIdent = function () {
        return (_jsx(GridRow, __assign({ style: { gap: "2em" } }, { children: renderInputs(inputIdent) })));
    };
    var renderInputTechDetails = function () {
        return (_jsx(_Fragment, { children: _jsxs(GridRow, __assign({ style: { gap: "2em" } }, { children: [renderInputs(inputTechDetails.slice(0, 3)), inputTechDetails.slice(3, 5).map(function (input) {
                        var id = input.id, label = input.label, type = input.type, placeholder = input.placeholder, onFileUpload = input.onFileUpload, unSelectFile = input.unSelectFile;
                        var icons = (_jsxs(_Fragment, { children: [formData[id] && (_jsx(Icon, { className: "icon-cross-circle-filled-16", onClick: unSelectFile, style: { marginRight: '2px' } })), _jsx(Icon, { className: "icon-upload-16", onClick: onFileUpload })] }));
                        var inputProps = {
                            id: id,
                            label: label,
                            placeholder: placeholder,
                            onChange: function (e) { return handleChange(id, e.target.value); },
                            type: type,
                            error: formErrors[id],
                            heightClass: 'small',
                            spanning: true,
                            onClick: onFileUpload,
                            value: formData[id],
                            icon: icons,
                            readOnly: true,
                        };
                        return (_jsx("span", __assign({ style: { width: "15em" } }, { children: _jsx(Input, __assign({}, inputProps)) })));
                    }), renderInputs(inputTechDetails.slice(5))] })) }));
    };
    var rightPageButtons = (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ loading: isLoading, disabled: isLoading, icon: "icon-cross-circle-16", labelPosition: "right", onClick: onCloseProcess, secondary: true }, { children: formatMessage("linerMaterialModal.close") })), _jsx(Button, __assign({ loading: isLoading, disabled: isLoading, icon: "icon-save-16", labelPosition: "right", onClick: handleSubmit, primary: true }, { children: formatMessage("linerMaterialModal.save") }))] }));
    return (_jsxs(Dialog, __assign({ dimmer: true, open: isOpen, size: 'fullscreen' }, { children: [isError.isError && _jsx(DialogDescription, { children: _jsx("p", __assign({ style: { color: 'red' } }, { children: isError.errorMessage })) }), _jsx(PageTitle, { title: modalTitle, extraItems: rightPageButtons }), _jsxs("div", __assign({ style: itemViewListContainerStyle }, { children: [_jsx("h4", { children: _jsx(FormattedMessage, { id: "linerMaterialModal.linerMaterialIden" }) }), _jsx(Grid, __assign({ columns: 3, style: { margin: "1em 0" } }, { children: renderInputIdent() })), _jsx("div", { style: itemViewSeparatorStyle }), _jsx("h4", { children: _jsx(FormattedMessage, { id: "linerMaterialModal.techDetails" }) }), _jsx(Grid, __assign({ columns: 3, style: { margin: "1em 0" } }, { children: renderInputTechDetails() })), _jsx("div", { style: itemViewSeparatorStyle })] })), _jsxs("div", __assign({ style: { width: "100%", textAlign: "center", marginTop: "3em" } }, { children: [_jsx(Icon, { className: "icon-info-o-24" }), " ", formatMessage('linerMaterialModal.requirements')] }))] })));
};
export default LinerMaterialLibraryModal;
