var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Header, HeaderMenu } from "@metsooutotec/modes-react-components";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import ErrorBoundary from '../error-handlers/error-boundary';
var MainLayout = function (_a) {
    var _b, _c;
    var children = _a.children;
    var navigate = useNavigate();
    var auth = useAuth();
    var location = useLocation();
    var userName = ((_b = auth === null || auth === void 0 ? void 0 : auth.user) === null || _b === void 0 ? void 0 : _b.profile.email) ? (_c = auth === null || auth === void 0 ? void 0 : auth.user) === null || _c === void 0 ? void 0 : _c.profile.email : "test";
    var iotDevicesMenu = [
        {
            text: "Sensor Batches",
            onClick: function (e) { navigate("/sensor-batches"); }
        }
    ];
    var provisionsMenu = [
        {
            text: "Provisioning Events",
            onClick: function (e) { navigate("/provision-events"); }
        },
        {
            text: "Liner Material Library",
            onClick: function (e) { navigate("/liner-material-library"); }
        },
    ];
    var stockManagementMenu = [
        {
            text: "Stock Management",
            onClick: function (e) { navigate("/stock-management"); }
        },
        {
            text: "Stock Allocation",
            onClick: function (e) { navigate("/stock-management/stock-allocation"); }
        },
        {
            text: "Dispatch",
            onClick: function (e) { navigate("/stock-management/dispatch"); }
        },
        {
            text: "Dispatch History",
            onClick: function (e) { navigate("/stock-management/dispatch-history"); }
        },
    ];
    var leftMenus = (_jsxs(_Fragment, { children: [_jsx(HeaderMenu, { items: [], onChevronClick: function noRefCheck() { }, onClick: function () { return navigate("/customers"); }, text: "Customers" }), _jsx(HeaderMenu, { items: iotDevicesMenu, onChevronClick: function noRefCheck() { }, text: "IoT Devices" }), _jsx(HeaderMenu, { items: provisionsMenu, onChevronClick: function noRefCheck() { }, text: "Provisioning" }), _jsx(HeaderMenu, { items: stockManagementMenu, onChevronClick: function noRefCheck() { }, text: "Stock Management" })] }));
    return (_jsx(Header, __assign({ title: "WearSense Admin Portal", username: userName, logoHref: "/", leftItems: leftMenus, additionalProfileMenuItems: [
            {
                onClick: function () { auth.signoutRedirect(); },
                iconName: 'icon-logout-16',
                text: 'Log out',
                additionalProps: { "data-cy": "header-menu-item-additional-item-1" },
            }
        ] }, { children: _jsx(ErrorBoundary, { children: children }, location.pathname) })));
};
export default MainLayout;
