export function filterProvisionEvents(provisionEvents, query) {
    if (!provisionEvents) {
        // Handle the case where provisionEvents is null or undefined
        return [];
    }
    try {
        return provisionEvents.filter(function (p) {
            var customerId = query.customerId, siteId = query.siteId, assetId = query.assetId, assetSectionId = query.assetSectionId;
            var thisCustomerId = p.customer ? p.customer.id : null;
            var thisSiteId = p.site ? p.site.id : null;
            var thisAssetId = p.asset ? p.asset.id : null;
            // You can add similar checks for assetSectionId if necessary
            if (customerId && thisCustomerId !== customerId)
                return false;
            if (siteId && thisSiteId !== siteId)
                return false;
            if (assetId && thisAssetId !== assetId)
                return false;
            // Include more checks here if needed for other fields like assetSectionId
            return true;
        });
    }
    catch (e) {
        console.error('Error filtering provision events:', e);
        // Decide how you want to handle errors. For now, returning passed provisionEvents.
        return provisionEvents;
    }
}
