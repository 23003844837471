var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, PageTitle, useIntl } from "@metsooutotec/modes-react-components";
import { useNavigate, useParams } from "react-router-dom";
import { provisionEventsLinkGeneratorSimple } from "../../provisions-utils/provision-events-link-generator";
var tableContainerStyles = {
    padding: "var(--size-m)",
    height: '91%',
    display: 'flex',
    width: '100%',
    gap: 'var(--size-m)'
};
export var ProvisionEventErrors = function (_a) {
    var errors = _a.errors;
    var _b = useParams(), customerId = _b.customerId, siteId = _b.siteId, assetId = _b.assetId, assetSectionId = _b.assetSectionId;
    var formatMessage = useIntl().formatMessage;
    var navigate = useNavigate();
    var link = provisionEventsLinkGeneratorSimple(customerId, siteId, assetId, assetSectionId);
    var errorRender = errors.map(function (errorLine, idx) {
        return (_jsx(Card, { className: "with-heading width100", "data-cy": "card", header: _jsx("div", __assign({ style: { width: '100%' } }, { children: errorLine.header })), content: _jsx("div", __assign({ style: { width: '100%' } }, { children: errorLine.content })) }, idx));
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { title: "Provisioning event errors", extraItems: _jsxs(_Fragment, { children: [_jsx(Button, __assign({ icon: "icon-arrow-left-16", labelPosition: "left", onClick: function () { return navigate(link); }, subtle: true }, { children: formatMessage("provisionEvents.edit.back") })), _jsx(Button, __assign({ icon: "icon-plus-16", labelPosition: "left", disabled: true, primary: true }, { children: formatMessage("provisionEvents.edit.save") }))] }) }), _jsx("div", __assign({ style: tableContainerStyles }, { children: _jsx(Card, { className: "with-heading width100", "data-cy": "card", header: _jsx("div", __assign({ style: { width: '100%' } }, { children: "Errors" })), content: _jsx("div", __assign({ style: { width: '100%' } }, { children: errorRender })) }) }))] }));
};
