// Hard-coded list of classes to preserve
export var CssSVGClasses;
(function (CssSVGClasses) {
    CssSVGClasses["SelectedPath"] = "selectedPath";
    CssSVGClasses["WsLiner"] = "ws-liner";
    CssSVGClasses["WsReferenceTemp"] = "ws-referenceTemp";
    CssSVGClasses["WsRegionTemp"] = "ws-regionTemp";
    CssSVGClasses["WsWearReferencePlaneTemp"] = "ws-wearReferencePlaneTemp";
    CssSVGClasses["WsHole"] = "ws-hole";
})(CssSVGClasses || (CssSVGClasses = {}));
export var EditorSteps;
(function (EditorSteps) {
    EditorSteps[EditorSteps["SVGSettings"] = 0] = "SVGSettings";
    EditorSteps[EditorSteps["LinersLocation"] = 1] = "LinersLocation";
    EditorSteps[EditorSteps["WearReferencePlane"] = 2] = "WearReferencePlane";
    EditorSteps[EditorSteps["HolesLocation"] = 3] = "HolesLocation";
    EditorSteps[EditorSteps["LinersDrawingReferences"] = 4] = "LinersDrawingReferences";
})(EditorSteps || (EditorSteps = {}));
