var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button, FormattedMessage, PageTitle, Table, useIntl, useToast, ToastLevel, Icon } from '@metsooutotec/modes-react-components';
import customerStore from 'stores/customer-stores/customer-store';
import AddressView from 'components/utils/address-view';
import ListView from 'components/utils/list-view/list-view';
import EntityDeleteModal from '../customers-utils/entity-delete-modal';
import { ModalTypes } from '../customers-utils/customer-modal-helper';
import { NotFound } from '../../utils/not-found';
import CustomerModal from './customer-modal';
import SiteModal from '../site/site-modal';
import { GenerateBreadcrumbs } from '../customers-utils/customers-bread-crumbs';
import { itemViewCellStyles, itemViewDetailsContainerStyles, itemViewDetailsTableStyles, itemViewListContainerStyles, itemViewListExtraTopPaddingStyles, itemViewSeparatorStyles, itemViewSubtitleStyles } from '../../common-style/common-style';
var CustomerView = observer(function () {
    var formatMessage = useIntl().formatMessage;
    var setMessage = useToast().setMessage;
    var navigate = useNavigate();
    var customerId = useParams().customerId;
    var location = useLocation();
    var customerMap = customerStore.customerMap;
    var customer = customerMap[customerId || ""];
    var params = location.state;
    var _a = params || {}, Created = _a.created, Edited = _a.edited;
    var deleteParams = location.state;
    var _b = deleteParams || {}, DeletedSite = _b.deleted, SiteName = _b.entityName;
    var _c = __read(useState(false), 2), isModalOpen = _c[0], setIsModalOpen = _c[1];
    var _d = __read(useState(false), 2), isDeleteModalOpen = _d[0], setIsDeleteModalOpen = _d[1];
    var _e = __read(useState(false), 2), isSiteModalOpen = _e[0], setSiteModalOpen = _e[1];
    //if siteToEdit is undefinded the modal will open with empty fields to create a new site.
    var _f = __read(useState(), 2), siteToEdit = _f[0], setSiteToEdit = _f[1];
    var breadcrumbsPath = GenerateBreadcrumbs(customer, undefined, undefined, undefined);
    var template = [
        {
            headingKey: "headings.site",
            cellFunc: function (s) { return s.name; },
        },
        {
            headingKey: "headings.address",
            cellFunc: function (s) { return _jsx(AddressView, { address: s.address }); },
        },
        {
            headingKey: "headings.contact",
            cellFunc: function (s) { return s.contact; },
        },
        {
            headingKey: "headings.metsoContact",
            cellFunc: function (s) { return s.metsoContact; },
        },
        {
            headingKey: "headings.icon",
            cellFunc: function (c) { return _jsx(Icon, { name: "icon-edit-16", onClick: function (event) { return handleEditSiteIconClick(event, c); } }); },
        },
    ];
    var showNotifier = function (text) {
        setMessage(text, ToastLevel.SUCCESS, 6000);
    };
    var customerModal = {
        open: function () {
            setIsModalOpen(true);
        },
        close: function (passed, customerId, navigationCustomerOptions) {
            setIsModalOpen(false);
            setSiteToEdit(undefined);
            if (passed) {
                navigate("/customers/".concat(customerId), {
                    state: navigationCustomerOptions
                });
            }
        }
    };
    var deleteCustomerModal = {
        open: function () {
            setIsDeleteModalOpen(true);
        },
        close: function (params) {
            setIsDeleteModalOpen(false);
            if (params.deleted) {
                navigate("/customers", { state: params });
            }
        }
    };
    var siteModal = {
        open: function () {
            setSiteModalOpen(true);
        },
        close: function (passed, siteId, navigationCustomerOptions) {
            setSiteModalOpen(false);
            setSiteToEdit(undefined);
            if (passed) {
                navigate("/customers/".concat(customer === null || customer === void 0 ? void 0 : customer.id, "/sites/").concat(siteId), {
                    state: navigationCustomerOptions
                });
            }
        }
    };
    var handleEditSiteIconClick = function (event, site) {
        event.stopPropagation();
        setSiteToEdit(site);
        setSiteModalOpen(true);
    };
    useEffect(function () {
        if (Created) {
            showNotifier("New Customer created successfully");
        }
        if (Edited) {
            showNotifier("All changes made successfully");
        }
        window.history.replaceState({}, document.title);
    }, [Created, Edited, params]);
    useEffect(function () {
        if (DeletedSite) {
            {
                showNotifier("You have deleted customer '".concat(SiteName, "'."));
            }
        }
        window.history.replaceState({}, document.title);
    }, [DeletedSite, SiteName, deleteParams]);
    var rightPageButtons = (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ icon: "icon-edit-16", labelPosition: "right", onClick: customerModal.open, primary: true }, { children: formatMessage("customer.edit") })), _jsx(Button, __assign({ icon: "icon-trash-16", labelPosition: "left", onClick: deleteCustomerModal.open, secondary: true }, { children: formatMessage("customer.delete") }))] }));
    var rightSitesButtons = (_jsx(_Fragment, { children: _jsx(Button, __assign({ icon: "icon-plus-16", labelPosition: "right", onClick: siteModal.open, primary: true }, { children: formatMessage("site.addNew") })) }));
    return !customer ? _jsx(NotFound, { id: customerId }) : (_jsxs(_Fragment, { children: [_jsx(PageTitle, { breadcrumbsPath: breadcrumbsPath, onPathChange: navigate, title: customer.name, extraItems: rightPageButtons }), _jsxs("div", __assign({ style: itemViewDetailsContainerStyles }, { children: [_jsx("h3", __assign({ style: itemViewSubtitleStyles }, { children: _jsx(FormattedMessage, { id: "customer.details" }) })), _jsx(Table, __assign({ style: itemViewDetailsTableStyles, tight: true }, { children: _jsxs(Table.Body, { children: [_jsxs(Table.Row, { children: [_jsx(Table.HeaderCell, __assign({ style: itemViewCellStyles }, { children: _jsx(FormattedMessage, { id: "headings.contact" }) })), _jsx(Table.Cell, __assign({ style: itemViewCellStyles }, { children: customer.contact }))] }), _jsxs(Table.Row, { children: [_jsx(Table.HeaderCell, __assign({ style: itemViewCellStyles }, { children: _jsx(FormattedMessage, { id: "headings.metsoContact" }) })), _jsx(Table.Cell, __assign({ style: itemViewCellStyles }, { children: customer.metsoContact }))] })] }) }))] })), _jsx("div", { style: itemViewSeparatorStyles }), _jsxs("div", __assign({ style: itemViewListContainerStyles }, { children: [_jsxs("div", __assign({ style: { display: "flex", justifyContent: "space-between", alignItems: "flex-start" } }, { children: [_jsx("h3", __assign({ style: itemViewSubtitleStyles }, { children: _jsx(FormattedMessage, { id: "siteList.title" }) })), rightSitesButtons] })), _jsx("div", __assign({ style: customer.sites && customer.sites.length
                            ? itemViewListExtraTopPaddingStyles
                            : {} }, { children: _jsx(ListView, { data: customer.sites || [], noneYetKey: "siteList.noSitesYet", onClickRow: function (s) { return navigate("/customers/".concat(customer.id, "/sites/").concat(s.id)); }, template: template }) }))] })), _jsx(CustomerModal, { isOpen: isModalOpen, onClose: customerModal.close, customer: customer }), _jsx(SiteModal, { isOpen: isSiteModalOpen, onClose: siteModal.close, site: siteToEdit, customerId: customer.id }), _jsx(EntityDeleteModal, { isOpen: isDeleteModalOpen, onClose: deleteCustomerModal.close, entity: customer, modalType: ModalTypes.CustomerModal, customerId: customer.id })] }));
});
export default CustomerView;
