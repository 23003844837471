var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Card, Spinner } from "@metsooutotec/modes-react-components";
import { getAssetSectionSVG } from "services/customer-services/asset-section-service";
import provisionEventStore from "stores/provisions/provision-event-store";
import customerStore from "stores/customer-stores/customer-store";
import { useParams } from "react-router-dom";
var cachedImages = {};
export var ProvisionEventsSVGLoader = function (_a) {
    var assetSectionId = _a.assetSectionId, assetId = _a.assetId, tabContent = _a.tabContent, SVGInnerComponent = _a.SVGInnerComponent, selectableElements = _a.selectableElements, selectedElements = _a.selectedElements, onSelectElement = _a.onSelectElement;
    var _b = __read(useState(null), 2), assetSectionImage = _b[0], setAssetSectionImage = _b[1];
    var _c = __read(useState(false), 2), loadingAssetImage = _c[0], setLoadingAssetImage = _c[1];
    var provisionEventsDetail = provisionEventStore.provisionEventsDetail, loadProvisionEventsForAsset = provisionEventStore.loadProvisionEventsForAsset;
    var assetSections = customerStore.assetSections;
    var assetSection = assetSections.filter(function (asec) { return asec.id === assetSectionId; })[0];
    useEffect(function () {
        if (!provisionEventsDetail.length) {
            loadProvisionEventsForAsset(assetId);
        }
    }, [assetId]);
    var fetchImage = function () { return __awaiter(void 0, void 0, void 0, function () {
        var imageUrl, response, content;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (cachedImages[assetSectionId]) {
                        setAssetSectionImage(cachedImages[assetSectionId]);
                        return [2 /*return*/];
                    }
                    setLoadingAssetImage(true);
                    return [4 /*yield*/, getAssetSectionSVG(assetSectionId)];
                case 1:
                    imageUrl = _a.sent();
                    return [4 /*yield*/, fetch(imageUrl)];
                case 2:
                    response = _a.sent();
                    return [4 /*yield*/, response.text()];
                case 3:
                    content = _a.sent();
                    cachedImages[assetSectionId] = content;
                    setAssetSectionImage(content);
                    setLoadingAssetImage(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var memoizedFetchImage = useMemo(function () { return fetchImage; }, [assetSectionId]);
    useEffect(function () {
        memoizedFetchImage();
    }, [memoizedFetchImage]);
    var provisionEventId = useParams().provisionEventId;
    var highlightedEvents = provisionEventId ? provisionEventsDetail.filter(function (a) { return a.id === provisionEventId; }) : provisionEventsDetail;
    var isLoading = loadingAssetImage;
    var isError = assetSectionImage == null;
    var content;
    if (isLoading) {
        content = _jsxs("div", __assign({ style: { position: 'relative', width: '100%', height: '100%' } }, { children: [" ", _jsx(Spinner, { text: "Loading..." })] }));
    }
    else if (isError) {
        content = _jsxs("div", __assign({ style: { position: 'relative', width: '100%', height: '100%' } }, { children: [" ", _jsx(Spinner, { text: "Error!" })] }));
    }
    else {
        content = _jsx(SVGInnerComponent, { rawSVGImage: assetSectionImage, provisionEventsDetail: highlightedEvents, selectableElements: selectableElements, selectedElements: selectedElements, scale: assetSection.sensorNotificationArea, onClick: onSelectElement });
    }
    return _jsx(Card, { className: "width50", header: _jsx("div", __assign({ style: { width: '100%' } }, { children: tabContent })), headerClassName: "tab-align-in-card-header", content: content, "data-cy": "card", style: { width: '100%', marginRight: '' } });
};
