var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * Reset a record-based form data.
 *
 * @param setState - The state setter function to update the form data state.
 */
export var resetRecordFormData = function (setState) {
    setState(function (prevState) {
        var emptyState = Object.fromEntries(Object.keys(prevState).map(function (key) { return [key, ""]; }));
        return emptyState;
    });
};
export var handleUploadFileClick = function (accept, setSelectedFile, setFormData, setFormErrors, formProperty) {
    try {
        var fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = accept;
        fileInput.addEventListener("change", function (event) {
            var _a;
            var file = ((_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0]) || null;
            setSelectedFile(file);
            setFormData(function (prevData) {
                var _a;
                return (__assign(__assign({}, prevData), (_a = {}, _a[formProperty] = file ? file.name : "", _a)));
            });
            if (setFormErrors) {
                setFormErrors(function (prevData) {
                    var _a;
                    return (__assign(__assign({}, prevData), (_a = {}, _a[formProperty] = "", _a)));
                });
            }
        });
        fileInput.click();
    }
    catch (e) {
        console.log(e);
    }
};
