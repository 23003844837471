import { makeAutoObservable, runInAction } from "mobx";
import { EditorSteps } from "../editor-utils/editor-types";
var AssetSectionEditorStore = /** @class */ (function () {
    function AssetSectionEditorStore() {
        var _this = this;
        this.currentStep = EditorSteps.SVGSettings;
        this.showEditBox = false;
        this.selectedLiners = [];
        this.selectedWearReferencePlane = [];
        this.selectedholes = [];
        this.selectedLinersDrawingRef = [];
        this.assetSectionImage = null;
        this.editBoxLinerRegionNameInputValue = "";
        this.selectedPathId = null;
        this.editBoxInputValue = "";
        this.firstLinerName = "";
        this.firstDrawingReferenceName = "";
        this.surroundingHoleLiner = "";
        this.assetSectionSettings = {
            sensorNotificationArea: 4,
            radiusHint: 0.2,
            lineWeight: 1
        };
        this.editAssetSectionWindow = {
            id: "",
            customerId: "",
            siteId: "",
            assetId: "",
            name: "",
            description: "",
            radiusHint: 0.2,
            sensorNotificationArea: 4,
            assetSectionSvg: null,
            assetSectionPdf: null
        };
        this.resetAll = function () {
            runInAction(function () {
                _this.currentStep = EditorSteps.SVGSettings;
                _this.showEditBox = false;
                _this.selectedLiners = [];
                _this.selectedWearReferencePlane = [];
                _this.selectedholes = [];
                _this.selectedLinersDrawingRef = [];
                _this.assetSectionImage = null;
                _this.editBoxLinerRegionNameInputValue = "";
                _this.selectedPathId = null;
                _this.editBoxInputValue = "";
                _this.firstLinerName = "";
                _this.firstDrawingReferenceName = "";
                _this.surroundingHoleLiner = "";
                _this.assetSectionSettings = {
                    sensorNotificationArea: 4,
                    radiusHint: 0.2,
                    lineWeight: 1
                };
                _this.editAssetSectionWindow = {
                    id: "",
                    customerId: "",
                    siteId: "",
                    assetId: "",
                    name: "",
                    description: "",
                    radiusHint: 0.2,
                    sensorNotificationArea: 4,
                    assetSectionSvg: null,
                    assetSectionPdf: null
                };
            });
        };
        this.resetUIStates = function () {
            _this.setSelectedPathId(null);
            _this.restEditBox();
        };
        this.restEditBox = function () {
            _this.setShowEditBox(false);
            _this.setEditBoxInputValue("");
            _this.setEditBoxLinerRegionNameInputValue("");
        };
        this.resetCurrentStep = function () {
            runInAction(function () {
                _this.currentStep = 0;
            });
        };
        this.nextCurrentStep = function () {
            runInAction(function () {
                _this.currentStep = _this.currentStep + 1;
            });
        };
        this.backCurrentStep = function () {
            runInAction(function () {
                _this.currentStep = _this.currentStep - 1;
            });
        };
        this.setShowEditBox = function (value) {
            runInAction(function () {
                _this.showEditBox = value;
            });
        };
        this.setSelectedLiners = function (array) {
            runInAction(function () {
                _this.selectedLiners = array;
            });
        };
        this.setSelectedWearReferencePlane = function (array) {
            runInAction(function () {
                _this.selectedWearReferencePlane = array;
            });
        };
        this.setSelectedHoles = function (array) {
            runInAction(function () {
                _this.selectedholes = array;
            });
        };
        this.setSelectedLinersDrawingRef = function (array) {
            runInAction(function () {
                _this.selectedLinersDrawingRef = array;
            });
        };
        this.setAssetSectionImage = function (image) {
            runInAction(function () {
                _this.assetSectionImage = image;
            });
        };
        this.setEditBoxLinerRegionNameInputValue = function (value) {
            runInAction(function () {
                _this.editBoxLinerRegionNameInputValue = value;
            });
        };
        this.setSelectedPathId = function (value) {
            runInAction(function () {
                _this.selectedPathId = value;
            });
        };
        this.setEditBoxInputValue = function (value) {
            runInAction(function () {
                _this.editBoxInputValue = value;
            });
        };
        this.setFirstLinerName = function (value) {
            runInAction(function () {
                _this.firstLinerName = value;
            });
        };
        this.setFirstDrawingReferenceName = function (value) {
            runInAction(function () {
                _this.firstDrawingReferenceName = value;
            });
        };
        this.setSurroundingHoleLiner = function (value) {
            runInAction(function () {
                _this.surroundingHoleLiner = value;
            });
        };
        this.setAssetSectionSettings = function (value) {
            runInAction(function () {
                _this.assetSectionSettings = value;
            });
        };
        this.setEditAssetSectionWindow = function (value) {
            runInAction(function () {
                _this.editAssetSectionWindow = value;
            });
        };
        makeAutoObservable(this);
    }
    return AssetSectionEditorStore;
}());
export { AssetSectionEditorStore };
var assetSectionEditorStore = new AssetSectionEditorStore();
export default assetSectionEditorStore;
