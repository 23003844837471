var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, PageTitle, useIntl } from "@metsooutotec/modes-react-components";
import { useNavigate, useParams } from "react-router-dom";
import { provisionEventsLinkGeneratorSimple } from "../../provisions-utils/provision-events-link-generator";
export var NoProvisionEvent = function () {
    var _a = useParams(), customerId = _a.customerId, siteId = _a.siteId, assetId = _a.assetId, assetSectionId = _a.assetSectionId;
    var formatMessage = useIntl().formatMessage;
    var navigate = useNavigate();
    var link = provisionEventsLinkGeneratorSimple(customerId, siteId, assetId, assetSectionId);
    return (_jsx(_Fragment, { children: _jsx(PageTitle, { title: "Provisioning event not found", extraItems: _jsxs(_Fragment, { children: [_jsx(Button, __assign({ icon: "icon-arrow-left-16", labelPosition: "left", onClick: function () { return navigate(link); }, subtle: true }, { children: formatMessage("provisionEvents.edit.back") })), _jsx(Button, __assign({ icon: "icon-plus-16", labelPosition: "left", disabled: true, primary: true }, { children: formatMessage("provisionEvents.edit.save") }))] }) }) }));
};
