var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext } from "react";
import { AuthProvider, useAuth } from "react-oidc-context";
import { hasAdminRole, userManagerConfig } from "./userManager";
export var UserContext = createContext(undefined);
var UserProvider = function (_a) {
    var _b, _c;
    var children = _a.children;
    var auth = useAuth();
    var roles = ((_b = auth.user) === null || _b === void 0 ? void 0 : _b.profile.role) || null;
    var handleLogout = function () {
        auth.signoutRedirect();
    };
    switch (auth.activeNavigator) {
        case "signinSilent":
            return _jsx("div", { children: "Signing you in..." });
        case "signoutRedirect":
            return _jsx("div", { children: "Signing you out..." });
    }
    if (auth.isLoading) {
        return _jsx("div", { children: "Loading..." });
    }
    if (auth.error) {
        return _jsxs("div", { children: ["Oops... Authintication problem message: ", auth.error.message, ", make sure to clean up the url from the old auth parammeters."] });
    }
    if (!auth.isAuthenticated) {
        auth.signinRedirect({ state: window.location.search });
        return _jsx("div", { children: "Signed out. Redirecting..." });
    }
    if (!hasAdminRole(roles)) {
        window.history.replaceState(null, '', '/');
        return (_jsxs("div", { children: [_jsx("p", { children: "You don't have permission to use this app." }), _jsx("button", __assign({ onClick: handleLogout, id: "signoutButton" }, { children: "Sign Out" }))] }));
    }
    return (_jsx(UserContext.Provider, __assign({ value: (_c = auth.user) === null || _c === void 0 ? void 0 : _c.profile }, { children: children })));
};
var AuthUserProvider = function (_a) {
    var children = _a.children;
    return (_jsx(AuthProvider, __assign({}, userManagerConfig, { children: _jsx(UserProvider, { children: children }) })));
};
export default AuthUserProvider;
