var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Sidebar, Button, Grid, PageTitle, useIntl, useToast } from "@metsooutotec/modes-react-components";
import AssetSectionEditorCloseModal from "../editor/asset-section-editor-close";
import customerStore from "../../../../stores/customer-stores/customer-store";
import asEditorStore from "./local-store/asset-section-editor-store";
import * as helper from "./helpers/svg-helpers";
import * as saveUtil from "../editor/editor-utils/save-utils";
import linersLocationsStores from 'stores/customer-stores/liner-locations-store';
import { ReScaleSetExistingRegionPath } from "./helpers/holes-helper";
import { getAssetSectionSVG } from "../../../../services/customer-services/asset-section-service";
import { CssSVGClasses, EditorSteps } from "./editor-utils/editor-types";
import SVGEditorWearReferencePlaneModal from "./modals/svg-editor-wrp-modal";
import { generateSteps } from "./sections/steps-generator";
import { SideBarContents } from "./sections/side-bar-contents";
import { FirstColumnEditor } from "./sections/first-column-editor";
import { SecondColumnEditor } from "./sections/second-column-editor";
import { observer } from "mobx-react-lite";
import { style } from '../editor/asset-section-editor-styles';
var AssetSectionEditor = observer(function () {
    var _a;
    //store
    var assetSectionSettings = asEditorStore.assetSectionSettings, setAssetSectionSettings = asEditorStore.setAssetSectionSettings;
    var currentStep = asEditorStore.currentStep, backCurrentStep = asEditorStore.backCurrentStep, nextCurrentStep = asEditorStore.nextCurrentStep, resetAll = asEditorStore.resetAll;
    var selectedLiners = asEditorStore.selectedLiners, setSelectedLiners = asEditorStore.setSelectedLiners;
    var selectedWearReferencePlane = asEditorStore.selectedWearReferencePlane, setSelectedWearReferencePlane = asEditorStore.setSelectedWearReferencePlane;
    var selectedholes = asEditorStore.selectedholes, setSelectedHoles = asEditorStore.setSelectedHoles;
    var selectedLinersDrawingRef = asEditorStore.selectedLinersDrawingRef, setSelectedLinersDrawingRef = asEditorStore.setSelectedLinersDrawingRef;
    var assetSectionImage = asEditorStore.assetSectionImage, setAssetSectionImage = asEditorStore.setAssetSectionImage;
    // #region Consts and States
    var formatMessage = useIntl().formatMessage;
    var navigate = useNavigate();
    var setMessage = useToast().setMessage;
    var _b = useParams(), customerId = _b.customerId, siteId = _b.siteId, assetId = _b.assetId, assetSectionId = _b.assetSectionId;
    var assetMap = customerStore.assetMap, assetSectionMap = customerStore.assetSectionMap;
    var asset = assetMap[assetId || ""];
    var assetSection = assetSectionMap[assetSectionId || ""];
    var navBack = "/customers/".concat(customerId, "/sites/").concat(siteId, "/assets/").concat(asset.id, "/assetsections/").concat(assetSectionId);
    var _c = __read(useState(false), 2), isCloseConfirmOpen = _c[0], setCloseConfirmOpen = _c[1];
    var _d = __read(useState(false), 2), loadingAssetImage = _d[0], setLoadingAssetImage = _d[1];
    var _e = __read(useState(true), 2), showRightSidebar = _e[0], setShowRightSidebar = _e[1];
    var _f = __read(useState(""), 2), errorMessage = _f[0], setErrorMessage = _f[1];
    var _g = __read(useState(false), 2), isModalWearReferencePlaneOpen = _g[0], setIsModalGlobalWearReferencePlaneOpen = _g[1];
    // Used for collect the data from the UI then post them to the backend. 
    var _h = __read(useState({
        id: assetSectionId,
        assetId: assetId,
        customerId: customerId,
        description: assetSection.description,
        radiusHint: 0.2,
        name: assetSection.name,
        sensorNotificationArea: assetSection.sensorNotificationArea <= 0 ? 4 : assetSection.sensorNotificationArea,
        siteId: siteId,
        assetSectionSvg: null,
        assetSectionPdf: null
    }), 2), newEditAssetSection = _h[0], setNewEditAssetSection = _h[1];
    // #endregion
    useEffect(function () {
        resetAll();
    }, []);
    // #region fetchs
    var fetchImage = function () { return __awaiter(void 0, void 0, void 0, function () {
        var imageUrl;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoadingAssetImage(true);
                    return [4 /*yield*/, getAssetSectionSVG(assetSectionId)];
                case 1:
                    imageUrl = _a.sent();
                    setAssetSectionImage(imageUrl);
                    setLoadingAssetImage(false);
                    if (!imageUrl) return [3 /*break*/, 3];
                    return [4 /*yield*/, fetch(imageUrl)
                            .then(function (response) { return response.text(); })
                            .then(function (content) {
                            // Parse the fetched content to an SVG Document.
                            var parser = new DOMParser();
                            var svgDocument = parser.parseFromString(content, "image/svg+xml");
                            // Get the first assigned liner.
                            var firstLiner = svgDocument.querySelector("path.".concat(CssSVGClasses.WsLiner));
                            if (firstLiner) {
                                // Get the stroke-width value of the first path.
                                var strokeWidth = firstLiner.getAttribute('stroke-width');
                                if (strokeWidth) {
                                    var strokeWidthNumber = parseFloat(strokeWidth);
                                    setAssetSectionSettings(__assign(__assign({}, assetSectionSettings), { lineWeight: strokeWidthNumber, sensorNotificationArea: assetSection.sensorNotificationArea }));
                                }
                            }
                        })
                            .catch(function (error) {
                            console.error("Error fetching Blob content:", error);
                        })];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var memoizedFetchImage = useMemo(function () { return fetchImage; }, [assetSectionId]);
    // #endregion
    // #region steps
    var steps = generateSteps(selectedLiners, selectedWearReferencePlane, selectedholes, selectedLinersDrawingRef);
    // #endregion
    // #region Svg editing handlers
    var svgEditingHandlers = {
        /** Get the data from the details and settings and set them into this assetSectionImage */
        onAssetSectionSettingsChange: function () { return __awaiter(void 0, void 0, void 0, function () {
            var svgDocument, liners, assignedHoles, blobUrl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, helper.fetchSvgImageFromUrl(assetSectionImage)];
                    case 1:
                        svgDocument = (_a.sent()).svgDocument;
                        liners = svgDocument.querySelectorAll("path.".concat(CssSVGClasses.WsLiner));
                        assignedHoles = svgDocument.querySelectorAll("path.".concat(CssSVGClasses.WsHole));
                        liners === null || liners === void 0 ? void 0 : liners.forEach(function (liner) {
                            liner.setAttribute('stroke', 'black');
                            liner.setAttribute('stroke-width', "".concat(assetSectionSettings.lineWeight));
                        });
                        assignedHoles === null || assignedHoles === void 0 ? void 0 : assignedHoles.forEach(function (hole) {
                            ReScaleSetExistingRegionPath(document, hole, assetSectionSettings.sensorNotificationArea);
                        });
                        return [4 /*yield*/, helper.updateAssetSectionImage(svgDocument)];
                    case 2:
                        blobUrl = _a.sent();
                        setAssetSectionImage(blobUrl);
                        newEditAssetSection.radiusHint = assetSectionSettings.radiusHint;
                        newEditAssetSection.sensorNotificationArea = assetSectionSettings.sensorNotificationArea;
                        asEditorStore.setEditAssetSectionWindow(newEditAssetSection);
                        return [2 /*return*/];
                }
            });
        }); }
    };
    // #endregion
    // #region useEffects
    useEffect(function () {
        linersLocationsStores.loadLinerLocations(assetSectionId);
    }, [currentStep === EditorSteps.WearReferencePlane]);
    useEffect(function () {
        if (!newEditAssetSection.description)
            setErrorMessage('Description must not be empty');
        else if (!newEditAssetSection.name)
            setErrorMessage('Name must not be empty');
        else
            setErrorMessage('');
    }, [newEditAssetSection]);
    useEffect(function () {
        svgEditingHandlers.onAssetSectionSettingsChange();
    }, [assetSectionSettings]);
    useEffect(function () {
        memoizedFetchImage();
    }, [memoizedFetchImage]);
    // #endregion
    // #region Save process
    var commonOnSaveProps = { assetSectionId: assetSectionId, assetSectionImage: assetSectionImage, errorMessage: errorMessage, setLoadingAssetImage: setLoadingAssetImage, setMessage: setMessage };
    var onSave = (_a = {},
        _a[EditorSteps.SVGSettings] = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveUtil.onSaveSettingsAndDetails(__assign(__assign({}, commonOnSaveProps), { newEditAssetSection: newEditAssetSection, fetchImage: fetchImage, setNewEditAssetSection: setNewEditAssetSection }))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        }); }); },
        _a[EditorSteps.LinersLocation] = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveUtil.onSaveLiners(__assign(__assign({}, commonOnSaveProps), { selectedLiners: selectedLiners, setSelectedLiners: setSelectedLiners }))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        }); }); },
        _a[EditorSteps.WearReferencePlane] = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveUtil.onSaveWearReferencePlane(__assign(__assign({}, commonOnSaveProps), { selectedWearReferencePlane: selectedWearReferencePlane, setSelectedWearReferencePlane: setSelectedWearReferencePlane }))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        }); }); },
        _a[EditorSteps.HolesLocation] = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveUtil.onSaveHoles(__assign(__assign({}, commonOnSaveProps), { selectedholes: selectedholes, setSelectedHoles: setSelectedHoles }))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        }); }); },
        _a[EditorSteps.LinersDrawingReferences] = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveUtil.onSaveLinerDrawingReferences(__assign(__assign({}, commonOnSaveProps), { selectedLinersDrawingRef: selectedLinersDrawingRef, setSelectedLinersDrawingRef: setSelectedLinersDrawingRef }))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        }); }); },
        _a);
    var handleSaveClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var saveFunction;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    saveFunction = onSave[currentStep];
                    if (!saveFunction) return [3 /*break*/, 2];
                    return [4 /*yield*/, saveFunction()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    console.warn("No save function defined for this step: ".concat(currentStep));
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // #endregion
    // #region Navigate Buttons
    var navigateButtonsHandlers = {
        previous: function () {
            if (currentStep > 0) {
                backCurrentStep();
                setErrorMessage("");
            }
        },
        next: function () {
            if (currentStep < steps.length - 1) {
                nextCurrentStep();
                setErrorMessage("");
            }
        },
        close: function () {
            setErrorMessage("");
            setCloseConfirmOpen(true);
        }
    };
    var navigateButtons = (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ icon: "icon-cross-circle-16", labelPosition: "left", onClick: navigateButtonsHandlers.close, secondary: true }, { children: formatMessage("assetSectionEditor.close") })), _jsx(Button, __assign({ icon: "icon-plus-16", labelPosition: "left", onClick: handleSaveClick, secondary: true }, { children: formatMessage("assetSectionEditor.save") })), _jsx(Button, __assign({ icon: "icon-arrow-left-16", labelPosition: "left", disabled: currentStep === EditorSteps.SVGSettings, onClick: navigateButtonsHandlers.previous, secondary: true }, { children: formatMessage("assetSectionEditor.previous") })), _jsx(Button, __assign({ icon: "icon-arrow-right-16", labelPosition: "right", disabled: currentStep === steps.length - 1, onClick: navigateButtonsHandlers.next, primary: true }, { children: formatMessage("assetSectionEditor.next") }))] }));
    return (_jsxs(_Fragment, { children: [_jsx("style", { children: style }), _jsx(PageTitle, { onPathChange: navigate, title: formatMessage("assetSectionEditor.tilte"), extraItems: navigateButtons }), _jsx("div", __assign({ className: "editor-container", style: { position: currentStep !== EditorSteps.SVGSettings ? 'absolute' : 'inherit', display: currentStep !== EditorSteps.SVGSettings ? 'contents' : 'table-cell' } }, { children: _jsxs(Sidebar.Pushable, { children: [(currentStep !== EditorSteps.SVGSettings) &&
                            _jsx(Sidebar, __assign({ id: "rightSidebar", direction: "right", animation: "push", visibleWhenCollapsed: true, visible: showRightSidebar, setVisible: setShowRightSidebar, onClick: function () {
                                    setShowRightSidebar(!showRightSidebar);
                                }, header: _jsx(Sidebar.Title, { title: steps[currentStep].sideBarTitle, onClick: function () {
                                        setShowRightSidebar(!showRightSidebar);
                                    }, visible: showRightSidebar }) }, { children: _jsx(Sidebar.Content, { children: _jsx(SideBarContents, {}) }) })), _jsx(Sidebar.Pusher, { content: _jsx("div", __assign({ className: "grid-container" }, { children: _jsxs(Grid, __assign({ columns: 2, stretched: true, stackable: true }, { children: [_jsx(FirstColumnEditor, { asset: asset, assetSection: assetSection, errorMessage: errorMessage, newEditAssetSection: newEditAssetSection, setNewEditAssetSection: setNewEditAssetSection }), _jsx(SecondColumnEditor, { assetSectionId: assetSectionId, assetSectionSettings: assetSectionSettings, loadingAssetImage: loadingAssetImage, newEditAssetSection: newEditAssetSection, setIsModalGlobalWearReferencePlaneOpen: setIsModalGlobalWearReferencePlaneOpen })] })) })) })] }) })), isCloseConfirmOpen &&
                _jsx(AssetSectionEditorCloseModal, { isOpen: isCloseConfirmOpen, setIsOpen: setCloseConfirmOpen, navigateTo: navBack }), isModalWearReferencePlaneOpen &&
                _jsx(SVGEditorWearReferencePlaneModal, { assetSectionId: assetSectionId, isModalOpen: isModalWearReferencePlaneOpen, setIsModalOpen: setIsModalGlobalWearReferencePlaneOpen })] }));
});
export default AssetSectionEditor;
