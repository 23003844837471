var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Icon, PageTitle, Select, ToastLevel, useIntl, useToast } from "@metsooutotec/modes-react-components";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import sensorBatchStore from "../../../stores/ioT-stores/sensor-batch-store";
import { itemViewDetailsContainerStyles } from "../../common-style/common-style";
import { convertValueToOption } from "../../common/select-component-helper";
import ListView from "../../utils/list-view/list-view";
import { getAbleToCommitSensorsNumber, getAllocatedSensors } from "../stock-managment/stock-management-helper";
import StockAllocationDeleteModal from "./stock-allocation-delete-modal";
import { getMatchingSensorIds, groupSensorBatchesStockAllocation } from "./stock-allocation-helper";
import StockAllocateModal from "./stock-allocation-modal/stock-allocation-modal";
import "./stock-allocation-styles.css";
var StockAllocationListView = observer(function () {
    var formatMessage = useIntl().formatMessage;
    var setMessage = useToast().setMessage;
    var navigate = useNavigate();
    var params = useParams();
    var sensorBatches = sensorBatchStore.sensorBatches, getAllHardwareVariants = sensorBatchStore.getAllHardwareVariants, sensors = sensorBatchStore.sensors;
    var _a = __read(useState([]), 2), dataTable = _a[0], setDataTable = _a[1];
    var _b = __read(useState(false), 2), openAddAllocation = _b[0], setOpenAddAllocation = _b[1];
    var _c = __read(useState(false), 2), openDeleteAllocation = _c[0], setOpenDeleteAllocation = _c[1];
    var _d = __read(useState(null), 2), stockAllocationToEdit = _d[0], setStockAllocationToEdit = _d[1];
    var _e = __read(useState(null), 2), stockAllocationToDelete = _e[0], setStockAllocationToDelete = _e[1];
    var _f = __read(useState({ text: "", value: "" }), 2), searchInput = _f[0], setSearchInput = _f[1];
    var _g = __read(useState([]), 2), listOfPartNumbers = _g[0], setListOfPartNumbers = _g[1];
    var _h = __read(useState(0), 2), sensorsAbleToCommitNumber = _h[0], setSensorsAbleToCommitNumber = _h[1];
    var loadHardwareVariants = function () {
        var hardwareVariants = getAllHardwareVariants;
        if (hardwareVariants.length > 0) {
            setListOfPartNumbers(hardwareVariants.map(function (h) { return ({
                text: h,
                value: h
            }); }));
        }
    };
    var tableContainerStyles = {
        borderTop: '2px solid gray',
    };
    useEffect(function () {
        loadHardwareVariants();
    }, [sensorBatches]);
    // Sync state with URL params on initial load or when params change
    useEffect(function () {
        if (params.partNumber && params.partNumber !== searchInput.text) {
            setSearchInput({ text: params.partNumber, value: params.partNumber });
        }
    }, [params.partNumber]);
    // Navigate when search input changes due to user action
    useEffect(function () {
        if (searchInput.text && searchInput.text !== params.partNumber) {
            navigate("/stock-management/stock-allocation/".concat(searchInput.text));
        }
    }, [searchInput]);
    useEffect(function () {
        var data = groupSensorBatchesStockAllocation(sensorBatches, searchInput.text);
        setDataTable(data);
        var sensors = getAbleToCommitSensorsNumber(sensorBatches, searchInput.text);
        setSensorsAbleToCommitNumber(sensors);
    }, [searchInput, sensorBatches, sensors]);
    var onSearchChange = function (resultValue) {
        setSearchInput(resultValue);
    };
    var rightButtonsFunctions = {
        add: function () {
            if (!searchInput.text) {
                setMessage("Please choose part number", ToastLevel.WARNING, 3000);
            }
            else if (sensorsAbleToCommitNumber < 1) {
                setMessage("No Sensors Available", ToastLevel.WARNING, 3000);
            }
            else {
                setOpenAddAllocation(true);
            }
        },
        edit: function (event, stockAllocation) {
            event.stopPropagation();
            if (!searchInput.text) {
                setMessage("Please choose part number", ToastLevel.WARNING, 3000);
            }
            else {
                setStockAllocationToEdit(stockAllocation);
                setOpenAddAllocation(true);
            }
        },
        closeModal: function () {
            setStockAllocationToEdit(null);
            setOpenAddAllocation(false);
        },
        delete: function (event, stockAllocation) {
            event.stopPropagation();
            setOpenDeleteAllocation(true);
            var sensors = getAllocatedSensors(sensorBatches, searchInput.text);
            var getRowSensorIds = getMatchingSensorIds(sensors, stockAllocation === null || stockAllocation === void 0 ? void 0 : stockAllocation.customer, stockAllocation === null || stockAllocation === void 0 ? void 0 : stockAllocation.site);
            setStockAllocationToDelete({ listOfSensorsIds: getRowSensorIds });
        },
        dispatch: function (event, stockAllocation) {
            event.stopPropagation();
            var customer = stockAllocation.customer, site = stockAllocation.site;
            if (customer && site && searchInput) {
                navigate("/stock-management/dispatch/".concat(searchInput.text, "/").concat(customer, "/").concat(site));
            }
        }
    };
    var onClickRow = function (stockAllocation) {
        navigate("/stock-management/allocated/".concat(params.partNumber, "/").concat(stockAllocation.customer, "/").concat(stockAllocation.site));
    };
    var rightButtons = (_jsx("div", __assign({ style: { display: 'flex', gap: '0.5em' } }, { children: _jsx(Button, __assign({ icon: "icon-submitted-16", labelPosition: "right", onClick: rightButtonsFunctions.add, primary: true }, { children: formatMessage("stockAllocation.addAllocation") })) })));
    var tableTemplate = [
        {
            headingKey: "headings.customer",
            cellFunc: function (s) {
                return s.customer;
            },
            isSearchable: true,
        },
        {
            headingKey: "headings.site",
            cellFunc: function (s) {
                return s.site;
            },
        },
        {
            headingKey: "headings.quantity",
            cellFunc: function (s) {
                return s.quantity;
            },
        },
        {
            headingKey: "headings.date",
            cellFunc: function (s) {
                var _a;
                return (_a = s.date) !== null && _a !== void 0 ? _a : null;
            },
        },
        {
            headingKey: "headings.actions",
            cellFunc: function (s) { return _jsxs("span", __assign({ style: { display: "flex", gap: "1.5em" } }, { children: [_jsx(Icon, { name: "icon-edit-16", onClick: function (event) { return rightButtonsFunctions.edit(event, s); } }), _jsx(Icon, { name: "icon-trash-16", onClick: function (event) { return rightButtonsFunctions.delete(event, s); } }), _jsx(Icon, { name: "icon-shop-16", onClick: function (event) { return rightButtonsFunctions.dispatch(event, s); } })] })); },
        }
    ];
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { onPathChange: navigate, title: formatMessage("stockAllocation.title"), extraItems: rightButtons }), _jsxs("div", __assign({ style: itemViewDetailsContainerStyles }, { children: [_jsx(Select, { id: "SockAllocationSearchInput", icon: _jsx(Icon, { className: "icon-search-16" }), label: formatMessage("headings.partNumber"), onChange: function (value) { return onSearchChange(convertValueToOption(value, listOfPartNumbers)); }, options: listOfPartNumbers, value: searchInput.text, style: { marginBottom: '0.5em' } }), _jsx("h5", { children: "Sensors Able to Commit: ".concat(sensorsAbleToCommitNumber) }), _jsx("div", __assign({ style: tableContainerStyles }, { children: _jsx(ListView, { noneYetKey: "stockAllocation.nonYet", template: tableTemplate, data: dataTable, onClickRow: function (s) { return onClickRow(s); } }) }))] })), openAddAllocation && _jsx(StockAllocateModal, { isOpen: openAddAllocation, onClose: rightButtonsFunctions.closeModal, existAbleToCommitSensorsNumber: sensorsAbleToCommitNumber, stockAllocateRow: stockAllocationToEdit, partNumber: searchInput.text }), openDeleteAllocation && _jsx(StockAllocationDeleteModal, { isOpen: openDeleteAllocation, setIsOpen: setOpenDeleteAllocation, entity: stockAllocationToDelete })] }));
});
export default StockAllocationListView;
