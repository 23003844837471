var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Dialog, DialogContent, DialogHeader, Icon, ToastLevel, useIntl, useToast } from '@metsooutotec/modes-react-components';
import { useState } from 'react';
import stockManagementStore from '../../../stores/stock-management-stores/stock-management-store';
import ModalsButtons from '../../common/modals-buttons';
var StockAllocationDeleteModal = function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, entity = _a.entity;
    var formatMessage = useIntl().formatMessage;
    var _b = __read(useState(false), 2), isLoading = _b[0], setLoading = _b[1];
    var _c = __read(useState({ isError: false, errorMessage: "" }), 2), isError = _c[0], setIsError = _c[1];
    var setMessage = useToast().setMessage;
    var labels = {
        header: "".concat(formatMessage("deleteAllocation.header"), ": #").concat(entity.listOfSensorsIds.length),
        entityTitle: formatMessage("deleteAllocation.entityTitle"),
        warrning: formatMessage("deleteAllocation.warrning")
    };
    var onConfirm = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var result, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, 3, 4]);
                    setLoading(true);
                    setIsError({ isError: false, errorMessage: "" });
                    return [4 /*yield*/, stockManagementStore.deleteStockAllocation(entity)];
                case 1:
                    result = _b.sent();
                    if (!result.passed) {
                        setLoading(false);
                        setIsError({ isError: true, errorMessage: result.message });
                        return [2 /*return*/];
                    }
                    setMessage("Sensors have been reset!", ToastLevel.SUCCESS, 3000);
                    // Handle success
                    console.log("Form submitted successfully!", result.message);
                    onClose();
                    return [3 /*break*/, 4];
                case 2:
                    _a = _b.sent();
                    console.log("Something went wrong on StockAllocationDeleteModal");
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    function onClose() {
        setLoading(false);
        setIsOpen(false);
    }
    return (_jsx(_Fragment, { children: _jsxs(Dialog, __assign({ open: isOpen, dimmer: true, size: "small", style: { maxWidth: "696px" } }, { children: [_jsx(DialogHeader, { children: labels.header }), isError.isError && _jsx("p", __assign({ style: { color: 'red' } }, { children: isError.errorMessage })), _jsx(DialogContent, __assign({ "data-cy": "dialog-content" }, { children: _jsxs(_Fragment, { children: [labels.entityTitle, ".", ' ', _jsxs("div", __assign({ style: { width: "100%", textAlign: "center", marginTop: "3em" } }, { children: [_jsx(Icon, { className: "icon-info-o-24" }), " ", labels.warrning] }))] }) })), _jsx(ModalsButtons, { handleSubmit: onConfirm, onCloseProcess: onClose, isLoading: isLoading })] })) }));
};
export default StockAllocationDeleteModal;
