var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Input, useToast } from "@metsooutotec/modes-react-components";
import { observer } from "mobx-react-lite";
import Draggable from "react-draggable";
import { EditorSteps } from "../editor-utils/editor-types";
import { StripLastSegment, TruncateString } from "../editor-utils/id-string-utils";
import { ResetSelectedPath } from "../helpers/svg-helpers";
import { GetLinerLocationInfo } from "../helpers/wear-reference-plane-helper";
import asEditorStore from "../local-store/asset-section-editor-store";
import { ProcessSubmitEditBox } from "./processses-edit-box-submit";
export var EditSvgBoxComponent = observer(function (_a) {
    var assetSectionId = _a.assetSectionId, linerLocations = _a.linerLocations, boxPosition = _a.boxPosition, svgCurrentPosition = _a.svgCurrentPosition;
    var setMessage = useToast().setMessage;
    var currentStep = asEditorStore.currentStep, editBoxInputValue = asEditorStore.editBoxInputValue;
    // Early return for SVGSettings step
    if (currentStep === EditorSteps.SVGSettings)
        return null;
    // Shared variables across different cases
    var pathId = StripLastSegment(asEditorStore.selectedPathId);
    var charLong = (currentStep === EditorSteps.LinersLocation) ? 12 : 22;
    var linerLocationInfo = GetLinerLocationInfo(linerLocations, pathId);
    var truncatePathId = TruncateString(pathId, charLong);
    // Render function for content based on currentStep
    var renderEditBoxContent = function () {
        switch (currentStep) {
            case EditorSteps.HolesLocation:
                return {
                    title: "Hole Id: ".concat(truncatePathId),
                    inputType: "text",
                    inputValue: editBoxInputValue,
                    onInputChange: handleEditBoxInputChange,
                    onCancel: onEditBoxCancle
                };
            case EditorSteps.WearReferencePlane:
                return {
                    title: "Wear Reference Plane: ".concat(linerLocationInfo === null || linerLocationInfo === void 0 ? void 0 : linerLocationInfo.reference, " => ").concat(linerLocationInfo === null || linerLocationInfo === void 0 ? void 0 : linerLocationInfo.wallThickness),
                    inputType: "number",
                    inputValue: editBoxInputValue,
                    onInputChange: handleEditBoxInputChange,
                    onCancel: onEditBoxCancle
                };
            case EditorSteps.LinersDrawingReferences:
                return {
                    title: "Liners Drawing References: ".concat(linerLocationInfo === null || linerLocationInfo === void 0 ? void 0 : linerLocationInfo.drawingReference),
                    inputType: "text",
                    inputValue: editBoxInputValue,
                    onInputChange: handleEditBoxInputChange,
                    onCancel: onEditBoxCancle
                };
            case EditorSteps.LinersLocation:
                return {
                    title: "Liner Location Reference: ".concat(truncatePathId),
                    inputType: "text",
                    inputValue: editBoxInputValue,
                    onInputChange: handleEditBoxInputChange,
                    onCancel: onEditBoxCancle
                };
        }
    };
    var contentProps = renderEditBoxContent();
    var onEditBoxCancle = function () {
        if (asEditorStore.selectedPathId)
            ResetSelectedPath(asEditorStore.selectedPathId);
        asEditorStore.setShowEditBox(false);
        asEditorStore.setSelectedPathId(null);
        asEditorStore.setEditBoxLinerRegionNameInputValue("");
    };
    var handleEditBoxInputChange = function (value) {
        asEditorStore.setEditBoxInputValue(value);
    };
    var leftPosition = (boxPosition === null || boxPosition === void 0 ? void 0 : boxPosition.x) + svgCurrentPosition.x;
    var topPosition = (boxPosition === null || boxPosition === void 0 ? void 0 : boxPosition.y) + svgCurrentPosition.y;
    if (currentStep === EditorSteps.HolesLocation)
        return (_jsx(SvgHoleLocationEditBox, { title: contentProps.title, inputNameValue: editBoxInputValue, leftPosition: leftPosition, topPosition: topPosition, onCancel: onEditBoxCancle, onSubmit: function () { return ProcessSubmitEditBox({ assetSectionId: assetSectionId, setMessage: setMessage }); }, onInputNameValueChange: function (e) { return handleEditBoxInputChange(e.target.value); } }));
    return (_jsx(Draggable, { children: _jsxs("div", __assign({ className: "edit-box", style: {
                left: "".concat(leftPosition, "px"),
                top: "".concat(topPosition, "px"),
            } }, { children: [_jsx("div", __assign({ className: "box-title" }, { children: contentProps.title })), _jsxs("div", __assign({ className: "box-body" }, { children: [_jsx(Input, { className: "reference-input", value: editBoxInputValue, onChange: function (e) { return handleEditBoxInputChange(e.target.value); }, type: contentProps.inputType }), _jsx(Button, __assign({ onClick: onEditBoxCancle, secondary: true }, { children: "Cancel" })), _jsx(Button, __assign({ onClick: function () { return ProcessSubmitEditBox({ assetSectionId: assetSectionId, setMessage: setMessage }); }, primary: true }, { children: "Submit" }))] }))] })) }));
});
var SvgHoleLocationEditBox = observer(function (_a) {
    var title = _a.title, inputNameValue = _a.inputNameValue, onInputNameValueChange = _a.onInputNameValueChange, onCancel = _a.onCancel, onSubmit = _a.onSubmit, leftPosition = _a.leftPosition, topPosition = _a.topPosition;
    var handleEditBoxLinerRegionNameInputChange = function (value) {
        asEditorStore.setEditBoxLinerRegionNameInputValue(value);
    };
    return (_jsx(Draggable, { children: _jsxs("div", __assign({ className: "edit-box", style: {
                left: "".concat(leftPosition, "px"),
                top: "".concat(topPosition, "px"),
            } }, { children: [_jsxs("div", __assign({ className: "box-title", style: { display: "flex", justifyContent: "space-between" } }, { children: [_jsx("span", { children: title }), _jsxs("span", __assign({ style: { display: "flex", gap: "0.3em" } }, { children: [_jsx(Button, __assign({ onClick: onCancel, secondary: true }, { children: "Cancel" })), _jsx(Button, __assign({ onClick: onSubmit, primary: true }, { children: "Submit" }))] }))] })), _jsxs("div", __assign({ className: "box-body", style: { display: "flex", alignItems: "baseline", gap: "0.5em" } }, { children: [_jsxs("span", { children: [_jsx("label", { children: "".concat(asEditorStore.surroundingHoleLiner, "-Region") }), _jsx(Input, { className: "hole-input", value: inputNameValue, onChange: onInputNameValueChange, type: "text" }), _jsx("label", { children: "-Hole1," })] }), _jsxs("span", { children: [_jsx("label", { children: "Region Name: " }), _jsx(Input, { className: "liner-location-draw-ref-input", value: asEditorStore.editBoxLinerRegionNameInputValue, onChange: function (e) { return handleEditBoxLinerRegionNameInputChange(e.target.value); }, type: "text" })] })] }))] })) }));
});
