var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import sensorBatchStore from "../../../../stores/ioT-stores/sensor-batch-store";
import stockManagementStore from "../../../../stores/stock-management-stores/stock-management-store";
import { convertDateToDDMMMYYYY } from "../../../utils/date-formatter-utils";
import { getAllocatedSensors } from "../../stock-managment/stock-management-helper";
import { convertUnknownSiteNameToNull, getMatchingSensorIds } from "../stock-allocation-helper";
/**
 * Retrieves IDs of sensors allocated to a specific part number and stock allocation row.
 * Filters the allocated sensors by matching customer and site names.
 *
 * @param {string} partNumber - The part number of the sensors to find.
 * @param {StockAllocationRow | null} stockAllocateRow - The stock allocation row to match against allocated sensors.
 * @returns {string[]} - An array of sensor IDs that match the criteria.
 */
function getRelatedAllocatedSensorsIDs(partNumber, stockAllocateRow) {
    var sensors = getAllocatedSensors(sensorBatchStore.sensorBatches, partNumber);
    var getRowSensorIds = getMatchingSensorIds(sensors, stockAllocateRow === null || stockAllocateRow === void 0 ? void 0 : stockAllocateRow.customer, stockAllocateRow === null || stockAllocateRow === void 0 ? void 0 : stockAllocateRow.site);
    ;
    return getRowSensorIds;
}
/**
 * Adjusts stock allocation based on the current and target quantities.
 * Adds or removes stock allocation as necessary.
 *
 * @param {number} currentQuantity - The current quantity of allocated sensors.
 * @param {number} targetQuantity - The target quantity of sensors to allocate.
 * @param {string} partNumber - The part number for the stock allocation.
 * @param {Date | null} allocationDate - The date of allocation.
 * @param {StockAllocationRow | null} stockAllocateRow - The stock allocation row being edited, if any.
 * @param {SelectOption | undefined} pickedCustomer - The customer selected for the stock allocation.
 * @param {SelectOption | undefined} pickedSite - The site selected for the stock allocation.
 * @returns {Promise<StoreResults>} - The result of the stock allocation adjustment.
 */
function adjustStockAllocation(currentQuantity, targetQuantity, partNumber, allocationDate, stockAllocateRow, pickedCustomer, pickedSite) {
    return __awaiter(this, void 0, void 0, function () {
        var difference, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 6, , 7]);
                    difference = targetQuantity - currentQuantity;
                    if (!(difference > 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, addStockAllocation(difference, partNumber, allocationDate, pickedCustomer, pickedSite)];
                case 1: 
                // Add Stock Allocation
                return [2 /*return*/, _b.sent()];
                case 2:
                    if (!(difference < 0)) return [3 /*break*/, 4];
                    return [4 /*yield*/, removeStockAllocation(difference, partNumber, stockAllocateRow)];
                case 3: 
                // Remove Stock Allocation
                return [2 /*return*/, _b.sent()];
                case 4: 
                // No adjustment needed
                return [2 /*return*/, { message: "No stock allocation adjustment needed.", passed: true }];
                case 5: return [3 /*break*/, 7];
                case 6:
                    _a = _b.sent();
                    return [2 /*return*/, { message: "Error occurred during stock allocation adjustment.", passed: false }];
                case 7: return [2 /*return*/];
            }
        });
    });
}
/**
 * Removes stock allocation for a given quantity, part number, and stock allocation row.
 *
 * @param {number} difference - The quantity of stock to remove (expressed as a positive number).
 * @param {string} partNumber - The part number associated with the allocation to remove.
 * @param {StockAllocationRow | null} stockAllocateRow - The specific stock allocation row to adjust.
 * @returns {Promise<StoreResults>} - The result of the stock allocation removal.
 */
function removeStockAllocation(difference, partNumber, stockAllocateRow) {
    return __awaiter(this, void 0, void 0, function () {
        var positiveNumber, existAllocatedSensorsIds, sensorsToDelete, deleteEntities;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    positiveNumber = Math.abs(difference);
                    existAllocatedSensorsIds = getRelatedAllocatedSensorsIDs(partNumber, stockAllocateRow);
                    sensorsToDelete = existAllocatedSensorsIds.splice(0, positiveNumber);
                    deleteEntities = { listOfSensorsIds: sensorsToDelete };
                    return [4 /*yield*/, stockManagementStore.deleteStockAllocation(deleteEntities)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
/**
 * Adds stock allocation for a given quantity, part number, and selected customer and site.
 *
 * @param {number} difference - The quantity of stock to add.
 * @param {string} partNumber - The part number for the stock allocation.
 * @param {Date | null} allocationDate - The date of allocation.
 * @param {SelectOption | undefined} pickedCustomer - The customer selected for the stock allocation.
 * @param {SelectOption | undefined} pickedSite - The site selected for the stock allocation.
 * @returns {Promise<StoreResults>} - The result of the stock allocation addition.
 */
export function addStockAllocation(difference, partNumber, allocationDate, pickedCustomer, pickedSite) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var siteId, addEntity, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    siteId = convertUnknownSiteNameToNull(pickedSite === null || pickedSite === void 0 ? void 0 : pickedSite.value);
                    addEntity = {
                        numberOfSensors: difference,
                        partNumber: partNumber,
                        customerId: (_a = pickedCustomer === null || pickedCustomer === void 0 ? void 0 : pickedCustomer.value) !== null && _a !== void 0 ? _a : "",
                        siteId: siteId,
                        allocationDate: convertDateToDDMMMYYYY(allocationDate !== null && allocationDate !== void 0 ? allocationDate : new Date()),
                    };
                    return [4 /*yield*/, stockManagementStore.addStockAllocation(addEntity)];
                case 1: return [2 /*return*/, _b.sent()];
                case 2:
                    e_1 = _b.sent();
                    return [2 /*return*/, { message: "Failed to add stock allocation.", passed: false }];
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * Processes editing of stock allocation based on the editing status, part number, and other parameters.
 *
 * @param {boolean} isEditing - Flag indicating if the operation is for editing an existing allocation.
 * @param {string} partNumber - The part number involved in the stock allocation.
 * @param {StockAllocationRow | null} stockAllocateRow - The specific stock allocation row to edit.
 * @param {number} quantity - The target quantity for the stock allocation.
 * @param {Date | null} allocationDate - The date of allocation.
 * @param {SelectOption | undefined} pickedCustomer - The customer selected for the stock allocation.
 * @param {SelectOption | undefined} pickedSite - The site selected for the stock allocation.
 * @returns {Promise<StoreResults | null>} - The result of the editing process, or null if not editing.
 */
export function editingProcess(isEditing, partNumber, stockAllocateRow, quantity, allocationDate, pickedCustomer, pickedSite) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var newSiteId, existAllocatedSensorsIds, editEntity, result, e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 4, , 5]);
                    if (!isEditing)
                        return [2 /*return*/, null];
                    newSiteId = convertUnknownSiteNameToNull(pickedSite === null || pickedSite === void 0 ? void 0 : pickedSite.value);
                    existAllocatedSensorsIds = getRelatedAllocatedSensorsIDs(partNumber, stockAllocateRow);
                    editEntity = {
                        listOfSensorsIds: existAllocatedSensorsIds !== null && existAllocatedSensorsIds !== void 0 ? existAllocatedSensorsIds : [],
                        newAllocationDate: convertDateToDDMMMYYYY(allocationDate !== null && allocationDate !== void 0 ? allocationDate : new Date()),
                        newCustomerId: (_a = pickedCustomer === null || pickedCustomer === void 0 ? void 0 : pickedCustomer.value) !== null && _a !== void 0 ? _a : "",
                        newSiteId: newSiteId
                    };
                    return [4 /*yield*/, stockManagementStore.updateStockAllocation(editEntity)];
                case 1:
                    result = _b.sent();
                    if (!(existAllocatedSensorsIds.length !== quantity)) return [3 /*break*/, 3];
                    return [4 /*yield*/, adjustStockAllocation(existAllocatedSensorsIds.length, quantity, partNumber, allocationDate, stockAllocateRow, pickedCustomer, pickedSite)];
                case 2:
                    _b.sent();
                    _b.label = 3;
                case 3: return [2 /*return*/, result];
                case 4:
                    e_2 = _b.sent();
                    return [2 /*return*/, { message: "Failed to edit stock allocation.", passed: false }];
                case 5: return [2 /*return*/];
            }
        });
    });
}
/**
 * Ensures that an "Unknown" site exists in the given list of sites. If the list is null or undefined,
 * it initializes the list with the "Unknown" site. If the list does not contain an "Unknown" site,
 * it adds one to the list.
 *
 * @param {Array|null|undefined} sites - The current array of site objects, or null/undefined if no sites are defined.
 * @returns {Array} - The array of site objects, guaranteed to include an "Unknown" site.
 */
export function ensureUnknownSiteExists(sites) {
    // Check if the array is undefined or if it doesn't already include an "Unknown" site.
    var unknownSiteExists = sites === null || sites === void 0 ? void 0 : sites.some(function (site) { return site.name.toLowerCase() === "unknown"; });
    // If sites is undefined or doesn't contain an "Unknown" site, return a new array with the "Unknown" site added.
    if (!unknownSiteExists) {
        var unknownSite = {
            name: "Unknown",
            id: "",
            assets: [],
            utcOffsetHours: "",
            metsoContact: ""
        };
        // Array spreading to include existing sites (if any) and add the "Unknown" site.
        return __spreadArray(__spreadArray([], __read((sites || [])), false), [unknownSite], false);
    }
    // If the sites array is defined and already includes an "Unknown" site, return it as is.
    return sites || [];
}
/**
* Filters sites to exclude those with null name or id, and ensures an "Unknown" site exists.
* Returns site options suitable for selection inputs.
*
* @param {Site[] | undefined} sites - Array of site objects to be filtered and mapped.
* @returns {SelectOption[]} - Mapped and filtered site options with text and value properties.
*/
export function getFilteredSiteOptions(sites) {
    // Ensure an "Unknown" site exists and then filter and map to SelectOption format
    var filteredSites = ensureUnknownSiteExists(sites)
        .filter(function (s) { return s.name !== null && s.id !== null; })
        .map(function (s) { return ({ text: s.name, value: s.id }); });
    return filteredSites;
}
