import { ProvisionTypes } from "models/provisionsModuleModels";
export var assetSectionData = [
    {
        id: '1f6c8d0b-d821-47f6-ad6c-d5d449318c6e',
        name: 'WearSense Cabinet',
        description: '',
        isDeleted: false,
        linerRegionScale: 2
    },
];
export var provisionEventDeepData = [
    {
        id: '29fbe83f-b197-4633-a88a-f5381a07aeb7',
        description: "A - Commissioning of asset WearSense Cabinet",
        type: ProvisionTypes.Commission,
        isDeleted: false,
        eventDate: new Date(2019, 9, 1),
        liners: [{
                id: '58e9d1fb-28bb-41d4-9143-bb7092bc08f8',
                name: 'WSC-A1',
                description: 'LinerA1',
                isDeleted: false,
                linerLocation: {
                    id: '6776f3af-b965-4114-ad1c-55a3b6e76274',
                    reference: 'LinerA1',
                    drawingReference: 'WSC-A1',
                    isDeleted: false,
                    name: '',
                    description: '',
                    assetSectionId: '1f6c8d0b-d821-47f6-ad6c-d5d449318c6e'
                }
            }, {
                id: '58e9d1fb-28bb-41d4-9143-bb7092bc08f9',
                name: 'WSC-A2',
                description: 'LinerA2',
                isDeleted: false,
                linerLocation: {
                    id: '6776f3af-b965-4114-ad1c-55a3b6e76275',
                    reference: 'LinerA2',
                    drawingReference: 'WSC-A2',
                    isDeleted: false,
                    name: '',
                    description: '',
                    assetSectionId: '1f6c8d0b-d821-47f6-ad6c-d5d449318c6e'
                }
            }],
        sensors: [{
                id: '0861ca3a-631d-4f92-b985-b1507e0fa313',
                description: '',
                isDeleted: false,
                name: 'Test-2001',
                macAddress: '54115f0000002001',
                linerRegion: {
                    id: '2a47c521-517d-47c5-b7fd-79c4d5fb2794',
                    reference: 'LinerA1-Region1',
                    name: 'LinerA1-Region1-Hole1',
                    description: '',
                    isDeleted: false,
                    linerId: "58e9d1fb-28bb-41d4-9143-bb7092bc08f8",
                }
            }, {
                id: '0861ca3a-631d-4f92-b985-b1507e0fa314',
                description: '',
                isDeleted: false,
                name: 'Test-2002',
                macAddress: '54115f0000002002',
                linerRegion: {
                    id: '2a47c521-517d-47c5-b7fd-79c4d5fb2795',
                    reference: 'LinerA2-Region1',
                    name: 'LinerA2-Region1-Hole1',
                    description: '',
                    isDeleted: false,
                    linerId: "58e9d1fb-28bb-41d4-9143-bb7092bc08f9",
                }
            }],
        assetId: "94d466f3-ade7-4578-b818-9e656902c833"
    },
    {
        id: '29fbe83f-b197-4633-a88a-f5381a07aeb8',
        description: "B - Decommissioning of asset WearSense Cabinet",
        type: ProvisionTypes.Decommission,
        isDeleted: false,
        eventDate: new Date(2019, 10, 1),
        liners: [{
                id: '58e9d1fb-28bb-41d4-9143-bb7092bc08f9',
                name: 'WSC-A2',
                description: 'LinerA2',
                isDeleted: false,
                linerLocation: {
                    id: '6776f3af-b965-4114-ad1c-55a3b6e76275',
                    reference: 'LinerA2',
                    drawingReference: 'WSC-A2',
                    isDeleted: false,
                    name: '',
                    description: '',
                    assetSectionId: '1f6c8d0b-d821-47f6-ad6c-d5d449318c6e'
                }
            }],
        sensors: [{
                id: '0861ca3a-631d-4f92-b985-b1507e0fa314',
                description: '',
                isDeleted: false,
                name: 'Test-2002',
                macAddress: '54115f0000002002',
                linerRegion: {
                    id: '2a47c521-517d-47c5-b7fd-79c4d5fb2795',
                    reference: 'LinerA2-Region1',
                    name: 'LinerA2-Region1-Hole1',
                    description: '',
                    isDeleted: false,
                    linerId: "58e9d1fb-28bb-41d4-9143-bb7092bc08f9",
                }
            }],
        assetId: "94d466f3-ade7-4578-b818-9e656902c833"
    },
    // {
    //   // Placeholder new event for easy debugging
    //   id: 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF',
    //   description: "C - Decommissioning of asset WearSense Cabinet",
    //   type: "Decommission",
    //   isDeleted: false,
    //   eventDate: new Date(2019, 9, 2),
    //   liners: [],
    //   sensors: [],
    //   assetId: "94d466f3-ade7-4578-b818-9e656902c833"
    // }
];
export var provisionEventSampleData = [
    {
        id: 'a',
        description: "Commissioning for asset VMX",
        type: ProvisionTypes.Commission,
        isDeleted: false,
        customer: {
            id: "x",
            name: "Anglo American"
        },
        site: {
            id: "x",
            name: "Kings Valley"
        },
        asset: {
            id: "x",
            name: "VMX"
        },
        eventDate: new Date(2023, 11, 2)
    },
    {
        id: 'b',
        description: "East side commissioning",
        type: ProvisionTypes.Commission,
        isDeleted: false,
        customer: {
            id: "y",
            name: "Karara Mining"
        },
        site: {
            id: "y",
            name: "Karara"
        },
        asset: {
            id: "y",
            name: "RC01"
        },
        eventDate: new Date(2023, 11, 2)
    },
    {
        id: 'c',
        description: "Partial commissioning",
        type: ProvisionTypes.Decommission,
        isDeleted: false,
        customer: {
            id: "z",
            name: "Karara Mining"
        },
        site: {
            id: "z",
            name: "Karara"
        },
        asset: {
            id: "z",
            name: "RC01"
        },
        eventDate: new Date(2023, 11, 2)
    },
    {
        id: 'd',
        description: "Partial commissioning",
        type: ProvisionTypes.Decommission,
        isDeleted: false,
        customer: {
            id: "88da013f-3807-48a2-82e1-132e19763840",
            name: "Davies"
        },
        site: {
            id: "1c49a3fd-4c45-4568-8076-cf3dc9200c32",
            name: "Demo Site"
        },
        asset: {
            id: "409b9faf-e734-4667-ae6e-49e5f3c0dfb0",
            name: "Davies-305-CH-408"
        },
        eventDate: new Date(2023, 11, 2)
    },
    {
        id: 'e',
        description: "Partial commissioning",
        type: ProvisionTypes.Decommission,
        isDeleted: false,
        customer: {
            id: "88da013f-3807-48a2-82e1-132e19763840",
            name: "Davies"
        },
        site: {
            id: "1c49a3fd-4c45-4568-8076-cf3dc9200c32",
            name: "Demo Site"
        },
        asset: {
            id: "409b9faf-e734-4667-ae6e-49e5f3c0dfb0",
            name: "Davies-305-CH-408"
        },
        eventDate: new Date(2023, 11, 2)
    },
    {
        id: '29fbe83f-b197-4633-a88a-f5381a07aeb7',
        description: "A - Commissioning of asset WearSense Cabinet",
        type: ProvisionTypes.Decommission,
        isDeleted: false,
        customer: {
            id: "f113c7ee-7fd5-4fea-ac1b-f26964148883",
            name: "Software Engineering"
        },
        site: {
            id: "be95f554-05ca-4e4b-bafd-c7f3dd75bec4",
            name: "Davies Office"
        },
        asset: {
            id: "94d466f3-ade7-4578-b818-9e656902c833",
            name: "WearSense Cabinet"
        },
        eventDate: new Date(2019, 9, 1)
    },
    {
        id: '29fbe83f-b197-4633-a88a-f5381a07aeb8',
        description: "B - Decommissioning of asset WearSense Cabinet",
        type: ProvisionTypes.Decommission,
        isDeleted: false,
        customer: {
            id: "f113c7ee-7fd5-4fea-ac1b-f26964148883",
            name: "Software Engineering"
        },
        site: {
            id: "be95f554-05ca-4e4b-bafd-c7f3dd75bec4",
            name: "Davies Office"
        },
        asset: {
            id: "94d466f3-ade7-4578-b818-9e656902c833",
            name: "WearSense Cabinet"
        },
        eventDate: new Date(2019, 10, 1)
    },
    // {
    //   id: 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF',
    //   description: "C - Decommissioning of asset WearSense Cabinet",
    //   type: "Decommission",
    //   isDeleted: false,
    //   customer: {
    //     id: "f113c7ee-7fd5-4fea-ac1b-f26964148883",
    //     name: "Software Engineering"
    //   },
    //   site: {
    //     id: "be95f554-05ca-4e4b-bafd-c7f3dd75bec4",
    //     name: "Davies Office"
    //   },
    //   asset: {
    //     id: "94d466f3-ade7-4578-b818-9e656902c833",
    //     name: "WearSense Cabinet"
    //   },
    //   eventDate: new Date(2019, 9, 2)
    // },
];
