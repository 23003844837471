var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Icon, PageTitle, useIntl } from "@metsooutotec/modes-react-components";
import { useNavigate } from "react-router-dom";
import sensorBatchStore from "../../../stores/ioT-stores/sensor-batch-store";
import { itemViewDetailsContainerStyles } from "../../common-style/common-style";
import ListView from "../../utils/list-view/list-view";
import { groupSensorBatchesStockManagement } from "./stock-management-helper";
import LowSpinner from "../../common/low-spinner";
import { style } from './stock-managment-styles';
var StockManagementListView = observer(function () {
    var formatMessage = useIntl().formatMessage;
    var navigate = useNavigate();
    var sensorBatches = sensorBatchStore.sensorBatches;
    var _a = __read(useState([]), 2), dataTable = _a[0], setDataTable = _a[1];
    var _b = __read(useState(true), 2), dataLoading = _b[0], setDataLoading = _b[1];
    var loadDataTable = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, convertData;
        return __generator(this, function (_a) {
            try {
                setDataLoading(true);
                // Remove Sensor Batches those having null in hardwareVariant.
                if (sensorBatches.length < 1) {
                    setDataLoading(true);
                    return [2 /*return*/];
                }
                data = sensorBatches.filter(function (sb) { return sb.hardwareVariant; });
                if (data.length > 0) {
                    convertData = groupSensorBatchesStockManagement(data);
                    setDataTable(convertData);
                }
            }
            catch (e) {
                console.error("Something wrong while loading the sensors!", e);
            }
            finally {
                setDataLoading(false);
            }
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        loadDataTable();
    }, [sensorBatches]);
    var editStockManegement = function (event, partNumber) {
        event.stopPropagation();
        navigate("/stock-management/stock-allocation/".concat(partNumber));
    };
    var onClickRow = function (partNumber) {
        navigate("/stock-management/onhand-sensors/".concat(partNumber));
    };
    var tableTemplate = [
        {
            headingKey: "headings.partNumber",
            cellFunc: function (s) {
                return s.partNumber;
            },
            isSearchable: true,
        },
        {
            headingKey: "headings.onHand",
            cellFunc: function (s) {
                return s.onHand;
            },
        },
        {
            headingKey: "headings.onOrder",
            cellFunc: function (s) {
                return s.onOrder;
            },
        },
        {
            headingKey: "headings.allocated",
            cellFunc: function (s) {
                return s.allocated;
            },
        },
        {
            headingKey: "headings.available",
            cellFunc: function (s) {
                return (s.available < 0) ? _jsx("span", __assign({ style: { color: 'red' } }, { children: s.available })) : s.available;
            },
        },
        {
            headingKey: "headings.dispatched",
            cellFunc: function (s) {
                return s.dispatched;
            },
        },
        {
            headingKey: "headings.ableToCommit",
            cellFunc: function (s) {
                return s.ableToCommit;
            },
        },
        {
            headingKey: "headings.actions",
            cellFunc: function (s) { return _jsx("span", __assign({ style: { display: "flex", gap: "1.5em" } }, { children: _jsx(Icon, { name: "icon-edit-16", onClick: function (event) { return editStockManegement(event, s.partNumber); } }) })); },
        }
    ];
    return (_jsxs(_Fragment, { children: [_jsx("style", { children: style }), _jsx(PageTitle, { onPathChange: navigate, title: formatMessage("stockManagement.title") }), _jsx("div", __assign({ style: itemViewDetailsContainerStyles }, { children: (dataLoading)
                    ? _jsx(LowSpinner, { id: "stockManagement.loading" })
                    : _jsx(ListView, { noneYetKey: "sensorBatch.nonYet", template: tableTemplate, data: dataTable, onClickRow: function (s) { return onClickRow(s.partNumber); } }) }))] }));
});
export default StockManagementListView;
