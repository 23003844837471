var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { PageTitle, useIntl, Card, Tab, Button, Icon } from "@metsooutotec/modes-react-components";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import provisionEventStore from "stores/provisions/provision-event-store";
import customerStore from "stores/customer-stores/customer-store";
import { provisionEventsBreadcrumbsGenerator } from "../../provisions-utils/provision-events-bread-crumbs";
import { provisionTypeToIcon } from "../../provisions-utils/provision-events-icon";
import ListView from "components/utils/list-view/list-view";
import { filterProvisionEvents } from "../../provisions-utils/provision-events-filter";
import { provisionEventsLinkGenerator, provisionEventsLinkGeneratorSimple } from "../../provisions-utils/provision-events-link-generator";
import { ProvisionEventModal } from "./provision-events-modal";
import ProvisionEventDeleteModal from "../../provisions-utils/provision-events-delete-modal";
import { ProvisionEventsSVGLoader } from "../provision-events-svg-loader";
import { ProvisionEventsViewSVGEnricher as ProvisionEventsViewSVGEditor } from "./provision-events-view-svg-enrich";
import { convertDateToDDMMMYY } from "../../../utils/date-formatter-utils";
var inlineStyles = "\n.width50 {\n    width: 50%\n}\n.tab-align-in-card-header {\n    padding-bottom: 0px !important;\n    padding-top: 22px !important;\n}\n\n.wrapper> div {\n    width: 100% !important;\n    height: 100% !important;\n}\n\n.wrapper svg {\n    width: 100% !important;\n    height: 100% !important;\n}\n";
var WrappedProvisionEventListView = function () {
    var _a = useParams(), customerId = _a.customerId, siteId = _a.siteId, assetId = _a.assetId, assetSectionId = _a.assetSectionId, provisionEventId = _a.provisionEventId;
    // Bad link?!
    if (!customerId)
        return null;
    if (!siteId)
        return null;
    if (!assetId)
        return null;
    return _jsx(ProvisionEventListView, { customerId: customerId, siteId: siteId, assetSectionId: assetSectionId, assetId: assetId, provisionEventId: provisionEventId });
};
var TabContent = function (_a) {
    var indexAssetSection = _a.indexAssetSection, customer = _a.customer, site = _a.site, asset = _a.asset, provisionEventId = _a.provisionEventId;
    var navigate = useNavigate();
    var assetSections = asset.assetSections;
    var assetSectionLabels = assetSections.map(function (s) { return s.name; });
    return (_jsx(Tab, { as: "div", "data-cy": "tab", activeIndex: indexAssetSection, onTabChange: function (e, d) {
            var nextIndex = Number(d.activeIndex);
            var nextAssetSection = assetSections[nextIndex];
            if (nextAssetSection) {
                navigate(provisionEventsLinkGenerator(customer, site, asset, nextAssetSection, { id: provisionEventId || null }));
            }
        }, menu: {
            pointing: true,
            secondary: true
        }, style: { width: '100%' }, panes: assetSectionLabels.map(function (asl) {
            return { menuItem: asl };
        }) }));
};
var ProvisionEventListView = observer(function (props) {
    var customerId = props.customerId, siteId = props.siteId, assetSectionId = props.assetSectionId, assetId = props.assetId, provisionEventId = props.provisionEventId;
    var navigate = useNavigate();
    var formatMessage = useIntl().formatMessage;
    var provisionEvents = provisionEventStore.provisionEvents;
    var customerMap = customerStore.customerMap, siteMap = customerStore.siteMap, assetMap = customerStore.assetMap, assetSectionMap = customerStore.assetSectionMap;
    var _a = __read(useState(false), 2), isCreateModalOpen = _a[0], setIsCreateModalOpen = _a[1];
    var _b = __read(useState(null), 2), provisionEventToEdit = _b[0], setProvisionEventToEdit = _b[1];
    var _c = __read(useState(false), 2), isProvisionEventEditModalOpen = _c[0], setIsProvisionEventEditModalOpen = _c[1];
    var _d = __read(useState(null), 2), provisionEventToDelete = _d[0], setProvisionEventToDelete = _d[1];
    var _e = __read(useState(false), 2), isProvisionEventDeleteModalOpen = _e[0], setIsProvisionEventDeleteModalOpen = _e[1];
    var customer = customerMap[customerId];
    var site = siteMap[siteId];
    var asset = assetMap[assetId];
    var assetSections = asset.assetSections;
    // Use zero index if not found (i.e. route does not include asset selection)
    var indexAssetSection = Math.max(assetSections.findIndex(function (s) { return s.id === assetSectionId; }), 0);
    var currentTabAssetSection = assetSections[indexAssetSection];
    var deleteProvisionEventModal = {
        open: function (event, provisionEvent) {
            event.stopPropagation();
            setProvisionEventToDelete(provisionEvent);
            setIsProvisionEventDeleteModalOpen(true);
        },
        close: function () {
            setIsProvisionEventDeleteModalOpen(false);
        }
    };
    var editProvisionEventModal = {
        open: function (event, provisionEvent) {
            event.stopPropagation();
            setProvisionEventToEdit(provisionEvent);
            setIsProvisionEventEditModalOpen(true);
        },
        close: function () {
            setIsProvisionEventEditModalOpen(false);
        }
    };
    var tableContainerStyles = {
        padding: "var(--size-m)",
        height: '91%',
        display: 'flex',
        width: '100%',
        gap: 'var(--size-m)'
    };
    var breadcrumbsPath = provisionEventsBreadcrumbsGenerator(customer, site, asset, undefined); // TODO: useMemo (maybe)
    // TODO: useMemo (maybe)
    var provisionEventsFiltered = filterProvisionEvents(provisionEvents, { customerId: customerId, siteId: siteId, assetId: assetId, assetSectionId: assetSectionId });
    var indexEvent = provisionEventsFiltered.findIndex(function (s) { return s.id === provisionEventId; });
    var template = [
        {
            headingKey: "provisionEvents.headings.eventDate",
            cellFunc: function (s) { return convertDateToDDMMMYY(s.eventDate); },
            isSearchable: true,
        },
        {
            headingKey: "provisionEvents.headings.provision",
            cellFunc: function (s) { return _jsxs("span", __assign({ style: { display: "flex", gap: "1.5em" } }, { children: [provisionTypeToIcon(s.type), s.type] })); },
            isSearchable: true,
        },
        {
            headingKey: "provisionEvents.headings.description",
            cellFunc: function (s) { return s.description; },
            isSearchable: true,
        },
        {
            headingKey: "headings.delete",
            cellFunc: function (s) { return _jsx("span", __assign({ style: { display: "flex", gap: "1.5em" } }, { children: _jsx(Icon, { name: "icon-trash-16", onClick: function (event) { return deleteProvisionEventModal.open(event, s); } }) })); },
        },
        {
            headingKey: "headings.edit",
            cellFunc: function (s) { return _jsx("span", __assign({ style: { display: "flex", gap: "1.5em" } }, { children: _jsx(Icon, { name: "icon-edit-16", onClick: function (event) { return editProvisionEventModal.open(event, s); } }) })); },
        },
    ];
    var createLinerMaterialModal = {
        open: function () {
            setIsCreateModalOpen(true);
        },
        close: function () {
            setIsCreateModalOpen(false);
        }
    };
    var selectedRow = (provisionEventId && indexEvent >= 0) ? indexEvent : undefined;
    var navStub = provisionEventsLinkGeneratorSimple(customer.id, site.id, asset.id, assetSectionId);
    return (_jsxs(_Fragment, { children: [_jsx("style", { children: inlineStyles }), _jsx(PageTitle, { breadcrumbsPath: breadcrumbsPath, onPathChange: navigate, title: assetId && assetMap[assetId].name, extraItems: _jsx(Button, __assign({ icon: "icon-plus-16", labelPosition: "left", onClick: createLinerMaterialModal.open, primary: true }, { children: formatMessage("provisionEvents.create") })) }), _jsxs("div", __assign({ style: tableContainerStyles }, { children: [_jsx(ProvisionEventsSVGLoader, { tabContent: _jsx(TabContent, { asset: asset, customer: customer, indexAssetSection: indexAssetSection, site: site, provisionEventId: provisionEventId }), assetSectionId: currentTabAssetSection.id, assetId: assetId, SVGInnerComponent: ProvisionEventsViewSVGEditor }), _jsx(Card, { className: "with-heading width50", content: _jsx("div", __assign({ style: { width: '100%' } }, { children: _jsx(ListView, { noneYetKey: "provisionEvents.noneYet", template: template, data: provisionEventsFiltered, onClickRow: function (a) {
                                    if (a.id === provisionEventId) {
                                        navigate("".concat(navStub));
                                    }
                                    else {
                                        navigate("".concat(navStub, "/event/").concat(a.id));
                                    }
                                }, selectedRow: selectedRow }) })), "data-cy": "card", header: "Provisioning Events" }), _jsx(ProvisionEventModal, { isOpen: isCreateModalOpen, onClose: createLinerMaterialModal.close }), _jsx(ProvisionEventModal, { isOpen: isProvisionEventEditModalOpen, onClose: editProvisionEventModal.close, provisionEvent: provisionEventToEdit }), _jsx(ProvisionEventDeleteModal, { isOpen: isProvisionEventDeleteModalOpen, onClose: deleteProvisionEventModal.close, entity: provisionEventToDelete })] }))] }));
});
export default WrappedProvisionEventListView;
