var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import customerStore from 'stores/customer-stores/customer-store';
import { FormattedMessage, Button, useIntl, PageTitle, Table, ToastLevel, useToast, ContextMenu } from '@metsooutotec/modes-react-components';
import { ReactSVG } from 'react-svg';
import { NotFound } from '../../../utils/not-found';
import { ModalTypes } from '../../customers-utils/customer-modal-helper';
import { GenerateBreadcrumbs } from '../../customers-utils/customers-bread-crumbs';
import EntityDeleteModal from '../../customers-utils/entity-delete-modal';
import { downloadFileFromBlobUrl } from '../../../utils/files-utils';
import { itemViewCellStyles, itemViewDetailsContainerStyles, itemViewDetailsTableStyles, itemViewListExtraTopPaddingStyles } from '../../../common-style/common-style';
import { getAssetSectionSVG, getAssetSectionPdf } from '../../../../services/customer-services/asset-section-service';
import LowSpinner from '../../../common/low-spinner';
import { style } from '../view/asset-section-view-styles';
var AssetSectionView = observer(function () {
    var formatMessage = useIntl().formatMessage;
    var navigate = useNavigate();
    var setMessage = useToast().setMessage;
    var _a = __read(useState(null), 2), assetSectionImage = _a[0], setAssetSectionImage = _a[1];
    var _b = __read(useState(false), 2), loadingAssetImage = _b[0], setLoadingAssetImage = _b[1];
    var _c = useParams(), customerId = _c.customerId, siteId = _c.siteId, assetId = _c.assetId, assetSectionId = _c.assetSectionId;
    var _d = __read(useState(false), 2), isDeleteModalOpen = _d[0], setIsDeleteModalOpen = _d[1];
    var _e = __read(useState(false), 2), downloadLoading = _e[0], setDownloadLoading = _e[1];
    var location = useLocation();
    var params = location.state;
    var _f = params || {}, Created = _f.created, Edited = _f.edited;
    var customerMap = customerStore.customerMap, siteMap = customerStore.siteMap, assetMap = customerStore.assetMap, assetSectionMap = customerStore.assetSectionMap;
    var customer = customerMap[customerId || ""];
    var site = siteMap[siteId || ""];
    var asset = assetMap[assetId || ""];
    var assetSection = assetSectionMap[assetSectionId || ""];
    var breadcrumbsPath = GenerateBreadcrumbs(customer, site, asset, assetSection);
    var fetchImage = function () { return __awaiter(void 0, void 0, void 0, function () {
        var imageUrl;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoadingAssetImage(true);
                    return [4 /*yield*/, getAssetSectionSVG(assetSectionId)];
                case 1:
                    imageUrl = _a.sent();
                    setAssetSectionImage(imageUrl);
                    setLoadingAssetImage(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var showNotifier = function (text) {
        setMessage(text, ToastLevel.SUCCESS, 6000);
    };
    var memoizedFetchImage = useMemo(function () { return fetchImage; }, [assetSectionId]);
    useEffect(function () {
        memoizedFetchImage();
    }, [memoizedFetchImage]);
    useEffect(function () {
        if (Created) {
            showNotifier("New Asset Section created successfully");
        }
        if (Edited) {
            showNotifier("All changes made successfully");
        }
        window.history.replaceState({}, document.title);
    }, [Created, Edited, params, assetSection]);
    var deleteAssetSectionModal = {
        open: function () {
            setIsDeleteModalOpen(true);
        },
        close: function (params) {
            setIsDeleteModalOpen(false);
            if (params.deleted) {
                navigate("/customers/".concat(customerId, "/sites/").concat(siteId, "/assets/").concat(assetId), { state: params });
            }
        }
    };
    var downloadFile = function (setDownloadLoading, fetchFunction, fileNameEndWith, fileExtension) {
        setDownloadLoading(true);
        fetchFunction(assetSectionId)
            .then(function (blobUrl) {
            if (blobUrl) {
                downloadFileFromBlobUrl(blobUrl, "AssetSection_".concat(assetSectionId, "-").concat(fileNameEndWith, ".").concat(fileExtension));
            }
            else {
                setMessage("The requested ".concat(fileExtension.toUpperCase(), " file could not be found"), ToastLevel.WARNING, 6000);
            }
        })
            .catch(function (err) {
            console.error('An error occurred:', err);
            setMessage("An error occurred while downloading the ".concat(fileExtension.toUpperCase(), " file"), ToastLevel.ALERT, 6000);
        })
            .finally(function () {
            setDownloadLoading(false);
        });
    };
    var downloadOptions;
    (function (downloadOptions) {
        downloadOptions["SVG"] = "svg";
        downloadOptions["PDF"] = "pdf";
    })(downloadOptions || (downloadOptions = {}));
    var downloadFiles = {
        svgFile: function () { return downloadFile(setDownloadLoading, getAssetSectionSVG, '2D', downloadOptions.SVG); },
        pdfFile: function () { return downloadFile(setDownloadLoading, getAssetSectionPdf, 'Pdf', downloadOptions.PDF); }
    };
    var onContextMenuChange = function (value) {
        if (value === downloadOptions.PDF)
            downloadFiles.pdfFile();
        else if (value === downloadOptions.SVG)
            downloadFiles.svgFile();
    };
    var navigateToEditor = function () {
        navigate("/customers/".concat(customerId, "/sites/").concat(siteId, "/assets/").concat(assetId, "/assetsections/").concat(assetSectionId, "/editor"));
    };
    var rightPageButtons = (_jsxs("div", __assign({ className: "asset-section-view-right-buttons" }, { children: [downloadLoading
                ? _jsx(Button, __assign({ icon: "icon-edit-16", labelPosition: "right", disabled: true, loading: true, primary: true }, { children: "Downloading" }))
                : _jsx(ContextMenu, { className: "download-menu", menuText: "Downloads", icon: "icon-download-16", onChange: onContextMenuChange, options: [{ text: formatMessage("assetSection.downloadPdf"), value: downloadOptions.PDF }, { text: formatMessage("assetSection.downloadSvg"), value: downloadOptions.SVG }] }), _jsx(Button, __assign({ icon: "icon-edit-16", labelPosition: "right", onClick: navigateToEditor, primary: true }, { children: formatMessage("assetSection.edit") })), _jsx(Button, __assign({ icon: "icon-trash-16", labelPosition: "left", onClick: deleteAssetSectionModal.open, secondary: true }, { children: formatMessage("assetSection.delete") }))] })));
    return (!assetSection ? _jsx(NotFound, { id: assetSectionId })
        :
            _jsxs(_Fragment, { children: [_jsx("style", { children: style }), _jsx(PageTitle, { breadcrumbsPath: breadcrumbsPath, onPathChange: navigate, title: assetSection.name, extraItems: rightPageButtons }), _jsxs("div", __assign({ style: itemViewDetailsContainerStyles }, { children: [_jsx(Table, __assign({ style: itemViewDetailsTableStyles, tight: true }, { children: _jsx(Table.Body, { children: _jsxs(Table.Row, { children: [_jsx(Table.HeaderCell, __assign({ style: __assign(__assign({}, itemViewCellStyles), { width: '20%' }) }, { children: _jsx(FormattedMessage, { id: "assetSection.description" }) })), _jsx(Table.Cell, __assign({ style: itemViewCellStyles }, { children: (assetSection.description) ? assetSection.description : 'Empty' }))] }) }) })), _jsx("div", __assign({ style: { marginTop: '1em' } }, { children: _jsx("div", __assign({ style: itemViewListExtraTopPaddingStyles }, { children: (loadingAssetImage)
                                        ? _jsx(LowSpinner, { id: "assetSection.imageLoading" })
                                        :
                                            _jsx(ReactSVG, { src: assetSectionImage, evalScripts: "once", className: "wrapper" }) })) }))] })), _jsx(EntityDeleteModal, { isOpen: isDeleteModalOpen, onClose: deleteAssetSectionModal.close, entity: assetSection, modalType: ModalTypes.AssetSectionModal, customerId: customer === null || customer === void 0 ? void 0 : customer.id, siteId: siteId, assetId: assetId })] }));
});
export default AssetSectionView;
