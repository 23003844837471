var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { GridRow, Button, useIntl } from "@metsooutotec/modes-react-components";
var ModalsButtons = function (_a) {
    var isLoading = _a.isLoading, onCloseProcess = _a.onCloseProcess, handleSubmit = _a.handleSubmit;
    var formatMessage = useIntl().formatMessage;
    var labels = {
        confirm: formatMessage("modal.confirm"),
        cancel: formatMessage("modal.cancel"),
    };
    var buttonsConfig = [
        { label: labels.confirm, onClick: handleSubmit, primary: true },
        { label: labels.cancel, onClick: onCloseProcess, primary: false },
    ];
    return (_jsxs(_Fragment, { children: [_jsx("div", { style: { marginBottom: "var(--size-xxl)" } }), buttonsConfig.map(function (button, index) {
                var label = button.label, onClick = button.onClick, primary = button.primary;
                var buttonGridStyle = index === 0 ? {
                    marginBottom: "var(--size-m)"
                } : {};
                return (_jsx(GridRow, __assign({ style: buttonGridStyle }, { children: _jsx(Button, { loading: isLoading, disabled: isLoading, onClick: onClick, flexible: true, children: label, centered: true, size: 'medium', primary: primary }) }), label));
            })] }));
};
export default ModalsButtons;
