var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PageTitle, useIntl } from "@metsooutotec/modes-react-components";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import sensorBatchStore from "../../../../stores/ioT-stores/sensor-batch-store";
import { itemViewListContainerStyles } from "../../../common-style/common-style";
import LowSpinner from "../../../common/low-spinner";
import { convertDateToDDMMMYY } from "../../../utils/date-formatter-utils";
import ListView from "../../../utils/list-view/list-view";
import { NotFound } from "../../../utils/not-found";
import { getOnHandSensors } from "../stock-management-helper";
var OnHandSensorsListView = observer(function () {
    var formatMessage = useIntl().formatMessage;
    var navigate = useNavigate();
    var partNumber = useParams().partNumber;
    var sensorBatches = sensorBatchStore.sensorBatches;
    var _a = __read(useState([]), 2), listOfSensors = _a[0], setListOfSensors = _a[1];
    var title = "On Hand Sensors For ".concat(formatMessage("sensorBatchs.hardwareVariant"), ": ").concat(partNumber);
    useEffect(function () {
        if (partNumber && sensorBatches.length > 0) {
            var sensors = getOnHandSensors(sensorBatches, partNumber);
            setListOfSensors(sensors);
        }
    }, [partNumber]);
    var breadcrumbsPath = [
        {
            text: formatMessage('stockManagement.title'),
            to: '/stock-management',
        },
        {
            text: partNumber,
            to: "/stock-management/".concat(partNumber),
        },
    ];
    var template = [
        {
            headingKey: "headings.serialNumber",
            cellFunc: function (a) { return a.serialNumber; },
        },
        {
            headingKey: "headings.macAddress",
            cellFunc: function (a) { return a.macAddress; },
        },
        {
            headingKey: "headings.customer",
            cellFunc: function (a) { return a.customerName; },
        },
        {
            headingKey: "headings.site",
            cellFunc: function (a) { return a.siteName; },
        },
        {
            headingKey: "headings.allocationDate",
            cellFunc: function (a) { return convertDateToDDMMMYY(a.allocatedDate); },
        }
    ];
    return ((sensorBatches.length < 1)
        ? _jsx(LowSpinner, { id: "sensorBatch.loading" })
        : (!listOfSensors)
            ? _jsx(NotFound, { id: title })
            :
                _jsxs(_Fragment, { children: [_jsx(PageTitle, { breadcrumbsPath: breadcrumbsPath, onPathChange: navigate, title: title }), _jsx("div", __assign({ style: itemViewListContainerStyles }, { children: _jsx(ListView, { data: listOfSensors || [], noneYetKey: "sensor.notFound", template: template }) }))] }));
});
export default OnHandSensorsListView;
