import { jsx as _jsx } from "react/jsx-runtime";
import { Spinner, useIntl } from '@metsooutotec/modes-react-components';
import { observer } from 'mobx-react-lite';
import { useEffect, } from 'react';
import { Outlet } from 'react-router-dom';
import provisionEventStore from '../../../stores/provisions/provision-event-store';
var ProvisionEventsLoader = observer(function () {
    var formatMessage = useIntl().formatMessage;
    var provisionEvents = provisionEventStore.provisionEvents, loadAllProvisionEvents = provisionEventStore.loadAllProvisionEvents;
    useEffect(function () {
        if (!provisionEvents.length) {
            loadAllProvisionEvents();
        }
    });
    if (!provisionEvents.length) {
        return _jsx(Spinner, { text: formatMessage("provisionEvents.loading"), style: { zIndex: 8 } });
    }
    else {
        return _jsx(Outlet, {});
    }
});
export default ProvisionEventsLoader;
