var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ProvisionEventsSVGViewer } from "../provision-events-svg-viewer";
import { CssSVGClasses } from "components/customers/asset-section/editor/editor-utils/editor-types";
import { styles } from "../assetsection-styles";
import { SvgHandler } from "../../../customers/asset-section/editor/helpers/svg-helpers";
var findMatching = function (elements, id) {
    var e_1, _a;
    try {
        for (var elements_1 = __values(elements), elements_1_1 = elements_1.next(); !elements_1_1.done; elements_1_1 = elements_1.next()) {
            var element = elements_1_1.value;
            if (element.id && element.id.includes(id))
                return element;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (elements_1_1 && !elements_1_1.done && (_a = elements_1.return)) _a.call(elements_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return null;
};
var provisionEventToLinerFill = {
    Commission: "rgba(0,0,255,0.2)",
    Decommission: "rgba(255,0,0,0.2)",
    Planned: "rgba(0,0,0,0.2)"
};
var provisionEventToSensorFill = {
    Commission: "rgba(0,0,255)",
    Decommission: "rgba(255,0,0)",
    Planned: "#979797"
};
var DEFAULT_LINER_REGION_SCALE = 5;
var tmpBBox = function (elem) {
    var bbox, tempDiv, tempSvg;
    if (document.contains(elem)) {
        return elem.getBBox();
    }
    else {
        tempDiv = document.createElement("div");
        tempDiv.setAttribute("style", "position:absolute; visibility:hidden; width:0; height:0");
        if (elem.tagName === "svg") {
            tempSvg = elem.cloneNode(true);
        }
        else {
            tempSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            tempSvg.appendChild(elem.cloneNode(true));
        }
        tempDiv.appendChild(tempSvg);
        document.body.appendChild(tempDiv);
        bbox = tempSvg.getBBox();
        document.body.removeChild(tempDiv);
        return bbox;
    }
};
var addUiForHoleElement = function (svgDocument, hole, scale, id, color, dataId) {
    var _a, _b;
    var regionEle = null;
    var d = hole.getAttribute("d");
    scale = scale !== null && scale !== void 0 ? scale : DEFAULT_LINER_REGION_SCALE;
    if (d !== undefined) {
        // Get the bounding box of the path
        var boundingBox = tmpBBox(hole);
        //Calculate the center of the circle.
        var centerX = boundingBox.x + boundingBox.width / 2;
        var centerY = boundingBox.y + boundingBox.height / 2;
        // Calculate the diameter based on the bounding box dimensions
        var diameter = Math.max(boundingBox.width, boundingBox.height);
        var radius = diameter / 2;
        var regionRadius = radius * scale;
        var regionX = centerX + regionRadius;
        var regionY = centerY;
        var id_splot = (_a = hole.id) === null || _a === void 0 ? void 0 : _a.split("-");
        if (id_splot !== undefined) {
            var regionD = "M " + regionX + " " + regionY +
                " a " + regionRadius + " " + regionRadius +
                " 0 1 0 " + (regionRadius * -2) + " 0 " +
                regionRadius + " " + regionRadius +
                " 0 1 0 " + (regionRadius * 2) + " 0" +
                " z";
            regionEle = svgDocument.createElementNS("http://www.w3.org/2000/svg", "path");
            regionEle.setAttribute("d", regionD);
            regionEle.setAttribute("class", "ws-region ws-autopopulated");
            regionEle.setAttribute("id", id);
            regionEle.style.fill = color;
            regionEle.style.strokeDasharray = 'none';
            regionEle.dataset.id = dataId;
            var basestyle = hole.getAttribute('style');
            hole.setAttribute('style', basestyle || "");
            hole.style.setProperty("fill", color);
            hole.style.setProperty("strokeWidth", '0.4px');
            (_b = hole === null || hole === void 0 ? void 0 : hole.parentElement) === null || _b === void 0 ? void 0 : _b.insertBefore(regionEle, hole);
        }
    }
    return regionEle;
};
export var ProvisionEventsViewSVGEnricher = function (_a) {
    var rawSVGImage = _a.rawSVGImage, provisionEventsDetail = _a.provisionEventsDetail, selectableElements = _a.selectableElements, selectedElements = _a.selectedElements, scale = _a.scale, onClick = _a.onClick;
    var svgDocument = SvgHandler.Parse(rawSVGImage);
    var errors = [];
    // Reset
    var svgLiners = svgDocument.querySelectorAll(".ws-liner");
    svgLiners.forEach(function (s) { return s.removeAttribute("style"); });
    svgDocument.querySelectorAll(".ws-autopopulated").forEach(function (w) { return w.remove(); });
    svgDocument.querySelectorAll('.ws-svg-hidden').forEach(function (w) { return w.classList.remove("ws-svg-hidden"); });
    // Get the first assigned liner.
    var liners = svgDocument.querySelectorAll("path.".concat(CssSVGClasses.WsLiner));
    var holes = svgDocument.querySelectorAll("path.".concat(CssSVGClasses.WsHole));
    // Provisioning events
    {
        provisionEventsDetail.forEach(function (pe) {
            pe.liners.forEach(function (liner) {
                if (!liner) {
                    errors.push("Liner not found: ", liner);
                    return;
                }
                var ref = liner.linerLocation.reference;
                var elem = findMatching(liners, ref);
                if (elem) {
                    elem.dataset.id = liner.id;
                    elem.style.fill = provisionEventToLinerFill[pe.type];
                    elem.style.stroke = "black";
                }
                else {
                    errors.push("Element not found: ", ref);
                }
            });
            pe.sensors.forEach(function (sensor) {
                var ref = sensor.linerRegion.reference;
                var elem = findMatching(holes, ref);
                if (elem) {
                    elem.dataset.id = sensor.id;
                    var color = provisionEventToSensorFill[pe.type];
                    addUiForHoleElement(svgDocument, elem, scale, "sensor-".concat(sensor.name), color, sensor.id);
                }
                else {
                    errors.push("Element not found: " + ref);
                }
            });
        });
    }
    // Selectable
    {
        selectableElements === null || selectableElements === void 0 ? void 0 : selectableElements.liners.forEach(function (liner) {
            if (!liner) {
                errors.push("Liner not found: ", liner);
                return;
            }
            var ref = liner.linerLocation.reference;
            var elem = findMatching(liners, ref);
            if (elem) {
                elem.dataset.id = liner.id;
                elem.style.fill = 'blue';
                elem.style.stroke = "black";
            }
            else {
                errors.push("Element not found: ", ref);
            }
        });
        selectableElements === null || selectableElements === void 0 ? void 0 : selectableElements.sensors.forEach(function (sensor) {
            var ref = sensor.linerRegion.reference;
            var elem = findMatching(holes, ref);
            if (elem) {
                elem.dataset.id = sensor.id;
                var color = 'blue';
                addUiForHoleElement(svgDocument, elem, scale, "sensor-".concat(sensor.name), color, sensor.id);
            }
            else {
                errors.push("Element not found: " + ref);
            }
        });
    }
    // Selected
    {
        selectedElements === null || selectedElements === void 0 ? void 0 : selectedElements.liners.forEach(function (liner) {
            if (!liner) {
                errors.push("Liner not found: ", liner);
                return;
            }
            var ref = liner.linerLocation.reference;
            var elem = findMatching(liners, ref);
            if (elem) {
                elem.dataset.id = liner.id;
                elem.style.fill = 'red';
                elem.style.stroke = "black";
            }
            else {
                errors.push("Element not found: ", ref);
            }
        });
        selectedElements === null || selectedElements === void 0 ? void 0 : selectedElements.sensors.forEach(function (sensor) {
            var ref = sensor.linerRegion.reference;
            var elem = findMatching(holes, ref);
            if (elem) {
                elem.dataset.id = sensor.id;
                var color = 'red';
                addUiForHoleElement(svgDocument, elem, scale, "sensor-".concat(sensor.name), color, sensor.id);
            }
            else {
                errors.push("Element not found: " + ref);
            }
        });
    }
    var assetSectionImage = SvgHandler.SerializeString(svgDocument);
    return _jsxs(_Fragment, { children: [_jsx("style", { children: styles }), _jsx(ProvisionEventsSVGViewer, { assetSectionImage: assetSectionImage, errors: errors, onClick: onClick })] });
};
