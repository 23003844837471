export function groupSensorBatchesDispatchedRows(sensorBatches, filteredBy) {
    try {
        if (!filteredBy || !sensorBatches)
            return [];
        // Step 1: Filter SensorBatch based on hardwareVariant
        var filteredBatches = sensorBatches.filter(function (sb) { var _a; return ((_a = sb.hardwareVariant) === null || _a === void 0 ? void 0 : _a.toLowerCase().trim()) === filteredBy.toLowerCase().trim(); });
        if (!filteredBatches)
            return [];
        // Step 2: Map filtered batches to sensors while collecting data for StockAllocation
        var allocationsMap_1 = {};
        filteredBatches.forEach(function (batch) {
            batch.sensors.forEach(function (sensor) {
                // Check for allocated state being true before processing.
                if (sensor.dispatchDate && sensor.dispatchState === true) {
                    // Create a unique key for each grouping of customer, site, and date.
                    var customer = sensor.customerName || 'Unknown';
                    var site = sensor.siteName || 'Unknown';
                    var key = "".concat(customer, "-").concat(site);
                    if (!allocationsMap_1[key]) {
                        allocationsMap_1[key] = {
                            id: sensor.id,
                            customer: customer,
                            site: site,
                            quantity: 0,
                        };
                    }
                    allocationsMap_1[key].quantity += 1;
                }
            });
        });
        // Step 3: Return the grouped allocations as an array
        var groupedSensors = Object.values(allocationsMap_1);
        return sortDispatchedRows(groupedSensors);
    }
    catch (e) {
        console.log("Something went wrong while groupSensorBatchesStockAllocation:", e);
        return [];
    }
}
function sortDispatchedRows(allocations) {
    return allocations.sort(function (a, b) {
        // Compare customers
        if (a.customer === null)
            return 1; // nulls last
        if (b.customer === null)
            return -1; // nulls last
        if (a.customer < b.customer)
            return -1;
        if (a.customer > b.customer)
            return 1;
        // If customers are equal, compare sites
        if (a.site === null)
            return 1; // nulls last
        if (b.site === null)
            return -1; // nulls last
        if (a.site < b.site)
            return -1;
        if (a.site > b.site)
            return 1;
        return 0; // equal
    });
}
