var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridColumn, GridRow } from "@metsooutotec/modes-react-components";
import { observer } from "mobx-react-lite";
import AssetSectionEditorDetails from "../asset-section-editor-details";
import AssetSectionEditorsettings from "../asset-section-editor-settings";
import { EditorSteps } from "../editor-utils/editor-types";
import asEditorStore from "./../local-store/asset-section-editor-store";
export var FirstColumnEditor = observer(function (params) {
    var asset = params.asset, assetSection = params.assetSection, newEditAssetSection = params.newEditAssetSection, setNewEditAssetSection = params.setNewEditAssetSection, errorMessage = params.errorMessage;
    return (_jsxs(GridColumn, __assign({ className: "first-column" }, { children: [_jsx(GridRow, __assign({ className: "first-row" }, { children: _jsxs("div", { children: [_jsx("p", __assign({ className: "asset-section-name" }, { children: _jsx("strong", { children: assetSection.name }) })), _jsxs("p", __assign({ className: "asset-name" }, { children: ["Asset section for asset: ", asset.name] }))] }) })), 
            /** If it's in the first step (Asset Section Details and SVG Settings) stage */
            (asEditorStore.currentStep === EditorSteps.SVGSettings) &&
                (_jsxs(GridRow, __assign({ className: "second-row", stretched: true }, { children: [_jsx(AssetSectionDetailsFields, { assetSection: assetSection, errorMessage: errorMessage, newEditAssetSection: newEditAssetSection, setNewEditAssetSection: setNewEditAssetSection }), _jsx(AssetSectionEditorsettings, {})] })))] })));
});
var AssetSectionDetailsFields = function (params) {
    var assetSection = params.assetSection, newEditAssetSection = params.newEditAssetSection, setNewEditAssetSection = params.setNewEditAssetSection, errorMessage = params.errorMessage;
    return (_jsx(AssetSectionEditorDetails, { assetSection: assetSection, editAssetSection: newEditAssetSection, setEditAssetSection: setNewEditAssetSection, errorMessage: errorMessage }));
};
