// Download utility function
export var downloadFileFromBlobUrl = function (blobUrl, fileName) {
    // Create an anchor element to trigger the download
    var a = document.createElement('a');
    a.href = blobUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    // Optional: Revoke the Blob URL
    URL.revokeObjectURL(blobUrl);
};
