var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Table, FormattedMessage, useIntl } from '@metsooutotec/modes-react-components';
import TableFilterBox from './table-filter-box';
import '../list-view/list-view-styles.css';
// This is a helper function to filter the data based on the filters applied.
var applyFilters = function (data, columnFilters, template) {
    var filtered = __spreadArray([], __read(data), false);
    Object.keys(columnFilters).forEach(function (key) {
        var columnIndex = parseInt(key, 10);
        var filterValue = columnFilters[columnIndex];
        if (filterValue) {
            filtered = filtered.filter(function (item) {
                var cellData = template[columnIndex].cellFunc(item);
                return String(cellData).toLowerCase().includes(filterValue.toLowerCase());
            });
        }
    });
    return filtered;
};
var ListView = function (props) {
    var data = props.data, noneYetKey = props.noneYetKey, onClickRow = props.onClickRow, template = props.template, selectedRow = props.selectedRow;
    var formatMessage = useIntl().formatMessage;
    var _a = __read(useState({}), 2), columnFilters = _a[0], setColumnFilters = _a[1];
    var _b = __read(useState(data), 2), filteredData = _b[0], setFilteredData = _b[1];
    var modifiedTemplate = __spreadArray([], __read(template), false);
    useEffect(function () {
        setFilteredData(applyFilters(data, columnFilters, template));
    }, [columnFilters, data, template]);
    var handleFilterClick = function (value, columnIndex) {
        var _a;
        setColumnFilters(__assign(__assign({}, columnFilters), (_a = {}, _a[columnIndex] = value, _a)));
    };
    // Find the index of the column with headingKey "headings.icon"
    var iconColumnIndex = modifiedTemplate.findIndex(function (t) { return t.headingKey === "headings.icon"; });
    // Move the column with headingKey "headings.icon" to the last position
    if (iconColumnIndex !== -1) {
        var iconColumn = modifiedTemplate.splice(iconColumnIndex, 1)[0];
        modifiedTemplate.push(iconColumn);
    }
    return (_jsxs(Table, __assign({ className: "list-view-table", hoverable: true, onSelectRow: function (row) { return onClickRow && onClickRow(filteredData[row]); }, selectable: true, selectedRow: selectedRow, fixedHeader: true, singleLine: true }, { children: [_jsx(Table.Header, { children: _jsx(Table.Row, { children: template.map(function (t, index) { return (_jsx(Table.HeaderCell, { children: _jsxs("div", __assign({ className: "list-view-header" }, { children: [_jsx(FormattedMessage, { id: t.headingKey === "headings.icon" ? "" : t.headingKey }), t.headingCellfun && t.headingCellfun(), t.isSearchable && _jsx(TableFilterBox, { onSearch: function (value) { return handleFilterClick(value, index); }, isLast: index === (template === null || template === void 0 ? void 0 : template.length) - 1 })] })) }, t.headingKey)); }) }) }), _jsx(Table.Body, { children: (filteredData === null || filteredData === void 0 ? void 0 : filteredData.length) === 0 ? (_jsx(Table.Row, { children: _jsx(Table.Cell, __assign({ colSpan: template === null || template === void 0 ? void 0 : template.length }, { children: formatMessage(noneYetKey) })) })) : (filteredData === null || filteredData === void 0 ? void 0 : filteredData.map(function (item) { return (_jsx(Table.Row, { children: template.map(function (t) { return (t.headingKey === "headings.icon" ?
                        _jsx(Table.Cell, __assign({ className: "list-view-cell" }, { children: _jsx("span", __assign({ className: "list-view-icon" }, { children: t.cellFunc(item) })) }), t.headingKey)
                        :
                            _jsx(Table.Cell, { children: t.cellFunc(item) }, t.headingKey)); }) }, item.id)); })) })] })));
};
export default ListView;
