var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, GridColumn, GridRow, Icon, ToastLevel, useIntl, useToast, WizardProcessIndicator } from "@metsooutotec/modes-react-components";
import { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { ReactSVG } from "react-svg";
import linersLocationsStores from 'stores/customer-stores/liner-locations-store';
import LowSpinner from "../../../../common/low-spinner";
import { CssSVGClasses, EditorSteps } from "../editor-utils/editor-types";
import { StripLastSegment } from "../editor-utils/id-string-utils";
import { AssignIncrementalIdsToHolesInLiners, GetLinerRegionReferenceFromHoleId } from "../helpers/holes-helper";
import { GetSurroundingLinerID, MapLiners } from "../helpers/liners-helper";
import { AssignIncrementalIdsToDrawingReferencesToLiners } from "../helpers/references-helper";
import { ResetSelectedPath, fetchSvgImageFromUrl, updateAssetSectionImage } from "../helpers/svg-helpers";
import { GetLinerLocationInfo } from "../helpers/wear-reference-plane-helper";
import { generateSteps } from "./steps-generator";
import { EditSvgBoxComponent } from "../edit-box/svg-edit-box";
import asEditorStore from "./../local-store/asset-section-editor-store";
import { observer } from "mobx-react-lite";
var svgSteps = [
    { step: EditorSteps.LinersLocation, message: "assetSectionEditor.stepLiners", buttonLabel: "Map Liners", widthSize: '25%' },
    { step: EditorSteps.WearReferencePlane, message: "assetSectionEditor.stepLinerThickness", buttonLabel: "Map Thicknesses", widthSize: '25%' },
    { step: EditorSteps.HolesLocation, message: "assetSectionEditor.stepHoles", buttonLabel: "Map Holes", widthSize: '25%' },
    { step: EditorSteps.LinersDrawingReferences, message: "assetSectionEditor.stepLinerReferences", buttonLabel: "Auto Drawing References", widthSize: '35%' },
];
export var SecondColumnEditor = observer(function (params) {
    var _a, _b;
    var loadingAssetImage = params.loadingAssetImage, assetSectionId = params.assetSectionId, assetSectionSettings = params.assetSectionSettings, newEditAssetSection = params.newEditAssetSection, setIsModalGlobalWearReferencePlaneOpen = params.setIsModalGlobalWearReferencePlaneOpen;
    var linerLocations = linersLocationsStores.GetLinerLocationsByAssetSectionId(assetSectionId || "");
    var setMessage = useToast().setMessage;
    var formatMessage = useIntl().formatMessage;
    var selectedLiners = asEditorStore.selectedLiners, setSelectedLiners = asEditorStore.setSelectedLiners;
    var selectedWearReferencePlane = asEditorStore.selectedWearReferencePlane;
    var selectedholes = asEditorStore.selectedholes, setSelectedHoles = asEditorStore.setSelectedHoles;
    var selectedLinersDrawingRef = asEditorStore.selectedLinersDrawingRef, setSelectedLinersDrawingRef = asEditorStore.setSelectedLinersDrawingRef;
    var assetSectionImage = asEditorStore.assetSectionImage, setAssetSectionImage = asEditorStore.setAssetSectionImage;
    var setSelectedPathId = asEditorStore.setSelectedPathId;
    var firstLinerName = asEditorStore.firstLinerName, firstDrawingReferenceName = asEditorStore.firstDrawingReferenceName, setEditBoxInputValue = asEditorStore.setEditBoxInputValue;
    var _c = __read(useState(1), 2), zoomLevel = _c[0], setZoomLevel = _c[1];
    var _d = __read(useState({ x: 0, y: 0 }), 2), svgCurrentPosition = _d[0], setSvgCurrentPosition = _d[1];
    // Used to render the string inside the tooltip
    var _e = __read(useState(""), 2), tooltipText = _e[0], setTooltipText = _e[1];
    var _f = __read(useState({ x: 0, y: 0 }), 2), boxPosition = _f[0], setBoxPosition = _f[1];
    var steps = generateSteps(selectedLiners, selectedWearReferencePlane, selectedholes, selectedLinersDrawingRef);
    useEffect(function () {
        asEditorStore.resetUIStates();
    }, [asEditorStore.currentStep]);
    var setBoxPositionBasedOnPath = function (path) {
        var pathRect = path.getBoundingClientRect();
        var containerRect = document.querySelector('.zoomable-svg-container').getBoundingClientRect();
        var adjustedX = pathRect.left - containerRect.left;
        var adjustedY = pathRect.top - containerRect.top;
        setBoxPosition({ x: adjustedX, y: adjustedY });
    };
    var onShowEditBoxSetter = function (path, editBixValue) {
        path.classList.add(CssSVGClasses.SelectedPath);
        setEditBoxInputValue(editBixValue);
    };
    var onShowEditBox = (_a = {},
        // Working with liners.
        _a[EditorSteps.LinersLocation] = function (path) {
            onShowEditBoxSetter(path, firstLinerName);
        },
        _a[EditorSteps.WearReferencePlane] = function (path) {
            onShowEditBoxSetter(path, "");
        },
        _a[EditorSteps.HolesLocation] = function (path) {
            onShowEditBoxSetter(path, "");
        },
        _a[EditorSteps.LinersDrawingReferences] = function (path) {
            if (path.classList.contains(CssSVGClasses.WsLiner)) {
                onShowEditBoxSetter(path, firstDrawingReferenceName);
            }
        },
        _a);
    // #endregion
    // #region map liners, map holes, and auto references buttons
    var stageButtonOnClick = (_b = {},
        _b[EditorSteps.LinersLocation] = function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, svgDocument, renderedSvg, blobUrl;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, fetchSvgImageFromUrl(assetSectionImage)];
                    case 1:
                        _a = _b.sent(), svgDocument = _a.svgDocument, renderedSvg = _a.renderedSvg;
                        MapLiners(linerLocations, svgDocument, renderedSvg, selectedLiners, setSelectedLiners, assetSectionSettings.lineWeight);
                        return [4 /*yield*/, updateAssetSectionImage(svgDocument)];
                    case 2:
                        blobUrl = _b.sent();
                        return [4 /*yield*/, setAssetSectionImage(blobUrl)];
                    case 3:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); },
        _b[EditorSteps.WearReferencePlane] = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                setIsModalGlobalWearReferencePlaneOpen(true);
                return [2 /*return*/];
            });
        }); },
        _b[EditorSteps.HolesLocation] = function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, svgDocument, renderedSvg, blobUrl;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, fetchSvgImageFromUrl(assetSectionImage)];
                    case 1:
                        _a = _b.sent(), svgDocument = _a.svgDocument, renderedSvg = _a.renderedSvg;
                        AssignIncrementalIdsToHolesInLiners(assetSectionId, svgDocument, renderedSvg, selectedholes, setSelectedHoles, newEditAssetSection.sensorNotificationArea);
                        return [4 /*yield*/, updateAssetSectionImage(svgDocument)];
                    case 2:
                        blobUrl = _b.sent();
                        return [4 /*yield*/, setAssetSectionImage(blobUrl)];
                    case 3:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); },
        _b[EditorSteps.LinersDrawingReferences] = function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, svgDocument, renderedSvg, blobUrl;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, fetchSvgImageFromUrl(assetSectionImage)];
                    case 1:
                        _a = _b.sent(), svgDocument = _a.svgDocument, renderedSvg = _a.renderedSvg;
                        AssignIncrementalIdsToDrawingReferencesToLiners(linerLocations, svgDocument, renderedSvg, selectedLinersDrawingRef, setSelectedLinersDrawingRef);
                        return [4 /*yield*/, updateAssetSectionImage(svgDocument)];
                    case 2:
                        blobUrl = _b.sent();
                        return [4 /*yield*/, setAssetSectionImage(blobUrl)];
                    case 3:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); },
        _b);
    // #endregion
    // #region Zoom Buttons
    var zoomButtonsHandlers = {
        zoomIn: function () { setZoomLevel(zoomLevel + 0.1); },
        zoomOut: function () {
            if (zoomLevel > 0.2) {
                setZoomLevel(zoomLevel - 0.1);
            }
        },
        reset: function () {
            setZoomLevel(1);
            setSvgCurrentPosition({ x: 0, y: 0 });
        }
    };
    // #endregion
    var mouseReactions = {
        onMouseOver: function (e) {
            var _a, _b, _c;
            var path = e.target;
            var pathId = path.id || ""; // Provide a fallback value
            setTooltipText("");
            if (path instanceof SVGPathElement) {
                if (path.classList.contains(CssSVGClasses.WsLiner)) {
                    var linerLocationInfo = GetLinerLocationInfo(linerLocations, pathId);
                    if (!linerLocationInfo)
                        return;
                    setTooltipText("Liner Ref: ".concat(linerLocationInfo.reference, ", WearRefPlane: ").concat(linerLocationInfo.wallThickness, ",  DrawingRef: ").concat(linerLocationInfo.drawingReference));
                }
                else if (path.classList.contains(CssSVGClasses.WsHole)) {
                    var svg = document.querySelector('svg');
                    var surroundingLinerId_1 = (_a = GetSurroundingLinerID(path, svg)) !== null && _a !== void 0 ? _a : "";
                    var linerLocation = linerLocations.find(function (l) { return l.reference.toLowerCase() === surroundingLinerId_1.trim().toLowerCase(); });
                    var linerRegionReference = GetLinerRegionReferenceFromHoleId(pathId);
                    var linerRegion = linersLocationsStores.GetLinerRegionByLinerRegionRef((_b = linerLocation === null || linerLocation === void 0 ? void 0 : linerLocation.id) !== null && _b !== void 0 ? _b : "", linerRegionReference !== null && linerRegionReference !== void 0 ? linerRegionReference : "");
                    var holeName = StripLastSegment(pathId);
                    setTooltipText("HoleId: ".concat(holeName, ", LinerRegion Name: ").concat(((_c = linerRegion === null || linerRegion === void 0 ? void 0 : linerRegion.name) !== null && _c !== void 0 ? _c : "")));
                }
            }
        },
        onMouseLeave: function () {
            setTooltipText("");
        },
        onMouseDown: function (e, div) {
            if (asEditorStore.selectedPathId)
                ResetSelectedPath(asEditorStore.selectedPathId);
            asEditorStore.restEditBox();
            if (asEditorStore.currentStep === EditorSteps.SVGSettings)
                return;
            var svg = div.querySelector('svg');
            var path = e.target;
            if (!(path instanceof SVGPathElement))
                return;
            if (!path.id) {
                setMessage("This path does not have an Id to be selected", ToastLevel.WARNING, 1000);
                return;
            }
            var processPath = function (path) {
                setSelectedPathId(path.id);
                // Do the proccess
                onShowEditBox[asEditorStore.currentStep](path);
                // set Box position
                setBoxPositionBasedOnPath(path);
                asEditorStore.setShowEditBox(true);
            };
            switch (asEditorStore.currentStep) {
                case EditorSteps.LinersLocation:
                    if (path.id.toLowerCase().startsWith("hole")) {
                        setMessage("".concat(path.id, " is a Hole not a Liner!"), ToastLevel.INFO, 3000);
                        return;
                    }
                    processPath(path);
                    break;
                case EditorSteps.WearReferencePlane:
                    if (path.id.toLowerCase().startsWith("hole")) {
                        setMessage("".concat(path.id, " is a Hole not a Liner!"), ToastLevel.INFO, 3000);
                        return;
                    }
                    processPath(path);
                    break;
                case EditorSteps.HolesLocation:
                    var surroundingLinerId = GetSurroundingLinerID(path, svg);
                    asEditorStore.setSurroundingHoleLiner(surroundingLinerId);
                    processPath(path);
                    break;
                case EditorSteps.LinersDrawingReferences:
                    if (path.classList.contains(CssSVGClasses.WsLiner)) {
                        processPath(path);
                    }
                    else {
                        setMessage("".concat(path.id, " does not contain ").concat(CssSVGClasses.WsLiner, " class to be clicked!"), ToastLevel.INFO, 3000);
                        return;
                    }
                    break;
            }
        }
    };
    return (_jsxs(GridColumn, __assign({ className: "second-column", stretched: true }, { children: [_jsxs(GridRow, __assign({ className: "first-row", style: { display: (asEditorStore.currentStep === EditorSteps.SVGSettings) ? 'contents' : 'flex' } }, { children: [_jsx(WizardProcessIndicator, { className: "wizard-component", currentStep: asEditorStore.currentStep, steps: steps.map(function (step) { return ({
                            key: step.key.toString(),
                            text: step.text
                        }); }) }), svgSteps.map(function (_a) {
                        var step = _a.step, buttonLabel = _a.buttonLabel, widthSize = _a.widthSize;
                        return asEditorStore.currentStep === step ? (_jsx(Button, __assign({ style: { width: widthSize, justifyContent: 'center' }, onClick: stageButtonOnClick[asEditorStore.currentStep], primary: true }, { children: buttonLabel }))) : null;
                    })] })), _jsx(GridRow, __assign({ stretched: true, className: 'second-row' }, { children: (loadingAssetImage)
                    ? _jsx(LowSpinner, { id: "assetSection.imageLoading" })
                    : (_jsxs(_Fragment, { children: [_jsx(Draggable, __assign({ position: svgCurrentPosition, onDrag: function (e, data) { setSvgCurrentPosition({ x: data.x, y: data.y }); } }, { children: _jsx("div", __assign({ className: "zoomable-svg-container" }, { children: _jsx("div", __assign({ onMouseDown: function (e) { return mouseReactions.onMouseDown(e, e.currentTarget); }, onMouseOver: function (e) { return mouseReactions.onMouseOver(e); }, onMouseLeave: mouseReactions.onMouseLeave, style: {
                                            minHeight: '30em',
                                            width: '100%',
                                            height: '100%',
                                            transform: "scale(".concat(zoomLevel, ")"),
                                            transformOrigin: 'top left',
                                        } }, { children: _jsx(ReactSVG, { src: assetSectionImage, useRequestCache: false, className: "wrapperEditor" }) })) })) })), asEditorStore.showEditBox && boxPosition &&
                                _jsx(EditSvgBoxComponent, { assetSectionId: assetSectionId, boxPosition: boxPosition, linerLocations: linerLocations, svgCurrentPosition: svgCurrentPosition }), tooltipText && _jsx("div", __assign({ className: "tooltip" }, { children: tooltipText })), _jsxs("div", __assign({ className: "zoom-buttons-container" }, { children: [_jsx(Icon, { className: "icon-zoom-out-24 zoom-button", onClick: zoomButtonsHandlers.zoomOut }), _jsx(Icon, { className: "icon-refresh-24 zoom-button", onClick: zoomButtonsHandlers.reset }), _jsx(Icon, { className: "icon-zoom-in-24 zoom-button", onClick: zoomButtonsHandlers.zoomIn })] }))] })) })), svgSteps.map(function (_a) {
                var step = _a.step, message = _a.message;
                return asEditorStore.currentStep === step ? (_jsxs(GridRow, __assign({ className: "third-row" }, { children: [_jsx(Icon, { className: "icon-info-o-24" }), _jsx("p", __assign({ className: "instructionNotes" }, { children: formatMessage(message) }))] }), step)) : null;
            })] })));
});
