var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Button, PageTitle, ToastLevel, useIntl, useToast } from "@metsooutotec/modes-react-components";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import ListView from "../../utils/list-view/list-view";
import sensorBatchStore from '../../../stores/ioT-stores/sensor-batch-store';
import { itemViewDetailsContainerStyles } from "../../common-style/common-style";
import SensorBatchModal from "./sensor-batch-modal";
import LowSpinner from "../../common/low-spinner";
import { convertDateToDDMMMYY } from "../../utils/date-formatter-utils";
var SensorBatchListView = observer(function () {
    var navigate = useNavigate();
    var formatMessage = useIntl().formatMessage;
    var location = useLocation();
    var sensorBatches = sensorBatchStore.sensorBatches;
    var _a = __read(useState(false), 2), isCreateModalOpen = _a[0], setIsCreateModalOpen = _a[1];
    var deleteParams = location.state;
    var _b = deleteParams || {}, DeletedSensorBatch = _b.deleted, SensorBatchName = _b.entityName;
    var setMessage = useToast().setMessage;
    var createSensorBatchModal = {
        open: function () {
            setIsCreateModalOpen(true);
        },
        close: function (passed, sensorBatchId, navigationsensorBatchOptions) {
            setIsCreateModalOpen(false);
            if (passed) {
                navigate("".concat(location.pathname, "/").concat(sensorBatchId), {
                    state: navigationsensorBatchOptions
                });
            }
        }
    };
    var showNotifier = function (text) {
        setMessage(text, ToastLevel.SUCCESS, 6000);
    };
    useEffect(function () {
        if (DeletedSensorBatch) {
            {
                showNotifier("You have deleted Sensor Batch '".concat(SensorBatchName, "'."));
            }
        }
        window.history.replaceState({}, document.title);
    }, [DeletedSensorBatch, SensorBatchName, deleteParams]);
    var template = [
        {
            headingKey: "headings.batchDate",
            cellFunc: function (s) {
                return convertDateToDDMMMYY(s.batchDate);
            },
            isSearchable: true,
        },
        {
            headingKey: "headings.purchaseOrder",
            cellFunc: function (s) { return s.purchaseOrder; },
            isSearchable: true,
        },
        {
            headingKey: "headings.manufacturer",
            cellFunc: function (s) { return s.manufacturer; },
            isSearchable: true,
        },
        {
            headingKey: "headings.hardwareRevision",
            cellFunc: function (s) { return s.hardwareRevision; },
            isSearchable: true,
        },
        {
            headingKey: "headings.hardwareVariant",
            cellFunc: function (s) { return s.hardwareVariant; },
            isSearchable: true,
        },
        {
            headingKey: "headings.firmwareRevision",
            cellFunc: function (s) { return s.firmwareRevision; },
            isSearchable: true,
        },
        {
            headingKey: "headings.description",
            cellFunc: function (s) { return s.description; },
            isSearchable: true,
        }
    ];
    var rightPageButtons = (_jsx(_Fragment, { children: _jsx(Button, __assign({ icon: "icon-plus-16", labelPosition: "left", onClick: createSensorBatchModal.open, primary: true }, { children: formatMessage("sensorBatch.create") })) }));
    var tableContainerStyles = __assign(__assign({}, itemViewDetailsContainerStyles), { height: '91%' });
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { onPathChange: navigate, title: formatMessage("sensorBatchs.title"), extraItems: rightPageButtons }), _jsxs("div", __assign({ style: tableContainerStyles }, { children: [(sensorBatches.length < 1)
                        ? (_jsx(LowSpinner, { id: "sensorBatch.loading" }))
                        : _jsx(ListView, { noneYetKey: "sensorBatch.nonYet", template: template, data: sensorBatches, onClickRow: function (s) { return navigate("/sensor-batches/" + s.id); } }), _jsx(SensorBatchModal, { isOpen: isCreateModalOpen, onClose: createSensorBatchModal.close })] }))] }));
});
export default SensorBatchListView;
