var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon, Input } from '@metsooutotec/modes-react-components';
import { observer } from "mobx-react-lite";
import asEditorStore from "./local-store/asset-section-editor-store";
var AssetSectionEditorsettings = observer(function () {
    var assetSectionSettings = asEditorStore.assetSectionSettings, setAssetSectionSettings = asEditorStore.setAssetSectionSettings;
    var handleChange = function (id, value) {
        var _a;
        var updatedSettings = __assign(__assign({}, assetSectionSettings), (_a = {}, _a[id] = Number(value), _a));
        setAssetSectionSettings(updatedSettings);
    };
    var onClearClick = function (inputId) {
        var _a;
        var updatedSettings = __assign(__assign({}, assetSectionSettings), (_a = {}, _a[inputId] = 0, _a));
        setAssetSectionSettings(updatedSettings);
    };
    var clearIcon = function (id) { return (assetSectionSettings[id] !== 0 ? _jsx(Icon, { className: "icon-cross-circle-filled-16", onClick: function () { return onClearClick(id); } }) : null); };
    var inputConfig = [
        {
            id: 'sensorNotificationArea',
            label: 'Sensor Notification Area',
            placeholder: 'Enter sensor notification area',
            type: 'number',
            required: false,
            uiRequired: false,
            value: assetSectionSettings.sensorNotificationArea,
            heightClass: 'small',
            spanning: true,
            icon: clearIcon('sensorNotificationArea')
        },
        {
            id: 'radiusHint',
            label: 'Radius Hint',
            placeholder: 'Enter radius hint',
            type: 'number',
            required: false,
            uiRequired: false,
            value: assetSectionSettings.radiusHint,
            heightClass: 'small',
            spanning: true,
            icon: clearIcon('radiusHint')
        },
        {
            id: 'lineWeight',
            label: 'Line Weight',
            placeholder: 'Enter line weight',
            type: 'number',
            required: false,
            uiRequired: false,
            value: assetSectionSettings.lineWeight,
            heightClass: 'small',
            spanning: true,
            icon: clearIcon('radiusHint')
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx("p", __assign({ style: { fontSize: '1.5vw', marginBottom: '0.25em' } }, { children: _jsx("strong", { children: "SVG Settings" }) })), inputConfig.map(function (config) { return (_jsx(Input, __assign({ style: { marginBottom: "var(--size-xs)" } }, config, { onChange: function (e) { return handleChange(config.id, e.target.value); } }), config.id)); })] }));
});
export default AssetSectionEditorsettings;
