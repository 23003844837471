var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { convertDDMMMYYYYtoDate } from "../components/utils/date-formatter-utils";
/**
 * Validates an email address.
 *
 * @param value - The email address to validate.
 * @returns An error message if the email is invalid, otherwise an empty string.
 */
export var validateEmail = function (value) {
    if (!value) {
        return "Email is required";
    }
    if (!/\S+@\S+\.\S+/.test(value)) {
        return "Invalid email format";
    }
    return "";
};
/**
 * Validates a text value.
 *
 * @param value - The text value to validate.
 * @returns An error message if the text is empty, otherwise an empty string.
 */
export var validateText = function (value) {
    if (value.trim() === "") {
        return "Text is required";
    }
    return "";
};
/**
 * Validates an address value.
 *
 * @param value - The address value to validate.
 * @returns An error message if the address is invalid, otherwise an empty string.
 */
export var validateAddress = function (value) {
    if (!value || value.trim() === "") {
        return "";
    }
    var addressParts = value.split(",");
    if (addressParts.length !== 3) {
        return "Address format is invalid. Please provide three parts [country, state, region] separated by commas";
    }
    var _a = __read(addressParts.map(function (part) { return part.trim(); }), 3), country = _a[0], state = _a[1], region = _a[2];
    if (!country || !state || !region) {
        return "Address format is invalid. Each part [country, state, region] must not be empty";
    }
    return "";
};
/**
 * Validates a decimal value.
 *
 * @param value - The decimal value to validate.
 * @returns An error message if the decimal value is invalid, otherwise an empty string.
 */
export var validateDecimal = function (value) {
    if (!value) {
        return "Value is required";
    }
    if (!/^(-)?\d+(\.\d+)?$/.test(value)) {
        return "Invalid decimal format";
    }
    return "";
};
/**
 * Validates an hour value.
 *
 * @param value - The hour value to validate.
 * @returns An error message if the hour value is invalid, otherwise an empty string.
 */
export var validateHours = function (value) {
    if (!value) {
        return "Value is required";
    }
    if (!/^-?\d+(\.\d+)?$/.test(value)) { // Updated regex to allow decimals
        return "Hour value must be a valid number";
    }
    var numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
        return "Invalid hour format";
    }
    if (numericValue < -23 || numericValue > 23) {
        return "Hour value must be between -23 and 23";
    }
    return "";
};
export var validateFile = function (value) {
    if (!value) {
        return "File is required";
    }
    return "";
};
export var validateSiteLogitudeLatitudeDecimal = function (value) {
    if (!value) {
        return "";
    }
    if (!/^(-)?\d+(\.\d+)?$/.test(value)) {
        return "Invalid decimal format";
    }
    return "";
};
/**
 * Validates a hexadecimal value with 16 digits.
 *
 * @param value - The hexadecimal value to validate.
 * @returns An error message if the value is not a 16-digit hexadecimal number, otherwise an empty string.
 */
export var validateHexadecimal16Digit = function (value) {
    var hex16DigitRegex = /^[0-9A-Fa-f]{16}$/;
    if (!hex16DigitRegex.test(value)) {
        return "The value must be a 16-digit hexadecimal number.";
    }
    return "";
};
/**
 * Validates a date string in dd.mm.yyyy format.
 *
 * @param dateString - The date string to validate.
 * @returns A boolean indicating whether the date string is valid.
 */
export var isValidDateDDMMMYYYY = function (dateString) {
    var dateRegex = /^(\d{2})\-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\-(\d{4})$/i;
    var match = dateRegex.exec(dateString);
    console.log(match);
    if (match) {
        var date = convertDDMMMYYYYtoDate(dateString);
        if (date) {
            return true;
        }
    }
    return false;
};
