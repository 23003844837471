import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ToastProvider, Toast } from '@metsooutotec/modes-react-components';
import { BrowserRouter } from 'react-router-dom';
import AuthUserProvider from './auth/AuthUserProvider';
import { I18nProvider } from 'i18n/i18n';
import MainLayout from 'layouts/MainLayout';
import { AppRoutes } from './AppRoutes';
import './App.css';
function App() {
    var dismissText = "DISMISS";
    return (_jsx(AuthUserProvider, { children: _jsx(BrowserRouter, { children: _jsx(I18nProvider, { children: _jsx(ToastProvider, { children: _jsxs(MainLayout, { children: [_jsx(AppRoutes, {}), _jsx(Toast, { dismissText: dismissText })] }) }) }) }) }));
}
export default App;
