var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, PageTitle, Tab, useIntl } from "@metsooutotec/modes-react-components";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import customerStore from "stores/customer-stores/customer-store";
import provisionEventStore from "stores/provisions/provision-event-store";
import { useNavigate } from "react-router-dom";
import { provisionEventsLinkGenerator } from "components/provisions/provisions-utils/provision-events-link-generator";
import { convertDateToDDMMMYY } from "../../../utils/date-formatter-utils";
var inlineStyles = "\n.width50 {\n    width: 50%\n}\n.tab-align-in-card-header {\n    padding-bottom: 0px !important;\n    padding-top: 22px !important;\n}\n.list-align-in-card-content {\n    padding-left: 0px !important;\n    padding-right: 0px !important;\n}\n\n.wrapper> div {\n    width: 100% !important;\n    height: 100% !important;\n}\n\n.wrapper svg {\n    width: 100% !important;\n    height: 100% !important;\n}\n";
var TabContent = function (_a) {
    var indexAssetSection = _a.indexAssetSection, customer = _a.customer, site = _a.site, asset = _a.asset, eventId = _a.eventId;
    var navigate = useNavigate();
    var assetSections = asset.assetSections;
    var assetSectionLabels = assetSections.map(function (s) { return s.name; });
    return (_jsx(Tab, { as: "div", "data-cy": "tab", activeIndex: indexAssetSection, onTabChange: function (e, d) {
            var nextIndex = Number(d.activeIndex);
            var nextAssetSection = assetSections[nextIndex];
            if (nextAssetSection) {
                navigate(provisionEventsLinkGenerator(customer, site, asset, nextAssetSection, { id: eventId || 'new-event' }, '/edit'));
            }
        }, menu: {
            pointing: true,
            secondary: true
        }, style: { width: '100%' }, panes: assetSectionLabels.map(function (asl) {
            return { menuItem: asl };
        }) }));
};
export var ProvisionEventListEditCommission = observer(function (props) {
    var customerId = props.customerId, siteId = props.siteId, assetSectionId = props.assetSectionId, assetId = props.assetId, provisionEvent = props.provisionEvent;
    var formatMessage = useIntl().formatMessage;
    var navigate = useNavigate();
    var editingProvisionEventDetail = provisionEventStore.editingProvisionEventDetail, provisionEventsDetail = provisionEventStore.provisionEventsDetail, editingProvisionId = provisionEventStore.editingProvisionId, saveProvisionEvent = provisionEventStore.saveProvisionEvent;
    var customerMap = customerStore.customerMap, siteMap = customerStore.siteMap, assetMap = customerStore.assetMap, assetSectionMap = customerStore.assetSectionMap;
    var _a = __read(useState(false), 2), isCreateModalOpen = _a[0], setIsSaveModalOpen = _a[1];
    var _b = __read(useState(false), 2), isLeaveModalOpen = _b[0], setIsLeaveModalOpen = _b[1];
    var _c = __read(useState({
        liners: [],
        sensors: [],
    }), 2), selected = _c[0], setSelected = _c[1];
    useEffect(function () {
        setSelected({
            liners: provisionEvent.liners,
            sensors: provisionEvent.sensors
        });
    }, [provisionEvent]);
    var customer = customerMap[customerId];
    var site = siteMap[siteId];
    var asset = assetMap[assetId];
    var assetSections = asset.assetSections;
    // const assetSection = assetSectionMap[assetSectionId]
    // Use zero index if not found (i.e. route does not include asset selection)
    var indexAssetSection = assetSections && assetSections.length ? Math.max(assetSections.findIndex(function (s) { return s.id === assetSectionId; }), 0) : null;
    var currentTabAssetSection = indexAssetSection != null ? assetSections[indexAssetSection] : null;
    var tableContainerStyles = {
        padding: "var(--size-m)",
        height: '91%',
        display: 'flex',
        width: '100%',
        gap: 'var(--size-m)'
    };
    var pageTitle = "".concat(convertDateToDDMMMYY(provisionEvent.eventDate), " / ").concat(provisionEvent.description);
    console.log("HERE");
    return (_jsxs(_Fragment, { children: [_jsx("style", { children: inlineStyles }), _jsx(PageTitle, { title: pageTitle, extraItems: _jsxs(_Fragment, { children: [_jsx(Button, __assign({ icon: "icon-arrow-left-16", labelPosition: "left", onClick: function () { return navigate(provisionEventsLinkGenerator(customer, site, asset)); }, subtle: true }, { children: formatMessage("provisionEvents.edit.back") })), _jsx(Button, __assign({ icon: "icon-plus-16", labelPosition: "left", onClick: function () { }, primary: true }, { children: formatMessage("provisionEvents.edit.save") }))] }) }), _jsx("div", __assign({ style: tableContainerStyles }, { children: _jsx("h1", { children: "TODO" }) }))] }));
});
