var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Button, Dialog, DialogDescription, DialogHeader, Grid, GridRow, Icon, Input, useIntl } from "@metsooutotec/modes-react-components";
import { validateText } from "../../../validators/inputValidators";
import { ModalTypes } from "../customers-utils/customer-modal-helper";
import customerStore from 'stores/customer-stores/customer-store';
import { handleUploadFileClick, resetRecordFormData } from "../../utils/modal-helper";
var AssetModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, asset = _a.asset, siteId = _a.siteId, customerId = _a.customerId;
    var formatMessage = useIntl().formatMessage;
    var _b = __read(useState(false), 2), isLoading = _b[0], setLoading = _b[1];
    var _c = __read(useState(Boolean(asset === null || asset === void 0 ? void 0 : asset.id)), 2), isEditing = _c[0], setIsEditing = _c[1];
    var _d = __read(useState({ isError: false, errorMessage: "" }), 2), isError = _d[0], setIsError = _d[1];
    var _e = __read(useState(false), 2), isFormModified = _e[0], setFormModified = _e[1];
    var _f = __read(useState(null), 2), selectedImage = _f[0], setSelectedImage = _f[1];
    var _g = __read(useState(null), 2), selected3DFile = _g[0], setSelected3DFile = _g[1];
    var _h = __read(useState({
        assetName: (asset === null || asset === void 0 ? void 0 : asset.name) || "",
        assetDescription: (asset === null || asset === void 0 ? void 0 : asset.description) || "",
        uploadImageName: selectedImage === null || selectedImage === void 0 ? void 0 : selectedImage.name,
        upload3DFileName: selectedImage === null || selectedImage === void 0 ? void 0 : selectedImage.name,
    }), 2), formData = _h[0], setFormData = _h[1];
    var _j = __read(useState({
        assetName: "",
        assetDescription: "",
    }), 2), formErrors = _j[0], setFormErrors = _j[1];
    var validateAssetForm = function (formData) {
        var errors = {};
        errors.assetName = validateText(formData.assetName);
        errors.assetDescription = validateText(formData.assetDescription);
        return errors;
    };
    useEffect(function () {
        if (asset) {
            var isSameSite = asset.id === formData.id;
            if (isSameSite)
                return;
            setIsEditing(Boolean(asset.id));
            setFormData({
                id: asset.id,
                assetName: asset.name || "",
                assetDescription: asset.description || "",
            });
        }
        else {
            setIsEditing(false);
            resetRecordFormData(setFormData);
        }
        // Reset the form modification state when the asset changes
        setFormModified(false);
    }, [asset, formData.id]);
    var handleValidation = function (fieldName, validationError) {
        setFormErrors(function (prevErrors) {
            var _a;
            return (__assign(__assign({}, prevErrors), (_a = {}, _a[fieldName] = validationError, _a)));
        });
    };
    var resetForm = function () {
        setIsEditing(false);
        resetRecordFormData(setFormData);
        resetRecordFormData(setFormErrors);
        setSelectedImage(null);
        setSelected3DFile(null);
    };
    var handleChange = function (fieldName, value) {
        isError.isError = false;
        setFormData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[fieldName] = value, _a)));
        });
        // Set the form modification state to true
        setFormModified(true);
    };
    var onCloseProcess = function () {
        resetForm();
        setIsError({ isError: false, errorMessage: "" });
        onClose(false);
    };
    var onClearClick = function (inputId) {
        setFormData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[inputId] = "", _a)));
        });
        setFormErrors(function (prevErrors) {
            var _a;
            return (__assign(__assign({}, prevErrors), (_a = {}, _a[inputId] = "", _a)));
        });
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var errors, hasErrors, assetName, assetDescription, assetData, requestResult, routeParam, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    setLoading(true);
                    // Check if the form has been modified
                    if (isEditing && !isFormModified && !selected3DFile && !selectedImage) {
                        setIsError({
                            isError: true,
                            errorMessage: "No changes made. Please update the form before submitting.",
                        });
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    errors = validateAssetForm(formData);
                    hasErrors = Object.values(errors).some(function (error) { return error !== ""; });
                    if (hasErrors) {
                        setIsError({ isError: true, errorMessage: "There are validation errors, update the form texts" });
                        setFormErrors(errors);
                        setLoading(false); // Reset loading state
                        return [2 /*return*/];
                    }
                    assetName = formData.assetName, assetDescription = formData.assetDescription;
                    assetData = {
                        customerId: customerId || '',
                        siteId: siteId || '',
                        name: assetName,
                        description: assetDescription,
                        assetImage: selectedImage || new File([], 'empty_file'),
                        asset3DFile: selected3DFile || new File([], 'empty_file'),
                    };
                    return [4 /*yield*/, (isEditing
                            ? customerStore.editEntity(__assign(__assign({}, assetData), { id: asset === null || asset === void 0 ? void 0 : asset.id }), ModalTypes.AssetModal)
                            : customerStore.createEntity(assetData, ModalTypes.AssetModal))];
                case 2:
                    requestResult = _a.sent();
                    if (!requestResult.passed) {
                        setIsError({ isError: true, errorMessage: requestResult.message });
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    //Handle success
                    console.log("Form submitted successfully!", requestResult.message, requestResult.entityId);
                    resetForm(); // Reset form data and errors
                    routeParam = { created: !isEditing, edited: isEditing };
                    onClose(true, requestResult.entityId, routeParam);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    setIsError({ isError: true, errorMessage: "Form submission error, please read logs" });
                    console.error("Form submission error:", error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleImageClick = function () {
        handleUploadFileClick(".jpg", setSelectedImage, setFormData, null, "uploadImageName");
    };
    var handle3DFileClick = function () {
        handleUploadFileClick(".obj", setSelected3DFile, setFormData, null, "upload3DFileName");
    };
    var clearImage = function () {
        console.log('unselect:', selectedImage === null || selectedImage === void 0 ? void 0 : selectedImage.name);
        setFormData(function (prevData) { return (__assign(__assign({}, prevData), { uploadImageName: "" })); });
        setSelectedImage(null);
    };
    var clear3DFile = function () {
        console.log('unselect:', selected3DFile === null || selected3DFile === void 0 ? void 0 : selected3DFile.name);
        setFormData(function (prevData) { return (__assign(__assign({}, prevData), { upload3DFileName: "" })); });
        setSelected3DFile(null);
    };
    var labels = {
        assetName: formatMessage("assetModal.name"),
        assetDescription: formatMessage("asset.description"),
        confirm: formatMessage("modal.confirm"),
        cancel: formatMessage("modal.cancel"),
        headerEdit: formatMessage("assetModal.headerEdit"),
        header: formatMessage("assetModal.header"),
        description: formatMessage("modal.description"),
        uploadImage: formatMessage("assetModal.uploadImage"),
        upload3DFile: formatMessage("assetModal.upload3DFile"),
    };
    var inputConfig = [
        { id: "assetName", label: labels.assetName, placeholder: "Type ".concat(labels.assetName, " here..."), type: "string", validate: validateText },
        { id: "assetDescription", label: labels.assetDescription, placeholder: "Type ".concat(labels.assetDescription, " here..."), type: "string", validate: validateText },
        { id: "uploadImageName", label: labels.uploadImage, placeholder: "select from files...", type: "string", onFileUpload: handleImageClick, unSelectFile: clearImage },
        { id: "upload3DFileName", label: labels.upload3DFile, placeholder: "select from files...", type: "string", onFileUpload: handle3DFileClick, unSelectFile: clear3DFile },
    ];
    var renderInputs = function () {
        return (_jsxs(_Fragment, { children: [inputConfig.slice(0, 2).map(function (input, index) {
                    var id = input.id, label = input.label, validate = input.validate, type = input.type, placeholder = input.placeholder;
                    var clearIcon = formData[id] && (_jsx(Icon, { className: "icon-cross-circle-filled-16", onClick: function () { return onClearClick(id); } }));
                    var inputProps = {
                        id: id,
                        label: label,
                        placeholder: placeholder,
                        validate: validate,
                        onValidation: function (validationError) { return handleValidation(id, validationError); },
                        onChange: function (e) { return handleChange(id, e.target.value); },
                        type: type,
                        error: formErrors[id],
                        spanning: true,
                        heightClass: 'medium',
                        required: true,
                        uiRequired: true,
                        value: formData[id],
                        icon: clearIcon
                    };
                    return (_jsx(GridRow, __assign({ style: { marginBottom: "var(--size-m)" } }, { children: _jsx(Input, __assign({}, inputProps)) }), id));
                }), inputConfig.slice(2).map(function (input, index) {
                    var id = input.id, label = input.label, type = input.type, placeholder = input.placeholder, onFileUpload = input.onFileUpload, unSelectFile = input.unSelectFile;
                    var icons = (_jsxs(_Fragment, { children: [formData[id] && (_jsx(Icon, { className: "icon-cross-circle-filled-16", onClick: unSelectFile, style: { marginRight: '2px' } })), _jsx(Icon, { className: "icon-upload-16", onClick: onFileUpload })] }));
                    var inputProps = {
                        id: id,
                        label: label,
                        placeholder: placeholder,
                        onChange: function (e) { return handleChange(id, e.target.value); },
                        type: type,
                        spanning: true,
                        heightClass: 'medium',
                        readOnly: true,
                        onClick: onFileUpload,
                        value: formData[id],
                        icon: icons
                    };
                    return (_jsx(GridRow, __assign({ style: { marginBottom: "var(--size-m)" } }, { children: _jsx(Input, __assign({}, inputProps)) }), id));
                })] }));
    };
    var renderButtons = function () {
        var buttonsConfig = [
            { label: labels.confirm, onClick: handleSubmit, primary: true },
            { label: labels.cancel, onClick: onCloseProcess, primary: false },
        ];
        return buttonsConfig.map(function (button, index) {
            var label = button.label, onClick = button.onClick, primary = button.primary;
            var buttonGridStyle = index === 0 ? {
                marginBottom: "var(--size-m)"
            } : {};
            return (_jsx(GridRow, __assign({ style: buttonGridStyle }, { children: _jsx(Button, { loading: isLoading, disabled: isLoading, onClick: onClick, flexible: true, children: label, centered: true, size: 'medium', primary: primary }) }), label));
        });
    };
    return (_jsx(Grid, { children: _jsxs(Dialog, __assign({ dimmer: true, open: isOpen, size: 'small', style: { maxWidth: "512px" } }, { children: [_jsx(DialogHeader, __assign({ username: '' }, { children: isEditing ? labels.headerEdit : labels.header })), isError.isError && _jsx("p", __assign({ style: { color: 'red' } }, { children: isError.errorMessage })), _jsx(DialogDescription, __assign({ style: { marginBottom: "var(--size-xxl)" } }, { children: labels.description })), renderInputs(), _jsx("div", { style: { marginBottom: "var(--size-xxl)" } }), renderButtons()] })) }));
};
export default AssetModal;
