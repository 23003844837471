var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { makeAutoObservable, observable, runInAction } from 'mobx';
import { getAllCustomers, getSitesForCustomer, postNewCustomer, editCustomer, deleteCustomer, editSite, postNewSite, deleteSite, postNewAsset, editAsset, deleteAsset, postNewAssetSection, deleteAssetSection, editAssetSection } from 'services/customer-services/customer-service';
import { ModalTypes } from '../../components/customers/customers-utils/customer-modal-helper';
var CustomerStore = /** @class */ (function () {
    function CustomerStore() {
        var _this = this;
        this.customerMap = observable.object({});
        this.siteMap = observable.object({});
        this.assetMap = observable.object({});
        this.assetSectionMap = observable.object({});
        this.linerLocationMap = observable.object({});
        this.linerRegionMap = observable.object({});
        this.customersLoading = false;
        this.sitesLoadingForCustomer = observable.object({});
        this.loadAllCustomers = function () { return __awaiter(_this, void 0, void 0, function () {
            var data;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.customersLoading) {
                            return [2 /*return*/];
                        }
                        runInAction(function () {
                            _this.customersLoading = true;
                        });
                        return [4 /*yield*/, getAllCustomers()];
                    case 1:
                        data = _a.sent();
                        runInAction(function () {
                            var e_1, _a, e_2, _b, e_3, _c, e_4, _d, e_5, _e, e_6, _f;
                            try {
                                for (var data_1 = __values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                                    var customer = data_1_1.value;
                                    _this.customerMap[customer.id] = observable.object(customer);
                                    try {
                                        for (var _g = (e_2 = void 0, __values(customer.sites || [])), _h = _g.next(); !_h.done; _h = _g.next()) {
                                            var site = _h.value;
                                            _this.siteMap[site.id] = observable.object(site);
                                            try {
                                                for (var _j = (e_3 = void 0, __values((site.assets || []))), _k = _j.next(); !_k.done; _k = _j.next()) {
                                                    var asset = _k.value;
                                                    _this.assetMap[asset.id] = asset;
                                                    try {
                                                        for (var _l = (e_4 = void 0, __values((asset.assetSections || []))), _m = _l.next(); !_m.done; _m = _l.next()) {
                                                            var assetSection = _m.value;
                                                            _this.assetSectionMap[assetSection.id] = assetSection;
                                                            try {
                                                                for (var _o = (e_5 = void 0, __values((assetSection.linerLocations || []))), _p = _o.next(); !_p.done; _p = _o.next()) {
                                                                    var linerLocation = _p.value;
                                                                    _this.linerLocationMap[linerLocation.id] = linerLocation;
                                                                    try {
                                                                        for (var _q = (e_6 = void 0, __values((linerLocation.linerRegions || []))), _r = _q.next(); !_r.done; _r = _q.next()) {
                                                                            var linerRegion = _r.value;
                                                                            _this.linerRegionMap[linerRegion.id] = linerRegion;
                                                                        }
                                                                    }
                                                                    catch (e_6_1) { e_6 = { error: e_6_1 }; }
                                                                    finally {
                                                                        try {
                                                                            if (_r && !_r.done && (_f = _q.return)) _f.call(_q);
                                                                        }
                                                                        finally { if (e_6) throw e_6.error; }
                                                                    }
                                                                }
                                                            }
                                                            catch (e_5_1) { e_5 = { error: e_5_1 }; }
                                                            finally {
                                                                try {
                                                                    if (_p && !_p.done && (_e = _o.return)) _e.call(_o);
                                                                }
                                                                finally { if (e_5) throw e_5.error; }
                                                            }
                                                        }
                                                    }
                                                    catch (e_4_1) { e_4 = { error: e_4_1 }; }
                                                    finally {
                                                        try {
                                                            if (_m && !_m.done && (_d = _l.return)) _d.call(_l);
                                                        }
                                                        finally { if (e_4) throw e_4.error; }
                                                    }
                                                }
                                            }
                                            catch (e_3_1) { e_3 = { error: e_3_1 }; }
                                            finally {
                                                try {
                                                    if (_k && !_k.done && (_c = _j.return)) _c.call(_j);
                                                }
                                                finally { if (e_3) throw e_3.error; }
                                            }
                                        }
                                    }
                                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                                    finally {
                                        try {
                                            if (_h && !_h.done && (_b = _g.return)) _b.call(_g);
                                        }
                                        finally { if (e_2) throw e_2.error; }
                                    }
                                }
                            }
                            catch (e_1_1) { e_1 = { error: e_1_1 }; }
                            finally {
                                try {
                                    if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
                                }
                                finally { if (e_1) throw e_1.error; }
                            }
                            _this.customersLoading = false;
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        this.loadSitesForCustomer = function (customerId) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.sitesLoadingForCustomer[customerId]) {
                            return [2 /*return*/];
                        }
                        runInAction(function () {
                            _this.sitesLoadingForCustomer[customerId] = true;
                        });
                        return [4 /*yield*/, getSitesForCustomer(customerId)];
                    case 1:
                        data = ((_a.sent()) || []);
                        runInAction(function () {
                            var e_7, _a, e_8, _b, e_9, _c;
                            _this.customerMap[customerId].sites = data;
                            try {
                                for (var data_2 = __values(data), data_2_1 = data_2.next(); !data_2_1.done; data_2_1 = data_2.next()) {
                                    var site = data_2_1.value;
                                    _this.siteMap[site.id] = site;
                                    try {
                                        for (var _d = (e_8 = void 0, __values((site.assets || []))), _e = _d.next(); !_e.done; _e = _d.next()) {
                                            var asset = _e.value;
                                            _this.assetMap[asset.id] = asset;
                                            try {
                                                for (var _f = (e_9 = void 0, __values((asset.assetSections || []))), _g = _f.next(); !_g.done; _g = _f.next()) {
                                                    var assetSection = _g.value;
                                                    _this.assetSectionMap[assetSection.id] = assetSection;
                                                }
                                            }
                                            catch (e_9_1) { e_9 = { error: e_9_1 }; }
                                            finally {
                                                try {
                                                    if (_g && !_g.done && (_c = _f.return)) _c.call(_f);
                                                }
                                                finally { if (e_9) throw e_9.error; }
                                            }
                                        }
                                    }
                                    catch (e_8_1) { e_8 = { error: e_8_1 }; }
                                    finally {
                                        try {
                                            if (_e && !_e.done && (_b = _d.return)) _b.call(_d);
                                        }
                                        finally { if (e_8) throw e_8.error; }
                                    }
                                }
                            }
                            catch (e_7_1) { e_7 = { error: e_7_1 }; }
                            finally {
                                try {
                                    if (data_2_1 && !data_2_1.done && (_a = data_2.return)) _a.call(data_2);
                                }
                                finally { if (e_7) throw e_7.error; }
                            }
                            _this.sitesLoadingForCustomer[customerId] = false;
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        this.createEntity = function (entity, modalType) { return __awaiter(_this, void 0, void 0, function () {
            var modalFunctions, modalFunction, response, createdEntity_1, entityId_1, message, error_1;
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.customersLoading) {
                            return [2 /*return*/, { passed: false, message: "", entityId: "" }];
                        }
                        runInAction(function () {
                            _this.customersLoading = true;
                        });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        modalFunctions = (_a = {},
                            _a[ModalTypes.CustomerModal] = postNewCustomer,
                            _a[ModalTypes.SiteModal] = postNewSite,
                            _a[ModalTypes.AssetModal] = postNewAsset,
                            _a[ModalTypes.AssetSectionModal] = postNewAssetSection,
                            _a);
                        modalFunction = modalFunctions[modalType];
                        return [4 /*yield*/, modalFunction(entity)];
                    case 2:
                        response = _b.sent();
                        if (response.statusCode === 200) {
                            createdEntity_1 = response.result;
                            entityId_1 = createdEntity_1.id;
                            runInAction(function () {
                                var _a, _b, _c;
                                // Add new update when succeed here.
                                switch (modalType) {
                                    case ModalTypes.CustomerModal:
                                        _this.customerMap[entityId_1] = observable.object(createdEntity_1);
                                        break;
                                    case ModalTypes.SiteModal:
                                        {
                                            var site = entity;
                                            var customer = _this.customerMap[site.customerId];
                                            if (customer) {
                                                customer.sites = customer.sites || [];
                                                customer.sites.push(createdEntity_1);
                                                // Add the new site to the siteMap
                                                _this.siteMap[entityId_1] = observable.object(createdEntity_1);
                                            }
                                            break;
                                        }
                                    case ModalTypes.AssetModal:
                                        {
                                            var asset = entity;
                                            var site_1 = _this.siteMap[asset.siteId];
                                            if (site_1) {
                                                site_1.assets = site_1.assets || [];
                                                site_1.assets.push(createdEntity_1);
                                                // Add the new asset to the assetMap
                                                _this.assetMap[entityId_1] = observable.object(createdEntity_1);
                                                // Update the customer's sites list as well
                                                var customer = _this.customerMap[asset.customerId];
                                                if (customer) {
                                                    var updatedSites = (_a = customer.sites) === null || _a === void 0 ? void 0 : _a.map(function (s) {
                                                        if (s.id === site_1.id) {
                                                            var updatedSite = __assign({}, s);
                                                            updatedSite.assets = site_1.assets;
                                                            return updatedSite;
                                                        }
                                                        return s;
                                                    });
                                                    customer.sites = updatedSites;
                                                }
                                            }
                                            break;
                                        }
                                    case ModalTypes.AssetSectionModal:
                                        {
                                            var assetSection = entity;
                                            var asset_1 = _this.assetMap[assetSection.assetId];
                                            var site_2 = _this.siteMap[assetSection.siteId];
                                            if (asset_1) {
                                                asset_1.assetSections = asset_1.assetSections || [];
                                                asset_1.assetSections.push(createdEntity_1);
                                                // Add the new asset section to the assetMap
                                                _this.assetSectionMap[entityId_1] = observable.object(createdEntity_1);
                                                // Update the customer's sites asset list as well                                
                                                if (site_2) {
                                                    var updatedAssets = (_b = site_2.assets) === null || _b === void 0 ? void 0 : _b.map(function (a) {
                                                        if (a.id === asset_1.id) {
                                                            var updatedAsset = __assign({}, a);
                                                            updatedAsset.assetSections = asset_1.assetSections;
                                                            return updatedAsset;
                                                        }
                                                        return a;
                                                    });
                                                    site_2.assets = updatedAssets;
                                                }
                                                // Update the customer's sites list as well
                                                var customer = _this.customerMap[assetSection.customerId];
                                                if (customer && customer.sites) {
                                                    var updatedSites = (_c = customer.sites) === null || _c === void 0 ? void 0 : _c.map(function (s) {
                                                        if (s.id === site_2.id) {
                                                            var updatedSite = __assign({}, s);
                                                            updatedSite.assets = site_2.assets;
                                                            return updatedSite;
                                                        }
                                                        return s;
                                                    });
                                                    customer.sites = updatedSites;
                                                }
                                            }
                                            break;
                                        }
                                    default:
                                        throw new Error("Invalid modal type");
                                }
                            });
                            return [2 /*return*/, { passed: true, message: "", entityId: entityId_1 }];
                        }
                        else if (response.statusCode === 409) {
                            return [2 /*return*/, { passed: false, message: "Conflict-409: an entity with the same name is exist.", entityId: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            return [2 /*return*/, { passed: false, message: message, entityId: "" }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _b.sent();
                        console.error("Error creating entity:", error_1);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs", entityId: "" }];
                    case 4:
                        runInAction(function () {
                            _this.customersLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.editEntity = function (entity, modalType) { return __awaiter(_this, void 0, void 0, function () {
            var modalFunctions, modalFunction, response, entityId_2, message, error_2;
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.customersLoading) {
                            return [2 /*return*/, { passed: false, message: "", entityId: "" }];
                        }
                        runInAction(function () {
                            _this.customersLoading = true;
                        });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        modalFunctions = (_a = {},
                            _a[ModalTypes.CustomerModal] = editCustomer,
                            _a[ModalTypes.SiteModal] = editSite,
                            _a[ModalTypes.AssetModal] = editAsset,
                            _a[ModalTypes.AssetSectionModal] = editAssetSection,
                            _a);
                        modalFunction = modalFunctions[modalType];
                        return [4 /*yield*/, modalFunction(entity)];
                    case 2:
                        response = _b.sent();
                        if (response.statusCode === 200) {
                            entityId_2 = entity === null || entity === void 0 ? void 0 : entity.id;
                            runInAction(function () {
                                var _a, _b, _c;
                                // Add new update when succeed here.
                                switch (modalType) {
                                    case ModalTypes.CustomerModal:
                                        {
                                            var customer = _this.customerMap[entityId_2];
                                            if (customer) {
                                                _this.customerMap[entityId_2] = __assign(__assign({}, customer), entity);
                                            }
                                            ;
                                            break;
                                        }
                                    case ModalTypes.SiteModal:
                                        {
                                            var site = _this.siteMap[entityId_2];
                                            if (site) {
                                                _this.siteMap[entityId_2] = __assign(__assign({}, site), entity);
                                                // Update the customer's sites list as well
                                                var customerId = entity.customerId;
                                                var customer = _this.customerMap[customerId];
                                                if (customer) {
                                                    var updatedSites = (_a = customer.sites) === null || _a === void 0 ? void 0 : _a.map(function (s) {
                                                        return s.id === entityId_2 ? __assign(__assign({}, s), entity) : s;
                                                    });
                                                    customer.sites = updatedSites;
                                                }
                                            }
                                            ;
                                            break;
                                        }
                                    case ModalTypes.AssetModal:
                                        {
                                            var oldAsset = entity;
                                            var asset = _this.assetMap[entityId_2];
                                            if (asset) {
                                                _this.assetMap[entityId_2] = __assign(__assign({}, asset), entity);
                                                // Update the site's assets list as well
                                                var siteId_1 = entity.siteId;
                                                var site = _this.siteMap[siteId_1];
                                                var updatedAssets_1;
                                                if (site) {
                                                    updatedAssets_1 = (_b = site.assets) === null || _b === void 0 ? void 0 : _b.map(function (a) {
                                                        return a.id === entityId_2 ? __assign(__assign({}, a), entity) : a;
                                                    });
                                                    site.assets = updatedAssets_1;
                                                }
                                                // Update the customer's sites list if the asset is associated with a site
                                                var customerId = oldAsset === null || oldAsset === void 0 ? void 0 : oldAsset.customerId;
                                                if (customerId) {
                                                    var customer = _this.customerMap[customerId];
                                                    if (customer) {
                                                        var updatedSites = (_c = customer.sites) === null || _c === void 0 ? void 0 : _c.map(function (s) {
                                                            if (s.id === siteId_1) {
                                                                var updatedSite = __assign({}, s);
                                                                updatedSite.assets = updatedAssets_1;
                                                                return updatedSite;
                                                            }
                                                            return s;
                                                        });
                                                        customer.sites = updatedSites;
                                                    }
                                                }
                                            }
                                            ;
                                            break;
                                        }
                                    case ModalTypes.AssetSectionModal:
                                        {
                                            var assetSection = _this.assetSectionMap[entityId_2];
                                            var editAssetSectionData_1 = entity;
                                            if (assetSection) {
                                                _this.assetSectionMap[entityId_2] = __assign(__assign({}, assetSection), editAssetSectionData_1);
                                                // Update the asset's asset sections list
                                                var assetId_1 = editAssetSectionData_1.assetId;
                                                var asset = _this.assetMap[assetId_1];
                                                if (asset) {
                                                    var updatedAssetSections_1 = asset.assetSections.map(function (as) {
                                                        return as.id === entityId_2 ? __assign(__assign({}, as), editAssetSectionData_1) : as;
                                                    });
                                                    asset.assetSections = updatedAssetSections_1;
                                                    // Update the site's assets list
                                                    var siteId_2 = editAssetSectionData_1.siteId;
                                                    var site = _this.siteMap[siteId_2];
                                                    if (site) {
                                                        var updatedAssets_2 = site.assets.map(function (a) {
                                                            return a.id === assetId_1 ? __assign(__assign({}, a), { assetSections: updatedAssetSections_1 }) : a;
                                                        });
                                                        site.assets = updatedAssets_2;
                                                        // Update the customer's sites list
                                                        var customerId = editAssetSectionData_1.customerId;
                                                        var customer = _this.customerMap[customerId];
                                                        if (customer && customer.sites) {
                                                            var updatedSites = customer.sites.map(function (s) {
                                                                return s.id === siteId_2 ? __assign(__assign({}, s), { assets: updatedAssets_2 }) : s;
                                                            });
                                                            customer.sites = updatedSites;
                                                        }
                                                    }
                                                }
                                            }
                                            ;
                                            break;
                                        }
                                    default:
                                        throw new Error("Invalid modal type");
                                }
                            });
                            return [2 /*return*/, { passed: true, message: "", entityId: entityId_2 }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            return [2 /*return*/, { passed: false, message: message, entityId: "" }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_2 = _b.sent();
                        console.error("Error editing entity:", error_2);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs", entityId: "" }];
                    case 4:
                        runInAction(function () {
                            _this.customersLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.deleteEntity = function (entityId, modalType, customerId, siteId, assetId) { return __awaiter(_this, void 0, void 0, function () {
            var modalFunctions, modalFunction, response, message, error_3;
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.customersLoading) {
                            return [2 /*return*/, { passed: false, message: "" }];
                        }
                        runInAction(function () {
                            _this.customersLoading = true;
                        });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        modalFunctions = (_a = {},
                            _a[ModalTypes.CustomerModal] = deleteCustomer,
                            _a[ModalTypes.SiteModal] = deleteSite,
                            _a[ModalTypes.AssetModal] = deleteAsset,
                            _a[ModalTypes.AssetSectionModal] = deleteAssetSection,
                            _a);
                        modalFunction = modalFunctions[modalType];
                        return [4 /*yield*/, modalFunction(entityId)];
                    case 2:
                        response = _b.sent();
                        if (response.statusCode === 200) {
                            runInAction(function () {
                                // Add new update when succeed here.
                                switch (modalType) {
                                    case ModalTypes.CustomerModal:
                                        {
                                            delete _this.customerMap[entityId];
                                            break;
                                        }
                                    case ModalTypes.SiteModal:
                                        {
                                            delete _this.siteMap[entityId];
                                            // Update the customer's sites list by removing the deleted site
                                            var customer = _this.customerMap[customerId];
                                            if (customer && customer.sites) {
                                                customer.sites = customer.sites.filter(function (s) { return s.id !== entityId; });
                                            }
                                            break;
                                        }
                                    case ModalTypes.AssetModal:
                                        {
                                            // Update the assetSectionMap if the deleted asset had any assetSections
                                            var asset = _this.assetMap[entityId];
                                            if (asset && asset.assetSections) {
                                                asset.assetSections.forEach(function (assetSection) {
                                                    delete _this.assetSectionMap[assetSection.id];
                                                });
                                            }
                                            delete _this.assetMap[entityId];
                                            // Update the site's assets list by removing the deleted asset
                                            var site_3 = _this.siteMap[siteId];
                                            if (site_3 && site_3.assets) {
                                                site_3.assets = site_3.assets.filter(function (a) { return a.id !== entityId; });
                                            }
                                            // Update the customer's sites list if the asset is associated with a site
                                            if (customerId) {
                                                var customer = _this.customerMap[customerId];
                                                if (customer && customer.sites) {
                                                    var updatedSites = customer.sites.map(function (s) {
                                                        if (s.id === siteId) {
                                                            var updatedSite = __assign({}, s);
                                                            updatedSite.assets = site_3.assets;
                                                            return updatedSite;
                                                        }
                                                        return s;
                                                    });
                                                    customer.sites = updatedSites;
                                                }
                                            }
                                            break;
                                        }
                                    case ModalTypes.AssetSectionModal:
                                        {
                                            delete _this.assetSectionMap[entityId];
                                            // Update the asset's assetSections list by removing the deleted asset section
                                            var asset = _this.assetMap[assetId];
                                            if (asset && asset.assetSections) {
                                                asset.assetSections = asset.assetSections.filter(function (as) { return as.id !== entityId; });
                                            }
                                            // Update the site's assets list by removing the deleted asset section
                                            var site = _this.siteMap[siteId];
                                            if (site && site.assets) {
                                                site.assets = site.assets.map(function (a) {
                                                    if (a.id === assetId) {
                                                        var updatedAsset = __assign({}, a);
                                                        updatedAsset.assetSections = updatedAsset.assetSections.filter(function (as) { return as.id !== entityId; });
                                                        return updatedAsset;
                                                    }
                                                    return a;
                                                });
                                            }
                                            // Update the customer's sites list if the asset is associated with a site
                                            var customer = _this.customerMap[customerId];
                                            if (customer && customer.sites) {
                                                var updatedSites = customer.sites.map(function (s) {
                                                    var updatedSite = __assign({}, s);
                                                    updatedSite.assets = updatedSite.assets.map(function (a) {
                                                        if (a.id === assetId) {
                                                            var updatedAsset = __assign({}, a);
                                                            updatedAsset.assetSections = updatedAsset.assetSections.filter(function (as) { return as.id !== entityId; });
                                                            return updatedAsset;
                                                        }
                                                        return a;
                                                    });
                                                    return updatedSite;
                                                });
                                                customer.sites = updatedSites;
                                            }
                                            break;
                                        }
                                }
                                return { passed: true, message: "" };
                            });
                            return [2 /*return*/, { passed: true, message: "" }];
                        }
                        else {
                            message = "An error has occurred, please check the logs";
                            return [2 /*return*/, { passed: false, message: message }];
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_3 = _b.sent();
                        console.error("Error creating customer:", error_3);
                        return [2 /*return*/, { passed: false, message: "An error has occurred, please check the logs" }];
                    case 4:
                        runInAction(function () {
                            _this.customersLoading = false;
                        });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        makeAutoObservable(this);
    }
    Object.defineProperty(CustomerStore.prototype, "customers", {
        get: function () {
            return Object.values(this.customerMap);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerStore.prototype, "sites", {
        get: function () {
            return Object.values(this.siteMap);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerStore.prototype, "assets", {
        get: function () {
            return Object.values(this.assetMap);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerStore.prototype, "assetSections", {
        get: function () {
            return Object.values(this.assetSectionMap);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerStore.prototype, "linerLocations", {
        get: function () {
            return Object.values(this.linerLocationMap);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerStore.prototype, "linerRegions", {
        get: function () {
            return Object.values(this.linerRegionMap);
        },
        enumerable: false,
        configurable: true
    });
    return CustomerStore;
}());
export default new CustomerStore();
