var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { itemViewCellStyles } from "../../common-style/common-style";
export var customerSidebarViewContentStyles = { padding: 0 };
export var assetImageViewStyle = { height: '15.9375em', width: '19.5em' };
export var assetitemViewCellStyles = __assign({}, itemViewCellStyles);
export var assetCellsStyle = __assign(__assign({}, itemViewCellStyles), { verticalAlign: 'top' });
