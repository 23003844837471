var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
// import Draggable from 'react-draggable';
import { Icon } from "@metsooutotec/modes-react-components";
import { CssSVGClasses } from "components/customers/asset-section/editor/editor-utils/editor-types";
import { GetLinerId, StripLastSegment } from "components/customers/asset-section/editor/editor-utils/id-string-utils";
import { SVGFromJson } from "./utils/svg-to-json";
import { parseSync } from 'svgson';
var Draggable = function (_a) {
    var children = _a.children;
    return children;
};
export var ProvisionEventsSVGViewer = function (_a) {
    var assetSectionImage = _a.assetSectionImage, errors = _a.errors, onClick = _a.onClick;
    var _b = __read(useState({ x: 0, y: 0 }), 2), svgCurrentPosition = _b[0], setSvgCurrentPosition = _b[1];
    var _c = __read(useState(1), 2), zoomLevel = _c[0], setZoomLevel = _c[1];
    var _d = __read(useState(false), 2), showBox = _d[0], setShowBox = _d[1];
    var _e = __read(useState(false), 2), showErrors = _e[0], setShowErrors = _e[1];
    var _f = __read(useState({ x: 0, y: 0 }), 2), boxPosition = _f[0], setBoxPosition = _f[1];
    var _g = __read(useState(""), 2), tooltipText = _g[0], setTooltipText = _g[1];
    var zoomButtonsHandlers = {
        zoomIn: function () { setZoomLevel(zoomLevel + 0.1); },
        zoomOut: function () {
            if (zoomLevel > 0.2) {
                setZoomLevel(zoomLevel - 0.1);
            }
        },
        reset: function () {
            setZoomLevel(1);
            setSvgCurrentPosition({ x: 0, y: 0 });
        },
        highlightErrors: function () {
            setShowErrors(function (errState) { return !errState; });
        }
    };
    var mouseReactions = {
        onMouseOver: function (e) {
            var path = e.target;
            var pathId = path.id || ""; // Provide a fallback value
            setTooltipText("");
            if (path instanceof SVGPathElement) {
                if (path.classList.contains(CssSVGClasses.WsLiner)) {
                    var linerName = GetLinerId(pathId);
                    setTooltipText("Liner Id: ".concat(linerName));
                }
                else if (path.classList.contains(CssSVGClasses.WsHole)) {
                    var holeName = StripLastSegment(pathId);
                    setTooltipText("Hole Id: ".concat(holeName));
                }
            }
        },
        onMouseLeave: function () {
            setTooltipText("");
        },
        onMouseDown: function (e) {
        },
        onClick: function (e) {
            var path = e.target;
            if (path instanceof SVGPathElement) {
                if (path.classList.contains(CssSVGClasses.WsLiner)) {
                    if (path.dataset.id) {
                        if (onClick)
                            onClick({ type: "Liner", id: path.dataset.id });
                    }
                }
                else if (path.classList.contains(CssSVGClasses.WsHole)) {
                    if (path.dataset.id) {
                        if (onClick)
                            onClick({ type: "Sensor", id: path.dataset.id });
                    }
                }
            }
        }
    };
    var editBoxComponent = function () {
        return _jsx(Draggable, { children: _jsx("div", { className: "edit-box", style: {
                    left: "".concat((boxPosition === null || boxPosition === void 0 ? void 0 : boxPosition.x) + svgCurrentPosition.x, "px"),
                    top: "".concat((boxPosition === null || boxPosition === void 0 ? void 0 : boxPosition.y) + svgCurrentPosition.y, "px"),
                } }) });
    };
    var errorRender = showErrors && _jsx("div", __assign({ style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
        } }, { children: _jsxs("div", __assign({ style: {
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                width: '95%',
                height: '95%'
            } }, { children: [_jsx("h1", { children: "SVG Errors" }), _jsx("div", __assign({ style: { marginTop: 15 } }, { children: errors.map(function (e, idx) { return _jsx("li", { children: e }, idx); }) }))] })) }));
    return (_jsxs("div", __assign({ style: { width: '100%', height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' } }, { children: [showErrors ? errorRender : _jsx(Draggable
            // position={svgCurrentPosition}
            // onDrag={(e, data) => { setSvgCurrentPosition({ x: data.x, y: data.y }); }}
            , { children: _jsx("div", __assign({ style: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                    } }, { children: _jsx("div", __assign({ onClick: function (e) { return mouseReactions.onClick(e); }, onMouseDown: function (e) { return mouseReactions.onMouseDown(e); }, onMouseOver: function (e) { return mouseReactions.onMouseOver(e); }, onMouseLeave: mouseReactions.onMouseLeave, style: {
                            width: '100%',
                            height: '100%',
                            transform: "scale(".concat(zoomLevel, ")"),
                            transformOrigin: 'top left',
                        } }, { children: assetSectionImage && _jsx(SVGFromJson, { json: parseSync(assetSectionImage) }) })) })) }), showBox && boxPosition && editBoxComponent(), tooltipText && _jsx("div", __assign({ className: "tooltip" }, { children: tooltipText })), _jsxs("div", __assign({ style: {
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "flex-end",
                    gap: 10,
                    margin: "0.5em"
                } }, { children: [errors.length > 0 && _jsx(Icon, { className: "icon-info-o-24 zoom-button red", onClick: zoomButtonsHandlers.highlightErrors }), _jsx(Icon, { className: "icon-zoom-out-24 zoom-button", onClick: zoomButtonsHandlers.zoomOut }), _jsx(Icon, { className: "icon-refresh-24 zoom-button", onClick: zoomButtonsHandlers.reset }), _jsx(Icon, { className: "icon-zoom-in-24 zoom-button", onClick: zoomButtonsHandlers.zoomIn })] }))] })));
};
