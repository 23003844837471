var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
// Define abbreviated month names.
var MonthStrings = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];
// Initialize an empty object to hold the month-to-index mapping.
var MonthValues = {};
// Populate the MonthValues object with month indices.
// Iterates over MonthStrings, assigning each month name to its respective index.
MonthStrings.forEach(function (m, idx) {
    MonthValues[m] = idx;
});
/**
 * Formats a given date into a string representation with either a full year or two-digit year.
 *
 * @param {Date | null} value - The date object to be formatted. If null, an empty string is returned.
 * @param {boolean} fullYear - Flag to determine the year format in the output string.
 *                             If true, the full year (e.g., 2024) is used.
 *                             If false, only the last two digits of the year are used (e.g., 24).
 *
 * @returns {string} A string representation of the date in the format 'DD-MMM-YY' or 'DD-MMM-YYYY'.
 *                   For example, '01-Jan-24' or '01-Jan-2024'.
 *                   Returns an empty string if the input date is null.
 */
var formatDate = function (value, fullYear) {
    try {
        if (value === null)
            return '';
        var date = new Date(value);
        var day = String(date.getDate()).padStart(2, '0');
        var month = MonthStrings[date.getMonth()];
        var year = fullYear ? date.getFullYear() : date.getFullYear().toString().substring(2);
        return "".concat(day, "-").concat(month, "-").concat(year);
    }
    catch (e) {
        console.error('Error converting date:', e); // For debugging
        return "";
    }
};
/**
 * Converts a Date object to a string in the format 'DD-MMM-YYYY'.
 * If the input is null, it returns an empty string.
 * This function utilizes the formatDate utility function with the fullYear parameter set to true.
 *
 * @param {Date | null} value - The date object to be formatted. If null, an empty string is returned.
 *
 * @returns {string} A string representation of the date in the format 'DD-MMM-YYYY'.
 *                   For example, '01-Jan-2024'.
 *                   Returns an empty string if the input date is null.
 */
export var convertDateToDDMMMYYYY = function (value) {
    return formatDate(value, true);
};
/**
 * Converts a Date object to a string in the format 'DD-MMM-YY'.
 * If the input is null, it returns an empty string.
 * This function utilizes the formatDate utility function with the fullYear parameter set to false.
 *
 * @param {Date | null} value - The date object to be formatted. If null, an empty string is returned.
 *
 * @returns {string} A string representation of the date in the format 'DD-MMM-YY'.
 *                   For example, '01-Jan-24'.
 *                   Returns an empty string if the input date is null.
 */
export var convertDateToDDMMMYY = function (value) {
    return formatDate(value, false);
};
/**
 * Converts a date string in the 'DD-MMM-YYYY' format to a JavaScript Date object.
 * If the input string is not in the correct format, or represents an invalid date, the function returns null.
 *
 * @param {string} dateValue - A string representing a date in 'DD-MMM-YYYY' format.
 *                             For example, '01-Jan-2024'.
 *
 * @returns {Date | null} - A JavaScript Date object representing the given date,
 *                          or null if the input is invalid or not in the correct format.
 */
export var convertDDMMMYYYYtoDate = function (dateValue) {
    try {
        var parts = dateValue.trim().split('-');
        if (parts.length !== 3)
            return null;
        var _a = __read(parts, 3), dayStr = _a[0], monthStr = _a[1], yearStr = _a[2];
        // Check if month is valid
        if (!MonthValues.hasOwnProperty(monthStr))
            return null;
        // Check if day and year are valid numbers
        var day = parseInt(dayStr, 10);
        var year = parseInt(yearStr, 10);
        if (isNaN(day) || isNaN(year))
            return null;
        // Create a date and check if it's valid
        var date = new Date(year, MonthValues[monthStr], day);
        if (date.getFullYear() !== year || date.getMonth() !== MonthValues[monthStr] || date.getDate() !== day) {
            return null;
        }
        return date;
    }
    catch (e) {
        console.error('Error converting date:', e); // For debugging
        return null;
    }
};
/**
 * Returns the current date as a string in the format 'DD-MMM-YYYY'.
 * This function uses the convertDateToDDMMMYYYY utility to format the date.
 *
 * @returns {string} The current date in 'DD-MMM-YYYY' format.
 *                   For example, if today is January 1, 2024, it returns '01-Jan-2024'.
 */
export var getTodayDateString = function () {
    return convertDateToDDMMMYYYY(new Date());
};
/**
 * Returns the current date as a Date object.
 *
 * @returns {Date} The current date and time as a JavaScript Date object.
 */
export var getTodayDate = function () {
    return new Date();
};
export var convertDDMMMYYtoDate = function (dateValue) {
    try {
        var trimmedDate = dateValue.trim();
        if (trimmedDate.length === 0)
            return null;
        var parts = trimmedDate.split('-');
        if (parts.length !== 3)
            return null;
        var year = parseInt('20' + parts[2], 10);
        var month = MonthValues[parts[1]];
        var day = parseInt(parts[0], 10);
        if (isNaN(year) || isNaN(month) || isNaN(day))
            return null;
        var date = new Date(year, month, day);
        // Check if the date rolled over to the next month
        if (date.getMonth() !== month || date.getFullYear() !== year || date.getDate() !== day) {
            return null;
        }
        return date;
    }
    catch (e) {
        console.error('Error converting date:', e);
        return null;
    }
};
