export var getCurrentCommissionStateForDecommission = function (events, currentEvent) {
    var currentDate = currentEvent.eventDate;
    var priorEvents = events.filter(function (a) {
        // Use <= otherwise other commission/decommission events on the same day are not processed
        // Maybe this could be smarter?
        if (a.id === currentEvent.id)
            return false;
        return a.eventDate <= currentDate;
    });
    var commissionedLiners = {};
    var commissionedSensors = {};
    var errors = [];
    var eventOrdered = priorEvents.sort(function (a, b) { return a.eventDate < b.eventDate ? -1 : 1; }); //.map(a => JSON.parse(JSON.stringify(a)))
    eventOrdered.forEach(function (event) {
        // Planned doesn't count (for now)
        if (event.type === 'Planned')
            return;
        var isCommission = event.type === 'Commission';
        event.liners.forEach(function (l) {
            if (isCommission) {
                // Commission
                if (commissionedLiners[l.id]) {
                    errors.push({
                        equipment: "liner",
                        id: l.id,
                        reason: "Attempting to commission an already commissioned equipment",
                        event: event
                    });
                }
                commissionedLiners[l.id] = l;
            }
            else {
                // Decommission
                if (!commissionedLiners[l.id]) {
                    errors.push({
                        equipment: "liner",
                        id: l.id,
                        reason: "Attempting to decommission an already decommissioned equipment",
                        event: event
                    });
                }
                delete commissionedLiners[l.id];
            }
        });
        event.sensors.forEach(function (s) {
            if (isCommission) {
                // Commission
                if (commissionedSensors[s.id]) {
                    errors.push({
                        equipment: "sensor",
                        id: s.id,
                        reason: "Attempting to commission an already commissioned equipment",
                        event: event
                    });
                }
                commissionedSensors[s.id] = s;
            }
            else {
                // Decommission
                if (!commissionedSensors[s.id]) {
                    errors.push({
                        equipment: "sensor",
                        id: s.id,
                        reason: "Attempting to decommission an already decommissioned equipment",
                        event: event
                    });
                }
                delete commissionedSensors[s.id];
            }
        });
    });
    return { commissionedLiners: commissionedLiners, commissionedSensors: commissionedSensors, errors: errors };
};
