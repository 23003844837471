var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Button, Dialog, FormattedMessage, Grid, GridRow, Icon, Input, PageTitle, useIntl, DialogDescription, DateTimeInput } from "@metsooutotec/modes-react-components";
import { handleUploadFileClick, resetRecordFormData } from "../../utils/modal-helper";
import { validateDecimal, validateText } from "../../../validators/inputValidators";
import sensorBatchStore from "../../../stores/ioT-stores/sensor-batch-store";
import { IotDevicesModalTypes } from "../iot-devices-utils/iot-entity-modal-helper";
import { convertDateToDDMMMYYYY, getTodayDateString } from "../../utils/date-formatter-utils";
var SensorBatchModal = function (_a) {
    var _b, _c, _d;
    var isOpen = _a.isOpen, onClose = _a.onClose, sensorBatch = _a.sensorBatch;
    var formatMessage = useIntl().formatMessage;
    var _e = __read(useState(false), 2), isLoading = _e[0], setLoading = _e[1];
    var _f = __read(useState({ isError: false, errorMessage: "" }), 2), isError = _f[0], setIsError = _f[1];
    var _g = __read(useState(Boolean(sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.id)), 2), isEditing = _g[0], setIsEditing = _g[1];
    var _h = __read(useState(false), 2), isFormModified = _h[0], setIsFormModified = _h[1];
    var _j = __read(useState({ isError: false, errorMessage: "" }), 2), datePickerError = _j[0], setDatePickerError = _j[1];
    var _k = __read(useState((isEditing) ? convertDateToDDMMMYYYY(sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.batchDate) : getTodayDateString()), 2), batchDate = _k[0], setBatchDate = _k[1];
    var _l = __read(useState(null), 2), selectedPdf = _l[0], setSelectedPdf = _l[1];
    var _m = __read(useState(null), 2), selectedCSV = _m[0], setSelectedCSV = _m[1];
    var _o = __read(useState({
        purchaseOrder: (sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.purchaseOrder) || "",
        manufacturer: (sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.manufacturer) || "",
        hardwareRevision: (sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.hardwareRevision) || "",
        hardwareVariant: (sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.hardwareVariant) || "",
        firmwareRevision: (sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.firmwareRevision) || "",
        description: (sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.description) || "",
        probeOffset: ((_b = sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.probeOffset) === null || _b === void 0 ? void 0 : _b.toString()) || "",
        probeResolution: ((_c = sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.probeResolution) === null || _c === void 0 ? void 0 : _c.toString()) || "",
        probeLength: ((_d = sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.probeLength) === null || _d === void 0 ? void 0 : _d.toString()) || "",
        uploadPdfName: selectedPdf === null || selectedPdf === void 0 ? void 0 : selectedPdf.name,
        uploadCSVName: selectedCSV === null || selectedCSV === void 0 ? void 0 : selectedCSV.name,
    }), 2), formData = _o[0], setFormData = _o[1];
    var _p = __read(useState({
        purchaseOrder: "",
        manufacturer: "",
        hardwareRevision: "",
        hardwareVariant: "",
        firmwareRevision: "",
        description: "",
        probeOffset: "",
        probeResolution: "",
        probeLength: "",
    }), 2), formErrors = _p[0], setFormErrors = _p[1];
    var modalTitle = (isEditing) ? formatMessage("sensorBatchModal.editTitle") : formatMessage("sensorBatchModal.createTitle");
    useEffect(function () {
        var _a, _b, _c;
        if (sensorBatch) {
            var isSameSite = sensorBatch.id === formData.id;
            if (isSameSite)
                return;
            setIsEditing(Boolean(sensorBatch.id));
            setFormData({
                id: sensorBatch.id,
                purchaseOrder: (sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.purchaseOrder) || "",
                manufacturer: (sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.manufacturer) || "",
                hardwareRevision: (sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.hardwareRevision) || "",
                hardwareVariant: (sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.hardwareVariant) || "",
                firmwareRevision: (sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.firmwareRevision) || "",
                description: (sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.description) || "",
                probeOffset: ((_a = sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.probeOffset) === null || _a === void 0 ? void 0 : _a.toString()) || "",
                probeResolution: ((_b = sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.probeResolution) === null || _b === void 0 ? void 0 : _b.toString()) || "",
                probeLength: ((_c = sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.probeLength) === null || _c === void 0 ? void 0 : _c.toString()) || "",
            });
        }
        else {
            setIsEditing(false);
            resetRecordFormData(setFormData);
        }
        // Reset the form modification state when the asset changes
        setIsFormModified(false);
    }, [sensorBatch, formData.id]);
    var validateForm = function (formData) {
        var errors = {};
        errors.purchaseOrder = validateText(formData.purchaseOrder);
        errors.manufacturer = validateText(formData.manufacturer);
        errors.hardwareRevision = validateText(formData.hardwareRevision);
        errors.hardwareVariant = validateText(formData.hardwareVariant);
        errors.firmwareRevision = validateText(formData.firmwareRevision);
        errors.probeOffset = validateDecimal(formData.probeOffset);
        errors.probeResolution = validateDecimal(formData.probeResolution);
        errors.probeLength = validateDecimal(formData.probeLength);
        return errors;
    };
    var handleValidation = useCallback(function (fieldName, validationError) {
        setFormErrors(function (prevErrors) {
            var _a;
            return (__assign(__assign({}, prevErrors), (_a = {}, _a[fieldName] = validationError, _a)));
        });
    }, []);
    var resetForm = function () {
        setIsEditing(false);
        resetRecordFormData(setFormData);
        resetRecordFormData(setFormErrors);
        setSelectedPdf(null);
        setSelectedCSV(null);
    };
    var handleChange = useCallback(function (fieldName, value) {
        isError.isError = false;
        setFormData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[fieldName] = value, _a)));
        });
        setIsFormModified(true);
    }, []);
    var handleDateChange = useCallback(function (value) {
        try {
            setDatePickerError({ isError: false, errorMessage: "" });
            var date = new Date(value);
            if (isNaN(date.getDate())) {
                setDatePickerError({ isError: true, errorMessage: "Invalid Date!" });
            }
            else {
                var formatedDate = convertDateToDDMMMYYYY(date);
                setBatchDate(formatedDate);
                setIsFormModified(true);
            }
        }
        catch (e) {
            console.log(e);
            setDatePickerError({ isError: true, errorMessage: e.message });
        }
    }, []);
    var onCloseProcess = function () {
        resetForm();
        setIsError({ isError: false, errorMessage: "" });
        onClose(false);
    };
    var onClearClick = function (inputId) {
        setFormData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[inputId] = "", _a)));
        });
        setFormErrors(function (prevErrors) {
            var _a;
            return (__assign(__assign({}, prevErrors), (_a = {}, _a[inputId] = "", _a)));
        });
    };
    var handlePdfClick = function () {
        handleUploadFileClick(".pdf", setSelectedPdf, setFormData, null, "uploadPdfName");
    };
    var handleCSVClick = function () {
        handleUploadFileClick(".csv", setSelectedCSV, setFormData, null, "uploadCSVName");
    };
    var clearPdf = function () {
        console.log('unselect:', selectedPdf === null || selectedPdf === void 0 ? void 0 : selectedPdf.name);
        setFormData(function (prevData) { return (__assign(__assign({}, prevData), { uploadImageName: "" })); });
        setSelectedPdf(null);
    };
    var clearCSV = function () {
        console.log('unselect:', selectedCSV === null || selectedCSV === void 0 ? void 0 : selectedCSV.name);
        setFormData(function (prevData) { return (__assign(__assign({}, prevData), { upload3DFileName: "" })); });
        setSelectedCSV(null);
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var errors, hasErrors, manufacturer, purchaseOrder, firmwareRevision, hardwareRevision, hardwareVariant, description, probeOffset, probeResolution, probeLength, sensorBatchDto, requestResult, routeParam, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    setLoading(true);
                    // Check if the form has been modified
                    if (isEditing && !isFormModified && !selectedCSV && !selectedPdf) {
                        setIsError({
                            isError: true,
                            errorMessage: "No changes made. Please update the form before submitting.",
                        });
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    errors = validateForm(formData);
                    hasErrors = Object.values(errors).some(function (error) { return error !== ""; });
                    if (hasErrors || datePickerError.isError) {
                        setIsError({ isError: true, errorMessage: "There are validation errors, update the form texts" });
                        setFormErrors(errors);
                        setLoading(false); // Reset loading state
                        return [2 /*return*/];
                    }
                    manufacturer = formData.manufacturer, purchaseOrder = formData.purchaseOrder, firmwareRevision = formData.firmwareRevision, hardwareRevision = formData.hardwareRevision, hardwareVariant = formData.hardwareVariant, description = formData.description, probeOffset = formData.probeOffset, probeResolution = formData.probeResolution, probeLength = formData.probeLength;
                    sensorBatchDto = {
                        batchDate: batchDate,
                        manufacturer: manufacturer,
                        purchaseOrder: purchaseOrder,
                        hardwareRevision: hardwareRevision,
                        hardwareVariant: hardwareVariant,
                        description: description,
                        probeOffset: parseFloat(probeOffset), probeResolution: parseFloat(probeResolution), probeLength: parseFloat(probeLength),
                        firmwareRevision: firmwareRevision,
                        sensorBatchCsv: selectedCSV || new File([], 'empty_file'),
                        sensorBatchPdf: selectedPdf || new File([], 'empty_file'),
                    };
                    return [4 /*yield*/, (isEditing
                            ? sensorBatchStore.editEntity(__assign(__assign({}, sensorBatchDto), { id: sensorBatch === null || sensorBatch === void 0 ? void 0 : sensorBatch.id }), IotDevicesModalTypes.SensorBatchModal)
                            : sensorBatchStore.createEntity(sensorBatchDto, IotDevicesModalTypes.SensorBatchModal))];
                case 2:
                    requestResult = _a.sent();
                    if (!requestResult.passed) {
                        setIsError({ isError: true, errorMessage: requestResult.message });
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    //Handle success
                    console.log("Form submitted successfully!", requestResult.message, requestResult.entityId);
                    resetForm(); // Reset form data and errors
                    routeParam = { created: !isEditing, edited: isEditing };
                    onClose(true, requestResult.entityId, routeParam);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    setIsError({ isError: true, errorMessage: "Form submission error, please read logs" });
                    console.error("Form submission error:", error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var labels = {
        batchDate: formatMessage("sensorBatchs.batchDate"),
        purchaseOrder: formatMessage("sensorBatchs.purchaseOrder"),
        manufacturer: formatMessage("sensorBatchs.manufacturer"),
        hardwareRevision: formatMessage("sensorBatchs.hardwareRevision"),
        hardwareVariant: formatMessage("sensorBatchs.hardwareVariant"),
        firmwareRevision: formatMessage("sensorBatchs.firmwareRevision"),
        description: formatMessage("sensorBatchs.description"),
        probeOffset: formatMessage("sensorBatchs.probeOffset"),
        probeResolution: formatMessage("sensorBatchs.probeResolution"),
        probeLength: formatMessage("sensorBatchs.probeLength"),
        uploadPdfName: formatMessage("sensorBatchs.uploadPdfName"),
        uploadCSVName: formatMessage("sensorBatchs.uploadCSVName"),
    };
    var inputIdent = [
        { id: "manufacturer", label: labels.manufacturer, placeholder: "Type ".concat(labels.manufacturer, " here..."), type: "string", validate: validateText, isRequired: true },
        { id: "purchaseOrder", label: labels.purchaseOrder, placeholder: "Type ".concat(labels.purchaseOrder, " here..."), type: "string", validate: validateText, isRequired: true },
    ];
    var inputTechDetails = [
        { id: "hardwareRevision", label: labels.hardwareRevision, placeholder: "Type ".concat(labels.hardwareRevision, " here..."), type: "string", validate: validateText, isRequired: true },
        { id: "hardwareVariant", label: labels.hardwareVariant, placeholder: "Type ".concat(labels.hardwareVariant, " here..."), type: "string", validate: validateText, isRequired: true },
        { id: "firmwareRevision", label: labels.firmwareRevision, placeholder: "Type ".concat(labels.firmwareRevision, " here..."), type: "string", validate: validateText, isRequired: true },
        { id: "uploadPdfName", label: labels.uploadPdfName, placeholder: "Select from files...", type: "string", onFileUpload: handlePdfClick, unSelectFile: clearPdf, isRequired: false },
        { id: "uploadCSVName", label: labels.uploadCSVName, placeholder: "Select from files...", type: "string", onFileUpload: handleCSVClick, unSelectFile: clearCSV, isRequired: false },
        { id: "description", label: labels.description, placeholder: "Type ".concat(labels.description, " here..."), type: "string", validate: undefined, isRequired: false },
    ];
    var inputPropsDetails = [
        { id: "probeOffset", label: labels.probeOffset, placeholder: "Type ".concat(labels.probeOffset, " here..."), type: "number", validate: validateDecimal, pattern: "^[0-9]+(\.[0-9]+)?$", step: "0.1", isRequired: true },
        { id: "probeResolution", label: labels.probeResolution, placeholder: "Type ".concat(labels.probeResolution, " here..."), type: "number", validate: validateDecimal, pattern: "^[0-9]+(\.[0-9]+)?$", step: "0.1", isRequired: true },
        { id: "probeLength", label: labels.probeLength, placeholder: "Type ".concat(labels.probeLength, " here..."), type: "number", validate: validateDecimal, pattern: "^[0-9]+(\.[0-9]+)?$", step: "0.1", isRequired: true },
    ];
    var renderInputs = function (inputs) {
        return (_jsx(_Fragment, { children: inputs.map(function (input) {
                var id = input.id, label = input.label, validate = input.validate, type = input.type, placeholder = input.placeholder, isRequired = input.isRequired, pattern = input.pattern, step = input.step;
                var clearIcon = formData[id] && (_jsx(Icon, { className: "icon-cross-circle-filled-16", onClick: function () { return onClearClick(id); } }));
                var inputProps = {
                    id: id,
                    label: label,
                    placeholder: placeholder,
                    validate: validate,
                    onValidation: function (validationError) { return handleValidation(id, validationError); },
                    onChange: function (e) { return handleChange(id, e.target.value); },
                    type: type,
                    error: formErrors[id],
                    heightClass: 'small',
                    spanning: true,
                    required: isRequired,
                    uiRequired: isRequired,
                    icon: clearIcon,
                    value: formData[id],
                    pattern: pattern,
                    step: step,
                };
                return (_jsx("span", __assign({ style: { width: "15em" } }, { children: _jsx(Input, __assign({}, inputProps)) }), id));
            }) }));
    };
    var renderInputIdent = function () {
        return (_jsxs(GridRow, __assign({ style: { gap: "2em" } }, { children: [_jsx("span", __assign({ style: { width: "15em" } }, { children: _jsx(DateTimeInput, { label: labels.batchDate, onChange: function (value) { return handleDateChange(value.toString()); }, value: batchDate, format: "DD-MMM-YYYY", timeFormat: false, error: datePickerError.errorMessage }) })), renderInputs(inputIdent)] })));
    };
    var renderInputTechDetails = function () {
        return (_jsx(_Fragment, { children: _jsxs(GridRow, __assign({ style: { gap: "2em" } }, { children: [renderInputs(inputTechDetails.slice(0, 3)), inputTechDetails.slice(3, 5).map(function (input, index) {
                        var id = input.id, label = input.label, type = input.type, placeholder = input.placeholder, onFileUpload = input.onFileUpload, unSelectFile = input.unSelectFile;
                        var icons = (_jsxs(_Fragment, { children: [formData[id] && (_jsx(Icon, { className: "icon-cross-circle-filled-16", onClick: unSelectFile, style: { marginRight: '2px' } })), _jsx(Icon, { className: "icon-upload-16", onClick: onFileUpload })] }));
                        var inputProps = {
                            id: id,
                            label: label,
                            placeholder: placeholder,
                            onChange: function (e) { return handleChange(id, e.target.value); },
                            type: type,
                            error: formErrors[id],
                            heightClass: 'small',
                            spanning: true,
                            onClick: onFileUpload,
                            value: formData[id],
                            icon: icons,
                            readOnly: true,
                        };
                        return (_jsx("span", __assign({ style: { width: "15em" } }, { children: _jsx(Input, __assign({}, inputProps)) })));
                    }), renderInputs(inputTechDetails.slice(5))] })) }));
    };
    var renderInputPropsDetails = function () {
        return (_jsx(GridRow, __assign({ style: { gap: "2em" } }, { children: renderInputs(inputPropsDetails) })));
    };
    var rightPageButtons = (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ loading: isLoading, disabled: isLoading, icon: "icon-cross-circle-16", labelPosition: "right", onClick: onCloseProcess, secondary: true }, { children: formatMessage("sensorBatchModal.close") })), _jsx(Button, __assign({ loading: isLoading, disabled: isLoading, icon: "icon-save-16", labelPosition: "right", onClick: handleSubmit, primary: true }, { children: formatMessage("sensorBatchModal.save") }))] }));
    var itemViewListContainerStyle = {
        marginTop: "var(--size-l)",
        padding: "0 var(--size-l)",
    };
    var itemViewSeparatorStyle = {
        height: "1px",
        borderBottom: "1px solid #B3B3B3",
        margin: "var(--size-s) 0 ",
    };
    return (_jsxs(Dialog, __assign({ dimmer: true, open: isOpen, size: 'fullscreen' }, { children: [isError.isError && _jsx(DialogDescription, { children: _jsx("p", __assign({ style: { color: 'red' } }, { children: isError.errorMessage })) }), _jsx(PageTitle, { title: modalTitle, extraItems: rightPageButtons }), _jsxs("div", __assign({ style: itemViewListContainerStyle }, { children: [_jsx("h4", { children: _jsx(FormattedMessage, { id: "sensorBatchModal.sensorBatchIden" }) }), _jsx(Grid, __assign({ columns: 3, style: { margin: "1em 0" } }, { children: renderInputIdent() })), _jsx("div", { style: itemViewSeparatorStyle }), _jsx("h4", { children: _jsx(FormattedMessage, { id: "sensorBatchModal.techDetails" }) }), _jsx(Grid, __assign({ columns: 3, style: { margin: "1em 0" } }, { children: renderInputTechDetails() })), _jsx("div", { style: itemViewSeparatorStyle }), _jsx("h4", { children: _jsx(FormattedMessage, { id: "sensorBatchModal.probeDetails" }) }), _jsx(Grid, __assign({ columns: 3, style: { margin: "1em 0" } }, { children: renderInputPropsDetails() }))] })), _jsxs("div", __assign({ style: { width: "100%", textAlign: "center", marginTop: "3em" } }, { children: [_jsx(Icon, { className: "icon-info-o-24" }), " Before you continue, you have to fill in all the required fields."] }))] })));
};
export default SensorBatchModal;
